import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Insight } from '../../../interfaces/insight';
import styles from './insightTooltip.module.scss';
import moment from 'moment-timezone';
import {
  generateScoresSnapshotText,
  generateVitalsSnapshotText,
  insightStringReplacement,
} from '../../../utils/data-parser';
import { useDraggable } from '@dnd-kit/core';
import { InsightTooltipProps } from '../draggable/draggable-insight';
import { getPatientTimezone } from '../../../contexts/PatientContext';

export type Props = InsightTooltipProps & {
  style?: React.CSSProperties;
};

const InsightTooltip: FunctionComponent<Props> = ({ reverse, insight, patient, hide, insightList, style }) => {
  const orgTimezone = getPatientTimezone();
  const [selectedInsight, setSelectedInsight] = useState<Insight>(insight);
  const [insightIndex, setInsightIndex] = useState(0);
  const modal = useRef<HTMLDivElement>(null);
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: insight.id.toString(),
  });

  const handleClickOutside = e => {
    if (modal.current?.contains(e.target) || e.target.parentElement?.id.includes('insight')) {
      return;
    }
    hide();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [insight]);

  useEffect(() => {
    setSelectedInsight(insight);
    setInsightIndex(0);
  }, [insight]);
  const nextInsight = () => {
    const insightPos = insightList.findIndex(value => value.id === selectedInsight.id);
    setSelectedInsight(insightList[insightPos + 1]);
    setInsightIndex(insightPos + 1);
  };

  const prevInsight = () => {
    const insightPos = insightList.findIndex(value => value.id === selectedInsight.id);
    setSelectedInsight(insightList[insightPos - 1]);
    setInsightIndex(insightPos - 1);
  };
  return (
    <div className={reverse ? styles.chartToolTipReverse : styles.chartToolTip} ref={modal} style={style}>
      <a className={styles.btnClose} onClick={() => hide()} />
      <div
        className={`${styles.insightContent} ${isDragging ? styles.dragging : ''}`}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      >
        <div className={styles.snapshotTitle}>
          <h1>
            {selectedInsight &&
              insightStringReplacement(selectedInsight?.title, selectedInsight, patient!, orgTimezone)}
          </h1>
          <span>
            {selectedInsight &&
              insightStringReplacement(selectedInsight?.description, selectedInsight, patient!, orgTimezone)}
          </span>
        </div>

        <div className={styles.snapshot}>
          <h3>Vitals Snapshot</h3>
          <span>{selectedInsight && generateVitalsSnapshotText(selectedInsight)}</span>
        </div>

        <div className={styles.snapshot}>
          <h3>Scores Snapshot</h3>
          <span>{selectedInsight && generateScoresSnapshotText(selectedInsight)}</span>
        </div>
        <div className={styles.insightDate}>
          <h4>
            <span>Insight Recorded: </span>
            {moment.utc(selectedInsight?.generatedTimestamp).tz(orgTimezone).format('MM/DD/YYYY hh:mm a')}
          </h4>
          <div className={styles.pagination}>
            {insightList.length > 1 && insightIndex !== 0 && (
              <span onClick={prevInsight} className={styles.prevArrow}>
                <i />
              </span>
            )}
            Insight {insightIndex + 1} of {insightList.length}
            {insightList.length > 1 && insightIndex !== insightList.length - 1 && (
              <span onClick={nextInsight} className={styles.nextArrow}>
                <i />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightTooltip;
