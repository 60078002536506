/* eslint-disable guard-for-in */
import moment from 'moment-timezone';
import { isReportInsight } from '../components/utilization-review/types-helper';
import { Name } from '../interfaces/common';
import { Insight } from '../interfaces/insight';
import { Patient, PhonePermissions } from '../interfaces/patient';
import { ReportInsight } from '../interfaces/report';
import { INSIGHT_STRING_REPLACEMENT, PATIENT_STATUS, RELAPSE_RISK_SCORE_RANGE, WeekDay } from './constants';

export const capitalizeWord = (word: string): string => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

export const parseTotalInches = (heightStr: string): number => {
  const height = heightStr.split("'");
  return parseInt(height[0], 10) * 12 + parseInt(height[1], 10);
};

export const parseTotalInchesToOriginalInput = (height: number): string => {
  if (!height) return '';

  let foot: number | string = Math.floor(height / 12);
  let inches: number | string = height % 12;
  if (foot < 10) {
    foot = `0${foot}`;
  }
  if (inches < 10) {
    inches = `0${inches}`;
  }

  return `${foot}'${inches}''`;
};

export const parseTimeSpent = (timeSpent: number) => {
  const hours = Math.floor(timeSpent / 60);
  const minutes = timeSpent % 60;
  if (hours === 0) {
    return `${timeSpent % 60} minutes`;
  }
  return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${minutes > 0 ? `${minutes} min` : ''}`;
};

export const parseTimeSpentShort = (timeSpent: number) => {
  const hours = Math.floor(timeSpent / 60);
  //changed code here
  //rounded minutes to neaerest whole number
  const minutes = Math.floor(timeSpent % 60);
  if (hours === 0) {
    return `${timeSpent % 60}m`;
  }
  return `${hours}h ${minutes > 0 ? `${minutes}m` : ''}`;
};

export const getPatientStatus = (score: number): string =>
  score < RELAPSE_RISK_SCORE_RANGE.AT_RISK.MIN
    ? PATIENT_STATUS.STABLE
    : score < RELAPSE_RISK_SCORE_RANGE.CRITICAL.MIN
    ? PATIENT_STATUS.AT_RISK
    : PATIENT_STATUS.CRITICAL;

export const generateVitalsSnapshotText = (insight: Insight | ReportInsight): string => {
  const vitalsArr: string[] = [];
  let vitalsStr = '';

  if (insight.hr) vitalsArr.push(`HEART RATE: ${Math.round(insight.hr)} BPM`);
  if (insight.hrv) vitalsArr.push(`HEART RATE VARIABILITY: ${Math.round(insight.hrv)} MS`);
  if (insight.rr) vitalsArr.push(`RESPIRATION: ${Math.round(insight.rr)} BPM`);

  vitalsArr.map((el, idx) => {
    vitalsStr += el;
    if (vitalsArr.length - 1 !== idx) vitalsStr += ', ';
  });

  return vitalsStr;
};

export const generateScoresSnapshotText = (insight: Insight | ReportInsight): string => {
  const scoresArr: string[] = [];
  let scoresStr = '';

  if (insight.viviHealthScore) scoresArr.push(`VIVIHEALTH SCORE: ${Math.round(insight.viviHealthScore)}`);
  if (insight.engagement) scoresArr.push(`ENGAGEMENT: ${Math.round(insight.engagement)}`);
  if (insight.stress) scoresArr.push(`STRESS: ${Math.round(insight.stress)}`);
  if (isReportInsight(insight) && insight.activity) scoresArr.push(`ACTIVITY SCORE: ${Math.round(insight.activity)}`);
  if (!isReportInsight(insight) && insight.exercise) scoresArr.push(`ACTIVITY SCORE: ${Math.round(insight.exercise)}`);
  if (insight.sleep) scoresArr.push(`SLEEP: ${Math.round(insight.sleep)}`);
  if (insight.relapseRisk) scoresArr.push(`RELAPSE SCORE: ${Math.round(insight.relapseRisk)}`);
  if (insight.adherence) scoresArr.push(`ADHERENCE SCORE: ${Math.round(insight.adherence)}`);
  if (insight.mood) scoresArr.push(`MOOD: ${Math.round(insight.mood)}`);
  if (insight.positiveAffect) scoresArr.push(`POSITIVE AFFECT: ${Math.round(insight.positiveAffect)}`);
  if (insight.negativeAffect) scoresArr.push(`NEGATIVE AFFECT: ${Math.round(insight.negativeAffect)}`);

  scoresArr.map((el, idx) => {
    scoresStr += el;
    if (scoresArr.length - 1 !== idx) scoresStr += ', ';
  });

  return scoresStr;
};

export const insightStringReplacement = (
  text: string,
  insight: Insight | ReportInsight,
  patient: Patient,
  timezone: string,
): string => {
  return text
    .replace(
      new RegExp(`\\b${INSIGHT_STRING_REPLACEMENT.SUBJECT_NAME}\\b`, 'g'),
      `${patient.name.first} ${patient.name.last}`,
    )
    .replace(
      INSIGHT_STRING_REPLACEMENT.START_TIMESTAMP,
      insightDateFormat(insight.startTimestamp ?? insight.generatedTimestamp!, timezone, 'TIMESTAMP'),
    )
    .replace(
      new RegExp(INSIGHT_STRING_REPLACEMENT.TIMESTAMP),
      insightDateFormat(
        insight.endTimestamp!,
        timezone,
        text.includes(INSIGHT_STRING_REPLACEMENT.START_TIMESTAMP) &&
          insight.endTimestamp &&
          moment(insight.endTimestamp).format('L') ===
            moment(insight.startTimestamp ?? insight.generatedTimestamp).format('L')
          ? 'TIME'
          : 'TIMESTAMP',
      ),
    )
    .replace(
      new RegExp(`\\b${INSIGHT_STRING_REPLACEMENT.DATE}\\b`, 'gm'),
      insightDateFormat(insight.endTimestamp!, timezone, 'DATE'),
    )
    .replace(
      INSIGHT_STRING_REPLACEMENT.START_DATE,
      insightDateFormat(insight.startTimestamp ?? insight.generatedTimestamp!, timezone, 'DATE'),
    )
    .replace(
      INSIGHT_STRING_REPLACEMENT.START_TIME,
      insightDateFormat(insight.startTimestamp ?? insight.generatedTimestamp!, timezone, 'TIME'),
    )
    .replace(
      new RegExp(`^${INSIGHT_STRING_REPLACEMENT.TIME}$`),
      insightDateFormat(insight.endTimestamp!, timezone, 'TIME'),
    );
};

const insightDateFormat = (timestamp: string, timezone: string, format: string): string => {
  const date = moment.utc(timestamp).tz(timezone);
  if (format === 'TIMESTAMP') {
    return date.format('LLLL');
  }
  if (format === 'DATE') {
    return date.format('ll').split(',')[0];
  }
  if (format === 'TIME') {
    return date.format('LT');
  }
  return date.format('ll');
};

export const displayRepeatInfo = (repeatWeekDays: string, repeatEndDate: string) => {
  const daysDisplay = repeatWeekDays
    ? repeatWeekDays
        .split('')
        .map(day => WeekDay[day])
        .join(', ')
    : null;
  const endDate = moment(repeatEndDate).isValid() ? repeatEndDate : '';
  return daysDisplay
    ? `Every ${daysDisplay} - ${endDate ? `ending ${moment(endDate).format('MM/DD')}` : 'ends never'}`
    : 'Does not repeat';
};

export const getWeekDayInitial = (day: string): string => {
  switch (day) {
    case 'Sunday':
      return 'Su';
    case 'Monday':
      return 'M';
    case 'Tuesday':
      return 'Tu';
    case 'Wednesday':
      return 'W';
    case 'Thursday':
      return 'Th';
    case 'Friday':
      return 'F';
    case 'Saturday':
      return 'Sa';
    default:
      return '';
  }
};

export const displayDateTimeFormat = (date: string | number, timezone: string): string => {
  return `${moment.utc(date).tz(timezone).format('L')} at ${moment.utc(date).tz(timezone).format('h mm A')}`;
};

export const displayTableDateTimeFormat = (date: string | number, timezone: string): string => {
  return moment.utc(date).tz(timezone).format('lll z');
};

export const displayTableDateFormat = (date: string | number, timezone: string): string => {
  return moment.utc(date).tz(timezone).format('L');
};

export const displayFullName = (name: Name): string => {
  return `${name.prefix ?? ''}${name.first ?? ''} ${name.last ?? ''}`;
};

export const displayDeviceType = ({ type, firmwareVersion }: { type: string; firmwareVersion?: string }): string => {
  const version = firmwareVersion ? `- v${firmwareVersion}` : '';
  return `${capitalizeWord(type)} ${version}`;
};

export const chunkArray = (array: unknown[], size: number): Array<unknown[]> => {
  const chunkedArr: Array<unknown[]> = [];
  const copied = [...array];
  const numOfChild = Math.ceil(copied.length / size);
  for (let i = 0; i < numOfChild; i++) {
    chunkedArr.push(copied.splice(0, size));
  }
  return chunkedArr;
};

export const parseRelapseRisk = (score: number): string => {
  if (score < 25) return 'LOW';
  if (score < 55) return 'MODERATE';
  return 'HIGH';
};

export const boldQueryString = (text: string, query: string) => {
  const res = new RegExp(query, 'g');
  return text.replace(res, `<b>${query}</b>`);
};

export const generateDescriptionWithTimestamps = (
  description: string,
  timezone: string,
  timestamps?: { [key: string]: string },
): string => {
  let textToReplace = description;
  if (timestamps) {
    for (const ts in timestamps) {
      textToReplace = textToReplace.replace(ts, moment.utc(timestamps[ts]).tz(timezone).format('lll z'));
    }
  }
  return textToReplace;
};

export const getSessionStorageOrDefault = <T>(key: string, defaultValue: T): T => {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
};

export const roundNumber = (number: number, precision: number) => {
  return +(Math.round(+`${number}e${precision}`) + 'e-' + precision);
};

export const isPermissionsActive = (patientPhonePermissions: PhonePermissions): boolean => {
  return Object.values(patientPhonePermissions.permissions).every(value => ['granted', 'always'].includes(value));
};

export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = '';
  const bytes = [].slice.call(new Uint8Array(buffer));

  bytes.forEach(b => (binary += String.fromCharCode(b)));

  return window.btoa(binary);
};
