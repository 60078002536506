import React, { useState, useEffect, useContext, FC } from 'react';
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, ReferenceLine } from 'recharts';
import moment from 'moment-timezone';
import { curveLinear } from 'd3-shape';

import styles from '../chart.module.scss';
import { getPatientTimezone, PatientContext } from '../../../../contexts/PatientContext';
import { getTicksH, getTicksDD } from '../utlity';
import { getActivityScore, getExerciseScores } from '../../../../services/api/chart';
import { GRAPH_REVERSE_TOOLTIP_COORDINATE, CHARTS_SIZES } from '../../../../utils/constants';
import { Tooltip as InfoTooltip } from '../../tooltip';
import InfoBox from './info-box';
import ActivityTooltip from './activity-tooltip';

type ActivityChartDto = {
  ts: number;
  activityLevel?: number;
  activityScore?: number;
};

type Props = {
  start: string;
  end: string;
  urData?: { ts: string; value: number }[];
  isPdf?: boolean;
  reference?: number;
};

export const ExerciseChart: FC<Props> = ({ start, end, urData, isPdf, reference }): JSX.Element => {
  const patientTimezone = getPatientTimezone();
  const [data, setData] = useState<Array<ActivityChartDto>>([]);
  const { patient } = useContext(PatientContext);
  const GRAPH_WIDTH_PX = urData ? CHARTS_SIZES.UR_WIDTH : CHARTS_SIZES.CDP_WIDTH;
  const GRAPH_HEIGHT_PX = urData ? CHARTS_SIZES.UR_HEIGHT : CHARTS_SIZES.CDP_HEIGHT;
  let unsubscribeExerciseScore: () => void = () => {};

  const parseExerciseScores = () => {
    const parsedData =
      urData?.map?.(entry => ({
        activityLevel: entry.value,
        activityScore: entry.value,
        ts: moment(entry.ts).tz(patientTimezone).unix() * 1000,
      })) || [];

    setData(parsedData!);
  };

  const getAndParseScores = async () => {
    const startDate = moment(start).format('YYYY-MM-DD');
    const endDate = moment(end).tz(patientTimezone).format('YYYY-MM-DD');
    const [activityScoreData, activityLevelData] = await Promise.allSettled([
      getActivityScore(patient!.id, Math.floor(GRAPH_WIDTH_PX / 4), {
        startDate,
        endDate,
        timezone: patientTimezone,
      }),
      getExerciseScores(patient!.id, Math.floor(GRAPH_WIDTH_PX / 4), {
        startDate,
        endDate,
        timezone: patientTimezone,
      }),
    ]);
    const map = new Map<string, ActivityChartDto>();
    if (activityLevelData.status === 'fulfilled') {
      activityLevelData.value.data.points.forEach(point =>
        map.set(point.ts, {
          ts: moment.utc(point.ts).tz(patientTimezone).unix() * 1000,
          activityLevel: point.value,
        }),
      );
    }
    
    //if (activityScoreData.status === 'fulfilled') {
    // if (activityScoreData.status === 'fulfilled') {
    //   activityScoreData.value.data.points.forEach(point => {
    //     if (map.has(point.ts)) {
    //       Object.assign(map.get(point.ts), { activityScore: point.activityScore });
    //     } else {
    //       map.set(point.ts, {
    //         ts: moment.utc(point.ts).tz(patientTimezone).unix() * 1000,
    //         activityScore: point.activityScore,
    //       });
    //     }
    //   });
    // }
    setData(Array.from(map.values()));
  };

  useEffect(() => {
    if (patient) {
      if (!urData) {
        getAndParseScores();
      } else {
        parseExerciseScores();
      }
    }

    return (): void => {
      unsubscribeExerciseScore();
    };
  }, [patient, start, end]);

  const YAxisFormatter = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { coordinate: number; value: number; offset: number };
  }): JSX.Element => {
    const { value } = payload;
    if (!y || !x) {
      return <text />;
    }
    if (value === 0 && !urData) {
      return (
        <text
          fontSize='10'
          x={x - 36}
          y={y}
          textAnchor='start'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          Dec. {value}
        </text>
      );
    }
    if (value === 100 && !urData) {
      return (
        <text
          fontSize='10'
          x={x - 42}
          y={y + 7}
          textAnchor='start'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          Inc. {value}
        </text>
      );
    }
    return (
      <text
        fontSize='10'
        x={x - 7}
        y={y + 4}
        textAnchor='end'
        color='#444444'
        fontWeight='normal'
        fontFamily='sans-serif'
      >
        {value}
      </text>
    );
  };

  const customToolTip = ({
    active,
    coordinate,
    payload,
    label,
  }: {
    active: boolean;
    coordinate: { x: number; y: number };
    label: number;
    payload: Array<any>;
  }): JSX.Element | null => {
    if (!payload?.[0]?.payload && !active) {
      return null;
    }
    const reverse = coordinate.x > GRAPH_REVERSE_TOOLTIP_COORDINATE;
    const value = payload?.[0]?.payload ?? {};
    return (
      <ActivityTooltip
        date={value?.ts}
        value={{ activityScore: value.activityScore, activityLevel: value.activityLevel }}
        reverse={reverse}
      />
    );
  };

  return (
    <div id={`exerciseChart${isPdf ? '__pdf' : ''}`} style={isPdf ? { display: 'none' } : {}}>
      <div className={styles.chartHeader}>
        <span className={styles.chartTitle}>
          ACTIVITY LEVELS <span style={{ color: '#417EB9' }}>(Blue)</span> & ACTIVITY SCORE{' '}
          <span style={{ color: '#219653' }}>(Green)</span>
        </span>
        {!urData && (
          <InfoTooltip baseStyles={`${styles.infoTooltip} ${styles.activity}`} type='left' background='#F5F6FA'>
            <InfoBox />
          </InfoTooltip>
        )}
      </div>
      <AreaChart
        width={isPdf ? CHARTS_SIZES.UR_PDF_WIDTH : GRAPH_WIDTH_PX}
        height={isPdf ? CHARTS_SIZES.UR_PDF_HEIGHT : GRAPH_HEIGHT_PX}
        data={data}
      >
        <defs>
          <linearGradient id='d1' x1={0} y1={0} x2={0} y2={1}>
            <stop offset='3%' stopColor='#417EB9' stopOpacity={0.8} />
            <stop offset='97%' stopColor='rgba(65, 126, 185, 0.2)' stopOpacity={0} />
          </linearGradient>
          {!urData && (
            <linearGradient id='d2' x1={0} y1={0} x2={0} y2={1}>
              <stop offset='3%' stopColor='#219653' stopOpacity={0.8} />
              <stop offset='97%' stopColor='rgba(65, 126, 185, 0.2)' stopOpacity={0} />
            </linearGradient>
          )}
        </defs>
        {!urData && (
          <XAxis
            xAxisId={0}
            dy={-10}
            dx={2}
            style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            tickSize={16}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!urData && (
          <XAxis
            xAxisId={0}
            dy={-18}
            dx={0}
            style={{
              fontSize: '11',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: '#000000',
            }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!urData && (
          <XAxis
            xAxisId={0}
            dy={-34}
            dx={0}
            style={{ fontSize: '11', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && (
          <XAxis
            xAxisId={0}
            dy={-10}
            dx={2}
            style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={true}
            tickSize={16}
            tickFormatter={(unixTime): string => moment.utc(unixTime).tz(patientTimezone).format('hA')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && (
          <XAxis
            xAxisId={2}
            dy={-18}
            dx={0}
            style={{
              fontSize: '11',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: '#000000',
            }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(unixTime): string => moment.utc(unixTime).format('ddd')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && (
          <XAxis
            xAxisId={3}
            dy={-34}
            dx={0}
            style={{ fontSize: '11', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(unixTime): string => moment.utc(unixTime).format('M/D')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && reference && <ReferenceLine y={reference} stroke='green' strokeWidth={1.2} />}
        <YAxis tick={YAxisFormatter} interval={0} tickSize={0} ticks={[100, 75, 50, 25, 0]} />y
        <CartesianGrid stroke='#A3A6B3' />
        {!urData && <Tooltip content={customToolTip} allowEscapeViewBox={{ x: false, y: true }} />}
        <Area type={curveLinear} dataKey='activityLevel' stroke='#417EB9' fill='url(#d1)' strokeWidth='2' />
        {!urData && (
          <Area type={curveLinear} dataKey='activityScore' stroke='#219653' fill='url(#d2)' strokeWidth='2' />
        )}
      </AreaChart>
    </div>
  );
};
