import React, { FunctionComponent } from 'react';
import styles from '../upsert-milestone-plan-container.module.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import { renderTrackVerticalDefault } from 'react-custom-scrollbars/lib/Scrollbars/defaultRenderElements';
import { Patient } from '../../../../interfaces/patient';
import { displayFullName } from '../../../../utils/data-parser';

type MilestonePlanProps = {
  patient: Patient;
};

const MilestonePlanDetail: FunctionComponent<MilestonePlanProps> = ({ patient }) => {
  return (
    <div>
      <p className={styles.body}>
        To construct your client’s recovery milestone plan, drag items from the list on the left to the client’s
        milestone plan on the right. You may use the recovery milestones provided or add your own custom recovery
        milestones.
      </p>
      <div className={styles.milestoneContainer}>
        <div className={styles.parentMilestoneList}>
          <span className={styles.label}>AVAILABLE RECOVERY MILESTONES (SELECT UP TO 7):</span>
          <Scrollbars
            className={styles.scrollingContainer}
            autoHide={true}
            autoHideTimeout={500}
            autoHeightMax={600}
            renderTrackHorizontal={props => <div {...props} className='track-horizontal' style={{ display: 'none' }} />}
            renderThumbHorizontal={props => <div {...props} className='thumb-horizontal' style={{ display: 'none' }} />}
            renderView={props => <div {...props} className={styles.droppableZone} />}
            renderTrackVertical={props =>
              renderTrackVerticalDefault({
                ...props,
                className: `track-vertical ${styles.trackVerticalCustom}`,
              })
            }
          >
            <div className={styles.draggableItem}>
              <div className={styles.milestoneContent}></div>
            </div>
          </Scrollbars>
        </div>
        <div className={styles.parentMilestoneList}>
          <span className={styles.label}>{displayFullName(patient.name)}'s recovery milestone plan</span>
          <Scrollbars
            className={styles.scrollingContainer}
            autoHide={true}
            renderTrackHorizontal={props => <div {...props} className='track-horizontal' style={{ display: 'none' }} />}
            renderThumbHorizontal={props => <div {...props} className='thumb-horizontal' style={{ display: 'none' }} />}
            renderView={props => <div {...props} className={styles.droppableZone} />}
            renderTrackVertical={props =>
              renderTrackVerticalDefault({
                ...props,
                className: `track-vertical ${styles.trackVerticalCustom}`,
              })
            }
          >
            <div className={styles.draggableItem}>
              <div className={styles.milestoneContent}></div>
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default MilestonePlanDetail;
