import React from 'react';

export const ApprovedIcon = props => {
  return (
    <svg
      width={props.width ?? 30}
      height={props.height ?? 30}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='15'
        cy='15'
        r='14.4375'
        fill={props.color ?? '#78D79B'}
        stroke={props.color ?? '#78D79B'}
        strokeWidth='1.125'
      />
      <path
        d='M9.00007 15.5352L14.4644 20.9994'
        stroke='white'
        strokeWidth='1.875'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.8394 8.25L14.4644 21'
        stroke='white'
        strokeWidth='1.875'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
