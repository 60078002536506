import React from 'react';
import ReactDOM from 'react-dom';
import './fonts/Roboto-Regular.ttf';
import LogRocket from 'logrocket';

import * as Sentry from '@sentry/react';
import { ToastProvider } from 'react-toast-notifications';
import { ApolloProvider } from '@apollo/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { User } from './contexts/User';

import { Toast } from './components/common/toast/toast';
import { apolloClient } from './graphql/apollo-client';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `clinician-app ${process.env.REACT_APP_VERSION}`,
  environment: process.env.REACT_APP_ENVIRONMENT,
  denyUrls: [
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  beforeSend(event: Sentry.Event, hint?: Sentry.EventHint): PromiseLike<Sentry.Event | null> | Sentry.Event | null {
    if (event.user) {
      //Dont send PII data to sentry
      delete event.user.email;
      delete event.user.username;
    }
    return event;
  },
  
});

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('tpphmc/vivihealth');
}

ReactDOM.render(
  <ToastProvider components={{ Toast }}>
    <ApolloProvider client={apolloClient}>
      <User>
        <App />
      </User>
    </ApolloProvider>
  </ToastProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
