import { gql } from '@apollo/client';

export const MY_PRACTITIONER = gql`
  query MyPractitioner {
    myPractitioner {
      id
      firebaseUid
      active
      acceptedTermsOfUse
      name {
        first
        last
        prefix
      }
      contact {
        cellphone
        homePhone
        email
        homeAddress {
          line1
          line2
          city
          state
          postalCode
          country
        }
        workAddress {
          line1
          line2
          city
          state
          postalCode
          country
        }
      }
      organization {
        id
        name
        organizationTheme {
          images {
            name
            url
          }
        }
      }
      roles {
        organizationId
        roles {
          code
          display
        }
      }
      viewSettings {
        triageView
      }
      image
    }
  }
`;
