import { endOfDay, startOfDay, subDays } from 'date-fns';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { getPatientTimezone, PatientContext } from '../../contexts/PatientContext';
import { PractitionerContext } from '../../contexts/Practitioner';
import { firestore } from '../../firebase/firebase';
import { Insight } from '../../interfaces/insight';
import { Practitioner } from '../../interfaces/practitioner';
import { clearAlert } from '../../services/api/insight';
import { getPractitioner } from '../../services/api/practitioner';
import { firebaseSendCurrentScreen } from '../../utils/analytics';
import { PATIENT_STATUS } from '../../utils/constants';
import { getPatientStatus, getSessionStorageOrDefault } from '../../utils/data-parser';
import { useDidMountEffect, useStateWithSessionStorage } from '../../utils/hooks';
import { loading, notAsked, RemoteData } from '../../utils/remote-data';
import {
  EngagementChart,
  EventChart,
  ExerciseChart,
  MoodChart,
  SleepChart,
  StressChart,
  ViviHealthChart,
} from '../common/chart';
import VitalsContainer from '../common/chart/vitals/vitals-container';
import DatePicker, { DateRange } from '../common/date-picker/date-range-picker';
import { DropDown } from '../dropdown';
import { InsightPanel } from '../insight-panel';
import InsightDetailsModal from '../insights/insight-details-modal';
import styles from '../insights/insights.module.scss';
import { LastNotePanel } from '../last-note-panel';
import RelapsePanel from '../relapse-panel/relapse-panel';
import './patient-detail.scss';
import DraggableInsight from '../common/draggable/draggable-insight';
import firebase from 'firebase/app';
import { AdherenceChart } from '../common/chart/adherence-chart/adherence-chart';
import SurveysChartContainer from '../common/chart/surveys/surveys-chart-container';
import { StepsChart } from 'components/common/chart/activity-chart/steps-chart';
import { getHealthbandInfo } from '../../services/api/device';

type SelectedTab = 'Scores' | 'Stats' | 'Vitals' | 'Surveys';

export const PatientDetail = () => {
  const chartContainerRef = useRef<any>(null);
  const scrollingContainerRef = useRef<any>(null);
  const insightLineRef = useRef<HTMLDivElement>(null);
  const { patient } = useContext(PatientContext);
  const { practitioner } = useContext(PractitionerContext);
  const patientTimezone = getPatientTimezone();
  const { addToast } = useToasts();
  const dateRangeStored = getSessionStorageOrDefault<{ startDate: string; endDate: string }>('chartDates', {
    startDate: subDays(startOfDay(new Date()), 6).toDateString(),
    endDate: endOfDay(new Date()).toDateString(),
  });
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>({
    startDate: new Date(dateRangeStored.startDate),
    endDate: new Date(dateRangeStored.endDate),
    key: 'selection',
    color: '#417EB9',
    showDateDisplay: true,
    autoFocus: true,
  });
  const [graphDates, setGraphDates] = useStateWithSessionStorage<{ startDate: string; endDate: string }>('chartDates', {
    startDate: moment().subtract(6, 'days').startOf('day').tz(patientTimezone, true).format(),
    endDate: moment().endOf('day').tz(patientTimezone, true).format(),
  });
  const [selectedTab, setSelectedTab] = useState<SelectedTab>('Stats');

  const [insight, setInsight] = useState<Insight | undefined>(undefined);
  const [showInsightModal, setShowInsightModal] = useState(false);
  const [insightPractitioner, setInsightPractitioner] = useState<RemoteData<Practitioner>>(notAsked());
  const [deviceInfo, setDeviceInfo] = useState<any>();
  const [model, setModel] = useState<string>();
  const [selectedEventsInsight, setSelectedEventsInsight] = useState<
    { selectedInsight: Insight; nearbyInsights: Insight[]; reverse: boolean } | undefined
  >(undefined);

  const getDeviceInfo = async () => {
    if (patient) {
      const data = await getHealthbandInfo(patient.id);
      setDeviceInfo(data);
    }
  };

  useEffect(() => {
    if (patient) {
      getDeviceInfo();
    }
  }, [patient]);

  useEffect(() => {
    if (practitioner) {
      firebaseSendCurrentScreen('ClientDetailPage', {
        practitionerId: practitioner?.id,
        organizationId: practitioner?.organization.id,
        firebaseUid: practitioner?.firebaseUid,
      });
    }
  }, [practitioner]);

  const setLineStyles = (element: HTMLElement): void => {
    const viviHealthChart = (
      document.querySelector('#viviHealthChart') ?? document.querySelector('.scrolling-container')
    )?.getBoundingClientRect();
    element.style.backgroundColor = '#6F727B';
    element.style.width = '2px';
    element.style.position = 'absolute';
    element.style.zIndex = '998';
    element.style.display = 'block';
    element.style.top = `${(viviHealthChart?.top || 0) + window.pageYOffset + 29}px`;
    document.querySelector('body')?.appendChild(element);
  };
  useLayoutEffect(() => {
    // create Lines Now and Latest Insight
    let nowLine = document.querySelector('.nowLine') as HTMLDivElement;
    let insightLine = document.querySelector('.latestInsightLine') as HTMLSpanElement;
    if (!nowLine && !insightLine) {
      nowLine = document.createElement('div');
      insightLine = document.createElement('span');
      nowLine.classList.add('nowLine');
      insightLine.classList.add('latestInsightLine');
      const lines: HTMLElement[] = [nowLine, insightLine];
      for (const lineHtml of lines) {
        setLineStyles(lineHtml);
      }
    }
    // nowLine icon from events chart
    const nowLineIcon = document.querySelector('#nowLine')?.getBoundingClientRect();
    if (nowLineIcon) {
      // get top chart y position
      nowLine.style.display = 'block';
      updatePositionInsightLine(nowLineIcon, nowLine);
    } else {
      nowLine.style.display = 'none';
    }
  }, [graphDates]);

  const handleScroll = e => {
    const { bottom, height } = chartContainerRef.current.getBoundingClientRect();
    const space = window.innerHeight - bottom + height;
    const { clientHeight, scrollHeight } = scrollingContainerRef.current;

    // nowLine icon from events chart
    const nowLineIcon = document.querySelector('#nowLine')?.getBoundingClientRect();
    if (nowLineIcon) {
      // nowLine that pass through graphs
      const nowLine = document.querySelector('.nowLine') as HTMLDivElement;
      updatePositionInsightLine(nowLineIcon, nowLine);
    }

    // Latest insight Line icon from events chart
    const latestInsightIcon = document.querySelector('.latestInsight')?.getBoundingClientRect();
    if (latestInsightIcon) {
      // latest insight line that pass through graphs
      const insightLine = document.querySelector('.latestInsightLine') as HTMLSpanElement;
      updatePositionInsightLine(latestInsightIcon, insightLine);
    }

    // update selected insight line position
    if (insightLineRef.current?.children.length) {
      const chartContainer = document.querySelector('.charts-container')?.getBoundingClientRect();
      insightLineRef.current!.style.height = `${chartContainer!.height - 97}px`;
    }

    // if going smaller set it
    if (space < height) {
      chartContainerRef.current.style = `height: ${space}px; max-height: ${space}px;`;
      return;
    }

    if (clientHeight >= scrollHeight) {
      return;
    }
    chartContainerRef.current.style = `height: ${space}px; max-height: ${space}px;`;
  };

  const updatePositionInsightLine = (nowTimeIcon: DOMRect, insightLine: HTMLElement) => {
    const chartContainer = document.querySelector('.charts-container')?.getBoundingClientRect();
    insightLine.style.height = `${chartContainer!.height - 97}px`;
    insightLine.style.left = `${Math.round(nowTimeIcon?.x || 0) + +window.pageXOffset + 6}px` || '';
  };

  const renderInsightEventComponent = (insight: Insight, nearbyInsights: Insight[]) => {
    const viviHealthChart = (
      document.querySelector('#viviHealthChart') ?? document.querySelector('.scrolling-container')
    )?.getBoundingClientRect();
    const insightChartIcon = document.querySelector(`#insight_${insight.id}`)?.getBoundingClientRect();
    const chartContainer = document.querySelector('.charts-container')?.getBoundingClientRect();
    insightLineRef.current!.style.top = `${(viviHealthChart?.top || 0) + window.pageYOffset + 29}px`;
    insightLineRef.current!.style.height = `${chartContainer!.height - 97}px`;
    insightLineRef.current!.style.left = `${Math.round(insightChartIcon?.x || 0) + +window.pageXOffset + 6}px` || '';
    insightLineRef.current!.style.display = 'block';
    setSelectedEventsInsight({
      selectedInsight: insight,
      nearbyInsights,
      reverse: insightChartIcon!.x > 900,
    });
  };

  const closeInsightEventModal = () => {
    setSelectedEventsInsight(undefined);
    insightLineRef.current!.style.bottom = '';
    insightLineRef.current!.style.display = 'none';
  };

  const removeInsightLines = (): void => {
    document.querySelector('.nowLine')?.remove();
    document.querySelector('.latestInsightLine')?.remove();
  };

  const getPractitionerAction = async (practitionerId: string): Promise<void> => {
    setInsightPractitioner(loading());
    const res = await getPractitioner(practitionerId);
    setInsightPractitioner(res);
  };
  const getPatientStatusElement = (score: number): JSX.Element => {
    const status = getPatientStatus(score);
    if (status === PATIENT_STATUS.AT_RISK) {
      return <span className={styles.statusAtRisk}>AT RISK</span>;
    }
    if (status === PATIENT_STATUS.CRITICAL) {
      return <span className={styles.statusCritical}>CRITICAL</span>;
    }
    return <span className={styles.statusStable}>STABLE</span>;
  };

  const clearAlertAction = async (patientId: string, insightId: number) => {
    const res = await clearAlert(patientId, insightId);
    setShowInsightModal(false);
    if (res.status === 'Done') {
      const currentInsight: Insight = {
        ...insight!,
        clearedAt: moment.utc().format(),
        clearedBy: practitioner!.id,
      };
      setInsight(currentInsight);
      addToast('Alert cleared', {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast(`Unable to clear Alert - Error: ${res.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  useDidMountEffect(() => {
    setGraphDates({
      startDate: moment(dateRangeFilter.startDate).tz(patientTimezone, true).format(),
      endDate: moment(dateRangeFilter.endDate).tz(patientTimezone, true).endOf('day').format(),
    });
  }, [dateRangeFilter]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      // insight lines attached to body so need to be manually removed
      removeInsightLines();
    };
  }, []);

  useEffect(() => {
    if (!patient) {
      return;
    }

    const unsubscribeInsight = firestore
      .collection('users')
      .doc(patient.firebaseUid)
      .collection('insights')
      .doc('current')
      .onSnapshot((Snapshot: firebase.firestore.DocumentSnapshot) => {
        if (Snapshot) {
          const current = Snapshot.data() as {
            alertInsights: Insight[];
            mostRecentInsight: Insight;
          };
          if (current) {
            const currentInsight = current.alertInsights?.length
              ? current.alertInsights[current.alertInsights.length - 1]
              : current.mostRecentInsight;
            if (currentInsight) {
              const { generatedTimestamp, startTimestamp, endTimestamp, clearedAt } = currentInsight;
              currentInsight.generatedTimestamp = generatedTimestamp
                ? moment((generatedTimestamp as any).toDate())
                    .utc()
                    .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
                : (currentInsight.generatedTimestamp as any).toDate();

              currentInsight.startTimestamp = startTimestamp
                ? moment((startTimestamp as any).toDate())
                    .utc()
                    .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
                : (currentInsight.startTimestamp as any)?.toDate();

              currentInsight.endTimestamp = endTimestamp
                ? moment((endTimestamp as any).toDate())
                    .utc()
                    .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
                : (currentInsight.endTimestamp as any).toDate();

              currentInsight.clearedAt = clearedAt
                ? moment((clearedAt as any).toDate())
                    .utc()
                    .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
                : undefined;
            }
            if (![undefined, 'undefined', 'UNDEFINED'].includes(currentInsight.title)) {
              setInsight(currentInsight);
            }
          }
        }
      });

    return (): void => {
      unsubscribeInsight();
    };
  }, [patient]);

  useEffect(() => {
    if (insight && !!insight.clearedBy) {
      getPractitionerAction(insight.clearedBy);
    }
    if (insight) {
      const insightLine = document.querySelector('.latestInsightLine') as HTMLSpanElement;
      // latestInsight icon from events chart
      const insightLatest = document.querySelector('.latestInsight')?.getBoundingClientRect();
      if (insightLatest) {
        insightLine.style.display = 'block';
        insightLine.style.backgroundColor = insight.positive ? '#6fcf97' : '#ed6f6a';
        updatePositionInsightLine(insightLatest, insightLine);
      } else {
        insightLine.style.display = 'none';
      }
    }
  }, [insight, graphDates]);

  return (
    <>
      <div className='detail-container'>
        <div className='top-row'>
          <InsightPanel insight={insight!} practitioner={insightPractitioner} setClearInsight={setShowInsightModal} />
          <LastNotePanel patient={patient} />
          <RelapsePanel patient={patient} />
        </div>
        <div className='chart-container' ref={chartContainerRef}>
          <div className='top-row'>
            <div className='left'>
              <button
                type='button'
                className={selectedTab === 'Stats' ? 'active' : 'inactive'}
                onClick={() => setSelectedTab('Stats')}
              >
                Stats
              </button>
              <button
                type='button'
                className={selectedTab === 'Scores' ? 'active' : 'inactive'}
                onClick={() => setSelectedTab('Scores')}
              >
                Scores
              </button>
              <button
                type='button'
                className={selectedTab === 'Vitals' ? 'active' : 'inactive'}
                onClick={() => setSelectedTab('Vitals')}
              >
                Vitals
              </button>
              <button
                type='button'
                className={selectedTab === 'Surveys' ? 'active' : 'inactive'}
                onClick={() => setSelectedTab('Surveys')}
              >
                Surveys
              </button>
            </div>
            <div>
              <strong>Current Patient Timezone:</strong> {moment().tz(patientTimezone).format('lll z')}
            </div>
            <div className='right'>
              <DropDown contentStyle='filter'>
                <span className='dropdownButton'>
                  {moment.tz(graphDates.startDate, patientTimezone).format('MM/DD/YYYY')} -{' '}
                  {moment.tz(graphDates.endDate, patientTimezone).format('MM/DD/YYYY')}
                  <i className='arrow'>
                    <svg width='14' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M13 1L7 7L1 1' stroke='#417EB9' strokeWidth='1.5' strokeLinecap='round' />
                    </svg>
                  </i>
                </span>
                <>
                  <DatePicker
                    onDateSelectionChange={(res: any) => {
                      setDateRangeFilter(res.selection);
                    }}
                    dateRange={dateRangeFilter}
                    parentStyle='filterDatepicker'
                  />
                </>
              </DropDown>
            </div>
          </div>
          {/* CHARTS */}
          <div className='charts-container'>
            {selectedTab === 'Scores' && (
              <>
                <ViviHealthChart start={graphDates.startDate} end={graphDates.endDate} />
                <div className='scrolling-container' ref={scrollingContainerRef}>
                  <div className='chart-wrapper'>
                    <EngagementChart start={graphDates.startDate} end={graphDates.endDate} />
                  </div>
                  <div className='chart-wrapper'>
                    <AdherenceChart start={graphDates.startDate} end={graphDates.endDate} />
                  </div>
                </div>
              </>
            )}
            {selectedTab === 'Stats' && (
              <>
                <div className='scrolling-container' ref={scrollingContainerRef}>
                  <SleepChart start={graphDates.startDate} end={graphDates.endDate} />
                  <MoodChart start={graphDates.startDate} end={graphDates.endDate} />
                  <StressChart start={graphDates.startDate} end={graphDates.endDate} />
                  {deviceInfo?.status !== 'Done' ? (
                    <div></div>
                  ) : deviceInfo.data.provider === 'APPLE' || deviceInfo.data.provider === 'FITBIT' ? (
                    <StepsChart start={graphDates.startDate} end={graphDates.endDate} patient={patient} />
                  ) : (
                    <ExerciseChart start={graphDates.startDate} end={graphDates.endDate} />
                  )}
                </div>
              </>
            )}
            {selectedTab === 'Vitals' && (
              <div className='scrolling-container' ref={scrollingContainerRef}>
                <VitalsContainer startDate={graphDates.startDate} endDate={graphDates.endDate} />
              </div>
            )}
            {selectedTab === 'Surveys' && (
              <div className='scrolling-container' ref={scrollingContainerRef}>
                <SurveysChartContainer startDate={graphDates.startDate} endDate={graphDates.endDate} />
              </div>
            )}
            <EventChart
              latestInsight={insight}
              start={graphDates.startDate}
              end={graphDates.endDate}
              onInsightClick={renderInsightEventComponent}
              urData={undefined}
              isPdf={undefined}
            />
            <div ref={insightLineRef} className='insight_line'>
              {selectedEventsInsight && (
                <DraggableInsight
                  insight={selectedEventsInsight.selectedInsight}
                  insightList={selectedEventsInsight.nearbyInsights}
                  hide={closeInsightEventModal}
                  patient={patient}
                  reverse={selectedEventsInsight.reverse}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <InsightDetailsModal
        insight={insight}
        hide={() => setShowInsightModal(false)}
        show={showInsightModal}
        practitioner={insightPractitioner}
        patient={patient}
        getPatientStatusElement={getPatientStatusElement}
        clearAlertAction={clearAlertAction}
      />
    </>
  );
};
