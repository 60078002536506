import React, { FunctionComponent } from 'react';
import styles from './search-input.module.scss';

type Props = {
  searchValue: string;
  handleInputChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnClick: (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  placeholder: string;
};

const SearchInput: FunctionComponent<Props> = ({ searchValue, handleInputChange, placeholder, handleOnClick }) => {
  return (
    <span className={styles.inputWrapper}>
      <input
        type='text'
        className={styles.searchInput}
        placeholder={placeholder}
        value={searchValue ?? ''}
        onChange={handleInputChange}
      />
      {!!searchValue.length && (
        <span className={styles.searchDelete} onClick={handleOnClick}>
          &#x2715;
        </span>
      )}
    </span>
  );
};

export default SearchInput;
