import React from 'react';

export const Grid = props => {
  return (
    <svg
      style={props.style}
      width='30'
      height='24'
      viewBox='0 0 30 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={props.onClick}
    >
      <line y1='1.33691' x2='30' y2='1.33691' stroke={props.style.color} strokeWidth='1.5' />
      <line y1='23.25' x2='30' y2='23.25' stroke={props.style.color} strokeWidth='1.5' />
      <line y1='15.9458' x2='30' y2='15.9458' stroke={props.style.color} strokeWidth='1.5' />
      <line y1='8.64111' x2='30' y2='8.64111' stroke={props.style.color} strokeWidth='1.5' />
    </svg>
  );
};
