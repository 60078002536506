export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Instant: any;
  /** A JSON scalar */
  JSON: any;
  LocalDate: any;
  LocalDateTime: any;
  LocalTime: any;
  UUID: any;
  _FieldSet: any;
};

export type AcceptInvitationInput = {
  allowShareData?: InputMaybe<Scalars['Boolean']>;
  invitationId?: InputMaybe<Scalars['Int']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AnswerInput = {
  answerId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['String']>;
  timeSpentSeconds?: InputMaybe<Scalars['Float']>;
  timesAnswered?: InputMaybe<Scalars['Int']>;
  timesSeen?: InputMaybe<Scalars['Int']>;
};

export type AnswerItem = {
  __typename?: 'AnswerItem';
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type AnswerQuestionInputs = {
  answers: Array<AnswerInput>;
  instanceId?: InputMaybe<Scalars['Int']>;
};

export type AnswerQuestionResponse = {
  __typename?: 'AnswerQuestionResponse';
  error?: Maybe<AnswerQuestionResponseError>;
  instance?: Maybe<QuestionnaireInstance>;
};

export enum AnswerQuestionResponseError {
  Duplicated = 'Duplicated',
  NotFound = 'NotFound',
  Unknown = 'Unknown'
}

export type CareTeam = {
  __typename?: 'CareTeam';
  careTeamMembers?: Maybe<Array<Maybe<CareTeamMember>>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['String']>;
};

export type CareTeamMember = {
  __typename?: 'CareTeamMember';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<NameQl>;
  period?: Maybe<PeriodQl>;
  practitioner?: Maybe<Practitioner>;
};

export type ClientCountRequestInput = {
  groups: Array<ClientTelemetryRequest>;
};

export type ClientIntakeByMonth = {
  __typename?: 'ClientIntakeByMonth';
  intakeByMonth: Array<ClientIntakeByMonthRow>;
};

export type ClientIntakeByMonthRow = {
  __typename?: 'ClientIntakeByMonthRow';
  count?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['LocalDate']>;
};

export type ClientStatusReport = {
  __typename?: 'ClientStatusReport';
  count?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ClientTelemetryRequest = {
  higherBound?: InputMaybe<Scalars['Float']>;
  lowerBound?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CodingDto = {
  __typename?: 'CodingDto';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
};

export type ConsumerPatientInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<NameInput>;
};

export type CreatePaymentIntentInput = {
  address?: InputMaybe<ShippingAddressInput>;
  lines?: InputMaybe<Array<InputMaybe<OrderLineInput>>>;
};

export type CreatePaymentIntentResult = {
  __typename?: 'CreatePaymentIntentResult';
  error?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  shippingPrice?: Maybe<Scalars['Float']>;
};

export type CreatePaymentResult = CreatePaymentIntentResult | OperationError;

export type CreateQuestionnaireInstanceInput = {
  fbUserUid: Scalars['String'];
  notificationMessage?: InputMaybe<Scalars['String']>;
  notificationTitle?: InputMaybe<Scalars['String']>;
  questionnaireId: Scalars['Int'];
  timeFromNowInSeconds: Scalars['Int'];
};

export type CreateQuestionnaireInstanceResponse = {
  __typename?: 'CreateQuestionnaireInstanceResponse';
  instance?: Maybe<QuestionnaireInstance>;
};

export type CrudOrderSuccess = {
  __typename?: 'CrudOrderSuccess';
  data?: Maybe<ShopOrder>;
};

export type CrudProductResult = CrudProductSuccess | OperationError;

export type CrudProductSuccess = {
  __typename?: 'CrudProductSuccess';
  product?: Maybe<ViviProduct>;
};

export enum DayOfWeek {
  Fri = 'Fri',
  Mon = 'Mon',
  Sat = 'Sat',
  Sun = 'Sun',
  Thu = 'Thu',
  Tue = 'Tue',
  Wed = 'Wed'
}

export type Device = {
  __typename?: 'Device';
  condition: DeviceCondition;
  deviceModel: DeviceModel;
  firmwareVersion?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  operational: Scalars['Boolean'];
};

export type DeviceAsignation = {
  __typename?: 'DeviceAsignation';
  allCount: Scalars['Int'];
  deviceType: Scalars['String'];
  used: Scalars['Int'];
};

export enum DeviceCondition {
  Bought = 'BOUGHT',
  Broken = 'BROKEN',
  Good = 'GOOD',
  Lost = 'LOST',
  Recycled = 'RECYCLED'
}

export type DeviceModel = {
  __typename?: 'DeviceModel';
  creationTime?: Maybe<Scalars['LocalDateTime']>;
  creatorUid?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  manufacturer: Scalars['String'];
  model: Scalars['String'];
  modelIdentifier: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  numberOfDevices?: Maybe<Scalars['Int']>;
  type?: Maybe<DeviceModelType>;
};

export enum DeviceModelType {
  Bac = 'BAC',
  Patch = 'PATCH',
  Wristband = 'WRISTBAND'
}

export type EocReport = {
  __typename?: 'EOCReport';
  count?: Maybe<Scalars['Int']>;
  episodeOfCareType?: Maybe<Scalars['String']>;
};

export type EpisodeOfCare = {
  __typename?: 'EpisodeOfCare';
  careTeam?: Maybe<CareTeam>;
  dateOfAdmission?: Maybe<Scalars['LocalDateTime']>;
  dateOfDischarge?: Maybe<Scalars['LocalDateTime']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN'
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN'
}


// extended practitioner info
export type ExtendedPractitioner = {
  __typename?: 'ExtendedPractitioner';
  acceptedTermsOfUse?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  activePatientCount?: Maybe<Scalars['Int']>;
  activePatients: Array<Patient>;
  contact?: Maybe<PersonalContactDto>;
  firebaseUid?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  name?: Maybe<NameQl>;
  organization?: Maybe<Organization>;
  roles?: Maybe<PractitionerRoles>;
  viewSettings: PractitionerViewDto;
};

export type ExternalContact = {
  __typename?: 'ExternalContact';
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type JoinInvitation = {
  __typename?: 'JoinInvitation';
  episodeOfCare?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
};

export type LastViewedInfo = {
  __typename?: 'LastViewedInfo';
  date: Scalars['Instant'];
  patientId: Scalars['UUID'];
  practitioner: Practitioner;
  practitionerId: Scalars['UUID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation?: Maybe<Patient>;
  addSchedule?: Maybe<QuestionnaireScheduleCrudResult>;
  answerQuestions?: Maybe<AnswerQuestionResponse>;
  createConsumerPatient?: Maybe<Patient>;
  createPaymentIntent?: Maybe<CreatePaymentResult>;
  createProduct?: Maybe<CrudProductResult>;
  createQuestionnaireInstance?: Maybe<CreateQuestionnaireInstanceResponse>;
  declineInvitation?: Maybe<Scalars['Int']>;
  deleteProduct?: Maybe<CrudProductResult>;
  removeSchedule?: Maybe<QuestionnaireScheduleCrudResult>;
  setOrderShipped?: Maybe<OrderManagmentOpResult>;
  updateProduct?: Maybe<CrudProductResult>;
  updateSchedule?: Maybe<QuestionnaireScheduleCrudResult>;
};


export type MutationAcceptInvitationArgs = {
  input?: InputMaybe<AcceptInvitationInput>;
};


export type MutationAddScheduleArgs = {
  input?: InputMaybe<QuestionnaireScheduleInput>;
};


export type MutationAnswerQuestionsArgs = {
  input?: InputMaybe<AnswerQuestionInputs>;
};


export type MutationCreateConsumerPatientArgs = {
  input?: InputMaybe<ConsumerPatientInput>;
};


export type MutationCreatePaymentIntentArgs = {
  input?: InputMaybe<CreatePaymentIntentInput>;
};


export type MutationCreateProductArgs = {
  input?: InputMaybe<ViviProductInput>;
};


export type MutationCreateQuestionnaireInstanceArgs = {
  input?: InputMaybe<CreateQuestionnaireInstanceInput>;
};


export type MutationDeclineInvitationArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteProductArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationRemoveScheduleArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationSetOrderShippedArgs = {
  id?: InputMaybe<Scalars['String']>;
  shippingCompany?: InputMaybe<Scalars['String']>;
  tracking?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProductArgs = {
  input?: InputMaybe<ViviProductInput>;
};


export type MutationUpdateScheduleArgs = {
  id?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<QuestionnaireScheduleInput>;
};

export type NameInput = {
  first?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
};

export type NameQl = {
  __typename?: 'NameQL';
  first?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
};

export type OperationError = {
  __typename?: 'OperationError';
  code?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type OrderLineInput = {
  productId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Float']>;
  variant?: InputMaybe<ProductVariantInput>;
};

export type OrderManagmentOpResult = CrudOrderSuccess | OperationError;

export type Organization = {
  __typename?: 'Organization';
  /** private final OrganizationSettingsDTO settings; */
  contact?: Maybe<OrganizationContact>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  organizationTheme?: Maybe<OrganizationTheme>;
  primaryContact?: Maybe<PrimaryContact>;
  type?: Maybe<OrganizationType>;
};

export type OrganizationContact = {
  __typename?: 'OrganizationContact';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type OrganizationPeriodReport = {
  __typename?: 'OrganizationPeriodReport';
  activeClients: Scalars['Int'];
  clientTelemetryCounts: Array<ClientStatusReport>;
  clientsByPractitioner: Array<PractitionerClientAssignment>;
  episodesOfCare: Array<EocReport>;
  pendingClients: Scalars['Int'];
};

export type OrganizationReport = {
  __typename?: 'OrganizationReport';
  clientsInTakeByMonth?: Maybe<ClientIntakeByMonth>;
  demographics?: Maybe<PatientDemographics>;
  devices?: Maybe<Array<DeviceAsignation>>;
  organizationId: Scalars['String'];
  periodReport?: Maybe<OrganizationPeriodReport>;
  practitionerByRole?: Maybe<Array<PractitionerRoleCount>>;
  practitionerUsage?: Maybe<PractitionersUsageReport>;
};


export type OrganizationReportClientsInTakeByMonthArgs = {
  monthsBefore?: InputMaybe<Scalars['Int']>;
};


export type OrganizationReportDemographicsArgs = {
  date?: InputMaybe<Scalars['LocalDate']>;
};


export type OrganizationReportPeriodReportArgs = {
  end?: InputMaybe<Scalars['LocalDateTime']>;
  metric?: InputMaybe<PtsMetric>;
  request?: InputMaybe<ClientCountRequestInput>;
  start?: InputMaybe<Scalars['LocalDateTime']>;
};


export type OrganizationReportPractitionerUsageArgs = {
  aggregatePeriodOfDays?: InputMaybe<Array<Scalars['Int']>>;
  daysToCollectData?: InputMaybe<Scalars['Int']>;
  today?: InputMaybe<Scalars['LocalDate']>;
};

export type OrganizationTheme = {
  __typename?: 'OrganizationTheme';
  images?: Maybe<Array<ThemeImage>>;
};

export enum OrganizationType {
  Consumer = 'Consumer',
  Normal = 'Normal'
}

export enum PtsMetric {
  RelapseRisk = 'RelapseRisk'
}

export type Patient = {
  __typename?: 'Patient';
  allEpisodesOfCare: Array<EpisodeOfCare>;
  bacFailureCount?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['LocalDate']>;
  careTeam?: Maybe<CareTeam>;
  contact?: Maybe<PersonalContactDto>;
  dateOfAdmission?: Maybe<Scalars['LocalDate']>;
  devicesIssued?: Maybe<PatientDeviceInfoDto>;
  emergencyContact?: Maybe<ExternalContact>;
  emrIdentifier?: Maybe<Scalars['String']>;
  episodeOfCare?: Maybe<EpisodeOfCare>;
  finishedEpisodesOfCare: Array<EpisodeOfCare>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  join_invites?: Maybe<Array<Maybe<JoinInvitation>>>;
  lastViewed?: Maybe<LastViewedInfo>;
  linkStatus?: Maybe<PatientFirebaseLinkStatus>;
  locationViolationCount?: Maybe<Scalars['Int']>;
  maritalStatus?: Maybe<Scalars['String']>;
  missedMeetingCount?: Maybe<Scalars['Int']>;
  name?: Maybe<NameQl>;
  observations?: Maybe<PatientObservation>;
  organization?: Maybe<Organization>;
  primaryCareContact?: Maybe<ExternalContact>;
  profileImageUrl?: Maybe<Scalars['String']>;
  relapseCount?: Maybe<Scalars['Int']>;
  userConfiguration?: Maybe<UserConfiguration>;
};


export type PatientBacFailureCountArgs = {
  end?: InputMaybe<Scalars['LocalDateTime']>;
  start?: InputMaybe<Scalars['LocalDateTime']>;
};


export type PatientLocationViolationCountArgs = {
  end?: InputMaybe<Scalars['LocalDateTime']>;
  start?: InputMaybe<Scalars['LocalDateTime']>;
};


export type PatientMissedMeetingCountArgs = {
  end?: InputMaybe<Scalars['LocalDateTime']>;
  start?: InputMaybe<Scalars['LocalDateTime']>;
};


export type PatientRelapseCountArgs = {
  end?: InputMaybe<Scalars['LocalDateTime']>;
  start?: InputMaybe<Scalars['LocalDateTime']>;
};

export type PatientDemographicStat = {
  __typename?: 'PatientDemographicStat';
  children?: Maybe<Array<PatientDemographicStat>>;
  count: Scalars['Int'];
  name: Scalars['String'];
  variable: Scalars['String'];
};

export type PatientDemographics = {
  __typename?: 'PatientDemographics';
  items: Array<PatientDemographicStat>;
};

export type PatientDeviceInfoDto = {
  __typename?: 'PatientDeviceInfoDto';
  bacDeviceProvided: Scalars['Boolean'];
  device1?: Maybe<Device>;
  device2?: Maybe<Device>;
  deviceName1?: Maybe<Scalars['String']>;
  deviceName2?: Maybe<Scalars['String']>;
};

export type PatientFirebaseLinkStatus = {
  __typename?: 'PatientFirebaseLinkStatus';
  fbEmail?: Maybe<Scalars['String']>;
  fbUserUid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  userConfiguration?: Maybe<UserConfiguration>;
};

export type PatientInsights = {
  __typename?: 'PatientInsights';
  steps?: Maybe<Array<Maybe<UserStep>>>;
  viviScore?: Maybe<Array<Maybe<PointDto>>>;
};

export type PatientObservation = {
  __typename?: 'PatientObservation';
  height?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
};

export type PeriodQl = {
  __typename?: 'PeriodQL';
  end?: Maybe<Scalars['LocalDateTime']>;
  start?: Maybe<Scalars['LocalDateTime']>;
};

export type PersonalContactDto = {
  __typename?: 'PersonalContactDTO';
  cellphone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  homeAddress?: Maybe<Address>;
  homePhone?: Maybe<Scalars['String']>;
  workAddress?: Maybe<Address>;
  workPhone?: Maybe<Scalars['String']>;
};

export type PointDto = {
  __typename?: 'PointDto';
  components?: Maybe<Scalars['JSON']>;
  texts?: Maybe<Scalars['JSON']>;
  ts?: Maybe<Scalars['LocalDateTime']>;
  value?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Int']>;
};

export type Practitioner = {
  __typename?: 'Practitioner';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<NameQl>;
};

export type PractitionerClientAssignment = {
  __typename?: 'PractitionerClientAssignment';
  count: Scalars['Int'];
  practitioner: Practitioner;
};

export type PractitionerDayUsage = {
  __typename?: 'PractitionerDayUsage';
  date: Scalars['LocalDate'];
  usage: Array<UsageOfPracitionerInOneDay>;
};

export type PractitionerRoleCount = {
  __typename?: 'PractitionerRoleCount';
  count: Scalars['Int'];
  role: Scalars['String'];
};

export type PractitionerRoles = {
  __typename?: 'PractitionerRoles';
  organizationId?: Maybe<Scalars['String']>;
  roles: Array<CodingDto>;
};

export type PractitionerViewDto = {
  __typename?: 'PractitionerViewDTO';
  triageView?: Maybe<TriageView>;
};

export type PractitionersUsageAggregate = {
  __typename?: 'PractitionersUsageAggregate';
  aggregateDays: Scalars['Int'];
  differenceWithLastAggregate: Scalars['Float'];
  meanSeconds: Scalars['Float'];
  practitionerId: Scalars['String'];
};

export type PractitionersUsageReport = {
  __typename?: 'PractitionersUsageReport';
  aggregates: Array<PractitionersUsageAggregate>;
  practitioners: Array<Practitioner>;
  rawData: Array<PractitionerDayUsage>;
};

export type PrimaryContact = {
  __typename?: 'PrimaryContact';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<NameQl>;
  phone?: Maybe<Scalars['String']>;
};

export enum ProductType {
  BacTrack = 'BacTrack',
  HealthBand = 'HealthBand'
}

export type ProductVariant = {
  __typename?: 'ProductVariant';
  size?: Maybe<Scalars['String']>;
};

export type ProductVariantInput = {
  size?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  _service?: Maybe<_Service>;
  activePatientsOfOrganization: Array<Patient>;
  activeProducts?: Maybe<Array<ViviProduct>>;
  afterCarePatients: Array<TriagePatient>;
  alumniPatientsOfOrganization: Array<Patient>;
  customerOrders?: Maybe<Array<Maybe<ShopOrder>>>;
  engagement_score?: Maybe<Array<Maybe<PointDto>>>;
  myPatient?: Maybe<Patient>;
  myPractitioner?: Maybe<ExtendedPractitioner>;
  orgPractitioners: Array<ExtendedPractitioner>;
  organization?: Maybe<Organization>;
  organizationReport: OrganizationReport;
  organizations?: Maybe<Array<Organization>>;
  patient?: Maybe<Patient>;
  practitioner?: Maybe<ExtendedPractitioner>;
  questionnaireInstance?: Maybe<QuestionnaireInstance>;
  questionnaireSchedulesOfUser: Array<QuestionnaireSchedule>;
  questionnaires: Array<QuestionnaireInfo>;
  questionnairesOfUser: Array<QuestionnaireInstance>;
  scheduleFrequencies: Array<ScheduleFrequency>;
  shippingPrice?: Maybe<Scalars['Float']>;
  sleep?: Maybe<Array<Maybe<PointDto>>>;
  triagePatients: Array<TriagePatient>;
  unshippedOrders?: Maybe<Array<Maybe<ShopOrder>>>;
  user_steps?: Maybe<Array<Maybe<UserStepDto>>>;
  user_steps_averages?: Maybe<Array<Maybe<UserStepAvgDto>>>;
  vivi_score?: Maybe<Array<Maybe<PointDto>>>;
};


export type QueryActivePatientsOfOrganizationArgs = {
  organizationID?: InputMaybe<Scalars['String']>;
};


export type QueryAfterCarePatientsArgs = {
  practitionerId?: InputMaybe<Scalars['String']>;
};


export type QueryAlumniPatientsOfOrganizationArgs = {
  organizationID?: InputMaybe<Scalars['String']>;
};


export type QueryCustomerOrdersArgs = {
  end?: InputMaybe<Scalars['LocalDateTime']>;
  fbUserUid?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['LocalDateTime']>;
};


export type QueryEngagement_ScoreArgs = {
  end?: InputMaybe<Scalars['LocalDateTime']>;
  id?: InputMaybe<Scalars['String']>;
  maxPoints?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['LocalDateTime']>;
};


export type QueryOrgPractitionersArgs = {
  id: Scalars['String'];
};


export type QueryOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryOrganizationReportArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryPatientArgs = {
  fbUserUid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type QueryPractitionerArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryQuestionnaireInstanceArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryQuestionnaireSchedulesOfUserArgs = {
  fbUserId: Scalars['String'];
  qName?: InputMaybe<Scalars['String']>;
  showAll?: InputMaybe<Scalars['Boolean']>;
};


export type QueryQuestionnairesOfUserArgs = {
  end: Scalars['Instant'];
  fbUserId: Scalars['String'];
  questionnaire?: InputMaybe<Scalars['Int']>;
  start: Scalars['Instant'];
};


export type QuerySleepArgs = {
  end?: InputMaybe<Scalars['LocalDateTime']>;
  id?: InputMaybe<Scalars['String']>;
  maxPoints?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['LocalDateTime']>;
};


export type QueryTriagePatientsArgs = {
  practitionerId?: InputMaybe<Scalars['String']>;
};


export type QueryUser_StepsArgs = {
  end?: InputMaybe<Scalars['LocalDateTime']>;
  id?: InputMaybe<Scalars['String']>;
  maxPoints?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['LocalDateTime']>;
  tz?: InputMaybe<Scalars['String']>;
};


export type QueryUser_Steps_AveragesArgs = {
  id?: InputMaybe<Scalars['String']>;
  tz?: InputMaybe<Scalars['String']>;
};


export type QueryVivi_ScoreArgs = {
  end?: InputMaybe<Scalars['LocalDateTime']>;
  id?: InputMaybe<Scalars['String']>;
  maxPoints?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['LocalDateTime']>;
};

export type QuestionItem = {
  __typename?: 'QuestionItem';
  answer?: Maybe<SelectedAnswerInfo>;
  answered?: Maybe<Scalars['LocalDateTime']>;
  availableAnswers: Array<AnswerItem>;
  designType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['LocalDateTime']>;
  subQuestions?: Maybe<Array<QuestionItem>>;
  subtext?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type QuestionnaireInfo = {
  __typename?: 'QuestionnaireInfo';
  clinicName: Scalars['String'];
  description: Scalars['String'];
  groupName: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  patientName: Scalars['String'];
  version: Scalars['Int'];
};

export type QuestionnaireInstance = {
  __typename?: 'QuestionnaireInstance';
  created: Scalars['LocalDateTime'];
  id: Scalars['Int'];
  interpretationText?: Maybe<Scalars['String']>;
  modified: Scalars['LocalDateTime'];
  questionnaire: QuestionnaireInfo;
  questions: Array<QuestionItem>;
  status: QuestionnaireStatus;
  value?: Maybe<Scalars['Float']>;
};

export type QuestionnaireSchedule = {
  __typename?: 'QuestionnaireSchedule';
  created?: Maybe<Scalars['LocalDateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  cron: Scalars['String'];
  cronDescription: Scalars['String'];
  endDate?: Maybe<Scalars['LocalDateTime']>;
  modified?: Maybe<Scalars['LocalDateTime']>;
  modifiedBy?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notificationMessage: Scalars['String'];
  notificationTitle: Scalars['String'];
  patientFbUserId: Scalars['String'];
  questionnaire?: Maybe<QuestionnaireInfo>;
  questionnaireName: Scalars['String'];
  repeat: RepeatSchedule;
  scheduleId: Scalars['Int'];
  sendWindow: Scalars['Float'];
  timezoneOffset: Scalars['String'];
};

export type QuestionnaireScheduleCrudResult = OperationError | SuccessQuestionnaireScheduleOperation;

export type QuestionnaireScheduleInput = {
  name: Scalars['String'];
  notificationMessage: Scalars['String'];
  notificationTitle: Scalars['String'];
  patientFbUserId: Scalars['String'];
  questionnaireName: Scalars['String'];
  repeat: RepeatInput;
  sendWindowTime?: InputMaybe<Scalars['Float']>;
  timezoneOffset: Scalars['String'];
  user?: InputMaybe<Scalars['String']>;
};

/**
 * extend type Query {
 * }
 * union OrderManagmentOpResult=CrudOrderSuccess|OperationError
 */
export enum QuestionnaireStatus {
  Finished = 'Finished',
  Initial = 'Initial',
  Missed = 'Missed',
  Started = 'Started',
  Waiting = 'Waiting'
}

export type RepeatInput = {
  at?: InputMaybe<Scalars['LocalTime']>;
  dayOfMonth?: InputMaybe<Scalars['Int']>;
  frequencyName?: InputMaybe<Scalars['String']>;
  repeatOn: Array<DayOfWeek>;
  until?: InputMaybe<Scalars['LocalDate']>;
  untilWeeks?: InputMaybe<Scalars['Int']>;
};

export type RepeatSchedule = {
  __typename?: 'RepeatSchedule';
  at: Scalars['LocalTime'];
  dayOfMonth?: Maybe<Scalars['Int']>;
  frequencyDescription?: Maybe<Scalars['String']>;
  frequencyDisplayName?: Maybe<Scalars['String']>;
  frequencyName?: Maybe<Scalars['String']>;
  repeatOn: Array<DayOfWeek>;
  until?: Maybe<Scalars['LocalDate']>;
  untilWeeks?: Maybe<Scalars['Int']>;
};

export type ScheduleFrequency = {
  __typename?: 'ScheduleFrequency';
  cronExpression?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SelectedAnswerInfo = {
  __typename?: 'SelectedAnswerInfo';
  created?: Maybe<Scalars['LocalDateTime']>;
  id?: Maybe<Scalars['String']>;
  timeSpentSeconds?: Maybe<Scalars['Float']>;
  timesAnswered?: Maybe<Scalars['Int']>;
  timesSeen?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};

export type ShippingAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type ShippingInfo = {
  __typename?: 'ShippingInfo';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type ShopOrder = {
  __typename?: 'ShopOrder';
  amount?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['LocalDateTime']>;
  email?: Maybe<Scalars['String']>;
  fbUserUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lines?: Maybe<Array<Maybe<ShopOrderLine>>>;
  modified?: Maybe<Scalars['LocalDateTime']>;
  shipped?: Maybe<Scalars['Boolean']>;
  shipping?: Maybe<ShippingInfo>;
  shippingCompany?: Maybe<Scalars['String']>;
  shippingPrice?: Maybe<Scalars['Float']>;
  shippingTrackingId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ShopOrderLine = {
  __typename?: 'ShopOrderLine';
  product?: Maybe<ViviProduct>;
  quantity?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['Float']>;
  variant?: Maybe<ProductVariant>;
};

export type SuccessQuestionnaireScheduleOperation = {
  __typename?: 'SuccessQuestionnaireScheduleOperation';
  data: QuestionnaireSchedule;
  error?: Maybe<Scalars['Boolean']>;
};

export type ThemeImage = {
  __typename?: 'ThemeImage';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type TriagePatient = {
  __typename?: 'TriagePatient';
  careTeam: CareTeam;
  episodeOfCare: EpisodeOfCare;
  patient: Patient;
  patientId: Scalars['String'];
};

export enum TriageView {
  Card = 'CARD',
  Table = 'TABLE'
}

export type UsageOfPracitionerInOneDay = {
  __typename?: 'UsageOfPracitionerInOneDay';
  practitionerId: Scalars['String'];
  secondsOfDay: Scalars['Int'];
};

export type UserConfiguration = {
  __typename?: 'UserConfiguration';
  appVersion?: Maybe<Scalars['String']>;
  firmwareVersion?: Maybe<Scalars['String']>;
  phoneBattery?: Maybe<Scalars['Float']>;
  platform?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  tzName?: Maybe<Scalars['String']>;
};

export type UserStep = {
  __typename?: 'UserStep';
  ts?: Maybe<Scalars['LocalDateTime']>;
};

export type UserStepAvgDto = {
  __typename?: 'UserStepAvgDto';
  averagePerDay?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['LocalDateTime']>;
  to?: Maybe<Scalars['LocalDateTime']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UserStepDto = {
  __typename?: 'UserStepDto';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  ts?: Maybe<Scalars['LocalDateTime']>;
};

export type ViviProduct = {
  __typename?: 'ViviProduct';
  active?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['LocalDateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['LocalDateTime']>;
  modifiedBy?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  unitPrice?: Maybe<Scalars['Float']>;
  variants?: Maybe<Array<Maybe<ProductVariant>>>;
};

export type ViviProductInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ProductType>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  variants?: InputMaybe<Array<InputMaybe<ProductVariantInput>>>;
};

export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String'];
};
