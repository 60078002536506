import React from 'react';
import styles from './modal.module.scss';

type Props = {
  closeModal: () => void;
  contentStyle: string;
  show: boolean;
  showBtnClose?: boolean;
};

const Modal: React.FunctionComponent<Props> = props => {
  return (
    <div className={`${styles.overlay} ${props.show ? styles.show : ''}`}>
      <div className={styles[props.contentStyle]}>
        {props.showBtnClose && <a className={styles.btnClose} onClick={props.closeModal} />}
        <div className='row'>{props.children}</div>
      </div>
    </div>
  );
};

export default Modal;
