import React from 'react';
import {
  TextInput,
  SelectInput,
  RadioButton,
  HeightInput,
  DateTimeInput,
  CalendarDateInput,
  DateInput,
} from '../../common/form';
import { MARITAL_STATUS } from '../../../utils/constants';
import styles from '../upsert-client.module.scss';

const PrimaryInfo = ({ errors, touched, values }) => {
  return (
    <div className={styles.body}>
      <TextInput name='name.first' label='First name:' />
      <TextInput name='name.last' label='Last name:' />
      <DateTimeInput name='dateOfAdmission' label='Date of admission:' />
      <DateInput name='birthDate' label='Date of birth:' selected={values.birthDate} />
      <div className={styles.genderRadioButtons}>
        <label className={styles.label}>Gender:</label>
        <div className={styles.radioButtons}>
          <RadioButton name='gender' field='Male' value='MALE' displayError={false} />
          <RadioButton name='gender' field='Female' value='FEMALE' displayError={false} />
          <span
            className={`${
              !!(errors as any).gender && touched.gender ? styles.errorText__checkbox__show : styles.errorText__checkbox
            }`}
          >
            Please choose at least one option.
          </span>
        </div>
      </div>
      <div className={styles.bottomSection}>
        <HeightInput name='height' label='Height:' />
        <TextInput name='weight' label='Weight:' type='number' size='small' />
        <SelectInput name='maritalStatus' label='Marital status:' defaultValue={values.maritalStatus}>
          <option value='' />
          <option value={MARITAL_STATUS.SINGLE}>Single</option>
          <option value={MARITAL_STATUS.ENGAGED}>Engaged</option>
          <option value={MARITAL_STATUS.MARRIED}>Married</option>
          <option value={MARITAL_STATUS.DIVORCED}>Divorced</option>
          <option value={MARITAL_STATUS.WIDOWED}>Widowed</option>
        </SelectInput>
      </div>
    </div>
  );
};

export default PrimaryInfo;
