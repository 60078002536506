import React, { FunctionComponent, useState } from 'react';
import Modal from '../../modal/Modal';
import styles from './upsert-milestone-plan-container.module.scss';
import WizardStepIndicator from '../../common/wizard-step-indicator';
import RecoveryMilestoneInfo from './recovery-milestone-plan-section/recovery-milestone-info';
import { Form, Formik } from 'formik';
import MilestonePlanDetail from './recovery-milestone-plan-section/milestone-plan-detail';
import { Patient } from '../../../interfaces/patient';
import { displayFullName } from '../../../utils/data-parser';

type Props = {
  setShow: (show: boolean) => void;
  editMode: boolean;
  patient: Patient;
};

const UpsertMilestonePlanContainer: FunctionComponent<Props> = ({ setShow, editMode, patient }) => {
  const [step, setStep] = useState<number>(1);
  const planSteps = 2;

  const createMilestonePLanInitialValues = {
    planName: 'Road to Sobriety',
    launchTimestamp: new Date(),
  };
  return (
    <Modal show={true} closeModal={() => setShow(false)} showBtnClose={false} contentStyle='bigContent__static'>
      <div className={styles.content}>
        {editMode ? (
          <div className={styles.header__edit}>
            <span className={styles.header_title}>
              MANAGE RECOVERY MILESTONE PLAN - {displayFullName(patient.name)}
            </span>
            <div className={styles.milestoneModalNav}>
              <span className={step === 1 ? styles.stepSelected : ''} onClick={() => setStep(1)}>
                Details
              </span>
              <span className={step === 2 ? styles.stepSelected : ''} onClick={() => setStep(2)}>
                Recovery milestones
              </span>
            </div>
          </div>
        ) : (
          <div className={styles.header}>
            <span>CREATE RECOVERY MILESTONE PLAN - {displayFullName(patient.name)}</span>
            <WizardStepIndicator totalPages={planSteps} page={step} />
          </div>
        )}

        <Formik
          initialValues={createMilestonePLanInitialValues}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
          }}
        >
          {({ values }) => (
            <Form className={styles.formContainer}>
              {step === 1 && <RecoveryMilestoneInfo />}
              {step === 2 && <MilestonePlanDetail patient={patient} />}

              <div className={styles.bottomButtons}>
                {step === 1 && (
                  <button className={styles.submitBtn} onClick={() => setStep(2)}>
                    Next
                  </button>
                )}
                {step === 2 && (
                  <>
                    <button className={styles.closeBtn} type='button' onClick={() => setShow(false)}>
                      Close
                    </button>
                    <button className={styles.submitBtn} type='submit'>
                      Save Plan
                    </button>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default UpsertMilestonePlanContainer;
