import axios from 'axios';
import { auth } from '../../firebase/firebase';
import { cacheAdapterEnhancer, throttleAdapterEnhancer, Cache } from 'axios-extensions';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_WEB_API_URL,
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter!, {
      enabledByDefault: false,
      defaultCache: new Cache({ max: 100, maxAge: 1000 * 20 }),
    }),
  ),
});
apiClient.interceptors.request.use(
  async config => {
    const token = await auth.currentUser?.getIdToken();
    return {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`,
        'X-vivihealth-app': 'VIVI_CLINIC',
      },
    };
  },
  error => Promise.reject(error),
);

apiClient.interceptors.response.use(
  response => response,
  async error => {
    return Promise.reject(error.response.data);
  },
);

const { get, post, put, patch, delete: destroy } = apiClient;
export { get, post, put, patch, destroy };
