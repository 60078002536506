import React, { FunctionComponent } from 'react';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Patient } from 'interfaces/patient';
import { Practitioner } from 'interfaces/practitioner';
import TableHeader from 'components/pdf/components/table-header';
import DocInfoHeader from 'components/pdf/components/doc-info-header';
import moment from 'moment-timezone';

import { QuestionnaireInstance } from 'graphql/codegen/schemas';
import { isSurveyUsable } from '../is-survey-useful';
import { getStatusColor, getStatusIcon, getStatusText } from '../survey-status-styling';

const styles = StyleSheet.create({
  page: {
    paddingTop: 100,
    paddingLeft: 208,
    paddingRight: 189,
    paddingBottom: 120,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderBottomColor: '#A3A6B3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingBottom: 18,
  },
  pageNote: {
    position: 'absolute',
    bottom: 90,
    right: 120,
    fontSize: 24,
  },
  content: {
    marginBottom: 45,
    borderBottomColor: '#A3A6B3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    fontSize: 30,
    fontWeight: 'normal',
    color: '#323335',
    borderBottomColor: '#A3A6B3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
  },
  logo: {
    width: 260,
    height: 100,
    objectFit: 'contain',
  },
});

type Props = {
  dateRange: { start: string; end: string; timestamp?: string };
  timezone: string;
  patient?: Patient;
  practitioner?: Practitioner;
  data: QuestionnaireInstance[];
  organizationLogo?: string;
};

const SurveyTablePdf: FunctionComponent<Props> = ({
  patient,
  dateRange,
  practitioner,
  data,
  timezone,
  organizationLogo,
}) => {
  const rowWidth = 2900 / 5;

  const getRowStyles = (width: number) =>
    StyleSheet.create({
      cell: {
        width,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 15,
      },
    });

  return (
    <Document>
      <Page wrap={true} style={styles.page} size={{ width: 3300, height: 2500 }}>
        <Text
          style={styles.pageNote}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
        <View fixed />
        <View style={styles.title} fixed>
          {organizationLogo ? (
            <Image src={organizationLogo} style={styles.logo} />
          ) : (
            <Text>{practitioner?.organization.name} (BAC Report)</Text>
          )}
          <Text>Patient ID: {patient?.emrIdentifier || '--'}</Text>
        </View>
        <DocInfoHeader patient={patient!} practitioner={practitioner!} dateRange={dateRange} />
        <TableHeader
          headers={[
            { title: 'DATE', width: rowWidth },
            {
              title: 'SURVEY NAME',
              width: rowWidth + 200,
            },
            {
              title: 'SURVEY GROUP',
              width: rowWidth,
            },
            {
              title: 'DID SURVEY',
              width: rowWidth - 100,
            },
            {
              title: 'SURVEY SCORE',
              width: rowWidth - 100,
            },
          ]}
        />
        {data.map((survey, index) => (
          <View style={styles.tableRow} key={survey.id}>
            <Text style={getRowStyles(rowWidth).cell}>{moment.utc(survey.modified).tz(timezone).format('lll')}</Text>
            <Text style={getRowStyles(rowWidth + 200).cell}>{survey.questionnaire.clinicName}</Text>
            <Text style={getRowStyles(rowWidth).cell}>{survey.questionnaire.groupName}</Text>
            <View style={{ ...getRowStyles(rowWidth - 100).cell, paddingTop: 0, marginTop: 0 }}>
              <Image style={{ width: 25, height: 25, marginRight: '20px' }} src={getStatusIcon(survey)} />
              <Text style={{ color: getStatusColor(survey) }}>{getStatusText(survey).toLocaleUpperCase()}</Text>
            </View>

            <Text style={getRowStyles(rowWidth - 100).cell}>{isSurveyUsable(survey) ? survey.value || '-' : '-'}</Text>
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default SurveyTablePdf;
