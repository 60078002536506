import React, { useEffect, useState } from 'react';
import './row.scss';
import moment from 'moment-timezone';
import {
  Signal,
  NoSignal,
  Heart,
  InsightGood,
  Insight as InsightLogo,
  BacIconWhite,
  CalendarIcon,
  LocationIcon,
  RelapseIcon,
  BacMiss,
  More,
} from '../../assets/icons';
import { EPISODE_OF_CARE, HEX_COLORS, statusColor, VIVIHEALTH_SCORE_RANGE } from '../../utils/constants';
import ProfileImage from '../common/profile-image';
import { getPatientStatus, isPermissionsActive, parseRelapseRisk } from '../../utils/data-parser';
import { useHover } from '../../utils/hooks';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-mark-gray.svg';
import { DropDown } from '../dropdown';
import { done, loading, notAsked, RemoteData } from '../../utils/remote-data';
import { NotesResult } from '../../interfaces/note-result';
import { getLastNote } from '../../services/api/note';
import { AppearanceTypes, useToasts } from 'react-toast-notifications';
import UpsertClientContainer from '../admin-clients/upsert-client-container';
import { getPatientDetails } from '../../services/api/patient';
import { Patient } from '../../interfaces/patient';
import { ApprovedIcon } from '../../assets/icons/permissions';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import ChatIcon from '@mui/icons-material/Chat';


export const Row = ({ patient, history, aftercarePage, setSelectedPatientAction, setNote }): JSX.Element => {
  const [hoverRef, isHovered] = useHover();
  const url: string = `/patients/${patient.id}`
  const { addToast } = useToasts();
  const [patientFullInfo, setPatientFullInfo] = useState<RemoteData<Patient>>(notAsked());
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [profileSection, setProfileSection] = useState<number>(1);
  const patientStatus = getPatientStatus(patient.relapseRiskScore);
  const displayToast = (message: string, type: AppearanceTypes) =>
    addToast(message, { appearance: type, autoDismiss: true });

  const getComplianceViolationInfo = (complianceValue: number): JSX.Element => {
    return (
      <span className={`badge ${!!complianceValue ? 'alert' : ''}`}>
        {!!complianceValue ? (
          <BacMiss
            style={{
              color: '#ffffff',
              marginRight: '4px',
            }}
          />
        ) : (
          <CheckIcon
            style={{
              color: '#ffffff',
              marginRight: '4px',
            }}
          />
        )}
        {complianceValue}
      </span>
    );
  };

  const getLastNoteAction = async (patientId: string): Promise<RemoteData<NotesResult>> => {
    setNote(loading());
    const res = await getLastNote(patientId);
    const note = done(res.data);
    setNote(note);
    return note;
  };
  const getPatientFullInfoAction = async (patientId: string): Promise<void> => {
    setPatientFullInfo(loading());
    const res = await getPatientDetails(patientId);
    setPatientFullInfo(res);
  };

  const onViewLatestNote = async (): Promise<void> => {
    const note = await getLastNoteAction(patient.id);
    if (note.status === 'Done' && note.data.size === 0) {
      displayToast(`${patient.name.first} ${patient.name.last} does not have a Note yet.`, 'error');
    } else {
      setSelectedPatientAction({ selectedPatient: patient, selectedAction: 'Notes' });
    }
  };

  const onViewProfile = (profileSection: number) => {
    setShowProfileModal(true);
    setProfileSection(profileSection);
  };

  const getPermissionsStatus = (): JSX.Element => {
    return patient?.phonePermissions && isPermissionsActive(patient.phonePermissions) ? (
      <ApprovedIcon width={15} height={15} />
    ) : (
      <ApprovedIcon width={15} height={15} color='#EFBB55' />
    );
  };

  const getHealthBandStatus = (): string => {
    if (patientFullInfo.status === 'Done') {
      const { deviceName1, deviceName2 } = patientFullInfo.data.devicesIssued!;
      if (!deviceName1 && !deviceName2) {
        return 'Not assigned';
      }
    }
    return patient.isConnected ? 'Worn' : 'Not Worn';
  };

  useEffect(() => {
    if (patient) {
      patientFullInfo.status === 'Not Asked' && getPatientFullInfoAction(patient.id);
    }
  }, [patient]);
  return (
    <>
      {patient && showProfileModal && (
        <UpsertClientContainer
          setShow={setShowProfileModal}
          editMode={true}
          selectedPatient={patientFullInfo}
          organization={patient?.organization}
          firebasePatient={patient}
          refetchPatient={() => getPatientFullInfoAction(patient.id)}
          setShowDischargeClientModal={() => { }}
          initialTab={profileSection}
        />
      )}
      <tr className='tableRow'>
        <td className='clientName'>
          <div className='profileImage'>
            <ProfileImage
              image={patient.image?.url}
              size={48}
              gender={patient.gender}
              fallbackImageType='table'
              fallbackBackgroundColor={statusColor[patientStatus]}
            />
            <div className='phoneStatusIcon'>{getPermissionsStatus()}</div>
          </div>
          <div className='nameDataContainer'>
            <span
              className={`name ${aftercarePage ? 'action' : ''}`}
              onClick={() =>
                setSelectedPatientAction &&
                setSelectedPatientAction({ selectedPatient: patient, selectedAction: 'Profile' })
              }
            >
              {patient?.name?.first} {patient?.name?.last}
            </span>
            <span className='treatment'>{EPISODE_OF_CARE[patient?.episodeOfCare]}</span>
          </div>
          <DropDown style={{ margin: '0 10px' }} contentStyle='standard' hideDropdownOnClick>
            <More style={{ cursor: 'pointer' }} />
            <>
              <ul>
                {patient?.insight?.hasAlert && !patient?.insight.clearedAt && (
                  <li
                    onClick={() =>
                      setSelectedPatientAction({
                        selectedPatient: patient,
                        selectedAction: 'Insight',
                      })
                    }
                  >
                    Clear Alert
                  </li>
                )}
                <li onClick={() => onViewLatestNote()}>View Latest Note</li>
                <li onClick={() => onViewProfile(1)}>View Profile</li>
                <li onClick={() => onViewProfile(7)}>Permissions State {getPermissionsStatus()}</li>
              </ul>
            </>
          </DropDown>
          {/* <div style={{ marginLeft: 50 }}>
            <ChatIcon fontSize='small' style={{ color: '#a3a6b3' }} />
          </div> */}
        </td>
        <td>
          <span className='align-icon'>
            <InsightLogo color={statusColor[patientStatus]} width='20' height='20' />
            <span>&nbsp;&nbsp;&nbsp;</span>
            <span>{patientStatus}</span>
          </span>
        </td>
        {!aftercarePage && (
          <td className='insight'>
            <>
              {!patient?.insight && <div>&nbsp;</div>}
              {!!patient?.insight &&
                (patient?.insight?.positive ? (
                  <InsightGood height='10' width='10' />
                ) : (
                  <InsightLogo color='#323335' width='10' height='10' />
                ))}
              <span>{patient?.insight?.title || '--'}</span>
            </>
          </td>
        )}
        {aftercarePage && (
          <td className='relapseText'>
            {patient.relapseRiskScore}% - {parseRelapseRisk(patient.relapseRiskScore)}
          </td>
        )}
        {aftercarePage && (
          <td className='relapseIcon'>
            <div className='iconBackgroundWrapper relapse'>{!!patient.relapseCount && <RelapseIcon />}</div>
          </td>
        )}
        {aftercarePage && (
          <td>
            <span ref={hoverRef} className='complianceRow'>
              <div className='iconBox'>
                <span className='iconBackground'>{!!patient.locationViolationCount && <LocationIcon />}</span>
              </div>
              <div className='iconBox'>
                <span className='iconBackground'>{!!patient.bacFailureCount && <BacIconWhite />}</span>
              </div>
              <div className='iconBox'>
                <span className='iconBackground'> {!!patient.missedMeetingCount && <CalendarIcon />}</span>
              </div>
              {isHovered && (
                <div className='complianceHoverBox'>
                  <div className='violationRow'>
                    {getComplianceViolationInfo(patient.locationViolationCount)}
                    <span className='title'>Location Violations</span>
                  </div>
                  <div className='violationRow'>
                    {getComplianceViolationInfo(patient.bacFailureCount)}
                    <span className='title'>BAC Failures</span>
                  </div>
                  <div className='violationRow'>
                    {getComplianceViolationInfo(patient.missedMeetingCount)}
                    <span className='title'>Meeting Violations</span>
                  </div>
                  <div className='complianceHoverBoxLeft' />
                </div>
              )}
            </span>
          </td>
        )}
        {!aftercarePage && (
          <>
            <td className='connected'>
              {patient.isConnected ? <Signal /> : <NoSignal />}
              <span>{getHealthBandStatus()}</span>
            </td>

            <td className='heart-rate'>
              <div className='score-wrapper'>
                <span className='score'>{patient?.hr || '--'}</span>
                <div className='bpm'>
                  <span>BPM</span>
                  <Heart color={HEX_COLORS.CRITICAL} />
                </div>
              </div>
            </td>
          </>
        )}
        <td className='viviScore'>
          <span>{patient.viviScore}</span>
          <div className='bar-wrapper'>
            <div className='static-bar' />

            <div
              className='result-bar'
              style={{
                background:
                  patient.viviScore < VIVIHEALTH_SCORE_RANGE.AT_RISK.MIN
                    ? 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)'
                    : patient.viviScore < VIVIHEALTH_SCORE_RANGE.STABLE.MIN
                      ? 'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)'
                      : 'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 100%)',
                width: `${patient.viviScore}%`,
              }}
            />
          </div>
        </td>
        <td className='engagement'>
          <span>{patient.engagement}</span>
          <div className='bar-wrapper'>
            <div className='static-bar' />
            <div
              className='result-bar'
              style={{
                background:
                  patient.engagement < VIVIHEALTH_SCORE_RANGE.AT_RISK.MIN
                    ? 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)'
                    : patient.engagement < VIVIHEALTH_SCORE_RANGE.STABLE.MIN
                      ? 'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)'
                      : 'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 100%)',
                width: `${patient.engagement}%`,
              }}
            />
          </div>
        </td>
        {!aftercarePage && (
          <>
            <td className='viewedBy'>
              <span className='name'>
                {patient?.lastViewed?.practitioner?.name?.first || '--'}{' '}
                {patient?.lastViewed?.practitioner?.name?.last || '--'}
              </span>
              <span className='date'>
                {patient?.lastViewed
                  ? moment.utc(patient?.lastViewed?.timestamp).local().format('MM/DD/YY hh:mm A')
                  : '--'}
              </span>
            </td>
            <td className='action' onClick={(): void => history.push(`/patients/${patient.id}/details`)}>
              <span>View</span>
            </td>
          </>
        )}
        {aftercarePage && (
          <>
            <td className='relapseText'>{patient.wornPercentage}%</td>
            <td
              className='action'
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <a onClick={() =>
                    setSelectedPatientAction({
                      selectedPatient: patient,
                      selectedAction: 'BacTest',
                    })
                  }
                    className='action'
                    style={{ paddingBottom: 10 }}
                  >
                    Request BAC Test
                  </a>
                  <a onClick={() =>
                    setSelectedPatientAction({
                      selectedPatient: patient,
                      selectedAction: 'Survey',
                    })
                  }
                    className='action'
                  >
                    Request Survey
                  </a>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Link className='action' style={{ textDecoration: 'none', paddingBottom: 10 }} to={{ pathname: url + '/compliance/bac' }} target="_blank"  >
                    View BAC's
                  </Link>
                  <Link className='action' style={{ textDecoration: 'none' }} to={{ pathname: url + '/survey' }} target="_blank" >
                    View Survey's
                  </Link>
                </div>
              </div>
            </td>
          </>
        )}
      </tr>
    </>
  );
};
