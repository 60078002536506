import React, { FunctionComponent, ReactNode, useState } from 'react';
import { Marker, MarkerProps, OverlayView } from '@react-google-maps/api';

type MarkerOptions = google.maps.MarkerOptions & MarkerProps;
type Props = {
  infoWindow?: ReactNode;
  useOnHover?: boolean;
  markerProps: MarkerOptions;
};

const CustomMarker: FunctionComponent<Props> = ({ children, markerProps, useOnHover = false }) => {
  const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false);
  const [showHoverTooltip, setShowHoverTooltip] = useState<boolean>(false);
  const createKey = position => position.lat + position.lng;

  return (
    <Marker
      key={createKey(markerProps.position)}
      {...markerProps}
      onClick={() => setIsInfoOpen(!isInfoOpen)}
      onMouseOver={() => useOnHover && setShowHoverTooltip(true)}
      onMouseOut={() => useOnHover && setShowHoverTooltip(false)}
    >
      {(isInfoOpen || showHoverTooltip) && (
        <OverlayView
          key={createKey(markerProps.position)}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          position={markerProps.position}
        >
          {children}
        </OverlayView>
      )}
    </Marker>
  );
};

export default CustomMarker;
