import { AxiosResponse } from 'axios';
import { NotesResult } from '../../interfaces/note-result';

import { get, post } from './base';

export const getLastNote = async (patientId: string): Promise<AxiosResponse<NotesResult>> => {
  return await get(`${process.env.REACT_APP_WEB_API_URL}/notes?patientId=${patientId}&limit=1`);
};

export const getNotes = async (
  patientId: string,
  startDate: string,
  endDate: string,
): Promise<AxiosResponse<NotesResult>> => {
  return await get(
    `${process.env.REACT_APP_WEB_API_URL}/notes?patientId=${patientId}&startDateTime=${startDate}&endDateTime=${endDate}`,
  );
};

export const createNote = async (
  patientId: string,
  title: string,
  summary: string,
  effectiveStartDateTime: string,
  effectiveEndDateTime: string,
) => {
  return await post(`${process.env.REACT_APP_WEB_API_URL}/notes`, {
    patientId,
    title,
    summary,
    effectiveEndDateTime,
    effectiveStartDateTime,
  });
};
