export enum BacTestStatus {
  Pass = 'PASS',
  Fail = 'FAIL',
  Miss = 'MISS',
  Pending = 'PENDING',
}

export enum GeofenceType {
  NonCompliant = 'NON_COMPLIANT',
  Special = 'SPECIAL',
}

export type FacialMatchStatus = 'TRUE' | 'FALSE' | 'PENDING';

export enum ScheduleType {
  Interval = 'BAC_INTERVAL',
  Random = 'BAC_RANDOM',
  None = 'NONE',
}
