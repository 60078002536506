import React, { FunctionComponent, lazy, Suspense } from 'react';
import Modal from '../modal/Modal';
import styles from './pdf-confirmation-modal.module.scss';
import LoaderRing from '../common/loader-ring';

const PDFDownloadLink = lazy(() => import('../pdf/download-link-index'));

type Props = {
  title: string;
  getPdfComponent: () => JSX.Element;
  filename: string;
  show: boolean;
  hide: () => void;
};

const PdfConfirmationModal: FunctionComponent<Props> = ({ title, show, hide, filename, getPdfComponent }) => {
  return (
    <Modal show={show} closeModal={hide} showBtnClose={false} contentStyle='bigContent__static'>
      <div className={styles.content}>
        <div className={styles.header}>Generate {title} Report</div>
        <div className={styles.body}>
          <p>Press "Generate" to create the PDF file {filename}.pdf</p>
        </div>
        <div className={styles.footer}>
          <p>
            <strong>Acknowledgement:</strong> By generating or using the report for any purpose, I acknowledge that I
            have reviewed, understood, and agreed to the contents of this report, and I have separately confirmed as
            being true and correct any and all data or information herein, including any portion copied, extracted,
            downloaded, cited, or distributed by any means in any format.
          </p>
          <div className={styles.buttons}>
            <button className={`${styles.btn} ${styles.secondary}`} type='button' onClick={hide}>
              Close
            </button>
            <Suspense fallback={<LoaderRing size={24} />}>
              <PDFDownloadLink document={getPdfComponent()} fileName={filename}>
                {({ loading }) =>
                  loading ? (
                    <LoaderRing size={24} auxStyles={styles.loadingPosition} />
                  ) : (
                    <button className={styles.btnPrimary} type='submit'>
                      Generate
                    </button>
                  )
                }
              </PDFDownloadLink>
            </Suspense>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PdfConfirmationModal;
