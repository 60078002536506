import React from 'react';
import ExplanationBar from '../explanation-bar';
import ScoreBar from '../score-bar';

const InfoBox = (): JSX.Element => {
  return (
    <div>
      <h3>Sleep</h3>
      <p>
        Your client’s sleep measurement helps you better understand how they may be sleeping each night, as well as
        overall sleep trends.
      </p>
      <p>
        ViviCompanion has sleep tracking capabilities through the health band, allowing it to collect information on the
        movement (using the accelerometer in the band) and heart rate (using the sensor) to provide data into our
        algorithms that determine sleep habits. Consistent data (wearing the health band properly) is key to getting the
        most accurate assessment of your client’s sleep. The SLEEP chart shows the duration of sleep (width of the blue
        bar) and the quality of sleep (height of the blue bar).
      </p>
      <p>Sleep quality is based on a combination of:</p>
      <ul>
        <li>• assessment of approximate sleep duration</li>
        <li>• assessment of sleep stages (light sleep, deep sleep, and REM sleep)</li>
        <li>• the time they spend awake or restless (movement while sleeping)</li>
      </ul>
      <p>
        <strong>Sleep Quality Ranges</strong>
      </p>
      <h5>
        <ScoreBar
          scoreList={[
            { width: '50%', value: 50, color: 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)' },
            { width: '20%', value: 70, color: 'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)' },
            { width: '15%', value: 85, color: 'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 70%)' },
            { width: '15%', value: 100, color: 'linear-gradient(90deg, #6BE3B9 100%, #5CC5E2 100%)' },
          ]}
        />
      </h5>
      <ExplanationBar
        rangeList={[
          { width: '50%', text: 'Low' },
          { width: '20%', text: 'Fair' },
          { width: '15%', text: 'Good' },
          { width: '15%', text: 'Great' },
        ]}
      />
    </div>
  );
};

export default InfoBox;
