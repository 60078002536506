import moment from 'moment-timezone';
import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Insight, InsightGood } from '../../assets/icons';
import WristbandWorn from 'assets/icons/wristband/icn_healthband_connect.png';
import WristbandNotWorn from 'assets/icons/wristband/icn_healthband_disconnect.png';
import MobilePhone from 'assets/icons/icn_mobile_phone.png';
import { PractitionerContext } from '../../contexts/Practitioner';
import { Allies } from '../../interfaces/common';
import { Patient } from '../../interfaces/patient';
import { UIViviPatient } from '../../machines/patient.machine';
import { dischargePatient, DischargePatientRequest, getPatientAllies } from '../../services/api/patient';
import { caseOf } from '../../utils/case-of';
import { EPISODE_OF_CARE, PATIENT_STATUS, VIVIHEALTH_SCORE_RANGE } from '../../utils/constants';
import { loading, notAsked, RemoteData } from '../../utils/remote-data';
import DischargeClientConfirmation from '../admin-clients/discharge-client-confirmation';
import UpsertClientContainer from '../admin-clients/upsert-client-container';
import ProfileImage from '../common/profile-image';
import InsightCard from './insight-card';
import './patient-detail-card.scss';
import { getPatientCardBatteryIcon } from '../../utils/battery-helper';
import { Tooltip as InfoTooltip } from '../common/tooltip';
import ScoreBar from '../common/chart/score-bar';
import ExplanationBar from '../common/chart/explanation-bar';
import { displayFullName, getPatientStatus, isPermissionsActive, roundNumber } from '../../utils/data-parser';
import { getPatientLastViewedPractitioner } from '../../contexts/PatientContext';
import Logo from '../../assets/images/ViviClinic_logo.jpg';
import { ApprovedIcon } from '../../assets/icons/permissions';
import { spawn } from 'child_process';

const ActivityInfoBox = (): JSX.Element => {
  return (
    <span className='tooltip-container'>
      <InfoTooltip baseStyles='insight-tooltip' type='left' background='#F5F6FA'>
        <div className='hover-text'>
          <p>
            The <strong>Activity Score</strong> measures your client’s physical activity and movement over the past
            7-days. Activity levels are collected by the health band and stored/categorized into different types of
            activities, like exercise periods, walking, simply moving, and non-activity.
          </p>
          <p>
            The Activity Score is expressed by the system observing the past 7 days to see if your client is achieving
            levels of activity that correspond with recommended guidelines for general health from the U.S. Department
            of Health and Human Services and the National Institutes of Health. The Activity Score rewards indications
            of exercise and high amounts of physical activity throughout the week, as well as certain activity levels
            each day.
          </p>
          <p>
            <strong>Activity Ranges:</strong>
          </p>
          <p>
            <ScoreBar
              scoreList={[
                { width: '40%', value: 40, color: 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)' },
                { width: '30%', value: 70, color: 'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)' },
                { width: '15%', value: 85, color: 'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 70%)' },
                { width: '15%', value: 100, color: 'linear-gradient(90deg, #6BE3B9 100%, #5CC5E2 100%)' },
              ]}
            />
          </p>
          <ExplanationBar
            rangeList={[
              { width: '40%', text: 'Sedentary' },
              { width: '30%', text: 'Light' },
              { width: '15%', text: 'Moderate' },
              { width: '15%', text: 'Vigorous' },
            ]}
            smallStyle={true}
          />
        </div>
      </InfoTooltip>
    </span>
  );
};

interface OwnProps {
  patient: UIViviPatient | undefined;
  patientDetails: RemoteData<Patient>;
  refetchDetails: () => void;
  deviceInfo: any;
}

type Props = OwnProps;

export const PatientDetailCard: FunctionComponent<Props> = ({
  patient,
  patientDetails,
  refetchDetails,
  deviceInfo,
}) => {
  const { addToast } = useToasts();
  const { practitioner } = useContext(PractitionerContext);
  const practitionerLastViewed = getPatientLastViewedPractitioner();

  const [viviHealthStatus, setViviHealthStatus] = useState<string>('');
  const [engagementStatus, setEngagementStatus] = useState<string>('critical');
  const [patientStatus, setPatientStatus] = useState<string>('');
  const [showDischargeClientModal, setShowDischargeClientModal] = useState<boolean>(false);
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [selectedPatientAllies, setSelectedPatientAllies] = useState<RemoteData<Allies>>(notAsked());

  const [vitals, setVitals] = useState<{
    hr: { value: number; time: number };
    hrv: { value: number; time: number };
    rr: { value: number; time: number };
  }>({
    hr: { value: 0, time: 0 },
    hrv: { value: 0, time: 0 },
    rr: { value: 0, time: 0 },
  });
  const [activity, setActivity] = useState<{ value: number; time: number }>({ value: 0, time: 0 });
  const [engagement, setEngagement] = useState<{
    score: number;
    timestamp: number | undefined;
  }>({ score: 0, timestamp: undefined });
  const [viviHealthScore, setViviHealthScore] = useState<{
    score: number;
    timestamp: number | undefined;
  }>({ score: 0, timestamp: undefined });
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [phoneBattery, setPhoneBattery] = useState<number>(0);

  const critical = { min: 0, max: 29 };
  const atRisk = { min: 30, max: 59 };
  const stable = { min: 60, max: 100 };

  const getFontSizeByLength = name => {
    const length = name.length || 0;
    if (length <= 12) {
      return 36;
    }
    if (length > 12 && length <= 13) {
      return 34;
    }
    if (length === 13) {
      return 32;
    }
    if (length <= 15) {
      return 28;
    }
    return 26;
  };

  useEffect(() => {
    if (!patient) {
      return;
    }
    setActivity({ value: patient.activityScore || 0, time: patient.activityScoreTimestamp?.seconds || 0 });
    setVitals({
      hr: { value: patient.hr || 0, time: patient.vitalsTimestamp?.seconds || 0 },
      hrv: { value: patient.hrv || 0, time: patient.vitalsTimestamp?.seconds || 0 },
      rr: { value: patient.rr || 0, time: patient.vitalsTimestamp?.seconds || 0 },
    });
    const engagementScore = patient.engagement;
    if (engagementScore >= critical.min && engagementScore <= critical.max) {
      setEngagementStatus('critical');
    } else if (engagementScore >= atRisk.min && engagementScore <= atRisk.max) {
      setEngagementStatus('atrisk');
    } else if (engagementScore >= stable.min && engagementScore <= stable.max) {
      setEngagementStatus('stable');
    }

    setEngagement({
      score: engagementScore,
      timestamp: patient.engagementTimestamp?.seconds * 1000 || 0,
    });
    const { viviScore, relapseRiskScore } = patient;

    if (viviScore >= VIVIHEALTH_SCORE_RANGE.CRITICAL.MIN && viviScore <= VIVIHEALTH_SCORE_RANGE.CRITICAL.MAX) {
      setViviHealthStatus('critical');
    } else if (viviScore >= VIVIHEALTH_SCORE_RANGE.AT_RISK.MIN && viviScore <= VIVIHEALTH_SCORE_RANGE.AT_RISK.MAX) {
      setViviHealthStatus('atrisk');
    } else if (
      !viviScore ||
      (viviScore >= VIVIHEALTH_SCORE_RANGE.STABLE.MIN && viviScore <= VIVIHEALTH_SCORE_RANGE.STABLE.MAX)
    ) {
      setViviHealthStatus('stable');
    }
    setViviHealthScore({
      score: viviScore || 0,
      timestamp: patient.viviScoreTimestamp?.seconds * 1000 || 0,
    });

    setPatientStatus(getPatientStatus(relapseRiskScore));
    setIsConnected(patient.isConnected);
    setPhoneBattery((patient.userConfiguration?.phoneBattery ?? 0) * 100);
    if (selectedPatientAllies.status === 'Not Asked') getPatientAlliesAction(patient.id);
  }, [patient]);

  const getPatientAlliesAction = async (patientId: string) => {
    setSelectedPatientAllies(loading());
    const res = await getPatientAllies(patientId);
    setSelectedPatientAllies(res);
  };

  const dischargePatientAction = async (patientId: string, body: DischargePatientRequest) => {
    const res = await dischargePatient(patientId, body);

    if (res.status === 'Done') {
      refetchDetails();
      addToast('Client discharged successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast(`Error: ${res.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const viviHealthCardColor = caseOf()
    .case(PATIENT_STATUS.CRITICAL, '#ED6F6A')
    .case(PATIENT_STATUS.AT_RISK, '#EFBB55')
    .case(PATIENT_STATUS.STABLE, '#6FCF97')
    .defaultCase(() => 'white')
    .eval(patientStatus);

  const getPermissionsStatus = useMemo<JSX.Element>(() => {
    return patient?.phonePermissions && isPermissionsActive(patient.phonePermissions) ? (
      <ApprovedIcon width={15} height={15} />
    ) : (
      <ApprovedIcon width={15} height={15} color='#EFBB55' />
    );
  }, [patient?.phonePermissions]);

  return (
    <div className='patient-detail-card-container'>
      <div
        className='patient-detail-card-status-card'
        style={{
          backgroundColor: viviHealthCardColor,
        }}
      >
        {caseOf()
          .case(PATIENT_STATUS.STABLE, () => (
            <>
              <InsightGood height='20' width='20' color='white' />
              <span>{PATIENT_STATUS.STABLE}</span>
            </>
          ))
          .case(PATIENT_STATUS.AT_RISK, () => (
            <>
              <Insight color='white' width='23' height='20' />
              <span>{PATIENT_STATUS.AT_RISK}</span>
            </>
          ))
          .case(PATIENT_STATUS.CRITICAL, () => (
            <>
              <Insight color='white' width='23' height='20' />
              <span>{PATIENT_STATUS.CRITICAL}</span>
            </>
          ))
          .eval(patientStatus)}
      </div>
      <div className='patient-detail-card-profile-card'>
        <div className='patient-detail-card-top-row'>
          <ProfileImage
            image={patient?.image?.url}
            gender={patient?.gender}
            size={82}
            fallbackImageType='colored'
            fallbackBackgroundColor={viviHealthCardColor}
          />
          <span onClick={() => setShowProfileModal(true)}>View Profile</span>
        </div>
        <span
          className='patient-detail-card-name'
          style={{
            fontSize: getFontSizeByLength((patient?.name?.first || '') + (patient?.name?.last || '')),
            display: patientDetails.status !== 'Done' || !patient ? 'none' : '',
          }}
        >
          {`${patientDetails.status === 'Done' ? patientDetails.data.name.first : patient?.name?.first} ${
            patientDetails.status === 'Done' ? patientDetails.data.name.last : patient?.name?.last
          }`}
        </span>
        <span className='patient-detail-card-encounter'>{EPISODE_OF_CARE[patient?.episodeOfCare || '']}</span>
        <div className='patient-detail-card-middle-row'>
          <div className='progress-container'>
            <div className='progress-container-top-row'>
              <span className='progress-container-top-row-number'>
                {viviHealthScore?.score === 0 ? '--' : viviHealthScore?.score}
              </span>
              <span className='progress-container-top-row-time'>
                {viviHealthScore?.timestamp ? moment(viviHealthScore.timestamp).fromNow() : ''}
              </span>
              <div className='progress-container-top-row-name'>ViviHealth</div>
            </div>
            <div className='progress-container-bottom-row'>
              <div className='progress-container-bottom-row-gray' />
              <div
                style={{
                  background: caseOf()
                    .case('critical', 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)')
                    .case('atrisk', 'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)')
                    .case('stable', 'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 100%)')
                    .eval(viviHealthStatus),
                  borderRadius: 100,
                  height: '100%',
                  width: `${viviHealthScore?.score}%`,
                  position: 'absolute',
                  top: 0,
                }}
              />
            </div>

            <div className='progress-container-score-label'>
              <span className='score-label-container first'>
                <span className={`score-label ${viviHealthStatus === 'critical' ? 'active' : ''}`}>Ask for help</span>
              </span>
              <span className='score-label-container second'>
                <span className={`score-label ${viviHealthStatus === 'atrisk' ? 'active' : ''}`}>Doing Okay</span>
              </span>
              <span className='score-label-container third'>
                <span
                  className={`score-label ${
                    viviHealthStatus === 'stable' && viviHealthScore.score < 80 ? 'active' : ''
                  }`}
                >
                  Good
                </span>
              </span>
              <span className='score-label-container fourth'>
                <span
                  className={`score-label ${
                    viviHealthStatus === 'stable' && viviHealthScore.score >= 80 ? 'active' : ''
                  }`}
                >
                  Great
                </span>
              </span>
            </div>
          </div>
          <div className='progress-container'>
            <div className='progress-container-top-row'>
              <span className='progress-container-top-row-number'>
                {engagement.score === 0 ? '--' : engagement.score}
              </span>
              <span className='progress-container-top-row-time'>
                {engagement?.timestamp ? moment(engagement.timestamp).fromNow() : ''}
              </span>
              <div className='progress-container-top-row-name'>Engagement</div>
            </div>
            <div className='progress-container-bottom-row'>
              <div className='progress-container-bottom-row-gray' />
              <div
                style={{
                  background:
                    engagementStatus === 'critical'
                      ? 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)'
                      : engagementStatus === 'atrisk'
                      ? 'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)'
                      : 'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 100%)',
                  borderRadius: 100,
                  height: '100%',
                  width: `${engagement.score}%`,
                  position: 'absolute',
                  top: 0,
                }}
              />
            </div>
            <div className='progress-container-score-label'>
              <span className='score-label-container first'>
                <span className={`score-label small ${engagementStatus === 'critical' ? 'active' : ''}`}>
                  Effort Needed
                </span>
              </span>
              <span className='score-label-container second'>
                <span className={`score-label ${engagementStatus === 'atrisk' ? 'active' : ''}`}>Okay</span>
              </span>
              <span className='score-label-container third'>
                <span
                  className={`score-label ${engagementStatus === 'stable' && engagement.score < 80 ? 'active' : ''}`}
                >
                  High
                </span>
              </span>
              <span className='score-label-container fourth'>
                <span
                  className={`score-label small ${
                    engagementStatus === 'stable' && engagement.score >= 80 ? 'active' : ''
                  }`}
                >
                  Engaged
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className='patient-detail-card-bottom-row'>
          <span className='patient-detail-card-bottom-row-last'>
            <b>Last viewed: </b>
            {practitionerLastViewed
              ? moment.utc(practitionerLastViewed?.timestamp).local().format('MM/DD/YYYY hh:mm a')
              : '--'}
          </span>
          <span className='patient-detail-card-bottom-row-by'>
            <b>Viewed by: </b>
            {practitionerLastViewed && (displayFullName(practitionerLastViewed?.practitioner?.name) || '--')}
          </span>
        </div>
        {/* device healthband row */}
        <div className='patient-detail-card-connected-row'>
          <div >
            <span>Device: Healthband </span>
            {/* <span className='worn-icon'>
              {isConnected ? 'Worn' : ' Not Worn'}
              {isConnected ? (
                <img src={WristbandWorn} alt='wristband-worn' />
              ) : (
                <img src={WristbandNotWorn} alt='wristband--not-worn' />
              )}
            </span> */}
          </div>
          <div className='patient-detail-card-percentage'>
            <div className='patient-device-info'>
              {deviceInfo.provider ? <span>Make: {deviceInfo?.provider}</span> : ''}
              <span>
                Model: {deviceInfo?.name ? deviceInfo?.name : 'ViviHealth'}
              </span>
            </div>
            {/* {deviceInfo.provider !== 'FITBIT' && deviceInfo.provider !== 'APPLE' && (
              <span style={{ paddingBottom: 7.5 }}>
                Battery {patient?.batteryLevel}% {getPatientCardBatteryIcon(patient?.batteryLevel!)}
              </span>
            )} */}
          </div>
        </div>
        {/* phone device row */}
        <div className='patient-detail-card-connected-row'>
          <div className='patient-detail-card-percentage'>
            <span>Device: Phone </span>
            <span>
              {'Permissions'}
              {getPermissionsStatus}
            </span>
          </div>
          <div className='patient-detail-card-percentage'>
            <span>
              <img src={MobilePhone} alt='phone' />
              {caseOf().case('ios', 'iOS').case('android', 'Android').eval(patient?.userConfiguration?.platform)}
            </span>
            <span>
              Battery {roundNumber(phoneBattery, 1)}% {getPatientCardBatteryIcon(phoneBattery)}
            </span>
          </div>
        </div>
      </div>
      {/* <InsightCard
        type='HR'
        title='Heart Rate'
        score={vitals.hr}
        unit='BPM'
        infoBox={() => {
          return null;
        }}
      />
      <InsightCard
        type='HRV'
        title='Heart Rate Variability'
        score={vitals.hrv}
        unit='MS'
        infoBox={() => {
          return null;
        }}
      />
      <InsightCard
        type='RR'
        title='Respiration'
        score={vitals.rr}
        unit='BPM'
        infoBox={() => {
          return null;
        }}
      />
      <InsightCard
        type='Activity'
        title='Activity Score'
        score={activity}
        showTooltip={true}
        infoBox={ActivityInfoBox}
      /> */}
      {/* <figure className='logo-image'>
        <Logo />
      </figure> */}
      {/* Changed Logo */}
      <figure className='logo-image'>
        <img
          src={Logo}
          alt='ViviHealth - ViviClinic logo'
          style={{
            height: '100px',
            width: '300px',
            marginLeft: '-25px',
          }}
        />
      </figure>
      {showProfileModal && !showDischargeClientModal && (
        <UpsertClientContainer
          setShow={setShowProfileModal}
          editMode={true}
          organization={practitioner?.organization}
          selectedPatient={patientDetails}
          selectedPatientAllies={selectedPatientAllies}
          refetchPatient={refetchDetails}
          setShowDischargeClientModal={setShowDischargeClientModal}
        />
      )}
      {showDischargeClientModal && patientDetails.status === 'Done' && (
        <DischargeClientConfirmation
          setShow={setShowDischargeClientModal}
          client={patientDetails.data}
          dischargePatientAction={dischargePatientAction}
        />
      )}
    </div>
  );
};
