import { LocationPermissionType, PhonePermissionType } from '../interfaces/patient';
import { caseOf } from './case-of';
import { ApprovedIcon, DeleteIcon } from '../assets/icons/permissions';
import React from 'react';
import { ReactComponent as LocationSVG } from 'assets/icons/permissions/icn_location.svg';
import { ReactComponent as ContactSVG } from 'assets/icons/permissions/icn_contact.svg';
import BluetoothSVG from 'assets/icons/permissions/icn_bluetooth.svg';
import MotionFitnessSVG from 'assets/icons/permissions/icn_motion_fitness.svg';
import { CameraIcon } from 'assets/icons/permissions/icn_camera';
import { NotificationsIcon } from '../assets/icons/permissions/icn_notifications';

export const getPermissionStatusComponent = (
  permissionType: string,
  permissionStatus: PhonePermissionType | LocationPermissionType,
): JSX.Element => {
  if (permissionType === 'Location') {
    return caseOf()
      .case(
        'off',
        <>
          <span>Off</span>
          <DeleteIcon />
        </>,
      )
      .case(
        'while-in-use',
        <>
          <span>On While Using App</span>
          <ApprovedIcon color='#EFBB55' />
        </>,
      )
      .case(
        'always',
        <>
          <span>Always On</span>
          <ApprovedIcon />
        </>,
      )
      .eval(permissionStatus);
  } else {
    return caseOf()
      .case('granted', <ApprovedIcon />)
      .case('unavailable', <DeleteIcon />)
      .case('denied', <DeleteIcon />)
      .case('blocked', <DeleteIcon color='#ED6F6A' />)
      .eval(permissionStatus);
  }
};

export const getPhonePermissionTyeImage = (permissionType: string): JSX.Element => {
  return caseOf()
    .case(
      'Location',
      <>
        <span style={{ marginRight: '10px' }}>
          <LocationSVG width={'70px'} />
        </span>
        <span>Location</span>
      </>,
    )
    .case(
      'Bluetooth',
      <>
        <span style={{ marginRight: '10px' }}>
          <img width={'70px'} src={BluetoothSVG} alt='bluetooth' />
        </span>
        <span>Bluetooth</span>
      </>,
    )
    .case(
      'Camera',
      <>
        <span style={{ marginRight: '10px' }}>
          <CameraIcon width={70} />
        </span>
        <span>Camera</span>
      </>,
    )
    .case(
      'Motion & Fitness',
      <>
        <span style={{ marginRight: '10px' }}>
          <img width='70px' src={MotionFitnessSVG} alt='motion&fitness' />
        </span>
        <span>Motion & Fitness</span>
      </>,
    )
    .case(
      'Contacts',
      <>
        <span style={{ marginRight: '10px' }}>
          <ContactSVG width={'70px'} />
        </span>
        <span>Contacts</span>
      </>,
    )
    .case(
      'Notifications',
      <>
        <span style={{ marginRight: '10px' }}>
          <NotificationsIcon width='70px' />
        </span>
        <span>Notifications</span>
      </>,
    )
    .eval(permissionType);
};

export const getPermissionStateIcon = (permissionStatus: PhonePermissionType | LocationPermissionType): JSX.Element => {
  return caseOf()
    .case(permission => ['granted', 'always'].includes(permission), <ApprovedIcon />)
    .case(permission => ['denied', 'off', 'unavailable'].includes(permission), <DeleteIcon />)
    .case('blocked', <DeleteIcon color='#ED6F6A' />)
    .case('while-in-use', <ApprovedIcon color='#EFBB55' />)
    .eval(permissionStatus);
};
