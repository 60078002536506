import React from 'react';

export const BacMiss = props => {
  return (
    <svg style={props.style} xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' viewBox='0 0 23 20'>
      <path
        fill={props.style.color}
        fillRule='evenodd'
        d='M13.537 1.135l9.151 15.46c.888 1.513-.221 3.405-2.052 3.405H2.332C.557 20-.608 18.108.336 16.595l9.151-15.46c.888-1.513 3.162-1.513 4.05 0zm-1.22.703c-.334-.649-1.277-.649-1.61 0l-9.151 15.46c-.388.594.055 1.35.776 1.35h18.304c.777 0 1.22-.756.832-1.35l-9.152-15.46z'
        clipRule='evenodd'
      />
      <path
        fill={props.style.color}
        fillRule='evenodd'
        d='M11.483 5.46c.444 0 .832.324.832.756v6.217c0 .432-.388.756-.832.756-.388 0-.776-.324-.776-.756V6.216c0-.432.388-.756.776-.756zM11.482 14.107c.554 0 .998.433.998.973 0 .541-.444.973-.998.973a.982.982 0 01-.999-.973c0-.54.444-.973.999-.973z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export default BacMiss;
