import React from 'react';

type Props = {
  featureName: string;
};

const ComingSoon: React.FC<Props> = ({ featureName }) => {
  return (
    <div>
      <p>The {featureName} feature will be coming soon...</p>
    </div>
  );
};

export default ComingSoon;
