import { getPatientTimezone } from 'contexts/PatientContext';
import { QuestionnaireInstance } from 'graphql/codegen/schemas';
import { Patient } from 'interfaces/patient';
import { Practitioner } from 'interfaces/practitioner';
import React, { FunctionComponent, useState, useMemo, useRef } from 'react';
import { displayDateTimeFormat } from 'utils/data-parser';
import * as html2pdf from 'html2pdf.js';
import { isSurveyUsable } from '../is-survey-useful';
import { getStatusColor, getStatusIcon, getStatusText } from '../survey-status-styling';
import ViewSurveyModalQuestion from './view-survey-modal-question';
import styles from './view-survey-modal.module.scss';
import { ReactComponent as DownloadButton } from '../../../assets/icons/icn_download.svg';
import ViewGADSurveyModalQuestion from './view-gad-survey-modal';
import ViewPhqSurveyModal from './view-phq-survey-modal';
import ViewPennCravingModal from './view-penn-craving-modal';
import ViewAwareSurveyModal from './view-aware-survey-modal';

type Props = {
  closeModal: () => void;
  patient: Patient;
  viewSurvey: QuestionnaireInstance | null;
  practitioner: Practitioner;
};

const ViewSurveyModal: FunctionComponent<Props> = ({ closeModal, patient, viewSurvey, practitioner }) => {
  const timezone = getPatientTimezone();
  const [gettingPdf] = useState(false);
  const componentRef2 = useRef<HTMLElement>(null);

  // const print = async () => {
  //   const height = viewSurvey ? viewSurvey.questions.length * 1000 / 2 : 2000
  //   const size = viewSurvey?.questionnaire?.name === 'AWARENESS' ? 6000 : height;
  //   const name = `${patient?.name?.first}-${patient?.name?.last}-${viewSurvey?.questionnaire?.name}-${viewSurvey?.created.split('T')?.[0]}.pdf`
  //   const opt = {
  //     margin:       1,
  //     filename:     name,
  //     pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
  //     html2canvas:  { scale: 10, height: size },
  //   };
  //   html2pdf()
  //   .set(opt)
  //   .from(componentRef2.current)
  //   .toContainer()
  //   .then(()=>{
  //     const doc = document.querySelector('.html2pdf__container') as HTMLElement;
  //     if (doc) {
  //       const section = doc?.querySelector('#survey-scroll-section') as HTMLElement
  //       if (section?.style) {
  //         section.style.overflow = 'visible';
  //       }
  //     }
  //   })
  //   .save();
  // };

  const print = async () => {
    const height = viewSurvey ? viewSurvey.questions.length * 1000 / 2 : 2000
    console.log('height', height);
    const size = viewSurvey?.questionnaire?.name === 'AWARENESS' ? 6000 : height * 100; // Adjust this multiplier as needed
    const name = `${patient?.name?.first}-${patient?.name?.last}-${viewSurvey?.questionnaire?.name}-${
      viewSurvey?.created.split('T')?.[0]
    }.pdf`;
    const opt = {
      margin: 1,
      filename: name,
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      html2canvas: { scale: 10, height: size },
    };
    html2pdf()
      .set(opt)
      .from(componentRef2.current)
      .toContainer()
      .then(() => {
        const doc = document.querySelector('.html2pdf__container') as HTMLElement;
        if (doc) {
          const section = doc?.querySelector('#survey-scroll-section') as HTMLElement;
          if (section?.style) {
            section.style.overflow = 'visible';
          }
        }
      })
      .save();
  };

  const getContent = useMemo(() => {
    if (viewSurvey?.questionnaire?.name === 'GAD7') {
      return <ViewGADSurveyModalQuestion viewSurvey={viewSurvey} patient={patient} practitioner={practitioner} />;
    }
    if (viewSurvey?.questionnaire?.name === 'PHQ_9') {
      return <ViewPhqSurveyModal viewSurvey={viewSurvey} />;
    }

    if (viewSurvey?.questionnaire?.name === 'PENN Craving Questionnaire') {
      return <ViewPennCravingModal viewSurvey={viewSurvey} />;
    }

    if (viewSurvey?.questionnaire?.name === 'AWARENESS') {
      return <ViewAwareSurveyModal viewSurvey={viewSurvey} />;
    }

    return (
      <section className={styles.questions}>
        {viewSurvey?.questions?.map((q, idx) => (
          <ViewSurveyModalQuestion key={q.id} question={q} index={idx + 1} />
        ))}
      </section>
    );
  }, [viewSurvey?.id, patient, practitioner]);

  if (!viewSurvey) return null;

  return (
    <section className={`${styles.bacTestRequestModal} survey`} ref={componentRef2}>
      <div className={styles.modalHeader}>
        <h4>{viewSurvey.questionnaire.clinicName}</h4>
        <div className={styles.patientInfoRow}>
          <h5>
            Client name: {patient?.name?.first} {patient?.name?.last}
          </h5>
          <h5>Patient ID: {patient?.id}</h5>
        </div>
      </div>
      <section className={styles.modalSubHeader}>
        <div>Survey group: {viewSurvey.questionnaire.groupName}</div>
        <div>Date: {displayDateTimeFormat(viewSurvey.created, timezone)}</div>
        <div className={styles.didSurvey}>
          <div>Did survey:</div>
          <img src={getStatusIcon(viewSurvey)} alt={`${getStatusText(viewSurvey)} icon`} />
          <div style={{ color: getStatusColor(viewSurvey) }}>{getStatusText(viewSurvey)}</div>
        </div>
        <div>Survey Score: {isSurveyUsable(viewSurvey) ? viewSurvey.value : '-'}</div>
        <DownloadButton style={{ cursor: 'pointer' }} onClick={print} />
      </section>
      <section style={{ margin: 30 }} className={gettingPdf ? styles.none : styles.scroll} id='survey-scroll-section'>
        {getContent}
      </section>
    </section>
  );
};

export default ViewSurveyModal;
