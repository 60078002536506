import React, { FunctionComponent } from 'react';
import styles from './score-bar.module.scss';

type Props = {
  scoreList: { width: string; color: string; value: number }[];
  showScore?: boolean;
};

const ScoreBar: FunctionComponent<Props> = ({ scoreList, showScore = true }) => {
  return (
    <>
      {showScore && (
        <div className={styles.scoreRow}>
          <span className={styles.score}> 0</span>
          {scoreList.map((score, index) => (
            <span key={index} className={styles.score} style={{ width: score.width }}>
              {score.value}
            </span>
          ))}
        </div>
      )}
      <div className={styles.scoreBar}>
        {scoreList.map((score, index) => (
          <span key={index} className={styles.colorBar} style={{ width: score.width, background: score.color }} />
        ))}
      </div>
    </>
  );
};

export default ScoreBar;
