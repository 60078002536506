import React, { useEffect, useState } from 'react';
import appLogo from '../../assets/images/ViviClinic_logo.jpg';
import styles from './login.module.scss';
import { auth } from '../../firebase/firebase';
import Modal from '../modal/Modal';
import ForgotPasswordModal from './ForgotPasswordModal';

export const Login: React.FunctionComponent = () => {
  const [error, setError] = useState(null);
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  const [showForgotPasswordModal, setForgotPasswordModal] = useState(false);
  const onEnter = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      submit();
    }
  };

  useEffect(() => {
    document.body.style.maxWidth = 'none';
    document.body.style.minWidth = '0px';
    return () => {
      document.body.style.maxWidth = '';
      document.body.style.minWidth = '';
    };
  }, []);

  const submit = () => {
    auth
      .signInWithEmailAndPassword(loginForm.email.toLowerCase(), loginForm.password)
      .then(res => {
        setError(null);
      })
      .catch(({ message }) => setError(message || 'Invalid user name or password'));
  };

  return (
    <div className={styles.loginWrapper}>
      <form className={styles.login} onKeyDown={(e): void => onEnter(e)}>
        <section>
          <h3 className={styles.welcome}>Welcome to</h3>
          <figure className={styles.logo}>
            <img src={appLogo} alt='ViviHealth - ViviClinic logo' />
          </figure>
        </section>
        <div className={styles.loginGroup}>
          {error && (
            <div role='alert' className={styles.error}>
              {error}
            </div>
          )}
          <input
            className={styles.loginInput}
            data-testid='emailInput'
            type='text'
            name='email'
            placeholder='Email'
            autoComplete='false'
            value={loginForm.email}
            onChange={e =>
              setLoginForm({
                ...loginForm,
                email: e.target.value,
              })
            }
          />
          <input
            className={styles.loginInput}
            type='password'
            name='password'
            placeholder='Password'
            autoComplete='false'
            value={loginForm.password}
            onChange={e =>
              setLoginForm({
                ...loginForm,
                password: e.target.value,
              })
            }
          />
          <button
            data-testid='submitBtn'
            type='button'
            className={styles.loginButton}
            disabled={loginForm.email.length === 0}
            onClick={submit}
          >
            Sign In
          </button>
          <a
            onClick={() => {
              setForgotPasswordModal(!showForgotPasswordModal);
              setError(null);
            }}
            className={styles.belowButton}
          >
            Forgot Password
          </a>
        </div>
      </form>
      <div className={styles.imageBackground} />
      <Modal
        contentStyle='content'
        show={showForgotPasswordModal}
        closeModal={() => setForgotPasswordModal(!showForgotPasswordModal)}
      >
        <ForgotPasswordModal
          email={loginForm.email}
          closeModal={() => setForgotPasswordModal(!showForgotPasswordModal)}
        />
      </Modal>
    </div>
  );
};
