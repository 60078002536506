import React, { FunctionComponent, useEffect, useState } from 'react';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import { useToasts } from 'react-toast-notifications';
import styles from './form.module.scss';
import { WeekDay } from '../../../utils/constants';
import { getWeekDayInitial } from '../../../utils/data-parser';
import { useDidMountEffect } from '../../../utils/hooks';

type Props = {
  id?: number;
  name: string;
  label: string;
  selectedDayOfMeeting?: string;
} & FieldAttributes<any>;

const DaySelectorInput: FunctionComponent<Props> = props => {
  const { addToast } = useToasts();

  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const [weekDay, setWeekDay] = useState({
    Su: false,
    M: false,
    Tu: false,
    W: false,
    Th: false,
    F: false,
    Sa: false,
  });

  const fieldSize = props.size === 'small' ? styles.field__small : styles.field;

  useDidMountEffect(() => {
    const selectedDay = getWeekDayInitial(props.selectedDayOfMeeting);
    if (selectedDay) {
      const dayIsSelected = !!Object.entries(weekDay).find(([key, value]) => key === selectedDay && value === true);
      if (!dayIsSelected) {
        addToast('Initial meeting weekday does not match repeat pattern weekday(s).', {
          appearance: 'warning',
          autoDismiss: true,
        });
      }
    }
  }, [props.selectedDayOfMeeting, weekDay]);

  useEffect(() => {
    if (field.value) {
      let daysSelected = { ...weekDay };
      const repeatWeekDays: string = field.value;
      repeatWeekDays.split('').forEach(day => {
        daysSelected = { ...daysSelected, [WeekDay[day]]: true };
      });
      setWeekDay(daysSelected);
    }
  }, [field.value]);

  useEffect(() => {
    let repeatWeekDays = '';
    Object.entries(weekDay)
      .filter(([, value]) => !!value)
      .map(([key]) => (repeatWeekDays += Object.keys(WeekDay).find(k => WeekDay[k] === key)));
    setFieldValue(props.name, repeatWeekDays);
  }, [weekDay]);

  const onDayChange = (key: string) => {
    setWeekDay({ ...weekDay, [key]: !weekDay[key] });
  };
  return (
    <div className={fieldSize}>
      <label htmlFor={props.id || props.name}>{props.label}</label>
      <div className={styles.repeatDaysSelector}>
        {Object.entries(weekDay).map(([key, value], index) => (
          <input
            key={index}
            type='text'
            className={value ? styles.dayChecked : ''}
            value={key.charAt(0)}
            onClick={() => onDayChange(key)}
            readOnly={true}
          />
        ))}
        {meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
      </div>
    </div>
  );
};

export default DaySelectorInput;
