import React from 'react';

import './compliance.scss';
import { useRouteMatch } from 'react-router-dom';
import { ComplianceRoutes } from '../../App';
import ActiveLink from '../common/active-link';

type ComplianceProps = {};

export const Compliance: React.FunctionComponent<ComplianceProps> = props => {
  const { url, path } = useRouteMatch();
  return (
    <>
      <header className='compliance-header'>
        <nav className='compliance-nav'>
          <ul className='compliance-tabs'>
            <ActiveLink to={`${url}/bac`} label='BAC test' activeOnlyWhenExact={true} />
            {process.env.REACT_APP_ENVIRONMENT === 'development' && (
              <ActiveLink to={`${url}/drug`} label='Drug test' activeOnlyWhenExact={true} />
            )}
            <ActiveLink to={`${url}/meetings`} label='Events' activeOnlyWhenExact={true} />
            <ActiveLink to={`${url}/geo`} label='Geofences' activeOnlyWhenExact={true} />
            <ActiveLink to={`${url}/locations`} label='Locations' activeOnlyWhenExact={true} />
            {process.env.REACT_APP_ENVIRONMENT === 'development' && (
              <ActiveLink to={`${url}/recovery`} label='Recovery Milestones' activeOnlyWhenExact={true} />
            )}
          </ul>
        </nav>
      </header>
      <ComplianceRoutes path={path} />
    </>
  );
};
