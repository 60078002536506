import React from 'react';

export const Lock = props => {
  return (
    <svg
      className={props.className}
      style={props.style}
      width='12'
      height='16'
      viewBox='0 0 12 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 0C3.89416 0 2.18182 1.71236 2.18182 3.81818V5.99997H0V6.54543V15.2727H12V5.99997H9.81818V3.81818C9.81818 1.71236 8.10584 0 6 0ZM6 1.09093C7.52035 1.09093 8.72727 2.29784 8.72727 3.81818V5.99997H3.27273V3.81818C3.27273 2.29784 4.47965 1.09093 6 1.09093ZM1.09091 7.09089H10.9091V14.1818H1.09091V7.09089ZM6 8.18182C5.10272 8.18182 4.36364 8.92087 4.36364 9.81814C4.36364 10.5235 4.82328 11.1246 5.45455 11.3522V13.0909H6.54545V11.3522C7.17673 11.1246 7.63636 10.5235 7.63636 9.81814C7.63636 8.92087 6.89728 8.18182 6 8.18182ZM6 9.27275C6.30771 9.27275 6.54545 9.51044 6.54545 9.81814C6.54545 10.1259 6.30771 10.3636 6 10.3636C5.69229 10.3636 5.45455 10.1259 5.45455 9.81814C5.45455 9.51044 5.69229 9.27275 6 9.27275Z'
        fill='#A3A6B3'
      />
    </svg>
  );
};
