import { capitalizeWord } from './data-parser';
import moment from 'moment-timezone';

export const getPdfFileName = (data: {
  firstName: string;
  lastName: string;
  startDate: string | Date;
  endDate: string | Date;
  pdfTitle: string;
}) => {
  const first = data.firstName?.trim() ?? '';
  const last = data.lastName?.trim() ?? '';
  const date = `${moment(data.startDate).format('YYYYMMDD')}_${moment(data.endDate).format('YYYYMMDD')}`;
  return `${data.pdfTitle}_${capitalizeWord(first)}_${capitalizeWord(last)}_${date}`;
};
