import React, { FunctionComponent } from 'react';
import styles from '../chart.module.scss';
import { displayDateTimeFormat, roundNumber } from '../../../../utils/data-parser';
import { AdherenceComponent } from '../../../../interfaces/chart-results';
import { getPatientTimezone } from '../../../../contexts/PatientContext';

type AdherenceTooltipProps = {
  reverse: boolean;
  date: number;
  data: {
    value: number;
    components: AdherenceComponent;
  };
};

const AdherenceTooltip: FunctionComponent<AdherenceTooltipProps> = ({ reverse, date, data }) => {
  const timezone = getPatientTimezone();
  const calculateScoreComponentWeight = (component: number, weight: number) => roundNumber(component * weight, 1);
  return (
    <div className={`${styles.chartBigToolTip} ${styles.adherence} ${reverse ? styles.reverse : ''}`}>
      <div className={styles.chartTooltipContent}>
        <span>{displayDateTimeFormat(date, timezone)}</span>
      </div>
      <div className={styles.chartToolTipTitle}>
        <span>ADHERENCE: {data.value}</span>
      </div>
      <div className={styles.chartTooltipComponentsContainer} style={{ marginLeft: '10px' }}>
        <span>. Meeting Attendance: {calculateScoreComponentWeight(data.components!.meetingAttendance, 50)} / 50%</span>
        <span>. BAC Test Completion: {calculateScoreComponentWeight(data.components!.testCompletion, 50)} / 50%</span>
      </div>
      <div className={styles.chartToolTipTitle}>
        <span>Violations Impacting Score:</span>
      </div>
      <div className={styles.chartTooltipComponentsContainer} style={{ marginLeft: '10px' }}>
        {data.components!.testFailure < 1 && <span className={styles.warning}> BAC Test Completion</span>}
        {data.components!.testFacial < 1 && <span className={styles.warning}>Facial Photo Non-Match</span>}
        {data.components!.geofenceViolation < 1 && <span className={styles.warning}>Geofence Violation</span>}
      </div>
    </div>
  );
};

export default AdherenceTooltip;
