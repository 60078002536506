import React, { Dispatch, FunctionComponent, SetStateAction, useMemo } from 'react';
import Table from '../../common/table/table';
import styles from './meetings.module.scss';
import { RemoteData } from '../../../utils/remote-data';
import { Meeting, Meetings } from '../../../interfaces/meeting';
import { Column } from 'react-table';
import moment from 'moment-timezone';
import { MeetingStatus, MeetingType, WeekDay } from '../../../utils/constants';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/icn_delete.svg';
import { ReactComponent as RepeatIcon } from '../../../assets/icons/icn_repeat.svg';
import getMeetingStatus from '../../../utils/meeting-helper';
import { getPatientTimezone } from '../../../contexts/PatientContext';

type Props = {
  meetingsList: RemoteData<Meetings>;
  setSelectedMeeting: Dispatch<SetStateAction<string>>;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  setShowMeetingModal: (selectedMeeting: string, futureMeeting: boolean) => Promise<void>;
};

const MeetingsTable: FunctionComponent<Props> = ({
  meetingsList,
  setSelectedMeeting,
  setShowDeleteModal,
  setShowMeetingModal,
}) => {
  const timezone = getPatientTimezone();
  const columns: Column<Meeting>[] = useMemo(
    () => [
      {
        accessor: 'occurrenceStartTimestamp',
        Header: 'Date',
        width: 90,
        Cell: ({ cell: { value } }) => moment.utc(value).tz(timezone).format('MMM DD'),
        sortType: 'basic',
      },
      // {
      //   accessor: 'title',
      //   Header: 'Title',
      //   Cell: ({ cell: { value } }) => <span className={styles.overflowWrapped}>{value}</span>,
      //   width: 95,
      //   sortType: 'basic',
      // },
      {
        accessor: 'type',
        Header: 'Type',
        width: 111,
        Cell: ({ cell: { value } }) => MeetingType[value],
        sortType: 'basic',
      },
      {
        accessor: originalRow => {
          const startTime = moment.utc(originalRow.occurrenceStartTimestamp).local().format('hh:mm a');
          const endTime = moment.utc(originalRow.occurrenceEndTimestamp).local().format('hh:mm a');
          return `${startTime} - ${endTime}`;
        },
        Header: 'Time',
        width: 130,
        sortType: (rowA, rowB) => {
          const diffStartDate =
            moment.utc(rowA.original.occurrenceStartTimestamp).valueOf() -
            moment.utc(rowB.original.occurrenceStartTimestamp).valueOf();
          const diffEndDate =
            moment.utc(rowA.original.occurrenceEndTimestamp).valueOf() -
            moment.utc(rowB.original.occurrenceEndTimestamp).valueOf();
          return diffStartDate === 0 ? diffEndDate : diffStartDate;
        },
      },
      {
        accessor: (originalRow): object => {
          return {
            daysDisplay: !!originalRow.repeatWeekDays
              ? originalRow.repeatWeekDays
                  .split('')
                  .map(day => WeekDay[day])
                  .join(', ')
              : null,
            repeatEndDate: moment(originalRow.repeatEndTimestamp).isValid() ? originalRow.repeatEndTimestamp : '',
          };
        },
        Cell: ({ cell: { value, row } }) =>
          !!value.daysDisplay ? (
            <div className={styles.overflowWrapped}>
              <RepeatIcon className={styles.repeatIcon} />
              {`every ${value.daysDisplay} - ${
                !!value.repeatEndDate
                  ? `ending ${moment.utc(value.repeatEndDate).local().subtract(1, 'd').format('MM/DD')}`
                  : 'ends never'
              }`}
            </div>
          ) : (
            'Does not repeat'
          ),

        Header: 'Repeat',
        width: 210,
        disableSortBy: true,
      },
      {
        accessor: 'location',
        Header: 'Location',
        width: 124,
        Cell: ({ cell: { value, row } }) =>
          value && (
            <span
              className={`${styles.action} ${styles.overflowWrapped}`}
              onClick={() => setSelectedMeeting(row.original.id.toString())}
            >
              {value?.address}
            </span>
          ),
        sortType: (rowA, rowB, columnID, desc) => {
          const addressA = rowA.original.location?.address || '';
          const addressB = rowB.original.location?.address || '';
          return !addressA ? -1 : !addressB ? 1 : addressA.localeCompare(addressB);
        },
      },
      {
        accessor: originalRow =>
          originalRow.scheduledBy
            ? originalRow.scheduledBy.type.charAt(0) + originalRow.scheduledBy.type.slice(1).toLowerCase()
            : '',
        Header: 'Scheduled By',
        width: 113,
        sortType: 'basic',
      },
      {
        accessor: 'attended',
        Header: 'Status',
        width: 158,
        Cell: ({ cell: { value, row } }) => getMeetingStatus(value, row.original),
        sortType: (rowA, rowB) => {
          const attendanceA = MeetingStatus[rowA.original.attended];
          const attendanceB = MeetingStatus[rowB.original.attended];
          return attendanceA.localeCompare(attendanceB);
        },
      },
      {
        Header: '',
        accessor: 'id',
        minWidth: 36,
        width: 36,
        Cell: ({ cell: { row } }): JSX.Element => (
          <span
            className={styles.action}
            onClick={() =>
              setShowMeetingModal(
                row.original.id.toString(),
                moment.utc(row.original.occurrenceStartTimestamp).local().isSameOrAfter(moment.utc().local()),
              )
            }
          >
            View
          </span>
        ),
        disableSortBy: true,
      },
      {
        id: 'delete-icon-column',
        minWidth: 36,
        width: 36,
        Cell: ({ cell: { value, row } }) =>
          moment.utc(row.original.occurrenceStartTimestamp).local().isSameOrAfter(moment.utc().local()) ? (
            <span
              className={styles.action}
              onClick={() => {
                setSelectedMeeting(row.original.id.toString());
                setShowDeleteModal(true);
              }}
            >
              <DeleteIcon />
            </span>
          ) : null,
        disableSortBy: true,
      },
    ],
    [timezone],
  );
  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'occurrenceStartTimestamp', desc: true }] }),
    [],
  );

  const getRowProps = row => {
    return {
      key: `${row?.id}`,
      className: styles.tr,
    };
  };

  const getHeaderProps = column => {
    return {
      className: styles.th,
      key: `${column?.id}`,
      style: {
        width: column?.width,
        minWidth: column?.minWidth,
        maxWidth: column?.maxWidth,
      },
    };
  };

  const getCellProps = cell => {
    return {
      key: `${cell?.column.id}`,
      className: styles.td,
    };
  };

  const getTableBodyProps = () => {
    return {
      className: styles.tbody,
      style: {
        height: '564px',
      },
    };
  };

  const getTableProps = () => {
    return {
      className: meetingsList.status === 'Done' && !meetingsList.data.size ? styles.noContent : styles.table,
    };
  };

  return (
    <Table<Meeting>
      columns={columns}
      data={meetingsList.status === 'Done' ? meetingsList.data.values : []}
      isLoading={meetingsList.status === 'Loading'}
      error={meetingsList.status === 'Failed'}
      getTableProps={() => getTableProps()}
      getRowProps={row => getRowProps(row)}
      getHeaderProps={column => getHeaderProps(column)}
      getCellProps={cell => getCellProps(cell)}
      getTableBodyProps={() => getTableBodyProps()}
      defaultColumn={{ width: 150, minWidth: 50 }}
      initialState={defaultSorting}
    />
  );
};
export default MeetingsTable;
