import React from 'react';
import { ReactComponent as BacIcon } from '../../../../assets/icons/icn_bac-small.svg';
import { ReactComponent as SelfCareIcon } from '../../../../assets/icons/icn_self-care.svg';
import { ReactComponent as StrapIcon } from '../../../../assets/icons/icn_strap.svg';
import { ReactComponent as MessageIcon } from '../../../../assets/icons/icn_message.svg';

import ExplanationBar from '../explanation-bar';
import ScoreBar from '../score-bar';
import { getEventIcon } from '../../../../utils/event-helper';

const InfoBox = (): JSX.Element => {
  return (
    <div>
      <h3>ADHERENCE SCORE</h3>
      <p>
        The Adherence Score measures how well the patient is adhering to key treatment directives important to your
        client’s recovery. The Adherence chart plots the Adherence Score hourly (from 0 to 100) across your client’s
        recovery journey. The score focuses on whether or not your client is successfully completing BAC tests,
        attending recovery meetings, and not violating geofenced areas.
      </p>
      <p>Client’s can increase their Adherence Score by:</p>
      <ul>
        <li>
          <figure>{getEventIcon('MEETING', 10, 10, '#444444', undefined, false)}</figure>
          attending <strong>meetings</strong>
        </li>
        <li>
          <figure>
            <BacIcon />
          </figure>
          completing and passing <strong>BAC check-ins</strong>
        </li>
        <li>
          <figure>
            <MessageIcon />
          </figure>
          <strong>starting communications</strong> with supporters through the app’s hub page
        </li>
      </ul>
      <p>
        When a client misses or fails the following, the Adherence Score will reflect a significant reduction to
        indicate one or more of these events have happened:
      </p>
      <ul>
        <li>
          <strong>• Failing a BAC test</strong> (indicated by a positive alcohol measurement {'>'} 0.01 BAC)
        </li>
        <li>
          <strong>• Confirmed Facial mismatch </strong> when taking the BAC test
        </li>
        <li>
          <strong>• Geofence violation</strong> (when a client enters an area you’ve designated as a place he/she
          struggles with)
        </li>
      </ul>
      <p>
        <strong>Adherence ranges</strong>
      </p>
      <h5>
        <ScoreBar
          scoreList={[
            { width: '30%', value: 30, color: 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)' },
            { width: '30%', value: 60, color: 'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)' },
            { width: '20%', value: 80, color: 'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 70%)' },
            { width: '20%', value: 100, color: 'linear-gradient(90deg, #6BE3B9 100%, #5CC5E2 100%)' },
          ]}
        />
      </h5>
      <ExplanationBar
        rangeList={[
          { width: '30%', text: 'Low' },
          { width: '30%', text: 'Fair' },
          { width: '20%', text: 'Good' },
          { width: '20%', text: 'Great' },
        ]}
      />
    </div>
  );
};

export default InfoBox;
