import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { PractitionerContext } from '../../contexts/Practitioner';
import { loading, notAsked, RemoteData } from '../../utils/remote-data';
import { DischargedPatient, DischargedPatients } from '../../interfaces/patient';
import { getPatients } from '../../services/api/patient';
import { firebaseSendCurrentScreen } from '../../utils/analytics';
import DischargedClientsTable from './discharged-clients-table';
import styles from './admin-alumni.module.scss';
import SearchInput from '../common/table/search-input';
import { EPISODE_OF_CARE } from '../../utils/constants';
import FilterCheckbox from '../common/checkbox/filter-checkbox';
import ReadmitClientModal from './readmit-client-modal';

type Props = {};

const AdminAlumni: FunctionComponent<Props> = () => {
  const { practitioner } = useContext(PractitionerContext);
  const [alumniPatients, setAlumniPatients] = useState<RemoteData<DischargedPatients>>(notAsked());
  const [filters, setFilters] = useState<{ searchInput: string; eoc: string[] }>({
    searchInput: '',
    eoc: [],
  });
  const [filteredPatients, setFilteredPatients] = useState<DischargedPatients | undefined>(undefined);
  const [selectedPatient, setSelectedPatient] = useState<DischargedPatient | undefined>(undefined);

  const clearSelectedDevice = useCallback((): void => {
    setSelectedPatient(undefined);
  }, []);

  useEffect(() => {
    if (practitioner) {
      firebaseSendCurrentScreen('AdminAlumniPage', {
        practitionerId: practitioner?.id,
        organizationId: practitioner?.organization.id,
        firebaseUid: practitioner?.firebaseUid,
      });
      getAlumniPatientsAction();
    }
  }, [practitioner]);

  useEffect(() => {
    if (!!filters.searchInput || filters.eoc.length) {
      if (alumniPatients.status === 'Done') {
        let filteredPatients: DischargedPatient[];
        const searchValue = filters.searchInput.toLowerCase();
        filteredPatients = alumniPatients.data.values.filter(p =>
          [
            p.name.first.toLowerCase(),
            p.name.last.toLowerCase(),
            `${p.name.first.toLowerCase()} ${p.name.last.toLowerCase()}`,
          ].find(name => name.includes(searchValue)),
        );
        if (filters.eoc.length) {
          filteredPatients = filteredPatients.filter(patient => {
            const eocList = patient.finishedEpisodeOfCareList.map(eoc => eoc.type);
            return filters.eoc.some(ec => eocList.includes(ec));
          });
        }
        setFilteredPatients({ values: filteredPatients, size: filteredPatients.length });
      }
    } else {
      setFilteredPatients(undefined);
    }
  }, [filters, alumniPatients]);

  const getAlumniPatientsAction = useCallback(async (): Promise<void> => {
    if (practitioner) {
      setAlumniPatients(loading());
      const res = (await getPatients(practitioner?.organization.id, false)) as RemoteData<DischargedPatients>;
      setAlumniPatients(res);
    }
  }, [practitioner]);

  const setSearchInputFilter = (filterValue: string | string[], key: string) => {
    setFilters({ ...filters, [key]: filterValue });
  };

  return (
    <div>
      <div className={styles.navHeader}>
        <SearchInput
          searchValue={filters.searchInput}
          handleInputChange={ev => setSearchInputFilter(ev.target.value, 'searchInput')}
          handleOnClick={ev => setSearchInputFilter('', 'searchInput')}
          placeholder='Search by name...'
        />
        <div className={styles.filtersRow}>
          {Object.entries(EPISODE_OF_CARE)
            .filter(([key, value]) => EPISODE_OF_CARE.SELF_CARE !== value)
            .map(([key, value]) => (
              <FilterCheckbox
                key={key}
                checked={filters.eoc.includes(key)}
                name='eoc'
                field={value}
                handleOnChange={ev =>
                  setSearchInputFilter(
                    ev.target.checked ? [...filters.eoc, key] : filters.eoc.filter(value => value !== key),
                    'eoc',
                  )
                }
              />
            ))}
        </div>
      </div>
      <DischargedClientsTable
        patients={alumniPatients}
        filterPatientsList={filteredPatients}
        setSelectedPatient={setSelectedPatient}
      />
      {!!selectedPatient && (
        <ReadmitClientModal
          patient={selectedPatient}
          show={!!selectedPatient}
          hide={clearSelectedDevice}
          getAlumniClients={getAlumniPatientsAction}
        />
      )}
    </div>
  );
};

export default AdminAlumni;
