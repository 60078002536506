import { endOfMonth, startOfMonth } from 'date-fns';
import moment from 'moment-timezone';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PractitionerContext } from '../../../contexts/Practitioner';
import { BacResult } from '../../../interfaces/bac-result';
import { getBacResultsByPatient } from '../../../services/api/bac';
import { firebaseSendCurrentScreen } from '../../../utils/analytics';
import { loading, RemoteData } from '../../../utils/remote-data';
import DatePicker, { DateRange } from '../../common/date-picker/date-range-picker';
import { DropDown } from '../../dropdown';
import Modal from '../../modal/Modal';
import Bac_Schedule from './bac-schedule';
import BacTable from './bac-table';
import styles from './bac.module.scss';
import BacFacialMatch from './modals/bac-facial-match';
import BacLocation from './modals/bac-location';
import BacTestRequest from './modals/bac-test-request';
import { getPatientTimezone, PatientContext } from '../../../contexts/PatientContext';
import { ReactComponent as DownloadButton } from '../../../assets/icons/icn_download.svg';
import PdfConfirmationModal from '../../pdf/pdf-confirmation-modal';
import { getPdfFileName } from '../../../utils/pdf-helper';
import BacTablePdf from '../../pdf/tables/bac/bac-table-pdf';
import { getOrganizationImageAsBase64 } from '../../../services/api/report';

type SelectedTab = 'BacList' | 'Settings';
export type BacModal = 'Location' | 'FacialMatch' | 'BacTest' | 'None';
type Props = {};

const BacTest: FunctionComponent<Props> = () => {
  const { practitioner } = useContext(PractitionerContext);
  const { patient } = useContext(PatientContext);
  const patientTimezone = getPatientTimezone();
  const [showBacModal, setShowBacModal] = useState<BacModal>('None');
  const [selectedTab, setSelectedTab] = useState<SelectedTab>('BacList');
  const [selectedBac, setSelectedBac] = useState<BacResult | undefined>(undefined);
  const [results, setResults] = useState<RemoteData<{ values: BacResult[] }>>(loading());
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    key: 'selection',
    color: '#417EB9',
    showDateDisplay: true,
    autoFocus: true,
  });
  const [organizationLogo, setOrganizationLogo] = useState<string>('');
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (practitioner) {
      firebaseSendCurrentScreen('BACTestPage', {
        practitionerId: practitioner?.id,
        organizationId: practitioner?.organization.id,
        firebaseUid: practitioner?.firebaseUid,
      });
      getBlobFromLogoURL();
    }
  }, [practitioner]);

  const requestBacResults = async () => {
    const res = await getBacResultsByPatient(id!, {
      startDate: moment(dateRangeFilter.startDate).utc().format('YYYY-MM-DD'),
      endDate: moment(dateRangeFilter.endDate).format('YYYY-MM-DD'),
      timezone: moment.tz.guess(),
    });
    setResults(res);
  };

  useEffect(() => {
    (async (): Promise<void> => {
      await requestBacResults();
    })();
  }, [dateRangeFilter]);

  const selectBacModal = (bacTest: BacResult, type: BacModal): void => {
    setSelectedBac(bacTest);
    setShowBacModal(type);
  };
  const updateBacTestRow = (bacTest: BacResult): void => {
    if (results.status === 'Done') {
      let updatedBacList = results.data.values.filter(bac => bac.id !== bacTest.id);
      updatedBacList = [...updatedBacList, bacTest];
      setResults({ data: { values: updatedBacList }, status: 'Done' });
    }
  };
  const onDateChange = async (item: any): Promise<void> => {
    setDateRangeFilter(item.selection);
  };

  const getBlobFromLogoURL = async () => {
    const base64Image = await getOrganizationImageAsBase64(practitioner!.organization.theme.images.logo);
    setOrganizationLogo(`data:image/png;base64,${base64Image}`);
  };

  const getTableAsPDF = (): JSX.Element => {
    const bacResults: BacResult[] = results.status === 'Done' ? results.data.values : [];

    bacResults.sort((bacA, bacB) => {
      return moment(bacB.timestamp).unix() - moment(bacA.timestamp).unix();
    });
    return (
      <BacTablePdf
        dateRange={{
          start: moment(dateRangeFilter.startDate).format(),
          end: moment(dateRangeFilter.endDate).format(),
          timestamp: moment().format(),
        }}
        timezone={patientTimezone}
        patient={patient}
        practitioner={practitioner}
        bacList={bacResults}
        organizationLogo={organizationLogo}
      />
    );
  };

  return (
    <>
      <header className={styles.bacHeader}>
        <nav>
          <ul className={styles.navLeft}>
            <li
              className={`${selectedTab.includes('BacList') ? styles.active : styles.inactive}`}
              onClick={() => setSelectedTab('BacList')}
            >
              List view
            </li>
            <li
              className={`${selectedTab.includes('Settings') ? styles.active : styles.inactive}`}
              onClick={() => setSelectedTab('Settings')}
            >
              Settings
            </li>
          </ul>
          <ul className={styles.navRight}>
            <li>
              <DownloadButton onClick={() => setShowPdf(true)} />
            </li>
            <li>
              <a href='#' className='href' onClick={() => setShowBacModal('BacTest')}>
                {' '}
                Request BAC Test Now
              </a>
            </li>
            {selectedTab === 'BacList' && (
              <>
                <li className={styles.options}>
                  <DropDown contentStyle='filter'>
                    <span className={styles.dropdownButton}>
                      {moment(dateRangeFilter.startDate).format('MM/DD/YYYY')} -{' '}
                      {moment(dateRangeFilter.endDate).format('MM/DD/YYYY')}
                      <i className={styles.arrow}>
                        <svg width='14' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M13 1L7 7L1 1' stroke='#417EB9' strokeWidth='1.5' strokeLinecap='round' />
                        </svg>
                      </i>
                    </span>
                    <>
                      <DatePicker
                        onDateSelectionChange={onDateChange}
                        dateRange={dateRangeFilter}
                        parentStyle={styles.filterDatepicker}
                      />
                    </>
                  </DropDown>
                </li>
              </>
            )}
          </ul>
        </nav>
      </header>
      {selectedTab === 'BacList' ? (
        <BacTable bacTests={results} onTableActionClick={selectBacModal} />
      ) : (
        <Bac_Schedule />
      )}
      {showBacModal === 'Location' && (
        <Modal
          contentStyle='bigContent'
          show={showBacModal === 'Location'}
          showBtnClose
          closeModal={() => setShowBacModal('None')}
        >
          <BacLocation bacTest={selectedBac!} />
        </Modal>
      )}
      {showBacModal === 'FacialMatch' && (
        <Modal
          contentStyle='bigContent'
          show={showBacModal === 'FacialMatch'}
          showBtnClose={false}
          closeModal={() => setShowBacModal('None')}
        >
          <BacFacialMatch
            bacTest={selectedBac!}
            updateBacTest={updateBacTestRow}
            closeModal={() => setShowBacModal('None')}
          />
        </Modal>
      )}
      {showBacModal === 'BacTest' && (
        <Modal
          contentStyle='content'
          show={showBacModal === 'BacTest'}
          showBtnClose
          closeModal={() => setShowBacModal('None')}
        >
          <BacTestRequest patient={patient!} closeModal={() => setShowBacModal('None')} />
        </Modal>
      )}
      {showPdf && (
        <PdfConfirmationModal
          title={'BAC'}
          getPdfComponent={getTableAsPDF}
          filename={getPdfFileName({
            firstName: patient!.name.first,
            lastName: patient!.name.last,
            startDate: dateRangeFilter.startDate!,
            endDate: dateRangeFilter.endDate!,
            pdfTitle: 'BAC',
          })}
          show={showPdf}
          hide={() => setShowPdf(false)}
        />
      )}
    </>
  );
};

export default BacTest;
