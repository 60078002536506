import React from 'react';

export const Arrow = ({ directionUp = false }) => {
  return (
    <svg
      style={{
        transform: directionUp ? `rotate(180deg)` : `rotate(0deg)`,
        marginBottom: 2,
        marginLeft: 13,
      }}
      width='14'
      viewBox='0 0 14 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M13 1L7 7L1 1' stroke='#417EB9' strokeWidth='1.5' strokeLinecap='round' />
    </svg>
  );
};
