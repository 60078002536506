import * as React from 'react';
import { QuestionnaireInstance } from 'graphql/codegen/schemas';
import styles from './view-aware-survey-modal.module.scss';
import PHQ_SCORE from './phq_score.jpeg';

interface Props {
  viewSurvey: QuestionnaireInstance;
}

const ViewAwareSurveyModal: React.FC<Props> = props => {
  const [invertedSum, setInvertedSum] = React.useState<number>(0);
  const [regSum, setRegSum] = React.useState<number>(0);

  React.useEffect(() => {
    let reg = 0;
    let invt = 0;
    props.viewSurvey.questions.forEach((question, index) => {
      const inverseNumbers = [8, 14, 20, 24, 26];
      const isInverse = inverseNumbers.includes(index + 1);
      if (isInverse && question.answer?.value) {
       invt += question.answer?.value;
      } else if (!isInverse && question.answer?.value) {
        reg += question.answer?.value;
      }
    })
    setInvertedSum(invt);
    setRegSum(reg)

  },[]);

  const getAnswerRow = (question, number) => {
    const inverseNumbers = [8, 14, 20, 24, 26];
    const isInverse = inverseNumbers.includes(number);
    return (
      <div className={[styles.paragraph, styles.row, styles.left50].join(" ")}>
        <div className={[styles.width200, styles.row].join(" ")}>
          {!isInverse && (
            <>
              <span className={styles.answerNumber}>{number}. </span>
              <span className={styles.answer}>
                {question.answer?.value}
              </span>
            </>
          )}

        </div>
        <div className={[styles.left50, styles.width100, styles.row].join(" ")}>
          {isInverse && (
            <>
              <span className={styles.answerNumber}>{number}. </span>
              <span className={styles.answer}>
                {props?.viewSurvey?.questions[number - 1].answer?.value}
              </span>
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={styles.awareTitleRow}>
        <span className={styles.title}>The AWARE Questionnaire (Revised Form)</span>
      </div>
      <div className={styles.paragraph}>
        <span className={styles.text}>The AWARE Questionnaire (Advance WArning of RElapse) was designed as a measure of thewarning signs of relapse, as described by Gorski (Gorski & Miller, 1982). In a prospective study ofrelapse following outpatient treatment for alcohol abuse or dependence (Miller et al., 1996) we foundthe AWARE score to be a good predictor of the occurrence of relapse (r = .42, p &lt; .001).  Withsubsequent analyses, we refined the scale from its 37-item original version to the current 28-item scale(version 3.0) (Miller & Harris, 2000).
        </span>
      </div>
      <div className={styles.paragraph}>
        <span className={styles.text}>The items are arranged in the order of occurrence of warning signs, as hypothesized by Gorski.  In ourprospective study, however, we found no evidence that the warning signs actually occur in this order inreal time (Miller & Harris, 2000).  Rather, the total score was the best predictor of impending relapse.
        </span>
      </div>
      <div className={styles.paragraph}>
        <span className={styles.text}>
          ADMINISTRATION: This is a self-report questionnaire that can be filled out by the client.  Be surethat the client understands the 1-7 rating scale.  When the client has finished, make sure that all itemshave been answered and none omitted.
        </span>
      </div>
      <div className={styles.paragraph}>
        <span className={styles.text}>
          SCORING:   Total the numbers circled for all items, but reverse the scoring for the following five items: 8, 14, 20, 24, 26. For these five items only:
        </span>
      </div>
      <div className={[styles.paragraph, styles.indent, styles.row].join(" ")}>
        <div className={styles.left}>
          <div className={styles.top}>
            <span className={styles.text}>If the client circles this number:</span>
          </div>
          <div className={styles.bottom}>
            <span className={styles.text}>Add this number to the total score:</span>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.top}>
            <span className={[styles.text, styles.textSpacing].join(" ")}>
              1234567
            </span>
          </div>
          <div className={styles.bottom}>
            <span className={[styles.text, styles.textSpacing].join(" ")}>
              7654321
            </span>
          </div>
        </div>
      </div>
      <div className={styles.paragraph}>
        <span className={styles.text}>
          INTERPRETATION: The higher the score, the more warning signs of relapse are being reported bythe client.  The range of scores is from 28 (lowest possible score) to 196 (highest possible score).  Thefollowing table shows the probability of heavy drinking (not just a slip) during the next two months,based on our prospective study of relapse in the first year after treatment (Miller & Harris, 2000).
        </span>
      </div>
      <div className={styles.paragraph}>
        <span className={styles.tableTitle}>Probability of Heavy Drinking During the Next Two Months</span>
        <table>
          <tr>
            <th style={{ width: 200 }}>AWARE Score</th>
            <th style={{ width: 200 }}>If <i>already</i> drinking inthe prior 2 months</th>
            <th style={{ width: 200 }}>If <i>abstinent</i> during theprior 2 months</th>
          </tr>
          <tr>
            <td>28-55</td>
            <td>37%</td>
            <td>11%</td>
          </tr>
          <tr>
            <td>56-69</td>
            <td>62%</td>
            <td>21%</td>
          </tr>
          <tr>
            <td>70-83</td>
            <td>72%</td>
            <td>24%</td>
          </tr>
          <tr>
            <td>84-97</td>
            <td>82%</td>
            <td>25%</td>
          </tr>
          <tr>
            <td>98-111</td>
            <td>86%</td>
            <td>28%</td>
          </tr>
          <tr>
            <td>112-125</td>
            <td>77%</td>
            <td>37%</td>
          </tr>
          <tr>
            <td>126-168</td>
            <td>90%</td>
            <td>43%</td>
          </tr>
          <tr>
            <td>169-196</td>
            <td>&gt;95%</td>
            <td>53%</td>
          </tr>
        </table>
      </div>
      <div className={styles.paragraph}>
        <span className={styles.text}>
          This instrument was developed through research funded by the National Institute on Alcohol Abuseand Alcoholism (NIAAA, contract ADM 281-91-0006).  It is in the public domain, and may be usedwithout specific permission provided that proper acknowledgment is given to its source.  Theappropriate citation is Miller & Harris (2000).
        </span>
      </div>
      <div className={[styles.paragraph, styles.centered].join(" ")}>
        <span className={styles.text}>References</span>
      </div>
      <div className={styles.paragraph}>
        <span className={styles.reference}>
          Gorski, T. F., & Miller, M. (1982).  <i>Counseling for relapse prevention.</i>  Independence, MO:Herald House - Independence Press.
        </span>
        <span className={styles.reference}>
          Miller, W. R., & Harris, R. J. (2000).  A simple scale of Gorski’s warning signs for relapse. <i>Journal of Studies on Alcohol</i>, 61, 759-765.
        </span>
        <span className={styles.reference}>
          Miller, W. R., Westerberg, V. S., Harris, R. J., & Tonigan, J. S. (1996).  What predicts relapse? Prospective testing of antecedent models.  <i>Addiction,</i> 91 (Supplement), S155-S171
        </span>
      </div>
      <div className={styles.paragraph} style={{ marginTop: 100 }}>
        <span className={[styles.text, styles.bold, styles.centered].join(" ")}>
          AWARE Questionnaire 3.0
        </span>
      </div>
      <div className={styles.paragraph}>
        <span className={styles.text}>Please read the following statements and for each one circle a number, from 1 to 7, to indicate <i>how much this has been true for you recently</i>.  Please circle one and only one number for every statement.</span>
        <div className={styles.centered}>
          <table className={styles.answerTable}>
            <tr>
              <th style={{ width: 300, height: 50 }}></th>
              <th className={[styles.height50, styles.width100, styles.textCenter].join(" ")}>Never</th>
              <th className={[styles.height50, styles.width100, styles.textCenter].join(" ")}>Rarely</th>
              <th className={[styles.height50, styles.width100, styles.textCenter].join(" ")}>Sometimes</th>
              <th className={[styles.height50, styles.width100, styles.textCenter].join(" ")}>Fairly often</th>
              <th className={[styles.height50, styles.width100, styles.textCenter].join(" ")}>Often</th>
              <th className={[styles.height50, styles.width100, styles.textCenter].join(" ")}>Almost always</th>
              <th className={[styles.height50, styles.width100, styles.textCenter].join(" ")}>Always</th>
            </tr>
            {props?.viewSurvey?.questions.map((question, index) => {
              return (
                <tr key={question.id}>
                  <td>{index + 1}. {question.text}</td>
                  {question.availableAnswers.map((aA, i) => {
                    const selectedAnswer = question.answer?.id === aA.id;
                    return (
                      <td key={aA.id} className={[styles.height50, styles.width100, styles.textCenter].join(" ")}>
                        <div className={selectedAnswer ? styles.selectedAnswerAware : ''}>
                          {i + 1}
                        </div>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </table>
        </div>
      </div>
      <div className={styles.paragraph} style={{ marginTop: 100 }}>
        <span className={[styles.text, styles.bold, styles.centered].join(" ")}>
          Scoring sheet for AWARE Questionnaire 3.0
        </span>
      </div>
      <div className={[styles.paragraph, styles.row, styles.left50].join(" ")}>
        <div className={styles.width200}>
          <span className={styles.text}>For these items, record the number circled</span>
        </div>
        <div className={[styles.left50, styles.width200].join(" ")}>
          <span className={styles.text}>For these 5 items, reverse the scale (* see below)</span>
        </div>
      </div>
      {props?.viewSurvey?.questions.map((question, index) => getAnswerRow(question, index + 1))}
      <div className={styles.paragraph}>
        <span className={styles.text}>
          TOTALS: 
        </span>
        <span className={styles.regSum}>
            {regSum}
        </span> 
        <span className={styles.plus}>
          +
        </span>
        <span className={styles.invertedSum}>
          {invertedSum}
        </span>
        <span className={styles.equal}>
          =
        </span>
        <div style={{display: 'inline-block'}}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          width: 100
        }}>
          <span className={styles.answerValue}>
            {props.viewSurvey.value}
          </span>
          <span>
            AWARE Score
          </span>
        </div>
        </div>

      </div>
    </>
  );
};

export default ViewAwareSurveyModal;
