import {
  SimpleData,
  VitalsData,
  PatientScoresData,
  ReadinessData,
  VitalsReferences,
  PatientScoresReferences,
  PatientDataScores,
  Score,
  EngagementScore,
  ReadinessScoreReferences,
  RateScore,
  ReportInsight,
} from '../../interfaces/report';

export function isSimpleData(data): data is SimpleData[] {
  return (
    data.length > 0 &&
    Object.prototype.hasOwnProperty.call(data[0], 'ts') &&
    Object.prototype.hasOwnProperty.call(data[0], 'value') &&
    Object.keys(data[0]).length === 2
  );
}

export function isVitalsData(data): data is VitalsData[] {
  return (
    data.length > 0 &&
    Object.prototype.hasOwnProperty.call(data[0], 'ts') &&
    Object.prototype.hasOwnProperty.call(data[0], 'hr') &&
    Object.prototype.hasOwnProperty.call(data[0], 'hrv') &&
    Object.prototype.hasOwnProperty.call(data[0], 'rr')
  );
}

export function isVitalReference(data): data is VitalsReferences {
  return (
    !!data &&
    Object.prototype.hasOwnProperty.call(data, 'hr') &&
    Object.prototype.hasOwnProperty.call(data, 'hrv') &&
    Object.prototype.hasOwnProperty.call(data, 'rr')
  );
}

export function isPatientScoresData(data): data is PatientScoresData | PatientScoresReferences | PatientDataScores {
  return (
    !!data &&
    Object.prototype.hasOwnProperty.call(data, 'mood') &&
    Object.prototype.hasOwnProperty.call(data, 'sleep') &&
    Object.prototype.hasOwnProperty.call(data, 'stress') &&
    Object.prototype.hasOwnProperty.call(data, 'activity')
  );
}

export function isRegularScoreSingle(data): data is Score {
  return (
    !!data && Object.prototype.hasOwnProperty.call(data, 'title') && Object.prototype.hasOwnProperty.call(data, 'value')
  );
}

export function isEngagementScore(data): data is EngagementScore {
  return (
    !!data &&
    Object.prototype.hasOwnProperty.call(data, 'engagement') &&
    Object.prototype.hasOwnProperty.call(data, 'level') &&
    Object.prototype.hasOwnProperty.call(data, 'color')
  );
}

export function isInsightData(data): data is ReportInsight[] {
  return (
    data.length > 0 &&
    Object.prototype.hasOwnProperty.call(data[0], 'title') &&
    Object.prototype.hasOwnProperty.call(data[0], 'description') &&
    Object.prototype.hasOwnProperty.call(data[0], 'relapseRisk') &&
    Object.prototype.hasOwnProperty.call(data[0], 'viviHealthScore') &&
    Object.prototype.hasOwnProperty.call(data[0], 'positive') &&
    Object.prototype.hasOwnProperty.call(data[0], 'engagement')
  );
}

export function isReportInsight(data): data is ReportInsight {
  return (
    !!data &&
    Object.prototype.hasOwnProperty.call(data, 'title') &&
    Object.prototype.hasOwnProperty.call(data, 'description') &&
    Object.prototype.hasOwnProperty.call(data, 'relapseRisk') &&
    Object.prototype.hasOwnProperty.call(data, 'viviHealthScore') &&
    Object.prototype.hasOwnProperty.call(data, 'positive') &&
    Object.prototype.hasOwnProperty.call(data, 'activity') &&
    Object.prototype.hasOwnProperty.call(data, 'engagement')
  );
}

export function isRateScore(data): data is RateScore {
  return (
    !!data &&
    Object.prototype.hasOwnProperty.call(data, 'baseline') &&
    Object.prototype.hasOwnProperty.call(data, 'average')
  );
}

export function isReadinessScoreReference(data): data is ReadinessScoreReferences {
  return !!data && Object.prototype.hasOwnProperty.call(data, 'engagement');
}

export function getReadinessReference(data): ReadinessScoreReferences | null {
  if (isReadinessScoreReference(data)) return data;
  return null;
}

export function getRateScore(data): RateScore | null {
  if (isRateScore(data)) return data;
  return null;
}

export function getRegularScore(data): Score | null {
  if (isRegularScoreSingle(data)) return data;
  return null;
}

export function getEngagementScore(data): EngagementScore | null {
  if (isEngagementScore(data)) return data;
  return null;
}
