import React, { FunctionComponent } from 'react';
import styles from './form.module.scss';
import { Field, FieldAttributes, useField } from 'formik';

type Props = {
  id?: number;
  name: string;
  label: string;
  type?: string;
  size?: string;
} & FieldAttributes<any>;

const TextAreaInput: FunctionComponent<Props> = props => {
  const [field, meta] = useField(props);
  const fieldSize = props.size === 'small' ? styles.field__small : styles.field__big;
  return (
    <div className={fieldSize} style={{ width: 700, marginRight: 27 }}>
      <label htmlFor={props.id || props.name}>{props.label}</label>
      <textarea
        className={meta.touched && meta.error ? styles.inputError : props.disabled ? styles.inputDisabled : ''}
        {...field}
        {...props}
        disabled={props.disabled}
      />
      {meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
    </div>
  );
};

export default TextAreaInput;
