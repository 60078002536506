import { destroy, get, post, put } from './base';
import { Done, done, Failed, failed, RemoteData } from '../../utils/remote-data';
import { Geofence, GeofencePeriods, GeofenceRequest, GeoLocationArray } from '../../interfaces/geofence';
import { castDateTimeFilters, DateTimeFiltersRequest } from '../../utils/requestHelper';

export const getGeofenceLocations = async (
  patientId: string,
  dateFilters: DateTimeFiltersRequest,
): Promise<RemoteData<GeofencePeriods>> => {
  try {
    const res = await get('/geofence-periods', {
      params: {
        patientId: patientId,
        ...castDateTimeFilters(dateFilters),
      },
    });
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getGeofences = async (patientId: string): Promise<RemoteData<{ values: Geofence[] }>> => {
  try {
    const res = await get(`/patients/${patientId}/geofences`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const createGeofence = async (
  geofence: GeofenceRequest,
  patientId: string,
): Promise<Done<Geofence> | Failed> => {
  try {
    const res = await post(`/patients/${patientId}/geofences/`, geofence);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const editGeofence = async (
  geofence: GeofenceRequest,
  geofenceId: number | undefined,
  patientId: string,
): Promise<Done<Geofence> | Failed> => {
  try {
    const res = await put(`/patients/${patientId}/geofences/${geofenceId}`, geofence);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const deleteGeofence = async (geofenceId: number, patientId: string): Promise<Done<any> | Failed> => {
  try {
    const res = await destroy(`/patients/${patientId}/geofences/${geofenceId}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getPatientGeolocations = async (
  patientId: string,
  dateFilters: DateTimeFiltersRequest,
): Promise<RemoteData<GeoLocationArray>> => {
  try {
    const res = await get(`/locations?patientId=${patientId}`, {
      params: {
        ...castDateTimeFilters(dateFilters),
      },
    });
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};
