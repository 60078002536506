import React from 'react';
import styles from '../../chart.module.scss';
import { getPatientTimezone } from 'contexts/PatientContext';
import { displayDateTimeFormat } from 'utils/data-parser';

type Props = {
  date?: number;
  dates?: number[];
  title: string;
  value: number;
  name?: string;
  reverse: boolean;
  selectedQuestion: any;
};

export const GadQuestionToolTip: React.FC<Props> = ({ date, dates, title, value, reverse, name, selectedQuestion }): JSX.Element => {
  const timezone = getPatientTimezone();
  return (
    <>
      <div className={`${reverse ? styles.chartToolTipReverse : styles.chartToolTip} ${dates ? styles.hasDates : ''}`}>
        {dates ? (
          <div className={styles.chartTooltipContentDates}>
            <span>{`START: ${displayDateTimeFormat(dates[0], timezone)}`}</span>
            <span>{`END: ${displayDateTimeFormat(dates[1], timezone)}`}</span>
          </div>
        ) : (
          <div className={styles.chartTooltipContent}>
            <span>{displayDateTimeFormat(date!, timezone)}</span>
          </div>
        )}
        <div>
          <span className={styles.chartToolTipTitle}>
            Answer: {value} - {title}
          </span>
        </div>
      </div>
    </>
  );
};
