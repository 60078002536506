import React, { FunctionComponent } from 'react';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Patient } from '../../../../interfaces/patient';
import { Practitioner } from '../../../../interfaces/practitioner';
import TableHeader from '../../components/table-header';
import DocInfoHeader from '../../components/doc-info-header';
import { Insight } from '../../../../interfaces/insight';
import { getPatientStatus } from '../../../../utils/data-parser';
import moment from 'moment-timezone';
import { PATIENT_STATUS } from '../../../../utils/constants';
import CheckMarkGray from '../../../../assets/icons/clear_icon.png';
import { caseOf } from '../../../../utils/case-of';

const styles = StyleSheet.create({
  page: {
    paddingTop: 100,
    paddingLeft: 208,
    paddingRight: 189,
    paddingBottom: 120,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderBottomColor: '#A3A6B3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingBottom: 18,
  },
  pageNote: {
    position: 'absolute',
    bottom: 90,
    right: 120,
    fontSize: 24,
  },
  content: {
    marginBottom: 45,
    borderBottomColor: '#A3A6B3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    fontSize: 30,
    fontWeight: 'normal',
    color: '#323335',
    borderBottomColor: '#A3A6B3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingBottom: 17,
    paddingTop: 17,
  },
  clearedOn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    width: 260,
    height: 100,
    objectFit: 'contain',
  },
});

type Props = {
  dateRange: { start: string; end: string; timestamp?: string };
  timezone: string;
  patient?: Patient;
  practitioner?: Practitioner;
  insights: Insight[];
  organizationLogo: string;
};

const InsightTablePdf: FunctionComponent<Props> = ({
  patient,
  dateRange,
  practitioner,
  insights,
  timezone,
  organizationLogo,
}) => {
  const rowWidth = 2900 / 4;

  console.log("insights: ", insights);

  const getStatusStyle = (status: string) => {
    return caseOf()
      .case(PATIENT_STATUS.CRITICAL, '#ed6f6a')
      .case(PATIENT_STATUS.AT_RISK, '#efbb55')
      .defaultCase(() => '#6fcf97')
      .eval(status);
  };

  return (
    <Document>
      <Page wrap={true} style={styles.page} size={{ width: 3300, height: 2500 }}>
        <Text
          style={styles.pageNote}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
        <View fixed />
        <View style={styles.title} fixed>
          {organizationLogo ? (
            <Image src={organizationLogo} style={styles.logo} />
          ) : (
            <Text>{practitioner?.organization.name} (Insight Report)</Text>
          )}
          <Text>ViviClinic Insight Report</Text>
          <Text>Patient ID: {patient?.emrIdentifier || '--'}</Text>
        </View>
        <DocInfoHeader patient={patient!} practitioner={practitioner!} dateRange={dateRange} />
        <TableHeader
          headers={[
            { title: 'Insight', width: rowWidth },
            { title: 'Date & Time', width: rowWidth },
            //{ title: 'Status', width: rowWidth },
            //{ title: 'Alert Insight Cleared On', width: rowWidth },
          ]}
        />
        {insights.map((insight, index) => (
          <View style={styles.tableRow} key={index}>
            <Text style={{ width: rowWidth }}>{insight.title}</Text>
            <Text style={{ width: rowWidth }}>{moment.utc(insight.endTimestamp).tz(timezone).format('lll')}</Text>
            <Text
              style={{
                width: rowWidth,
                color: getStatusStyle(getPatientStatus(insight.relapseRisk!)),
              }}
            >
              {getPatientStatus(insight.relapseRisk!)}
            </Text>
            {!insight.hasAlert ? (
              <Text style={{ width: rowWidth }}>N/A</Text>
            ) : !insight.clearedAt ? (
              <Text style={{ width: rowWidth }}>--</Text>
            ) : (
              <View style={{ width: rowWidth, ...styles.clearedOn }}>
                <Image style={{ width: 30, height: 30, marginRight: '20px', color: '#a3a6b3' }} src={CheckMarkGray} />
                <Text>Cleared - {moment.utc(insight.clearedAt).tz(timezone).format('lll')}</Text>
              </View>
            )}
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default InsightTablePdf;
