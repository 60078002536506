import React, { useEffect, useMemo, useState } from 'react';
import styles from './relapse.module.scss';
import { getCurrentRelapseRisk } from '../../services/api/firestore';
import { Tooltip } from '../common/tooltip';
import { useFirebaseDateWithOrganizationTimezone, useHover } from '../../utils/hooks';
import { displayDateTimeFormat, parseRelapseRisk } from '../../utils/data-parser';
import moment from 'moment-timezone';
import ArrowUp from '../../assets/icons/icn_arrow_big.svg';
import { RelapseRisk } from '../../interfaces/vitals';
import { RelapseRiskScoreComponentWeight } from '../../utils/constants';
import firebase from 'firebase/app';
import { getPatientTimezone } from '../../contexts/PatientContext';

const RelapsePanel = ({ patient }) => {
  const [relapseRisk, setRelapseRisk] = useState<RelapseRisk>({
    score: 0,
    timestamp: moment().valueOf(),
    components: undefined,
  });
  const [relapseValueText, setRelapseValueText] = useState('');
  const [hoverRef, isHovered] = useHover<HTMLParagraphElement>();
  const dateTz = useFirebaseDateWithOrganizationTimezone();
  const patientTimezone = getPatientTimezone();

  useEffect(() => {
    if (!patient) {
      return;
    }

    const unsubscribeInsight = getCurrentRelapseRisk(patient.firebaseUid, {
      next: (Snapshot: firebase.firestore.DocumentSnapshot) => {
        if (Snapshot) {
          const current = Snapshot.data();
          if (current) {
            const { score, timestamp, components } = current;
            setRelapseRisk({
              score,
              components,
              timestamp: dateTz(timestamp).valueOf(),
            });
            setRelapseValueText(parseRelapseRisk(score));
          }
        }
      },
    });

    return (): void => {
      unsubscribeInsight();
    };
  }, [patient]);

  const componentsOrderedByContribution: { title: string; value: number }[] = useMemo(() => {
    return relapseRisk.components
      ? Object.entries(relapseRisk.components)
        .flatMap((value: [string, number]) =>
          Object.keys(RelapseRiskScoreComponentWeight).includes(value[0])
            ? [
              {
                title: value[0],
                value: RelapseRiskScoreComponentWeight[value[0]] * (1 - value[1]),
              },
            ]
            : [],
        )
        .sort((a, b) => b.value - a.value)
      : [];
  }, [relapseRisk]);

  return (
    <div className={styles.relapse}>
      <div className={styles.header}>
        <span className={styles.title}>Compliance Risk</span>
        <Tooltip baseStyles={styles.infoTooltip} type='right' background='#F5F6FA'>
          <div>
            <h3 className={styles.mainTitle}>Compliance Risk</h3>
            <p>
              Compliance Risk (or risk of possible relapse) is an approximate risk measurement informed by a combination of
              factors. The system continuously observes and calculates the Compliance Risk Score based on events and client
              trends in the past 7 days. The higher the Compliance Risk Score the higher the probability of disengagement from the recovery process, which
              may be an indication of increased risk of relapse.
            </p>
            <p>Compliance Risk is made up of several factors:</p>
            <ul>
              <li>
                <div>
                  <strong>• BAC check-ins,</strong> including: missed BAC's, failed BAC's, passed BAC's.
                </div>
              </li>
              <li>
                <div>
                  <strong>•</strong> Meeting Attendance - attended or missed
                </div>
              </li>
              <li>
                <strong>•</strong> {"Sleep - Increased risk if sleep is < 6 hours or > 9 hours"}
              </li>
            </ul>
            <div className={styles.bottomContainer}>
              <div>
                <p>
                  <strong>Compliance Risk Ranges</strong>
                </p>
                <div className={styles.relapseRange}>
                  <p>
                    <span className={styles.range}>0-34</span>
                    <span className={styles.text}>
                      <b>Low</b> degree of compliance risk
                    </span>
                  </p>
                  <p>
                    <span className={styles.range}>34-64</span>
                    <span className={styles.text}>
                      <b>Moderate</b> degree of compliance risk
                    </span>
                  </p>
                  <p>
                    <span className={styles.range}>65-100</span>
                    <span className={styles.text}>
                      <b>High</b> degree of compliance risk
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.statusContainer}>
                <p style={{opacity: 1}}>
                  <strong>Status</strong>
                </p>
                <div className={`${styles.statusLabel} ${styles.stable}`}>
                  <strong>Stable</strong>
                </div>
                <div className={`${styles.statusLabel} ${styles.atRisk}`}>
                  <strong>At Risk</strong>
                </div>
                <div className={`${styles.statusLabel} ${styles.critical}`}>
                  <strong>Critical</strong>
                </div>
              </div>
              {/* <div className={styles.inlineText}>
                <em>
                  The Compliance Risk Score also determines the client’s triage status and corresponding color state.
                </em>
              </div> */}
            </div>
          </div>
        </Tooltip>
      </div>

      <span className={styles.value}>{relapseValueText || <>&nbsp;</>}</span>
      <svg className={styles.gauge} viewBox='0 0 100 50'>
        <g fillOpacity='0' strokeWidth='10'>
          <path d='M5 50a45 45 0 1 1 90 0' stroke='#A3A6B3' />
          <path
            strokeDashoffset={`calc(142 - (${relapseRisk.score || 0} * 142 / 100))`}
            d='M5 50a45 45 0 1 1 90 0'
            stroke='#333335'
            strokeDasharray='142'
          />
        </g>
      </svg>
      <div ref={hoverRef}>
        <p className={styles.percentage}>{relapseRisk.score}%</p>
        {isHovered && (
          <div className={styles.relapseScoreHover}>
            <span>{displayDateTimeFormat(relapseRisk.timestamp, patientTimezone)}</span>
            <p>
              <strong>
                RELAPSE RISK: {relapseRisk.score}% {relapseValueText}
              </strong>
            </p>
            <div className={styles.relapseContent}>
              <div className={styles.textVertical}>
                <div className={styles.rotate}>
                  <span>
                    <img alt='arrow' src={ArrowUp} />
                  </span>
                  <strong>Highest Contributor</strong>
                </div>
              </div>
              <div className={styles.componentsContainer}>
                {componentsOrderedByContribution.map((component, index) => (
                  <div key={index}>{component.title}</div>
                ))}
              </div>
            </div>
            <div className={styles.hoverRight} />
          </div>
        )}
      </div>
    </div>
  );
};

export default RelapsePanel;
