import React, { useMemo, Dispatch, SetStateAction } from 'react';
import Table from '../common/table/table';
import { Practitioner, Practitioners } from '../../interfaces/practitioner';
import styles from './admin-practitioners.module.scss';
import { Column } from 'react-table';
import { ROLE_CODES } from '../../utils/constants';
import { RemoteData } from '../../utils/remote-data';
import MaleAvatarIcon from '../../assets/icons/icn-inverted-male.svg';
import FemaleAvatarIcon from '../../assets/icons/icn-inverted-female.svg';
import { displayFullName } from '../../utils/data-parser';

type Props = {
  practitioners: RemoteData<Practitioners>;
  setSelectedPractitioner: Dispatch<SetStateAction<Practitioner | undefined>>;
};

const AdminPractitionersTable: React.FC<Props> = ({ practitioners, setSelectedPractitioner }): JSX.Element => {
  const columns: Column<Practitioner>[] = useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: (originalRow): object => {
          return {
            nameDisplay: displayFullName(originalRow.name),
            profilePicture: originalRow.image ? originalRow.image.base64 : null,
          };
        },
        width: 260,
        Cell: ({ cell: { value, row } }) => (
          <div className={styles.nameContainer}>
            {value.profilePicture ? (
              <img className={styles.profilePicture} src={`data:image/jpeg;base64,${value.profilePicture}`} />
            ) : (
              <img
                className={styles.picturePlaceholder}
                src={row.original.gender === 'FEMALE' ? FemaleAvatarIcon : MaleAvatarIcon}
              />
            )}
            <span className={styles.overflowWrapped}>{value.nameDisplay}</span>
          </div>
        ),
      },
      {
        Header: 'ROLE',
        width: 317,
        accessor: originalRow => {
          const admin = originalRow.roles.roles.find(el => el.code === ROLE_CODES.ADMIN);
          const clinician = originalRow.roles.roles.find(el => el.code === ROLE_CODES.CLINICIAN);
          const aftercare = originalRow.roles.roles.find(el => el.code === ROLE_CODES.AFTERCARE);
          return {
            admin: admin?.display ?? null,
            clinician: clinician?.display ?? null,
            aftercare: aftercare?.display ?? null,
          };
        },
        Cell: ({ cell: { value } }) => (
          <div className={styles.overflowWrapped}>
            {value.admin && <span>{value.admin}</span>} <br />
            {value.clinician && <span>{value.clinician}</span>} <br />
            {value.aftercare && <span>{value.aftercare}</span>} <br />
          </div>
        ),
      },
      {
        Header: 'PHONE NUMBER',
        accessor: (originalRow): string => {
          return originalRow.contact.cellphone;
        },
        width: 266,
      },
      {
        Header: 'EMAIL ADDRESS',
        accessor: (originalRow): string | number => {
          return originalRow.contact.email;
        },
        width: 281,
      },
      {
        Header: 'ACTIVE CLIENTS',
        width: 160,
        accessor: 'activePatients',
      },
      {
        Header: '',
        accessor: 'id',
        width: 100,
        Cell: ({ cell }): JSX.Element => (
          <span className={styles.action} onClick={() => setSelectedPractitioner(cell.row.original)}>
            View
          </span>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'timestamp', desc: true }] }),
    [],
  );

  const getRowProps = (row, idx) => {
    return {
      key: `${row?.id}`,
      className: styles.tr__md,
    };
  };

  const getHeaderProps = column => {
    return {
      className: styles.th,
      key: `${column?.id}`,
      style: {
        width: column?.width,
        minWidth: column?.minWidth,
        maxWidth: column?.maxWidth,
      },
    };
  };

  const getCellProps = (cell, idx) => {
    return {
      key: `${cell?.column.id}`,
      className: styles.td,
    };
  };

  const getTableBodyProps = () => {
    return {
      className: styles.tbody,
      style: {
        height: '564px',
      },
    };
  };

  const getTableProps = () => {
    return {
      className: practitioners.status === 'Done' && !practitioners.data.size ? styles.noContent : styles.table,
      style: {
        width: 'calc(100% - 36px)',
        marginLeft: '18px',
      },
    };
  };

  return (
    <Table<Practitioner>
      columns={columns}
      data={practitioners.status === 'Done' ? practitioners.data.values : []}
      isLoading={practitioners.status === 'Loading'}
      error={practitioners.status === 'Failed'}
      getTableProps={() => getTableProps()}
      getRowProps={(row, idx) => getRowProps(row, idx)}
      getHeaderProps={column => getHeaderProps(column)}
      getCellProps={(cell, idx) => getCellProps(cell, idx)}
      getTableBodyProps={() => getTableBodyProps()}
      defaultColumn={{ width: 180, minWidth: 100 }}
      initialState={defaultSorting}
      emptyDataMessage='There are no Practitioners at this time.'
    />
  );
};

export default AdminPractitionersTable;
