import Modal from 'components/modal/Modal';
import { PatientContext } from 'contexts/PatientContext';
import {
  useDeleteQuestionnaireScheduleMutation,
  useQuestionnaireSchedulesOfUserQuery,
  useUpdateQuestionnaireScheduleMutation,
} from 'graphql/codegen/hooks';
import { QuestionnaireSchedule } from 'graphql/codegen/schemas';
import * as React from 'react';
import AddQuestionnaireScheduleModal from './modals/schedule-modal';
import styles from './schedules-page.module.scss';
import SchedulesTable from './tables/schedules-table';

const SchedulesPage: React.FC<{}> = () => {
  const { patient } = React.useContext(PatientContext);
  const {
    data: schedules,
    loading,
    error,
    refetch,
  } = useQuestionnaireSchedulesOfUserQuery({
    variables: { fbId: patient?.firebaseUid ?? '' },
    skip: !patient?.firebaseUid,
  });
  const [deleteQuestionnaire, { loading: deleting }] = useDeleteQuestionnaireScheduleMutation();
  const [updateQuestionnaire, { loading: updating }] = useUpdateQuestionnaireScheduleMutation();

  const [showUpsertSchedule, setShowUpsertSchedule] = React.useState(false);
  const [selectedSchedule, setSelectedSchedule] = React.useState(null as QuestionnaireSchedule | null);
  const closeModal = () => {
    setShowUpsertSchedule(false);
    setSelectedSchedule(null);
    refetch();
  };

  return (
    <div>
      <section className={styles.commandHeader}>
        <a href='#' className='href' onClick={() => setShowUpsertSchedule(true)}>
          Create Schedule
        </a>
      </section>
      <SchedulesTable
        patient={patient!}
        onSelect={setSelectedSchedule}
        schedules={(schedules?.questionnaireSchedulesOfUser as QuestionnaireSchedule[]) || []}
        loading={loading || deleting}
        error={error}
        onDelete={schedule => deleteQuestionnaire({ variables: { id: schedule.scheduleId } }).then(() => refetch())}
      />
      <Modal
        contentStyle='bigContent'
        closeModal={closeModal}
        show={showUpsertSchedule || !!selectedSchedule}
        showBtnClose
      >
        <AddQuestionnaireScheduleModal
          key={`modal-${selectedSchedule?.scheduleId ?? 'new'}`}
          closeModal={closeModal}
          patient={patient!}
          schedule={selectedSchedule}
        />
      </Modal>
    </div>
  );
};

export default SchedulesPage;
