import React from 'react';
import { getPatientTimezone } from 'contexts/PatientContext';
import { curveLinear } from 'd3-shape';
import { QuestionnaireInfo } from 'graphql/codegen/schemas';
import moment from 'moment-timezone';
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { CHARTS_SIZES, GRAPH_REVERSE_TOOLTIP_COORDINATE } from '../../../../../utils/constants';
import { Tooltip as InfoTooltip } from '../../../tooltip';
import styles from '../../chart.module.scss';
import { getTicksDD, getTicksH } from '../../utlity';
import { PhqQuestionToolTip } from './phq-question-tooltip';

type Props = {
  title: string;
  start: string;
  end: string;
  data: { ts: number; value: number }[];
  survey?: QuestionnaireInfo;
  showXAxis?: boolean;
  selectedQuestion?: any;
};
const PhqQuestionChart = ({ title, start, end, data, survey, showXAxis, selectedQuestion }: Props) => {
  const GRAPH_WIDTH_PX = CHARTS_SIZES.CDP_WIDTH;
  const GRAPH_HEIGHT_PX = showXAxis ? CHARTS_SIZES.UR_HEIGHT : CHARTS_SIZES.CDP_HEIGHT;
  const patientTimezone = getPatientTimezone();

  const customToolTip = ({
    active,
    coordinate,
    payload,
    label,
  }: {
    active: boolean;
    coordinate: { x: number; y: number };
    label: number;
    payload: Array<any>;
  }): JSX.Element | null => {
    if (!active) {
      return null;
    }
    const reverse = coordinate.x > GRAPH_REVERSE_TOOLTIP_COORDINATE;
    let yValue = payload ? payload[0]?.payload?.value : 'Unable to retrieve value';
    const text = payload ? payload[0]?.payload?.text : '';
    return <PhqQuestionToolTip date={label} title={text} value={yValue} reverse={reverse} selectedQuestion={selectedQuestion} />;
  };

  return (
    <div className={styles.chartContainer}>
      <div className={styles.chartHeader}>
        <span className={styles.chartTitle}>{title}</span>
        {survey?.description && (
          <InfoTooltip portal baseStyles={`${styles.infoTooltip} ${styles.events}`} type='left' background='#F5F6FA'>
            <div dangerouslySetInnerHTML={{ __html: survey?.description }} />
          </InfoTooltip>
        )}
      </div>
      <AreaChart width={GRAPH_WIDTH_PX} height={GRAPH_HEIGHT_PX} data={data}>
        <defs>
          <linearGradient id='d1' x1={0} y1={0} x2={0} y2={1}>
            <stop offset='3%' stopColor='#417EB9' stopOpacity={0.8} />
            <stop offset='97%' stopColor='rgba(65, 126, 185, 0.2)' stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis
          xAxisId={0}
          dy={-10}
          dx={2}
          tickLine={true}
          tickSize={16}
          tickFormatter={(unixTime): string => moment.utc(unixTime).tz(patientTimezone).format('hA')}
          dataKey='ts'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
          hide={!showXAxis}
        />
        <XAxis
          xAxisId={2}
          dy={-18}
          dx={0}
          style={{
            fontSize: '11',
            fontWeight: 'bold',
            fontFamily: 'sans-serif',
            color: '#000000',
          }}
          tickLine={false}
          axisLine={false}
          tickFormatter={(unixTime): string => moment.utc(unixTime).format('ddd')}
          dataKey='ts'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
          hide={!showXAxis}
        />

        <XAxis
          xAxisId={3}
          dy={-34}
          dx={0}
          tickLine={false}
          axisLine={false}
          tickFormatter={(unixTime): string => moment.utc(unixTime).format('M/D')}
          dataKey='ts'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
          hide={!showXAxis}
        />
        <YAxis dataKey='value' interval={0} tickSize={0} domain={[0, 3]} ticks={[0, 1, 2, 3]} tickMargin={10} />
        <CartesianGrid stroke='#A3A6B3' />
        <Tooltip content={customToolTip} allowEscapeViewBox={{ x: false, y: true }} />
        <Area type={curveLinear} dataKey='value' stroke='#417EB9' fill='url(#d1)' strokeWidth='2' />
      </AreaChart>
    </div>
  );
};

export default PhqQuestionChart;
