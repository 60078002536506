import React, { useState, useEffect } from 'react';
import { FieldArray, FieldAttributes, useField } from 'formik';
import styles from './form.module.scss';
import CloseIcon from '../../../assets/icons/close_blue.svg';
import { displayFullName } from '../../../utils/data-parser';
import { Name } from '../../../interfaces/common';
import { ROLE_CODES } from '../../../utils/constants';
import { Practitioner } from '../../../interfaces/practitioner';

type Props<T extends object = {}> = {
  id?: number;
  name: string;
  label: string;
  items: T[];
  selectedItems: any[];
  itemsHeader: string;
  size: string;
  edit?: boolean;
} & FieldAttributes<{}>;

const SelectArrayInput = <T extends { id?: number | string; name: Name }>({ ...props }: Props<T>) => {
  const [field, meta] = useField(props);
  const [itemsToShow, setItemsToShow] = useState<typeof props.items | undefined>(undefined);
  const [selectedItem, setSelectedItem] = useState<string | undefined>('');

  const fieldSize =
    props.size === 'small' ? styles.field__small : props.size === 'big' ? styles.field__big : styles.field;

  useEffect(() => {
    setItemsToShow(props.items.filter(itm => !props.selectedItems.includes(itm.id)));
  }, [props.selectedItems]);

  useEffect(() => {
    setSelectedItem(undefined);
  }, [itemsToShow]);

  const getPractitionerName = id => {
    const pract = props.items.find(itm => itm.id === id);
    if (pract) {
      return displayFullName(pract.name);
    }
    return '';
  };

  const setAllPractitionersList = (pushArray: (obj: any) => void, practitioners: Practitioner[], role: string) => {
    practitioners.forEach(practitioner => {
      if (practitioner.roles.roles.find(el => el.code === role)) {
        pushArray(practitioner.id);
      }
    });
  };

  return (
    <FieldArray
      name={props.name}
      render={({ remove, push }) => {
        return (
          <div className={fieldSize}>
            <label>{props.label}</label>
            <select
              className={meta.touched && meta.error ? styles.inputError : props.disabled ? styles.inputDisabled : ''}
              name='items'
              onChange={e => !!e.target.value && push(e.target.value)}
              value={selectedItem}
            >
              <option value=''>Select practitioner</option>
              {itemsToShow &&
                itemsToShow.length > 0 &&
                itemsToShow.map(item => (
                  <option key={item.id} value={item.id}>
                    {displayFullName(item.name)}
                  </option>
                ))}
            </select>
            {meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
            {!props.edit && (
              <div className={styles.rowButtons}>
                <button
                  className={styles.addPractitionerButton}
                  type='button'
                  onClick={() =>
                    setAllPractitionersList(push, itemsToShow as unknown as Practitioner[], ROLE_CODES.ADMIN)
                  }
                >
                  Add ALL Admins
                </button>
                <button
                  className={styles.addPractitionerButton}
                  type='button'
                  onClick={() =>
                    setAllPractitionersList(push, itemsToShow as unknown as Practitioner[], ROLE_CODES.CLINICIAN)
                  }
                >
                  Add ALL Clinicians
                </button>
              </div>
            )}
            <div className={styles.fullSectionContainer}>
              <span className={styles.selectArrayHeader}>{props.itemsHeader}</span>
              {props.selectedItems.length ? (
                <div className={styles.cardsContainer}>
                  {props.selectedItems.map(item => (
                    <div key={item} className={styles.itemCard}>
                      <span>{`${getPractitionerName(item)}`}</span>
                      <img src={CloseIcon} onClick={() => remove(props.selectedItems.indexOf(item))} />
                    </div>
                  ))}
                </div>
              ) : (
                <span className={styles.empty}>
                  No practitioners assigned yet. Please add practitioners using the dropdown above.
                </span>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default SelectArrayInput;
