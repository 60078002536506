import { QuestionnaireStatus } from 'graphql/codegen/schemas';
import { caseOf } from 'utils/case-of';
import BacPending from 'assets/icons/bac_pending.png';
import BacMiss from 'assets/icons/bac_missed.png';
import BacPassed from 'assets/icons/bac_passed.png';
import BacFailed from 'assets/icons/bac_failed.png';

export const getStatusColor = caseOf<QuestionnaireStatus>()
  .case(QuestionnaireStatus.Initial, '#6fcf97')
  .case(QuestionnaireStatus.Finished, '#6fcf97')
  .case(QuestionnaireStatus.Started, '#6fcf97')
  .case(QuestionnaireStatus.Waiting, '#efbb55')
  .case(QuestionnaireStatus.Missed, '#ED6F6A')
  .defaultCase(() => '#ED6F6A')
  .compileMapSingle((s: { status: QuestionnaireStatus }) => s.status);
export const getStatusIcon = caseOf<QuestionnaireStatus>()
  .case(QuestionnaireStatus.Initial, BacPassed)
  .case(QuestionnaireStatus.Finished, BacPassed)
  .case(QuestionnaireStatus.Started, BacPassed)
  .case(QuestionnaireStatus.Waiting, BacMiss)
  .case(QuestionnaireStatus.Missed, BacFailed)
  .defaultCase(() => BacFailed)
  .compileMapSingle((s: { status: QuestionnaireStatus }) => s.status);

export const getStatusText = caseOf<QuestionnaireStatus>()
  .case(QuestionnaireStatus.Initial, 'Scheduled')
  .case(QuestionnaireStatus.Finished, 'On Time')
  .case(QuestionnaireStatus.Waiting, 'In Progress')
  .case(QuestionnaireStatus.Missed, 'Missed')
  .case(QuestionnaireStatus.Started, 'Started')
  .defaultCase(() => BacFailed)
  .compileMapSingle((s: { status: QuestionnaireStatus }) => s.status);
