import React from 'react';
import styles from './table-state.module.scss';
import { EmptyIcon } from '../../../assets/icons';

type Props = {
  isModal?: boolean;
  children: React.ReactNode;
};

const TableEmpty: React.FC<Props> = ({ isModal, children }) => {
  return (
    <div className={isModal ? styles.noDataContainer__modal : styles.noDataContainer}>
      <div className='emptyLogo'>
        <EmptyIcon />
      </div>
      {children}
    </div>
  );
};

export default TableEmpty;
