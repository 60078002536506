import { AxiosResponse } from 'axios';
import { destroy, get, post, put } from './base';
import { Practitioner, Practitioners, CreatePractitionerRequest } from '../../interfaces/practitioner';
import { RemoteData, done, failed, Done, Failed } from '../../utils/remote-data';
import { SMSConfig } from '../../interfaces/sms-config';

export const getSelf = async (): Promise<AxiosResponse<Practitioner>> => {
  return await get('/practitioners/me');
};

export const getPractitioner = async (practitionerId: string): Promise<RemoteData<Practitioner>> => {
  try {
    const res = await get(`/practitioners/${practitionerId}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getPractitioners = async (organizationId: string | undefined): Promise<RemoteData<Practitioners>> => {
  if (organizationId) {
    try {
      const res = await get(`/practitioners?organizationId=${organizationId}`);
      return done(res.data);
    } catch (err) {
      return failed(err);
    }
  } else {
    return failed({
      status: 0,
      title: 'Invalid Operation',
      message: 'Organization Id is required.',
    });
  }
};

export const createPractitioner = async (
  practitioner: CreatePractitionerRequest,
): Promise<Done<Practitioner> | Failed> => {
  if (practitioner.organizationId) {
    try {
      const formData = new FormData();
      Object.entries(practitioner).map(entry => {
        formData.append(entry[0], entry[1]);
      });
      const res = await post('/practitioners', formData);
      return done(res.data);
    } catch (err) {
      return failed(err);
    }
  } else {
    return failed({
      status: 0,
      title: 'Invalid Operation',
      message: 'Organization Id is required.',
    });
  }
};

export const editPractitioner = async (
  practitioner: CreatePractitionerRequest,
  practitionerId: string | undefined,
): Promise<Done<any> | Failed> => {
  if (practitioner.organizationId && practitionerId) {
    try {
      const formData = new FormData();
      Object.entries(practitioner).map(entry => {
        formData.append(entry[0], entry[1]);
      });
      const res = await put(`/practitioners/${practitionerId}`, formData);
      return done(res.data);
    } catch (err) {
      return failed(err);
    }
  } else {
    return failed({
      status: 0,
      title: 'Invalid Operation',
      message: 'Organization Id is required.',
    });
  }
};

export const removePractitionerCare = async (
  careTeamId: string,
  practitionerId: string,
): Promise<Done<any> | Failed> => {
  try {
    const res = await destroy(`/care-teams/${careTeamId}/members/${practitionerId}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const updatePractitionerStatus = async (
  practitionerId: string,
  active: boolean,
): Promise<Done<any> | Failed> => {
  try {
    const res = await put(`/practitioners/${practitionerId}/status`, {
      active,
    });
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const acceptPractitionerPolicies = async (): Promise<Done<any> | Failed> => {
  try {
    const res = await put('/practitioners/me/terms-of-use');
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getSMSConfiguration = async (practitionerId: string): Promise<RemoteData<SMSConfig>> => {
  try {
    const res = await get(`/practitioner-sms?practitionerId=${practitionerId}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const storeSMSConfiguration = async (configuration: SMSConfig): Promise<Done<unknown> | Failed> => {
  try {
    const res = await post('/practitioner-sms', configuration);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const sendSMSVerificationCode = async (code: string): Promise<Done<unknown> | Failed> => {
  try {
    const res = await put('/sms-verification', { code });
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const resendSMSVerificationCode = async (): Promise<Done<unknown> | Failed> => {
  try {
    const res = await put('/sms-verification/code');
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const editSMSConfiguration = async (configuration: SMSConfig): Promise<Done<unknown> | Failed> => {
  try {
    const res = await put('/practitioner-sms', configuration);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const deleteSMSConfiguration = async (): Promise<Done<unknown> | Failed> => {
  try {
    const res = await destroy('/practitioner-sms');
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};
