import React, { Dispatch, FunctionComponent, SetStateAction, useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Form, Formik } from 'formik';
import { addMeetingValidator } from '../../../../utils/form-validations';
import Modal from '../../../modal/Modal';
import styles from './upsert-meeting-form.module.scss';
import { RemoteData } from '../../../../utils/remote-data';
import { Meeting } from '../../../../interfaces/meeting';
import { Checkbox, RadioButton, SelectInput, TextInput, CalendarDateInput } from '../../../common/form';

import { MeetingType } from '../../../../utils/constants';
import TextAreaInput from '../../../common/form/textarea-input';
import { createMeeting, editMeeting, meetingTypes, addressSuggestions } from '../../../../services/api/meeting';
import DaySelectorInput from '../../../common/form/day-selector-input';
import { Loading } from '../../../common/loading';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  editMode: boolean;
  getMeetingAction?: () => void;
  selectedMeeting: RemoteData<Meeting>;
};

type REFERENCE = {
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
};

const UpsertMeetingForm: FunctionComponent<Props> = ({ setShow, editMode, selectedMeeting, getMeetingAction }) => {
  const { addToast } = useToasts();
  const { id }: { id: string } = useParams();
  const orgTimezone = moment.tz.guess();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const checkboxes = {
    repeatIndefinitely: false,
    repeatEnding: false,
    repeatEndingOccurrences: false,
  };
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [address, setAddress] = useState<string>('');
  const [addressInfo, setAddressInfo] = useState({ address: '', city: '', state: '', zipcode: '' } as REFERENCE);
  const [meetingsList, setMeetingsList] = useState<string[]>([]);
  const addMeetingValues = {
    patientId: id,
    timezone: orgTimezone,
    title: '',
    type: 'AA',
    location: {
      address: '',
    },
    note: '',
    startDate: '',
    startTime: '',
    endTime: '',
    allowRemote: false,
    repeat: false,
    repeatEnding: false,
    repeatEndingOccurrences: false,
    repeatEndTimestamp: '',
    repeatOccurrences: '',
    repeatIndefinitely: false,
    repeatWeekDays: '',
    updateType: undefined,
  };

  const editMeetingValues = (data: Meeting) => ({
    patientId: id,
    timezone: orgTimezone,
    title: data.title,
    type: data.type,
    location: {
      address: data.location.address,
    },
    note: data.note,
    startDate: moment.utc(data.occurrenceStartTimestamp).tz(orgTimezone).format('YYYY-MM-DD'),
    startTime: moment.utc(data.occurrenceStartTimestamp).tz(orgTimezone).format('HH:mm'),
    endTime: moment.utc(data.occurrenceEndTimestamp).tz(orgTimezone).format('HH:mm'),
    allowRemote: data.allowRemote,
    repeat: !!data.repeatWeekDays,
    repeatEnding: !!data.repeatEndTimestamp,
    repeatEndingOccurrences: !!data.repeatOccurrences,
    repeatEndTimestamp: data.repeatEndTimestamp
      ? moment.utc(data.repeatEndTimestamp).tz(orgTimezone).subtract(1, 'd').format('YYYY-MM-DD')
      : '',
    repeatOccurrences: data.repeatOccurrences ?? '',
    repeatIndefinitely: data.repeatIndefinitely ? true : false,
    repeatWeekDays: data.repeatWeekDays ?? '',
    updateType: '',
  });

  const toastStyle = () => {
    return (
      <div>
        <div style={{ fontWeight: 'bolder' }}>Double Booked!</div> 
        <div style={{marginTop: '10px'}}>This event overlaps with another scheduled events for this client.</div>
      </div>
    );
  };

  const handleUpsertMeeting = async values => {
    setShowLoading(true);
    let formResult = { ...values };
    const occurrenceStartTimestamp = moment(`${formResult.startDate} ${formResult.startTime}`)
      .tz(orgTimezone, true)
      .utc()
      .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    const occurrenceEndTimestamp = moment(`${formResult.startDate} ${formResult.endTime}`)
      .tz(orgTimezone, true)
      .utc()
      .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    formResult = !formResult.repeat
      ? {
          ...formResult,
          occurrenceStartTimestamp,
          occurrenceEndTimestamp,
          repeatWeekDays: null,
          repeatOccurrences: null,
          repeatEndTimestamp: null,
          repeatIndefinitely: null,
        }
      : {
          ...formResult,
          repeatEndTimestamp: formResult.repeatEnding
            ? moment
                .tz(formResult.repeatEndTimestamp, orgTimezone)
                .add(1, 'd')
                .utc()
                .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
            : null,
          repeatOccurrences: formResult.repeatEndingOccurrences ? formResult.repeatOccurrences : null,
          repeatIndefinitely: formResult.repeatIndefinitely ? true : null,
          occurrenceStartTimestamp,
          occurrenceEndTimestamp,
        };

    const result = editMode
      ? await editMeeting(formResult, selectedMeeting.status === 'Done' ? selectedMeeting.data.id : 0)
      : await createMeeting(formResult);
    setShowLoading(false);

    if (result.status === 'Done') {
      getMeetingAction && getMeetingAction();
      addToast(`Event ${!editMode ? 'created' : 'updated'} successfully`, {
        appearance: 'success',
        autoDismiss: true,
      });
      setShow(false);
    } else {
      console.log('');
      addToast(toastStyle(), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await meetingTypes().then(res => (res.status === 'Done' ? res.data : []));
      console.log(response);
      setMeetingsList(response);
    };

    fetchData();
  }, []);

  const handleRepeatCheckbox = async (
    target: EventTarget & HTMLInputElement,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ): Promise<void> => {
    const { name, checked } = target;
    for (const [key, value] of Object.entries(checkboxes)) {
      await setFieldValue(key, key === name ? checked : false, true);
    }
  };

  const fetchingAddress = async (userInput: any) => {
    if (userInput) {
      setAddress(userInput.target.value);
    }
    if (address && address.length >= 3) {
      addressSuggestions(address).then(res => {
        if (res.status === 'Done') {
          setSuggestions(res.data.addresses);
        }
      });
    }
  };

  const addressValues = i => {
    const addressValues = i.split(', ');
    for (let i = 0; i < addressValues.length; i++) {
      setSuggestions([]);
      setAddressInfo({
        address: addressValues[0],
        city: addressValues[1],
        state: addressValues[2],
        zipcode: addressValues[3],
      });
      return;
    }
    return;
  };

  useEffect(() => {}, [suggestions]);

  return (
    <Formik
      initialValues={
        editMode && selectedMeeting.status === 'Done' ? editMeetingValues(selectedMeeting.data) : addMeetingValues
      }
      validationSchema={addMeetingValidator(editMode)}
      onSubmit={(values, { setSubmitting }) => {
        handleUpsertMeeting(values);
        setSubmitting(false);
      }}
    >
      {({ touched, errors, values, setFieldValue, setFieldTouched }) => (
        <Modal show={true} closeModal={() => setShow(false)} showBtnClose={false} contentStyle='bigContent__static'>
          {!showLoading ? (
            <Form className={styles.form}>
              <div className={styles.modalHeader}>
                <h4>{editMode ? 'EVENT DETAILS' : 'ADD CLIENT EVENT'}</h4>
              </div>
              <div className={styles.body}>
                {/* <TextInput name='title' label='Meeting Title:' /> */}
                <SelectInput name='type' label='Event Type:'>
                  {meetingsList.map((item, index) => {
                    let label = '';
                    let value = '';
                    const keys = Object.entries(item).map(i => i);
                    keys.map(i => {
                      if (i[0] === 'label') {
                        label = i[1];
                      } else {
                        value = i[1];
                      }
                    });
                    return (
                      <option value={value} key={index}>
                        {label}
                      </option>
                    );
                  })}
                </SelectInput>
                <CalendarDateInput name='startDate' label='Date of Event:' selected={values.startDate} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ marginBottom: 10 }}>Event Location: </span>
                  <input
                    className={styles.input}
                    name='location.address'
                    onChange={event => fetchingAddress(event)}
                    autoComplete='off'
                    value={address}
                  />
                  <div style={{ marginTop: 82.5, position: 'absolute', marginRight: 20, zIndex: 9999 }}>
                    {suggestions.map((i, index) => (
                      <div
                        key={index}
                        className={styles.option}
                        onClick={() => {
                          addressValues(i);
                          values.location.address = i;
                          setAddress(i);
                        }}
                      >
                        <div style={{ marginTop: `${index * 10}` }}>{i}</div>
                      </div>
                    ))}
                  </div>
                </div>
                {addressInfo.address && (
                  <div className={styles.repeatSection} style={{ marginTop: 'px' }}>
                    <TextInput
                      name='address'
                      label='Address:'
                      type='text'
                      placeholder={addressInfo.address ? addressInfo.address : ''}
                      disabled
                    />
                    <TextInput
                      name='city'
                      label='City:'
                      type='text'
                      placeholder={addressInfo.city ? addressInfo.city : ''}
                      disabled
                    />
                    <TextInput
                      name='state'
                      label='State:'
                      type='text'
                      placeholder={addressInfo.state ? addressInfo.state : ''}
                      disabled
                    />
                    <TextInput
                      name='zipcode'
                      label='Zipcode:'
                      type='text'
                      placeholder={addressInfo.zipcode !== '0' ? addressInfo.zipcode : ''}
                      disabled
                    />
                  </div>
                )}
                <div className={styles.repeatSection} style={{ marginTop: '25px' }}>
                  <TextInput name='startTime' label='Event start time:' type='time' size='small' />
                  <TextInput name='endTime' label='Event end time:' type='time' size='small' />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      marginTop: '5px',
                    }}
                  >
                    <Checkbox name='allowRemote' field='Allow Remote' />
                    <Checkbox name='repeat' field='Repeat' />
                  </div>
                </div>
                {values.repeat && (
                  <DaySelectorInput
                    name='repeatWeekDays'
                    label='Repeat On:'
                    selectedDayOfMeeting={values.startDate ? moment.utc(values.startDate).format('dddd') : ''}
                  />
                )}

                <div className={styles.bottomSection}>
                  <TextAreaInput name='note' label='Notes:' style={{ padding: 10 }} />
                  <div className={styles.rightSection}>
                    {values.repeat && (
                      <div className={styles.repeatEndingSection}>
                        <label> Repeat Ending:</label>
                        <div className={styles.repeatInputs}>
                          <span className={styles.repeatCheckboxes}>
                            <Checkbox
                              name='repeatIndefinitely'
                              field='Never'
                              checked={values.repeatIndefinitely}
                              handleOnChange={ev => handleRepeatCheckbox(ev.currentTarget, setFieldValue)}
                            />
                          </span>
                          <span className={styles.repeatCheckboxes}>
                            <Checkbox
                              name='repeatEnding'
                              field='On'
                              checked={values.repeatEnding}
                              handleOnChange={ev => handleRepeatCheckbox(ev.currentTarget, setFieldValue)}
                            />
                          </span>
                          <CalendarDateInput
                            name='repeatEndTimestamp'
                            label=''
                            disabled={!values.repeatEnding}
                            position='top'
                            size='small'
                          />
                          <span className={`${styles.repeatCheckboxes} ${styles.afterCheckbox}`}>
                            <Checkbox
                              name='repeatEndingOccurrences'
                              field='After'
                              checked={values.repeatEndingOccurrences}
                              handleOnChange={ev => handleRepeatCheckbox(ev.currentTarget, setFieldValue)}
                            />
                          </span>
                          <TextInput
                            name='repeatOccurrences'
                            type='text'
                            label=''
                            disabled={!values.repeatEndingOccurrences}
                            size='smallNoLabel'
                          />
                          <label className={styles.labelOccurrences}> weeks </label>
                          <span
                            className={`${
                              !!(errors as any).endingOption &&
                              touched.repeat &&
                              (touched.repeatEnding || touched.repeatEndingOccurrences)
                                ? styles.errorText__checkbox__show
                                : styles.errorText__checkbox
                            }`}
                          >
                            Please choose at least one option.
                          </span>
                        </div>
                      </div>
                    )}
                    {editMode && (
                      <div className={styles.applyChangesSection}>
                        <label className={styles.label}>Apply event changes to:</label>
                        <div className={styles.radioButtons}>
                          <RadioButton
                            name='updateType'
                            field='This event only'
                            value='SINGLE_EVENT'
                            displayError={false}
                          />
                          <RadioButton
                            name='updateType'
                            field='This and following events'
                            value='SINGLE_EVENT_AND_FUTURE_EVENTS'
                            displayError={false}
                          />
                          <RadioButton
                            name='updateType'
                            field='All future events'
                            value='ALL_FUTURE_EVENTS'
                            displayError={false}
                          />
                          <span
                            className={`${
                              !!(errors as any).updateType && touched.updateType
                                ? styles.errorText__checkbox__show
                                : styles.errorText__checkbox
                            }`}
                          >
                            Please choose at least one option.
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.bottomButtons}>
                <div className={styles.rightButtons}>
                  <button className={styles.closeBtn} type='button' onClick={() => setShow(false)}>
                    Close
                  </button>
                  <button
                    className={styles.submitBtn}
                    type='submit'
                    onClick={() => {
                      values.title = values.type;
                    }}
                  >
                    Save Settings
                  </button>
                </div>
              </div>
            </Form>
          ) : (
            <div className={styles.loadingModal}>
              <Loading />
            </div>
          )}
        </Modal>
      )}
    </Formik>
  );
};

export default UpsertMeetingForm;
