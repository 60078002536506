import React from 'react';

import './admin.scss';
import { Link, useRouteMatch, NavLink } from 'react-router-dom';
import { AdminRoutes } from '../../App';

export const Admin = ({ location, history }): JSX.Element => {
  const { url } = useRouteMatch();

  return (
    <div className='admin-container'>
      <nav className='main-nav'>
        {/* TODO: change to route back if in the app or if you came from somewhere else (history) then route just to default view */}
        <Link to='/home' className='back-link'>
          <svg width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M8 13L2 7L8 1' stroke='#417EB9' strokeWidth='1.5' strokeLinecap='round' />
          </svg>
          Back to Triage Page
        </Link>
        <span style={{ flex: 1 }} />
        <NavLink to={`${url}/clients`} activeClassName='active'>
          Clients
        </NavLink>
        <NavLink to={`${url}/alumni`} activeClassName='active'>
          Alumni
        </NavLink>
        <NavLink to={`${url}/devices`} activeClassName='active'>
          Devices
        </NavLink>
        <NavLink to={`${url}/practitioners`} activeClassName='active'>
          Practitioners
        </NavLink>
        <NavLink to={`${url}/dashboard`} activeClassName='active'>
          Clinic Dashboard
        </NavLink>
      </nav>
      <div className='detail-child-container'>
        <div className='right-container'>{AdminRoutes}</div>
      </div>
    </div>
  );
};
