import React from 'react';
import styles from './client-stats-section.module.scss';
import AvatarGray from '../../../assets/icons/avatar-gray.svg';

type ReportRow = {
  name: string;
  todayCount: number;
  actualWeekCount: number;
  lastWeekCount: number;
  actualMonthCount: number;
  lastMonthCount: number;
};

type Props = {
  title: string;
  statsList: ReportRow[];
};
const ClientStatsSection = ({ title, statsList }: Props) => {
  return (
    <div className={styles.rowContainer}>
      <p className={styles.header}>{title}</p>
      {statsList.map(item => (
        <div className={styles.row} key={item.name}>
          <div className={styles.cell}>
            <img src={AvatarGray} alt='icon' />
            <strong>{item.name}</strong>
          </div>
          <div className={styles.cell}>{item.todayCount}</div>
          <div className={styles.cell}>{item.actualWeekCount}</div>
          <div className={styles.cell}>{item.lastWeekCount}</div>
          <div className={styles.cell}>{item.actualMonthCount}</div>
          <div className={styles.cell}>{item.lastMonthCount}</div>
        </div>
      ))}
      <div className={styles.horizontalLine} />
    </div>
  );
};

export default ClientStatsSection;
