import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { RemoteData } from '../../../../utils/remote-data';
import { Meeting } from '../../../../interfaces/meeting';
import Modal from '../../../modal/Modal';
import { Form, Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import styles from './meeting-dialog.module.scss';
import m from 'moment-timezone';
import { MeetingStatus, MeetingType } from '../../../../utils/constants';
import { RadioButton } from '../../../common/form';
import { displayRepeatInfo } from '../../../../utils/data-parser';
import { patchMeetingAttendedStatus } from '../../../../services/api/meeting';
import { updateAttendedMeetingValidator } from '../../../../utils/form-validations';
import getMeetingStatus from '../../../../utils/meeting-helper';
import { getPatientTimezone } from '../../../../contexts/PatientContext';

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  selectedMeeting: RemoteData<Meeting>;
  patientId: string;
  getMeetingAction: () => void;
};

const MeetingDialog: FunctionComponent<Props> = ({ selectedMeeting, setShow, patientId, getMeetingAction }) => {
  const { addToast } = useToasts();
  const timezone = getPatientTimezone();
  const formValue = {
    attended: selectedMeeting.status === 'Done' ? selectedMeeting.data.attended : '',
    patientId,
  };

  const handleAction = async (values: { attended: string; patientId: string }) => {
    const result = await patchMeetingAttendedStatus(
      values,
      selectedMeeting.status === 'Done' ? selectedMeeting.data.id.toString() : '',
    );
    if (result.status === 'Done') {
      getMeetingAction && getMeetingAction();
      addToast(`Meeting attended status updated successfully`, {
        appearance: 'success',
        autoDismiss: true,
      });
      setShow(false);
    } else {
      addToast(`Error: ${result.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <Modal show={true} closeModal={() => setShow(false)} showBtnClose={false} contentStyle='bigContent__static'>
      {selectedMeeting.status === 'Done' && (
        <Formik
          initialValues={formValue}
          validationSchema={updateAttendedMeetingValidator}
          onSubmit={(values, { setSubmitting }) => {
            handleAction(values);
            setSubmitting(false);
          }}
        >
          {({ touched, errors }) => (
            <Form>
              <section className={styles.meetingModal}>
                <div className={`${styles.modalHeader}`}>
                  <div>
                    VIEW MEETING: {selectedMeeting.data.title} -{' '}
                    {m(selectedMeeting.data.occurrenceStartTimestamp).isValid() &&
                      m.utc(selectedMeeting.data.occurrenceStartTimestamp).tz(timezone).format('MMM DD')}{' '}
                    {m.utc(selectedMeeting.data.occurrenceStartTimestamp).tz(timezone).format('hh:mm a')}
                    {' - '}
                    {m.utc(selectedMeeting.data.occurrenceEndTimestamp).tz(timezone).format('hh:mm a')}
                  </div>
                  <div>{getMeetingStatus(selectedMeeting.data.attended, selectedMeeting.data)}</div>
                </div>
                <div className={styles.meetingDetail}>
                  <div className={styles.box}>
                    <span>Address: </span>
                    {selectedMeeting.data.location?.address}
                  </div>
                  <div className={styles.box}>
                    <span>Meeting Type: </span>
                    {MeetingType[selectedMeeting.data.type]} meeting
                  </div>
                  <div className={styles.box}>
                    <span>Recurrence: </span>
                    {displayRepeatInfo(selectedMeeting.data.repeatWeekDays, selectedMeeting.data.repeatEndTimestamp)}
                  </div>
                  <div className={styles.box}>
                    <span>Scheduled by: </span>
                    {selectedMeeting.data.scheduledBy.type}
                  </div>
                </div>
                <div className={styles.mainSection}>
                  {selectedMeeting.data.allowRemote && (
                    <>
                      <div className={styles.mainText}>This meeting was allowed to be remote</div>
                      {/attended|missed/gim.test(MeetingStatus[selectedMeeting.data.attended]) && (
                        <p className={styles.secondaryText}>
                          Reported as '{MeetingStatus[selectedMeeting.data.attended]}' by{' '}
                          {selectedMeeting.data.attended.toLowerCase().includes('clinician') ? 'clinician' : 'client'}
                        </p>
                      )}
                    </>
                  )}
                  <div className={selectedMeeting.data.allowRemote ? styles.applyChangesSection : ''}>
                    <label className={styles.label}>
                      {' '}
                      Revise {selectedMeeting.data.allowRemote ? 'remote' : ''} meeting attendance:
                    </label>
                    <div className={styles.radioButtons}>
                      <RadioButton
                        name='attended'
                        field='Attended meeting'
                        value={
                          selectedMeeting.data.allowRemote ? 'CLINICIAN_REMOTE_ATTENDED' : 'CLINICIAN_REVISE_ATTENDED'
                        }
                        displayError={false}
                      />
                      <RadioButton
                        name='attended'
                        field="Didn't attend meeting"
                        value={selectedMeeting.data.allowRemote ? 'CLINICIAN_REMOTE_MISSED' : 'CLINICIAN_REVISE_MISSED'}
                        displayError={false}
                      />
                      {!!errors.attended && touched.attended && (
                        <span className={styles.errorCheckbox}>Please choose at least one option.</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.bottomButtons}>
                  <div className={styles.rightButtons}>
                    <button className={styles.closeBtn} type='button' onClick={() => setShow(false)}>
                      Close
                    </button>
                    <button className={styles.submitBtn} type='submit'>
                      Confirm
                    </button>
                  </div>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default MeetingDialog;
