import React, { FunctionComponent } from 'react';
import styles from './table-state.module.scss';
import { Loading } from '../loading';

const TableLoading: FunctionComponent = () => {
  return (
    <div className={styles.noDataContainer}>
      <Loading />
    </div>
  );
};

export default TableLoading;
