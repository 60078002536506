import { isSurveyUsable } from 'components/surveys/is-survey-useful';
import { getPatientTimezone, PatientContext } from 'contexts/PatientContext';
import { useAvailableQuestionnairesQuery, useQuestionnairesQuery } from 'graphql/codegen/hooks';
import { QuestionnaireInfo } from 'graphql/codegen/schemas';
import moment from 'moment-timezone';
import React, { useContext, useMemo } from 'react';
import GadTotalChart from './gad/gad-total-chart';
import PhqTotalChart from './phq/phq-total-chart';
import SurveyChart from './survey-chart';

type Props = {
  startDate: string;
  endDate: string;
};

const SurveysChartContainer = ({ startDate, endDate }: Props) => {
  const timezone = getPatientTimezone();
  const { patient } = useContext(PatientContext);
  const { data: allQuestionnaires } = useAvailableQuestionnairesQuery();


  const getSurveyChart = (survey) => {
    switch (survey?.name) {
      case 'GAD7':
        return (
          <GadTotalChart
            key={survey.id}
            start={startDate}
            end={endDate}
            title={survey.clinicName}
            data={patientQuestionnairesById?.[survey.id]?.values || []}
            survey={survey as any}
          />
        );

        case 'PHQ_9':
          return (
            <PhqTotalChart
            key={survey.id}
            start={startDate}
            end={endDate}
            title={survey.clinicName}
            data={patientQuestionnairesById?.[survey.id]?.values || []}
            survey={survey as any}
            />
          );

      default:
        return (
          <SurveyChart
            key={survey.id}
            start={startDate}
            end={endDate}
            title={survey.clinicName}
            data={patientQuestionnairesById?.[survey.id]?.values || []}
            survey={survey as any}
          />
        );
    }
  }

  const sortedQuestionnaires = React.useMemo(() => {
    const sorted = [...(allQuestionnaires?.questionnaires || [])];

    sorted.sort((a, b) => a.clinicName.localeCompare(b.clinicName));
    return sorted;
  }, [allQuestionnaires]);
  const { data: patientSurveys } = useQuestionnairesQuery({
    variables: {
      id: patient?.firebaseUid!,
      start: startDate,
      end: endDate,
    },
    skip: !patient,
    pollInterval: 5 * 60 * 1000,
  });
  const patientQuestionnairesById = useMemo(
    () =>
      allQuestionnaires?.questionnaires.reduce(
        (questionnairesById, q) => ({
          ...questionnairesById,
          [q.id]: {
            questionnaire: q as any,
            values:
              patientSurveys?.questionnairesOfUser
                ?.filter(qP => !!qP && qP.questionnaire.id === q.id && isSurveyUsable(qP))
                .map(qP => ({ ts: moment.utc(qP.modified).tz(timezone).valueOf(), value: qP.value! }))
                .sort((a, b) => a.ts - b.ts) || [],
          },
        }),
        {} as { [id: number]: { questionnaire: QuestionnaireInfo; values: { ts: number; value: number }[] } },
      ),
    [allQuestionnaires, patientSurveys, timezone],
  );
  return (
    <>
      {sortedQuestionnaires
        .filter(q => !!patientQuestionnairesById?.[q.id]?.values?.length)
        ?.map(q => getSurveyChart(q))}
    </>
  );
};

export default SurveysChartContainer;
