import React, { useState, useContext, useEffect, FC } from 'react';
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, ReferenceLine } from 'recharts';
import moment from 'moment-timezone';
import { curveLinear } from 'd3-shape';
import styles from '../chart.module.scss';
import { getPatientTimezone, PatientContext } from '../../../../contexts/PatientContext';
import { getTicksH, getTicksDD } from '../utlity';
import { getEngagementScores } from '../../../../services/api/chart';
import { getCurrentEngagementScore } from '../../../../services/api/firestore';
import { useIsMountedRef } from '../../../../utils/hooks';
import { CHARTS_SIZES, GRAPH_REVERSE_BIG_TOOLTIP_COORDINATE } from '../../../../utils/constants';
import { Tooltip as InfoTooltip } from '../../tooltip';
import InfoBox from './info-box';
import EngagementTooltip from './engagement-tooltip';
import { EngagementComponent } from '../../../../interfaces/chart-results';
import firebase from 'firebase/app';

type Props = {
  start: string;
  end: string;
  isPdf?: boolean;
  urData?: { ts: string; value: number }[];
  reference?: number;
};

export const EngagementChart: FC<Props> = ({ start, end, isPdf, urData, reference }): JSX.Element => {
  const patientTimezone = getPatientTimezone();
  const [data, setData] = useState<Array<{ ts: number; value: number; components?: EngagementComponent }>>([]);
  const { patient } = useContext(PatientContext);
  const GRAPH_WIDTH_PX = urData ? CHARTS_SIZES.UR_WIDTH : CHARTS_SIZES.CDP_WIDTH;
  const GRAPH_HEIGHT_PX = urData ? CHARTS_SIZES.UR_HEIGHT : CHARTS_SIZES.CDP_HEIGHT;
  let unsubscribeEngagementScore: () => void = () => {};
  const isMountedRef = useIsMountedRef();

  const parseChartData = (): void => {
    const parsedData = urData?.map(entry => ({
      value: entry.value,
      ts: moment(entry.ts).tz(patientTimezone).unix() * 1000,
    }));

    setData(parsedData!);
  };

  const getAndParseScores = async () => {
    const { data } = await getEngagementScores(patient!.id, Math.floor(GRAPH_WIDTH_PX / 4), {
      startDate: moment(start).format('YYYY-MM-DD'),
      endDate: moment(end).tz(patientTimezone).format('YYYY-MM-DD'),
      timezone: patientTimezone,
    });
    return (
      data?.points?.map(p => {
        return { ...p, ts: moment.utc(p.ts).tz(patientTimezone).unix() * 1000 };
      }) || []
    );
  };

  const getChartData = async (): Promise<void> => {
    let points = await getAndParseScores();

    unsubscribeEngagementScore = getCurrentEngagementScore(patient?.firebaseUid!, {
      next: async (Snapshot: firebase.firestore.DocumentSnapshot) => {
        if (Snapshot) {
          const current = Snapshot.data();
          if (!!current?.score && !!current?.timestamp) {
            const { score, timestamp } = current;
            points = await getAndParseScores();
            const ts = moment(timestamp.seconds * 1000)
              .tz(patientTimezone)
              .format();
            // Need to have the points ordered by date so later I can easily get the latest sleep entry and compare it to the firebase current
            // if the firebase current is the same or earlier?than the last sleep entry, ignore it.
            const orderedPoints = points.sort((a, b) => moment(a.ts).unix() - moment(b.ts).unix());
            if (
              ts >= start &&
              ts <= end &&
              isMountedRef.current &&
              moment(timestamp.seconds * 1000).isAfter(orderedPoints.slice(0).pop()?.ts)
            ) {
              setData(prevState => [
                ...(points.length ? points : prevState),
                {
                  ts:
                    moment(timestamp.seconds * 1000)
                      .tz(patientTimezone)
                      .unix() * 1000,
                  value: score,
                },
              ]);
            } else if (points.length) {
              setData(points);
            }
            points = [];
          } else {
            setData(points);
          }
        }
      },
    });
  };

  useEffect(() => {
    if (patient) {
      if (!urData) {
        getChartData();
      } else {
        parseChartData();
      }
    }

    return (): void => {
      unsubscribeEngagementScore();
    };
  }, [patient, start, end]);

  const YAxisFormatter = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { coordinate: number; value: number; offset: number };
  }): JSX.Element => {
    const { value } = payload;
    if (!y || !x) {
      return <text />;
    }
    if (!urData && value === 0) {
      return (
        <text
          fontSize='10'
          x={x - 33}
          y={y}
          textAnchor='start'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          Low {value}
        </text>
      );
    }
    if (!urData && value === 100) {
      return (
        <text
          fontSize='10'
          x={x - 47}
          y={y + 7}
          textAnchor='start'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          High {value}
        </text>
      );
    }
    return (
      <text
        fontSize='10'
        x={x - 7}
        y={y + 4}
        textAnchor='end'
        color='#444444'
        fontWeight='normal'
        fontFamily='sans-serif'
      >
        {value}
      </text>
    );
  };

  const customToolTip = ({
    active,
    coordinate,
    payload,
    label,
  }: {
    active: boolean;
    coordinate: { x: number; y: number };
    label: number;
    payload: Array<any>;
  }): JSX.Element | null => {
    if (!active) {
      return null;
    }
    const reverse = coordinate.x > GRAPH_REVERSE_BIG_TOOLTIP_COORDINATE - 40;
    const yValue = payload ? payload[0]?.payload : 'Unable to retrieve value';
    return <EngagementTooltip date={label} reverse={reverse} data={yValue} />;
  };

  return (
    <div id={`engagementChart${isPdf ? '__pdf' : ''}`} style={isPdf ? { display: 'none' } : {}}>
      <div className={styles.chartHeader}>
        <span className={styles.chartTitle}>ENGAGEMENT</span>
        {!urData && (
          <InfoTooltip baseStyles={`${styles.infoTooltip} ${styles.engagement}`} type='up' background='#F5F6FA'>
            <InfoBox />
          </InfoTooltip>
        )}
      </div>
      <AreaChart
        className={styles.areaContainer}
        width={isPdf ? CHARTS_SIZES.UR_PDF_WIDTH : GRAPH_WIDTH_PX}
        height={isPdf ? CHARTS_SIZES.UR_PDF_HEIGHT : GRAPH_HEIGHT_PX}
        data={data}
      >
        <defs>
          <linearGradient id='d1' x1={0} y1={0} x2={0} y2={1}>
            <stop offset='3%' stopColor='#417EB9' stopOpacity={0.8} />
            <stop offset='97%' stopColor='rgba(65, 126, 185, 0.2)' stopOpacity={0} />
          </linearGradient>
        </defs>
        {!urData && (
          <XAxis
            xAxisId={0}
            dx={2}
            style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            tickSize={16}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!urData && (
          <XAxis
            xAxisId={0}
            dy={-18}
            dx={0}
            style={{
              fontSize: '11',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: '#000000',
            }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!urData && (
          <XAxis
            xAxisId={0}
            dy={-34}
            dx={0}
            style={{ fontSize: '11', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(): string => ''}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && (
          <XAxis
            xAxisId={0}
            dy={-10}
            dx={2}
            style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={true}
            tickSize={16}
            tickFormatter={(unixTime): string => moment.utc(unixTime).tz(patientTimezone).format('hA')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && (
          <XAxis
            xAxisId={2}
            dy={-18}
            dx={0}
            style={{
              fontSize: '11',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: '#000000',
            }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(unixTime): string => moment.utc(unixTime).format('ddd')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && (
          <XAxis
            xAxisId={3}
            dy={-34}
            dx={0}
            style={{ fontSize: '11', fontFamily: 'sans-serif', color: '#000000' }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(unixTime): string => moment.utc(unixTime).format('M/D')}
            dataKey='ts'
            domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
            allowDataOverflow={true}
            type='number'
            scale='time'
            textAnchor='start'
            ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
            mintickgap={0}
            interval={0}
          />
        )}
        {!!urData && reference && <ReferenceLine y={reference} stroke='green' strokeWidth={1.2} />}
        <YAxis tick={YAxisFormatter} interval={0} tickSize={0} ticks={[100, 75, 50, 25, 0]} />
        <CartesianGrid stroke='#A3A6B3' />
        {!urData && <Tooltip content={customToolTip} allowEscapeViewBox={{ x: false, y: true }} />}
        <Area type={curveLinear} dataKey='value' stroke='#417EB9' fill='url(#d1)' strokeWidth='2' />
      </AreaChart>
    </div>
  );
};
