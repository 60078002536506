import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { DischargedPatient } from '../../interfaces/patient';
import styles from './readmit-client-modal.module.scss';
import { EPISODE_OF_CARE } from '../../utils/constants';
import Modal from '../modal/Modal';
import { Form, Formik } from 'formik';
import SelectArrayInput from '../common/form/select-array-input';
import { loading, notAsked, RemoteData } from '../../utils/remote-data';
import { Practitioners } from '../../interfaces/practitioner';
import { getPractitioners } from '../../services/api/practitioner';
import { PractitionerContext } from '../../contexts/Practitioner';
import { readmitClientValidationSchema } from '../../utils/form-validations';
import { readmitPatient, ReadmitPatientRequest } from '../../services/api/patient';
import { Loading } from '../common/loading';
import { useOnClickOutside } from '../../utils/hooks';
import { getKeyByValue } from '../../utils/func.utils';
import { AppearanceTypes, useToasts } from 'react-toast-notifications';

type Props = {
  patient: DischargedPatient | undefined;
  show: boolean;
  hide: () => void;
  getAlumniClients: () => Promise<void>;
};

const ReadmitClientModal: FunctionComponent<Props> = ({ show, patient, hide, getAlumniClients }) => {
  const { practitioner } = useContext(PractitionerContext);
  const { addToast } = useToasts();
  const [showSelectOptions, setShowSelectOptions] = useState<boolean>(false);
  const [practitioners, setPractitioners] = useState<RemoteData<Practitioners>>(notAsked);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const formValues: ReadmitPatientRequest = {
    episodeOfCareType: '',
    practitionerIdList: [],
    organizationId: practitioner?.organization.id ?? '',
  };

  useOnClickOutside(ref, event => {
    if (!dropdownRef.current?.contains(event.target)) {
      setShowSelectOptions(false);
    }
  });

  const displayToast = (message: string, type: AppearanceTypes) =>
    addToast(message, { appearance: type, autoDismiss: true });

  const handleSelectOption = (
    option: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    setShowSelectOptions(false);
    setFieldValue('episodeOfCareType', option, true);
  };

  const handleReadmitClient = async (formData: ReadmitPatientRequest) => {
    const formValues: ReadmitPatientRequest = {
      organizationId: formData.organizationId,
      episodeOfCareType: formData.episodeOfCareType,
      practitionerIdList: formData.episodeOfCareType.includes(
        getKeyByValue(EPISODE_OF_CARE, EPISODE_OF_CARE.AFTER_CARE)!,
      )
        ? []
        : formData.practitionerIdList,
    };
    const result = await readmitPatient(patient!.id, formValues);
    if (result.status === 'Done') {
      getAlumniClients();
      hide();
      displayToast('Client Readmitted successfully', 'success');
    } else {
      displayToast(`Error: ${result.errorApi.message}`, 'error');
    }
  };

  useEffect(() => {
    (async (): Promise<void> => {
      setPractitioners(loading());
      const pract = await getPractitioners(practitioner?.organization.id);
      setPractitioners(pract);
    })();
  }, [practitioner]);

  return practitioners.status === 'Done' ? (
    <Modal show={show} closeModal={hide} showBtnClose={false} contentStyle='bigContent__static'>
      <Formik
        initialValues={formValues}
        validationSchema={readmitClientValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleReadmitClient(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <div className={styles.readmitModal}>
              <div className={styles.header}>
                <span>{`RE-ADMIT ${patient?.name.first.toUpperCase()} ${patient?.name.last.toUpperCase()}`}</span>
              </div>
              <div className={styles.body}>
                <span className={styles.confirmationText}>
                  {`Select the episode of care to re-admit ${patient?.name.first} ${patient?.name.last}`}
                </span>
                <div className={styles.outerSelect}>
                  <div>
                    <div
                      ref={dropdownRef}
                      className={styles.customSelect}
                      onClick={() => setShowSelectOptions(!showSelectOptions)}
                    >
                      {EPISODE_OF_CARE[values.episodeOfCareType] ?? 'Select episode of care'}
                    </div>
                    {touched && errors.episodeOfCareType && (
                      <span className={styles.errorText}> Please select the client episode of care</span>
                    )}
                  </div>
                  {showSelectOptions && (
                    <div ref={ref} className={styles.customSelectOptions}>
                      {Object.entries(EPISODE_OF_CARE).flatMap(([key, label]) =>
                        EPISODE_OF_CARE.SELF_CARE !== label ? (
                          <div
                            className={styles.customOption}
                            key={key}
                            onClick={() => handleSelectOption(key, setFieldValue)}
                          >
                            {label}
                          </div>
                        ) : null,
                      )}
                    </div>
                  )}

                  <span className={styles.helpNote}>
                    Note: {patient?.name.first}’s client profile will be updated to reflect this new episode of care.
                  </span>
                </div>
                {!values.episodeOfCareType.includes(getKeyByValue(EPISODE_OF_CARE, EPISODE_OF_CARE.AFTER_CARE)!) && (
                  <div>
                    <SelectArrayInput
                      name='practitionerIdList'
                      label='Practitioner to be Assigned:'
                      itemsHeader='Practitioners Assigned:'
                      items={practitioners.status === 'Done' ? practitioners.data.values : []}
                      selectedItems={values.practitionerIdList!}
                      size='big'
                    />
                  </div>
                )}
              </div>
              <div className={styles.buttons}>
                <button className={styles.closeBtn} type='button' onClick={hide}>
                  Cancel
                </button>
                <button className={styles.submitBtn} type='submit'>
                  Confirm
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  ) : (
    <Modal show={true} closeModal={hide} showBtnClose={false} contentStyle='bigContent__static'>
      <div className={styles.readmitModal__loading}>
        <Loading />
      </div>
    </Modal>
  );
};

export default ReadmitClientModal;
