import React, { FunctionComponent } from 'react';
import styles from './bac-location.module.scss';
import m from 'moment-timezone';
import { BacResult } from '../../../../interfaces/bac-result';
import Map from '../../../common/map/map';
import { Marker } from '@react-google-maps/api';
import bacPassedIcon from '../../../../assets/icons/icn-bac-passed.svg';
import bacFailedIcon from '../../../../assets/icons/icn-bac-failed.svg';
import { getPatientTimezone } from '../../../../contexts/PatientContext';

type BacLocationProps = {
  bacTest: BacResult;
};

const BacLocation: FunctionComponent<BacLocationProps> = ({ bacTest }) => {
  const marker = {
    lat: bacTest.location.latitude,
    lng: bacTest.location.longitude,
  };
  const timezone = getPatientTimezone();

  return (
    <section className={styles.locationModal}>
      <div className={`${styles.modalHeader}`}>
        <h4>
          Client location during BAC test -{' '}
          {bacTest?.timestamp && m(bacTest.timestamp).isValid() && m.utc(bacTest.timestamp).tz(timezone).format('lll')}
        </h4>
      </div>
      <div className={styles.locationDetail}>
        <div className={styles.box}>
          <span>Address: </span>
          {bacTest.location?.address}
        </div>
      </div>
      <div className={styles.mapContainer}>
        <Map
          mapOptions={{
            center: { lat: marker.lat, lng: marker.lng },
            zoom: 17,
          }}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          markers={[marker]}
          fitBounds={false}
        >
          <Marker
            key={`${marker.lng} ${marker.lat}`}
            position={marker}
            cursor='pointer'
            icon={{
              url: bacTest.status.toLowerCase().includes('pass') ? bacPassedIcon : bacFailedIcon,
            }}
          />
        </Map>
      </div>
    </section>
  );
};

export default BacLocation;
