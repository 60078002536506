import React from 'react';
import styles from './wizard-step-indicator.module.scss';

type Props = {
  totalPages: number;
  page: number;
};

const WizardStepIndicator: React.FC<Props> = ({ totalPages, page }) => {
  const totalPagesArr: number[] = [];

  for (let i = 0; i < totalPages; i++) {
    totalPagesArr.push(i + 1);
  }

  return (
    <div className={styles.wizardPagesContainer}>
      {totalPagesArr.map((p, idx) =>
        p === page ? <div key={idx} className={styles.actual} /> : <div key={idx} className={styles.other} />,
      )}
    </div>
  );
};

export default WizardStepIndicator;
