import React, { ReactNode, useState } from 'react';
import ReactDom from 'react-dom';
import { caseOf } from 'utils/case-of';
import { useHover } from '../../utils/hooks';
import styles from './tooltip.module.scss';
type Props = {
  baseStyles: string;
  children: ReactNode;
  type?: string;
  iconWidth?: string;
  iconHeight?: string;
  background?: string;
  portal?: boolean;
};
const PortalTooltip = ({
  baseStyles,
  children,
  type = 'bottom',
  iconHeight = '13',
  iconWidth = '13',
  background = '#fff',
  hoverRef,
  isHovering,
}) => {
  const [dimensions, setDimensions] = useState({ top: 0, left: 0 });
  React.useEffect(() => {
    if (isHovering && hoverRef.current) {
      const rect = hoverRef.current.getBoundingClientRect();
      const { addTop, addLeft } = caseOf<string>()
        .case('up', () => ({ addTop: 0, addLeft: rect.width }))
        .case('left', () => ({ addTop: 0, addLeft: rect.width }))
        .defaultCase(() => ({ addTop: 0, addLeft: 0 }))
        .eval(type);
      setDimensions({ top: rect.y + addTop, left: rect.x + addLeft * 2 });
    }
  }, [hoverRef, isHovering, type]);
  return ReactDom.createPortal(
    <div
      className={`${styles.tooltip} ${baseStyles}`}
      style={{
        position: 'absolute',
        ...dimensions,
        transform: type === 'left' ? `translateY(-50%) translate(5px,10px)` : '',
      }}
    >
      {children}
      {type === 'bottom' && <div className={styles.tooltipBottom} style={{ background: background }} />}
      {type === 'left' && <div className={styles.tooltipLeft} style={{ background: background }} />}
      {type === 'up' && <div className={styles.tooltipUp} style={{ background: background }} />}
      {type === 'right' && <div className={styles.tooltipRight} style={{ background: background }} />}
      {type === 'bottom-left' && <div className={styles.tooltipBottomLeft} style={{ background: background }} />}
    </div>,
    document.getElementById('tooltip-portal')!,
  );
};

export const Tooltip: React.FC<Props> = ({
  baseStyles,
  children,
  type = 'bottom',
  iconHeight = '13',
  iconWidth = '13',
  background = '#fff',
  portal,
}) => {
  const [hoverRef, isHovered] = useHover();

  return (
    <div className={styles.helper} >
      <figure ref={hoverRef}>
        <svg width={iconWidth} height={iconHeight} viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <circle cx='6.5' cy='6.5' r='6.25' stroke='#417EB9' strokeWidth='1' />
          <path
            d='M5.74316 7.99805C5.74967 7.61068 5.79362 7.30469 5.875 7.08008C5.95638 6.85547 6.1224 6.60645 6.37305 6.33301L7.0127 5.67383C7.28613 5.36458 7.42285 5.03255 7.42285 4.67773C7.42285 4.33594 7.33333 4.06901 7.1543 3.87695C6.97526 3.68164 6.71484 3.58398 6.37305 3.58398C6.04102 3.58398 5.77409 3.67188 5.57227 3.84766C5.37044 4.02344 5.26953 4.25944 5.26953 4.55566H4.36621C4.37272 4.02832 4.5599 3.60352 4.92773 3.28125C5.29883 2.95573 5.7806 2.79297 6.37305 2.79297C6.98828 2.79297 7.4668 2.95898 7.80859 3.29102C8.15365 3.61979 8.32617 4.07227 8.32617 4.64844C8.32617 5.2181 8.0625 5.77962 7.53516 6.33301L7.00293 6.86035C6.7653 7.12402 6.64648 7.50326 6.64648 7.99805H5.74316ZM5.7041 9.5459C5.7041 9.39941 5.74805 9.27734 5.83594 9.17969C5.92708 9.07878 6.06055 9.02832 6.23633 9.02832C6.41211 9.02832 6.54557 9.07878 6.63672 9.17969C6.72786 9.27734 6.77344 9.39941 6.77344 9.5459C6.77344 9.69238 6.72786 9.81445 6.63672 9.91211C6.54557 10.0065 6.41211 10.0537 6.23633 10.0537C6.06055 10.0537 5.92708 10.0065 5.83594 9.91211C5.74805 9.81445 5.7041 9.69238 5.7041 9.5459Z'
            fill='#417EB9'
          />
        </svg>
      </figure>
      {isHovered && !portal && (
        <div className={`${styles.tooltip} ${baseStyles}`}>
          {children}
          {type === 'bottom' && <div className={styles.tooltipBottom} style={{ background: background }} />}
          {type === 'left' && <div className={styles.tooltipLeft} style={{ background: background }} />}
          {type === 'up' && <div className={styles.tooltipUp} style={{ background: background }} />}
          {type === 'right' && <div className={styles.tooltipRight} style={{ background: background }} />}
          {type === 'bottom-left' && <div className={styles.tooltipBottomLeft} style={{ background: background }} />}
        </div>
      )}
      {isHovered && portal && (
        <PortalTooltip
          {...{
            baseStyles,
            children,
            type,
            iconHeight,
            iconWidth,
            background,
            portal,
            hoverRef,
            isHovering: isHovered,
          }}
        >
          {children}
          {type === 'bottom' && <div className={styles.tooltipBottom} style={{ background: background }} />}
          {type === 'left' && <div className={styles.tooltipLeft} style={{ background: background }} />}
          {type === 'up' && <div className={styles.tooltipUp} style={{ background: background }} />}
          {type === 'right' && <div className={styles.tooltipRight} style={{ background: background }} />}
          {type === 'bottom-left' && <div className={styles.tooltipBottomLeft} style={{ background: background }} />}
        </PortalTooltip>
      )}
    </div>
  );
};
