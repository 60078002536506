import React, { FunctionComponent, useMemo, useState } from 'react';
import Modal from '../../../modal/Modal';
import { Geofence, GeofenceRequest } from '../../../../interfaces/geofence';
import { useToasts } from 'react-toast-notifications';
import { GeofenceType } from '../../../../interfaces/enums';
import { Form, Formik } from 'formik';
import { upsertGeofenceValidator } from '../../../../utils/form-validations';
import styles from './upsert-geofence-form.module.scss';
import Map from '../../../common/map/map';
import { Marker } from '@react-google-maps/api';
import { SelectInput, TextInput } from '../../../common/form';
import ConfirmUpdateGeofence from './confirm-update-geofence';
import { Done, Failed } from '../../../../utils/remote-data';
import RemoveGeofenceDialog from './remove-geofence-dialog';
import { useParams } from 'react-router-dom';

type Props = {
  setCloseModal: () => void;
  selectedGeofence?: Geofence | undefined;
  createGeofence: (geofence: GeofenceRequest, patientId: string) => Promise<Done<Geofence> | Failed>;
  editGeofence: (
    geofence: GeofenceRequest,
    geofenceId: number | undefined,
    patientId: string,
  ) => Promise<Done<Geofence> | Failed>;
  deleteGeofence: (geofenceId: number, patientId: string) => Promise<Done<any> | Failed>;
  updateGeofenceAction: (geofence: Geofence, action: string) => void;
  editMode: boolean;
};

const UpsertGeofenceForm: FunctionComponent<Props> = ({
  setCloseModal,
  selectedGeofence,
  editMode,
  editGeofence,
  createGeofence,
  updateGeofenceAction,
  deleteGeofence,
}) => {
  const { addToast } = useToasts();
  const { id } = useParams<{ id: string }>();
  const [showConfirmUpdate, setShowConfirmUpdate] = useState<boolean>(false);
  const [showRemoveGeofence, setShowRemoveGeofence] = useState<boolean>(false);

  const handleUpsertGeofence = async geofenceForm => {
    const data: {
      type: GeofenceType;
      name: string;
      geofencePoint: { address: string };
    } = { ...geofenceForm };
    const result = editMode ? await editGeofence(data, selectedGeofence?.id, id!) : await createGeofence(data, id!);

    if (result.status === 'Done') {
      addToast(`Geofence ${editMode ? 'updated' : 'added'} successfully`, {
        appearance: 'success',
        autoDismiss: true,
      });
      setCloseModal();
      updateGeofenceAction(result.data, editMode ? 'edit' : 'create');
    } else {
      addToast(`Error: ${result.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
      if (editMode) {
        setShowConfirmUpdate(false);
      }
    }
  };
  const handleDeleteGeofence = async () => {
    const result = await deleteGeofence(selectedGeofence?.id!, id!);
    if (result.status === 'Done') {
      addToast('Geofence deleted successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      updateGeofenceAction(selectedGeofence!, 'delete');
      setCloseModal();
    } else {
      addToast(`Error: ${result.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };
  const marker = {
    lat: selectedGeofence?.geofencePoint?.latitude!,
    lng: selectedGeofence?.geofencePoint?.longitude!,
  };
  const mapOptions: google.maps.MapOptions = useMemo(
    () => ({
      center: marker,
      zoomControl: true,
      mapTypeControl: true,
      streetViewControl: true,
      zoom: 17,
    }),
    [],
  );

  const upsertGeofenceValues = {
    type: selectedGeofence?.type ?? GeofenceType.NonCompliant,
    name: selectedGeofence?.name ?? '',
    geofencePoint: {
      address: selectedGeofence?.geofencePoint?.address ?? '',
    },
  };
  return (
    <Formik
      initialValues={upsertGeofenceValues}
      validationSchema={upsertGeofenceValidator}
      onSubmit={(values, { setSubmitting }) => {
        handleUpsertGeofence(values);
        setSubmitting(false);
      }}
    >
      {({ errors, values, isSubmitting }) => (
        <Modal show={true} closeModal={setCloseModal} showBtnClose={true} contentStyle='bigContent__static'>
          <Form className={styles.form}>
            {!showConfirmUpdate && !showRemoveGeofence && (
              <>
                <div className={styles.modalHeader}>
                  <h4>{editMode ? 'Edit Geofence Settings' : 'Create Geofence'}</h4>
                </div>
                {editMode && (
                  <div className={styles.mapContainer}>
                    <Map
                      mapOptions={mapOptions}
                      mapContainerStyle={{ width: '100%', height: '100%' }}
                      markers={[marker]}
                      fitBounds={false}
                    >
                      <Marker
                        key={`geofence`}
                        position={{
                          lat: marker.lat,
                          lng: marker.lng,
                        }}
                        cursor='pointer'
                      ></Marker>
                    </Map>
                  </div>
                )}
                <div className={styles.body}>
                  <SelectInput label='Geofence type:' name='type' defaultValue={values?.type ?? undefined}>
                    <option value={GeofenceType.NonCompliant}>Non-Compliant</option>
                    <option value={GeofenceType.Special}>Special</option>
                  </SelectInput>
                  <TextInput label='Geofence name:' name='name' />
                  <TextInput label='Address:' name='geofencePoint.address' />
                </div>
                <div className={`${styles.rowButtons}`}>
                  {editMode && (
                    <button className={styles.deleteButton} type='button' onClick={() => setShowRemoveGeofence(true)}>
                      Delete
                    </button>
                  )}
                  <button className={styles.cancelButton} type='button' onClick={() => setCloseModal()}>
                    {editMode ? 'Close' : 'Cancel'}
                  </button>
                  {editMode ? (
                    <button className={styles.saveButton} onClick={() => setShowConfirmUpdate(true)}>
                      Save
                    </button>
                  ) : (
                    <button className={styles.saveButton} type='submit' disabled={isSubmitting}>
                      Save
                    </button>
                  )}
                </div>
              </>
            )}
            {showConfirmUpdate && (
              <ConfirmUpdateGeofence
                selectedGeofence={selectedGeofence!}
                closeModal={() => setShowConfirmUpdate(false)}
              />
            )}
            {showRemoveGeofence && (
              <RemoveGeofenceDialog
                removeGeofence={handleDeleteGeofence}
                selectedGeofence={selectedGeofence!}
                closeModal={() => setShowRemoveGeofence(false)}
              />
            )}
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default UpsertGeofenceForm;
