import { firestore } from '../../firebase/firebase';
import firebase from 'firebase/app';

export const getCurrentViviHealthScore = (
  patientFirebaseId: string,
  observer: {
    next: (snapshot: firebase.firestore.DocumentSnapshot) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
): (() => void) => {
  return firestore
    .collection('users')
    .doc(patientFirebaseId)
    .collection('viviHealthScore')
    .doc('current')
    .onSnapshot(observer.next, observer.error, observer.complete);
};

export const getCurrentActivityScore = (
  patientFirebaseId: string,
  observer: {
    next: (snapshot: firebase.firestore.DocumentSnapshot) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
): (() => void) => {
  return firestore
    .collection('users')
    .doc(patientFirebaseId)
    .collection('activityScore')
    .doc('current')
    .onSnapshot(observer.next, observer.error, observer.complete);
};

export const getCurrentEngagementScore = (
  patientFirebaseId: string,
  observer: {
    next: (snapshot: firebase.firestore.DocumentSnapshot) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
): (() => void) => {
  return firestore
    .collection('users')
    .doc(patientFirebaseId)
    .collection('engagement')
    .doc('current')
    .onSnapshot(observer.next, observer.error, observer.complete);
};

export const getCurrentStressScore = (
  patientFirebaseId: string,
  observer: {
    next: (snapshot: firebase.firestore.DocumentSnapshot) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
): (() => void) => {
  return firestore
    .collection('users')
    .doc(patientFirebaseId)
    .collection('stress')
    .doc('current')
    .onSnapshot(observer.next, observer.error, observer.complete);
};

export const getCurrentExerciseScore = (
  patientFirebaseId: string,
  observer: {
    next: (snapshot: firebase.firestore.DocumentSnapshot) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
): (() => void) => {
  return firestore
    .collection('users')
    .doc(patientFirebaseId)
    .collection('exercise')
    .doc('current')
    .onSnapshot(observer.next, observer.error, observer.complete);
};

export const getCurrentSleepScore = (
  patientFirebaseId: string,
  observer: {
    next: (snapshot: firebase.firestore.DocumentSnapshot) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
): (() => void) => {
  return firestore
    .collection('users')
    .doc(patientFirebaseId)
    .collection('sleep')
    .doc('current')
    .onSnapshot(observer.next, observer.error, observer.complete);
};

export const getCurrentVitals = (
  patientFirebaseId: string,
  observer: {
    next: (snapshot: firebase.firestore.DocumentSnapshot) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
): (() => void) => {
  return firestore
    .collection('users')
    .doc(patientFirebaseId)
    .collection('vitals')
    .doc('current')
    .onSnapshot(observer.next, observer.error, observer.complete);
};

export const getCurrentWristBandStatus = (
  patientFirebaseId: string,
  observer: {
    next: (snapshot: firebase.firestore.DocumentSnapshot) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
): (() => void) => {
  return firestore
    .collection('users')
    .doc(patientFirebaseId)
    .collection('connected')
    .doc('current')
    .onSnapshot(observer.next, observer.error, observer.complete);
};
export const getCurrentInsights = (
  patientFirebaseId: string,
  observer: {
    next: (snapshot: firebase.firestore.DocumentSnapshot) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
): (() => void) => {
  return firestore
    .collection('users')
    .doc(patientFirebaseId)
    .collection('insights')
    .doc('current')
    .onSnapshot(observer.next, observer.error, observer.complete);
};

export const getReportStatus = (
  patientFirebaseId: string,
  reportId: string,
  observer: {
    next: (snapshot: firebase.firestore.DocumentSnapshot) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
): (() => void) => {
  return firestore
    .collection('users')
    .doc(patientFirebaseId)
    .collection('utilizationReview')
    .doc(reportId)
    .onSnapshot(observer.next, observer.error, observer.complete);
};

export const getAllReportsStatus = (
  patientFirebaseId: string,
  observer: {
    next: (snapshot: firebase.firestore.DocumentData) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
): (() => void) => {
  return firestore
    .collection('users')
    .doc(patientFirebaseId)
    .collection('utilizationReview')
    .onSnapshot(observer.next, observer.error, observer.complete);
};

export const getCurrentRelapseRisk = (
  patientFirebaseId: string,
  observer: {
    next: (snapshot: firebase.firestore.DocumentSnapshot) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
): (() => void) => {
  return firestore
    .collection('users')
    .doc(patientFirebaseId)
    .collection('relapseRisk')
    .doc('current')
    .onSnapshot(observer.next, observer.error, observer.complete);
};

export const getCurrentBatteryLevel = (
  patientFirebaseId: string,
  observer: {
    next: (snapshot: firebase.firestore.DocumentSnapshot) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
): (() => void) => {
  return firestore
    .collection('users')
    .doc(patientFirebaseId)
    .collection('battery')
    .doc('current')
    .onSnapshot(observer.next, observer.error, observer.complete);
};

export const getPhonePermissions = (
  patientFirebaseId: string,
  observer: {
    next: (snapshot: firebase.firestore.DocumentSnapshot) => void;
    error?: (error: Error) => void;
    complete?: () => void;
  },
): (() => void) => {
  return firestore
    .collection('users')
    .doc(patientFirebaseId)
    .collection('viviCompanionPhonePermissions')
    .doc('current')
    .onSnapshot(observer.next, observer.error, observer.complete);
};
