import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import NonCompliantGeofence from '../../../assets/icons/non-compliant-geofence';
import SpecialGeofence from '../../../assets/icons/special-geofence';
import { GeofenceType } from '../../../interfaces/enums';
import { Geofence } from '../../../interfaces/geofence';
import { createGeofence, deleteGeofence, editGeofence, getGeofences } from '../../../services/api/geofence';
import { loading, RemoteData } from '../../../utils/remote-data';
import Table from '../../common/table/table';
import styles from './geofence.module.scss';
import UpsertGeofenceForm from './modals/upsert-geofence-form';

type Props = {
  filterType: string;
  showAddModal: boolean;
  setShowAddModal: Dispatch<SetStateAction<boolean>>;
};

const GeofencesTable: FunctionComponent<Props> = ({ filterType = 'all', showAddModal, setShowAddModal }) => {
  const [geofenceList, setGeofenceList] = useState<RemoteData<{ values: Geofence[] }>>(loading());
  const [filteredGeofences, setFilteredGeofences] = useState<Geofence[]>([]);

  const [selectedGeofence, setSelectedGeofence] = useState<Geofence | undefined>(undefined);
  const [showGeofenceModal, setShowGeofenceModal] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    (async (): Promise<void> => {
      await requestGeofences();
    })();
  }, []);

  useEffect(() => {
    ((): void => {
      performFilter();
    })();
  }, [filterType, geofenceList]);

  useEffect(() => {
    setShowGeofenceModal(showAddModal);
  }, [showAddModal]);

  useEffect(() => {
    if (!showGeofenceModal) {
      setSelectedGeofence(undefined);
    }
  }, [showGeofenceModal]);

  const requestGeofences = async () => {
    try {
      const res = await getGeofences(id!);
      setGeofenceList(res);
    } catch (err) {
      console.error(err);
    }
  };

  const performFilter = () => {
    if (geofenceList.status === 'Done') {
      setFilteredGeofences(
        filterType.toLowerCase() === 'all'
          ? geofenceList.data.values
          : geofenceList.data.values.filter(geofence => geofence.type.toLowerCase() === filterType.toLowerCase()),
      );
    }
  };

  const updateGeofenceAction = async (geofence: Geofence, action: string): Promise<void> => {
    if (geofenceList.status === 'Done') {
      const updatedList =
        action === 'create'
          ? [...geofenceList.data.values, geofence]
          : action === 'edit'
          ? [...geofenceList.data.values.filter(geo => geo.id !== geofence.id), geofence]
          : geofenceList.data.values.filter(geo => geo.id !== geofence.id);
      setGeofenceList({ data: { values: updatedList }, status: 'Done' });
    }
  };

  const closeModal = (): void => {
    setShowAddModal(false);
    setShowGeofenceModal(false);
  };

  const tableColumns: Column<Geofence>[] = useMemo(
    () => [
      {
        accessor: 'geofencePoint',
        Header: 'Address',
        width: 309,
        Cell: ({ cell: { value } }) => value && <span className={styles.ellipsis}>{value?.address}</span>,
        sortType: (rowA, rowB, columnID, desc) => {
          const addressA = rowA.original.geofencePoint?.address || '';
          const addressB = rowB.original.geofencePoint?.address || '';
          return !addressA ? -1 : !addressB ? 1 : addressA.localeCompare(addressB);
        },
      },
      {
        accessor: 'name',
        Header: 'Geofence Name',
        width: 239,
        sortType: 'basic',
      },
      {
        accessor: 'type',
        Header: 'Geofence Type',
        width: 350,
        Cell: ({ cell: { value } }) =>
          value === GeofenceType.Special ? <SpecialGeofence /> : <NonCompliantGeofence />,
        sortType: 'basic',
      },
      {
        Header: '',
        accessor: 'id',
        width: 151,
        Cell: ({ cell: { value, row } }): JSX.Element => (
          <span
            className={styles.action}
            onClick={() => {
              setSelectedGeofence(row.original);
              setShowGeofenceModal(true);
            }}
          >
            Edit
          </span>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );
  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'name', desc: true }] }),
    [],
  );

  const getRowProps = row => {
    return {
      key: `${row?.id}`,
      className: styles.tr,
    };
  };

  const getHeaderProps = column => {
    return {
      className: styles.th,
      key: `${column?.id}`,
      style: {
        width: column?.width,
        minWidth: column?.minWidth,
        maxWidth: column?.maxWidth,
      },
    };
  };

  const getCellProps = cell => {
    return {
      key: `${cell?.column.id}`,
      className: styles.td,
    };
  };

  const getTableBodyProps = () => {
    return {
      className: styles.tbody,
      style: {
        height: '564px',
      },
    };
  };

  const getTableProps = () => {
    return {
      className: geofenceList.status === 'Done' && !geofenceList.data.values.length ? styles.noContent : styles.table,
    };
  };
  return (
    <div>
      <Table<Geofence>
        columns={tableColumns}
        data={geofenceList.status === 'Done' ? filteredGeofences : []}
        isLoading={geofenceList.status === 'Loading'}
        error={geofenceList.status === 'Failed'}
        getTableProps={() => getTableProps()}
        getRowProps={row => getRowProps(row)}
        getHeaderProps={column => getHeaderProps(column)}
        getCellProps={cell => getCellProps(cell)}
        getTableBodyProps={() => getTableBodyProps()}
        defaultColumn={{ width: 150, minWidth: 80 }}
        initialState={defaultSorting}
        emptyDataMessage='There is no data at this time.'
      />
      {showGeofenceModal && (
        <UpsertGeofenceForm
          setCloseModal={closeModal}
          editMode={!!selectedGeofence}
          selectedGeofence={selectedGeofence}
          createGeofence={createGeofence}
          editGeofence={editGeofence}
          deleteGeofence={deleteGeofence}
          updateGeofenceAction={updateGeofenceAction}
        />
      )}
    </div>
  );
};

export default GeofencesTable;
