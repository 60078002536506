import React, { Dispatch, SetStateAction } from 'react';
import { TextInput, PhoneInput } from '../../common/form';
import styles from '../upsert-client.module.scss';
import { PATIENT_ACCOUNT_STATUS } from '../../../utils/constants';

type Props = {
  editMode: boolean;
  patientStatus: string;
  openEditEmailModal?: () => void;
};

const ContactInfo: React.FC<Props> = ({ editMode, patientStatus, openEditEmailModal }) => {
  return (
    <div className={styles.body}>
      <TextInput name='personalContact.home.line1' label='Home address:' />
      <TextInput name='personalContact.home.city' label='City:' />
      <TextInput name='personalContact.home.state' label='State:' size='small' />
      <TextInput name='personalContact.home.postalCode' label='Zip:' size='small' />
      <TextInput name='personalContact.work.line1' label='Work address:' />
      <TextInput name='personalContact.work.city' label='City:' />
      <TextInput name='personalContact.work.state' label='State:' size='small' />
      <TextInput name='personalContact.work.postalCode' label='Zip:' size='small' />
      <TextInput
        name='personalContact.email'
        label='Email:'
        disabled={editMode}
        type={
          editMode ? (patientStatus === PATIENT_ACCOUNT_STATUS.PENDING_INVITE ? 'editableEmail' : 'emailTooltip') : ''
        }
        openEditEmailModal={openEditEmailModal}
      />
      <PhoneInput name='personalContact.cellphone' label='Cell phone:' />
      <PhoneInput name='personalContact.homePhone' label='Home phone:' />
    </div>
  );
};

export default ContactInfo;
