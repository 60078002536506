import styles from '../survey.module.scss';
import React, { useState } from 'react';
import Modal from '../../modal/Modal';


const ClinicianSurveyModal = ({closeClinicianSurveyModal, chosenSurveys}) => {
  const [ciwaChecked, setCiwaChecked] = useState(false);
  const [cowsChecked, setCowsChecked] = useState(false);

  return (
    <Modal show={true} closeModal={() => console.log('closed')} showBtnClose={false} contentStyle='bigContent__static'>
      <div className={styles.modal}>
        <h2>SELECT SURVEY TO TAKE</h2>
        <div className={styles.hr}></div>
        <div className={styles.checkBoxes}>
          <div style={{ display: 'flex', marginTop: 10 }}>
              <input
                type='radio'
                id='ciwa'
                name='survey'
                className={styles.hiddenCheckbox} // This class hides the default checkbox
                checked={ciwaChecked}
                onChange={() => {
                  setCiwaChecked(true);
                  setCowsChecked(false);
                }}
              />
              <span />
              Clinical Alcohol Withdrawal Assessment (CIWA)
          </div>
          <div style={{ display: 'flex', marginTop: 10 }}>
              <input
                type='radio'
                name='survey'
                id='cows'
                className={styles.hiddenCheckbox} // This class hides the default checkbox
                checked={cowsChecked}
                onChange={() => {
                  setCowsChecked(true);
                  setCiwaChecked(false);
                }}
              />
              <span />
              Clinical Opiod Withdrawal Scale (COWS)
          </div>
        </div>
        <div className={styles.buttons}>
          <button
            className={styles.closeBtn}
            type='button'
            onClick={() => {
                closeClinicianSurveyModal(true);
            }}
          >
            Cancel
          </button>
          <button
            className={styles.nextBtn}
            type='button'
            onClick={() => {
                closeClinicianSurveyModal(true);
                chosenSurveys(cowsChecked, ciwaChecked);
            }}
          >
            Next
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ClinicianSurveyModal;
