import React, { useState, useEffect, useContext, FC } from 'react';
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area } from 'recharts';
import moment from 'moment-timezone';
import { curveLinear } from 'd3-shape';
import styles from '../chart.module.scss';
import { getPatientTimezone, PatientContext } from '../../../../contexts/PatientContext';
import { getTicksH, getTicksDD } from '../utlity';
import { getAdherenceScores } from '../../../../services/api/chart';
import { CHARTS_SIZES, GRAPH_REVERSE_BIG_TOOLTIP_COORDINATE } from '../../../../utils/constants';
import { Tooltip as InfoTooltip } from '../../tooltip';
import InfoBox from './info-box';
import AdherenceTooltip from './adherence-tooltip';
import { ChartToolTip } from '../chart-tooltip';
import { AdherenceComponent } from '../../../../interfaces/chart-results';

type AdherenceChartDto = {
  ts: number;
  value: number;
  components: AdherenceComponent;
  version: number;
};

type Props = {
  start: string;
  end: string;
  urData?: { ts: string; value: number }[];
  isPdf?: boolean;
  reference?: number;
};

export const AdherenceChart: FC<Props> = ({ start, end, urData, isPdf, reference }): JSX.Element => {
  const patientTimezone = getPatientTimezone();
  const [data, setData] = useState<Array<AdherenceChartDto>>([]);
  const { patient } = useContext(PatientContext);
  const GRAPH_WIDTH_PX = CHARTS_SIZES.CDP_WIDTH;
  const GRAPH_HEIGHT_PX = CHARTS_SIZES.CDP_HEIGHT;

  const getAndParseScores = async () => {
    const { data } = await getAdherenceScores(patient!.id, Math.floor(GRAPH_WIDTH_PX / 4), {
      startDate: moment(start).format('YYYY-MM-DD'),
      endDate: moment(end).tz(patientTimezone).format('YYYY-MM-DD'),
      timezone: patientTimezone,
    });
    const points = (data?.points || []).map(p => {
      return { ...p, ts: moment.utc(p.ts).tz(patientTimezone).valueOf() };
    });
    setData(points);
  };

  useEffect(() => {
    if (patient) {
      getAndParseScores();
    }
  }, [patient, start, end]);

  const YAxisFormatter = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { coordinate: number; value: number; offset: number };
  }): JSX.Element => {
    const { value } = payload;
    if (!y || !x) {
      return <text />;
    }
    if (value === 0) {
      return (
        <text
          fontSize='10'
          x={x - 36}
          y={y}
          textAnchor='start'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          Poor {value}
        </text>
      );
    } else if (value === 100) {
      return (
        <text
          fontSize='10'
          x={x - 49}
          y={y + 7}
          textAnchor='start'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          Good {value}
        </text>
      );
    } else {
      return (
        <text
          fontSize='10'
          x={x - 7}
          y={y + 4}
          textAnchor='end'
          color='#444444'
          fontWeight='normal'
          fontFamily='sans-serif'
        >
          {value}
        </text>
      );
    }
  };

  const customToolTip = ({
    active,
    coordinate,
    payload,
    label,
  }: {
    active: boolean;
    coordinate: { x: number; y: number };
    label: number;
    payload: Array<any>;
  }): JSX.Element | null => {
    if (!active || !payload?.[0].payload) {
      return null;
    }
    const reverse = coordinate.x > GRAPH_REVERSE_BIG_TOOLTIP_COORDINATE;
    const score: AdherenceChartDto = payload?.[0].payload;
    return !!Object.values(score.components || {}).filter(value => typeof value === 'number').length ? (
      <AdherenceTooltip date={label} reverse={reverse} data={score} />
    ) : (
      <ChartToolTip date={label} title='ADHERENCE' value={score.value} reverse={reverse} />
    );
  };

  return (
    <div id='adherenceChart'>
      <div className={styles.chartHeader}>
        <span className={styles.chartTitle}>ADHERENCE</span>
        {!urData && (
          <InfoTooltip baseStyles={`${styles.infoTooltip} ${styles.adherence}`} type='left' background='#F5F6FA'>
            <InfoBox />
          </InfoTooltip>
        )}
      </div>
      <AreaChart width={GRAPH_WIDTH_PX} height={GRAPH_HEIGHT_PX} data={data}>
        <defs>
          <linearGradient id='d1' x1={0} y1={0} x2={0} y2={1}>
            <stop offset='3%' stopColor='#417EB9' stopOpacity={0.8} />
            <stop offset='97%' stopColor='rgba(65, 126, 185, 0.2)' stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          xAxisId={0}
          dx={2}
          style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
          tickLine={false}
          tickSize={16}
          tickFormatter={(): string => ''}
          dataKey='ts'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
        />
        <XAxis
          xAxisId={0}
          dy={-18}
          dx={0}
          style={{
            fontSize: '11',
            fontWeight: 'bold',
            fontFamily: 'sans-serif',
            color: '#000000',
          }}
          tickLine={false}
          axisLine={false}
          tickFormatter={(): string => ''}
          dataKey='ts'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
        />
        <XAxis
          xAxisId={0}
          dy={-34}
          dx={0}
          style={{ fontSize: '11', fontFamily: 'sans-serif', color: '#000000' }}
          tickLine={false}
          axisLine={false}
          tickFormatter={(): string => ''}
          dataKey='ts'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
        />
        <YAxis tick={YAxisFormatter} interval={0} tickSize={0} ticks={[100, 75, 50, 25, 0]} />
        <CartesianGrid stroke='#A3A6B3' />
        <Tooltip content={customToolTip} allowEscapeViewBox={{ x: false, y: true }} />
        <Area type={curveLinear} dataKey='value' stroke='#417EB9' fill='url(#d1)' strokeWidth='2' />
      </AreaChart>
    </div>
  );
};
