import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useToasts } from 'react-toast-notifications';
import Modal from '../modal/Modal';
import styles from './admin-devices.module.scss';
import { Device } from '../../interfaces/device';
import { Patient, Patients } from '../../interfaces/patient';
import { RemoteData } from '../../utils/remote-data';
import { Loading } from '../common/loading';
import { assignDeviceToPatient } from '../../services/api/device';
import { deviceStatus } from '../../utils/constants';

type Props = {
  show: boolean;
  hide: () => void;
  device: Device;
  patients: RemoteData<Patients>;
  fetchDevices: () => void;
  setConfirmationModalMode: Dispatch<SetStateAction<string>>;
  selectedPatient: Patient | undefined;
  setSelectedPatient: Dispatch<SetStateAction<Patient | undefined>>;
};

const AssignDeviceModal: React.FC<Props> = ({
  show,
  hide,
  device,
  patients,
  fetchDevices,
  setConfirmationModalMode,
  selectedPatient,
  setSelectedPatient,
}): JSX.Element => {
  const { addToast } = useToasts();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [selectedPatientId, setSelectedPatientId] = useState<string>('');
  const origin = device.patientAssignedId ? 'assigned' : 'unassigned';

  useEffect(() => {
    if (patients.status === 'Done') {
      setShowLoading(false);
      setSelectedPatientId(patients.data.values[0].id);
    } else {
      setShowLoading(true);
    }
  }, [patients]);

  useEffect(() => {
    if (selectedPatientId === device.patientAssignedId) {
      setSelectedPatientId('');
      setSelectedPatient(selectedPatient);
    } else if (!!selectedPatientId && patients.status === 'Done') {
      const patient = patients.data.values.find(pat => pat.id === selectedPatientId);
      setSelectedPatient(patient);
    }
  }, [selectedPatientId]);

  const assignDeviceAction = async () => {
    setShowLoading(true);
    const result = await assignDeviceToPatient(selectedPatientId, device.name);
    setShowLoading(false);

    if (result.status === 'Done') {
      addToast('Device assigned successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      hide();
      fetchDevices();
    } else {
      addToast(`Error: ${result.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return !showLoading && patients.status === 'Done' ? (
    <Modal show={show} closeModal={() => hide()} showBtnClose={false} contentStyle='bigContent__static'>
      <div className={styles.assignDeviceModal}>
        {origin === 'assigned' ? (
          <div className={styles.header}>
            <span>VIEW ASSIGNED DEVICE</span>
          </div>
        ) : (
          <div className={styles.header}>
            {device.condition === deviceStatus.GOOD ? <span>ASSIGN DEVICE</span> : <span>VIEW DEVICE</span>}
          </div>
        )}
        <div className={styles.body}>
          <div className={styles.deviceInfo}>
            <div>
              <span>DEVICE NAME:</span>
              <span>{device.name}</span>
            </div>
            <div>
              <span>DEVICE TYPE:</span>
              <span>{device.deviceModel.type}</span>
            </div>
            <div>
              <span>DATE IN INVENTORY:</span>
              <span>{moment.utc(device.organizationAssignedTimestamp).local().format('lll')}</span>
            </div>
            <div>
              <span>CONDITION:</span>
              <span>{device.condition}</span>
            </div>
          </div>
          {(device.condition === deviceStatus.GOOD || device.condition === deviceStatus.RECYCLED) && (
            <div className={styles.assignSelect}>
              <span>Assign to client</span>
              <select
                onChange={e => setSelectedPatientId(e.target.value)}
                defaultValue={patients.data.values.find(p => p.id === device.patientAssignedId)?.id}
              >
                {patients.data.values.map((patient: Patient, idx: number) => (
                  <option key={idx} value={patient.id}>{`${patient.name.first} ${patient.name.last}`}</option>
                ))}
              </select>
            </div>
          )}
        </div>
        {origin === 'assigned' ? (
          <div className={styles.buttons}>
            <button className={styles.closeBtn} type='button' onClick={() => hide()}>
              Cancel
            </button>
            <button className={styles.closeBtn} type='button' onClick={() => setConfirmationModalMode('unassign')}>
              Unassign
            </button>
            <button
              className={!selectedPatientId ? styles.btnDisabled : styles.submitBtn}
              type='button'
              onClick={() => (!selectedPatientId ? undefined : setConfirmationModalMode('reassign'))}
            >
              Reassign
            </button>
          </div>
        ) : (
          <div
            className={
              device.condition === deviceStatus.GOOD || device.condition === deviceStatus.RECYCLED
                ? styles.buttons
                : styles.buttons__bottomFixed
            }
          >
            <button className={styles.closeBtn} type='button' onClick={() => hide()}>
              Cancel
            </button>
            {(device.condition === deviceStatus.GOOD || device.condition === deviceStatus.RECYCLED) && (
              <button className={styles.submitBtn} type='button' onClick={() => assignDeviceAction()}>
                Assign
              </button>
            )}
          </div>
        )}
      </div>
    </Modal>
  ) : (
    <Modal show={true} closeModal={() => hide} showBtnClose={false} contentStyle='bigContent__static'>
      <div className={styles.assignDeviceModal__loading}>
        <Loading />
      </div>
    </Modal>
  );
};

export default AssignDeviceModal;
