import moment from 'moment-timezone';
import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { CellProps, Column, Row, UseExpandedOptions } from 'react-table';
import FemaleAvatarIcon from '../../assets/icons/icn-inverted-female.svg';
import MaleAvatarIcon from '../../assets/icons/icn-inverted-male.svg';
import { DischargedPatient, DischargedPatients } from '../../interfaces/patient';
import { EPISODE_OF_CARE } from '../../utils/constants';
import { displayFullName, displayTableDateTimeFormat } from '../../utils/data-parser';
import { RemoteData } from '../../utils/remote-data';
import Table from '../common/table/table';
import styles from './admin-alumni.module.scss';

type Props = {
  patients: RemoteData<DischargedPatients>;
  filterPatientsList: DischargedPatients | undefined;
  setSelectedPatient: Dispatch<SetStateAction<DischargedPatient | undefined>>;
};

const DischargedClientsTable: FC<Props> = ({ patients, filterPatientsList, setSelectedPatient }) => {
  const timezone = moment.tz.guess();
  const columns: Column<DischargedPatient>[] = useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: (originalRow): object => {
          return {
            nameDisplay: displayFullName(originalRow.name),
            profilePicture: originalRow.image ? originalRow.image.base64 : null,
          };
        },
        width: 200,
        Cell: ({ cell: { value, row } }: CellProps<DischargedPatient>) => (
          <div className={styles.nameContainer}>
            {value.profilePicture ? (
              <img
                className={styles.profilePicture}
                src={`data:image/jpeg;base64,${value.profilePicture}`}
                alt='avatar'
              />
            ) : (
              <img
                className={styles.picturePlaceholder}
                src={row.original.gender === 'FEMALE' ? FemaleAvatarIcon : MaleAvatarIcon}
                alt='avatar'
              />
            )}
            <span className={styles.overflowWrapped}>{value.nameDisplay}</span>
          </div>
        ),
      },
      {
        Header: 'PRACTITIONERS',
        width: 260,
        accessor: originalRow => {
          return originalRow.careTeam?.careTeamMembers?.map((tm, idx) => displayFullName(tm.name)).join(', ')||'';
        },
        Cell: ({ cell: { value } }) => <div className={styles.dataFullDisplay}>{value}</div>,
      },
      {
        Header: 'LATEST ADMISSION DATE',
        width: 220,
        accessor: (originalRow): object => {
          return {
            dateOfAdmission: originalRow.dateOfAdmission ??
                (originalRow.finishedEpisodeOfCareList[0] ? originalRow.finishedEpisodeOfCareList[0].dateOfDischarge : "-"),
            episodeOfCare: EPISODE_OF_CARE[originalRow.episodeOfCare],
          };
        },
        Cell: ({
          cell: { value, row },
        }: CellProps<DischargedPatient, { dateOfAdmission: string; episodeOfCare: string }>) => (
          <div className={styles.admissionDateContainer}>
            <span>Discharged to {value.episodeOfCare}</span>
            <span>{displayTableDateTimeFormat(value.dateOfAdmission, timezone)}</span>
            {value.episodeOfCare === EPISODE_OF_CARE.SELF_CARE && (
              <span className={styles.action} onClick={() => setSelectedPatient(row.original)}>
                RE-ADMIT
              </span>
            )}
          </div>
        ),
      },
      {
        Header: 'PHONE NUMBER',
        accessor: originalRow => originalRow.contact.cellphone,
        width: 180,
      },
      {
        Header: 'EMAIL ADDRESS',
        accessor: originalRow => originalRow.contact.email,
        width: 200,
      },
      {
        Header: 'EPISODE OF CARE',
        width: 280,
        accessor: originalRow => originalRow.finishedEpisodeOfCareList,
        Cell: ({ cell: { value } }: CellProps<DischargedPatient, any[]>) => `${value.length} Total`,
      },
      {
        Header: () => null, // No header
        id: 'expander',
        width: 30,
        Cell: ({ row }) => (
          <span
            className={`${styles.expandAction} ${row.isExpanded && styles.expanded}`}
            {...row.getToggleRowExpandedProps()}
          >
            {row.isExpanded ? '-' : '+'}
          </span>
        ),
      },
    ],
    [],
  );

  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'timestamp', desc: true }] }),
    [],
  );

  const defaultExpandedOptions: UseExpandedOptions<DischargedPatient> = useMemo(() => ({ expandSubRows: false }), []);

  const defaultColumnOptions = useMemo(() => ({ width: 180, minWidth: 100 }), []);

  const getHeaderProps = column => {
    return {
      className: styles.th,
      key: `${column?.id}`,
      style: {
        width: column?.width,
        minWidth: column?.minWidth,
        maxWidth: column?.maxWidth,
      },
    };
  };

  const getTableProps = () => {
    return {
      className: patients.status === 'Done' && !patients.data.values ? styles.noContent : styles.table,
      style: {
        width: 'calc(100% - 36px)',
        marginLeft: '18px',
      },
    };
  };

  const getTableBodyProps = () => {
    return {
      className: styles.tbody,
      style: {
        height: '564px',
      },
    };
  };

  const getRowProps = row => {
    return {
      key: `${row?.id}`,
      className: styles.alumniTr,
    };
  };

  const getCellProps = cell => {
    return {
      key: `${cell?.column.id}`,
      className: `${styles.td} ${styles.tdInline}`,
    };
  };

  const renderRowSubComponent = React.useCallback(
    (row: Row<DischargedPatient>): JSX.Element => (
      <div {...row.getRowProps({ className: styles.subRowTr })}>
        <div className={styles.subRow}>
          <div className={styles.header}>
            <span className={styles.columnHeader}>Practitioners</span>
            <span className={styles.columnHeader} style={{ width: '334px' }}>
              Admission Date
            </span>
            <span className={styles.columnHeader}>Episode of Care</span>
          </div>
          {row.original.finishedEpisodeOfCareList.map(value => (
            <div className={styles.eocRow} key={value.id}>
              <span
                className={`${styles.td} ${styles.cell}`}
                style={{ padding: '6px 21px 18px 21px', whiteSpace: 'pre-wrap' }}
              >
                {Object.values(value.practitionerIdToNameMap)
                  .map((tm, idx) => displayFullName(tm))
                  .join(', ')}
              </span>
              <span className={`${styles.td} ${styles.cell}`} style={{ width: '334px' }}>
                {displayTableDateTimeFormat(value.dateOfAdmission, timezone)} -{' '}
                {displayTableDateTimeFormat(value.dateOfDischarge, timezone)}
              </span>
              <span className={`${styles.td} ${styles.cell}`}> {EPISODE_OF_CARE[value.type]}</span>
            </div>
          ))}
        </div>
      </div>
    ),
    [],
  );

  return (
    <Table<DischargedPatient>
      columns={columns}
      data={filterPatientsList ? filterPatientsList.values : patients.status === 'Done' ? patients.data.values : []}
      isLoading={patients.status === 'Loading'}
      error={patients.status === 'Failed'}
      getTableProps={() => getTableProps()}
      getRowProps={row => getRowProps(row)}
      getHeaderProps={column => getHeaderProps(column)}
      getCellProps={cell => getCellProps(cell)}
      getTableBodyProps={() => getTableBodyProps()}
      defaultColumn={defaultColumnOptions}
      initialState={defaultSorting}
      tableOptions={defaultExpandedOptions}
      renderRowSubComponent={renderRowSubComponent}
    />
  );
};
export default DischargedClientsTable;
