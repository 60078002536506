import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from './forgotPassswordModal.module.scss';
import { sendResetPassword } from '../../services/api/auth';

type Props = {
  email: string;
  closeModal: () => void;
};

const ForgotPasswordModal: FunctionComponent<Props> = ({ email, closeModal }) => {
  const [resetPasswordMessage, setResetPasswordMessage] = useState<
    { status: 'success' | 'failed'; message: string } | undefined
  >(undefined);
  const [resetEmail, setResetEmail] = useState('');

  useEffect(() => {
    setResetEmail(email);
  }, [email]);

  const submit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setResetPasswordMessage(undefined);
    const result = await sendResetPassword(resetEmail.trim().toLowerCase());
    if (result.status === 'Done') {
      setResetPasswordMessage({ status: 'success', message: '' });
    } else {
      setResetPasswordMessage({ status: 'failed', message: result.errorApi.message! });
    }
  };

  const onCloseModal = () => {
    setResetPasswordMessage(undefined);
    setResetEmail('');
    closeModal();
  };

  return (
    <section className={styles.forgotPasswordModal}>
      <div className={styles.modal_header}>
        <h4>Forgot password</h4>
      </div>
      <p>Please enter your email address to be sent a password reset email.</p>
      <form>
        <label>
          <span className={styles.emailLabel}>Email: </span>
          <input
            type='text'
            className={`${styles.emailInput} ${
              !!resetPasswordMessage
                ? resetPasswordMessage.status.includes('success')
                  ? styles.success
                  : styles.messageError
                : ''
            }`}
            name='email'
            placeholder='Email address'
            value={resetEmail}
            onChange={e => setResetEmail(e.target.value)}
          />
        </label>
        {!!resetPasswordMessage && (
          <div
            className={`${styles.tooltip} ${
              resetPasswordMessage.status.includes('success') ? styles.success : styles.messageError
            }`}
          >
            {resetPasswordMessage.status.includes('failed') ? (
              <span> {resetPasswordMessage.message}</span>
            ) : (
              <span>
                Your request has been submitted. If the email address you provided is associated with an account, you
                will receive an email with reset instructions. Please check the inbox.
                <i>You may only submit requests once every 15 minutes</i>
              </span>
            )}
          </div>
        )}
      </form>
      <div className={styles.rowButtons}>
        <button className={styles.saveButton} disabled={!resetEmail.length} onClick={submit}>
          Send Email
        </button>
        <button className={styles.cancelButton} onClick={onCloseModal}>
          Cancel
        </button>
      </div>
    </section>
  );
};

export default ForgotPasswordModal;
