import React, { useMemo, Dispatch, SetStateAction } from 'react';
import Table from '../common/table/table';
import styles from './admin-devices.module.scss';
import { Column } from 'react-table';
import { RemoteData } from '../../utils/remote-data';
import { Devices, Device } from '../../interfaces/device';
import { capitalizeWord, displayDeviceType } from '../../utils/data-parser';
import { deviceStatus } from '../../utils/constants';
import TableEmpty from '../common/table/table-empty';
import moment from 'moment-timezone';

type Props = {
  devices: RemoteData<Devices>;
  setSelectedDevice: Dispatch<SetStateAction<Device | undefined>>;
  searchResultDevices: Devices | undefined;
};

const UnassignedDevicesTable: React.FC<Props> = ({ devices, setSelectedDevice, searchResultDevices }): JSX.Element => {
  const columns: Column<Device>[] = useMemo(
    () => [
      {
        Header: 'DEVICE NAME',
        accessor: 'name',
        width: 290,
      },
      {
        Header: 'DEVICE TYPE',
        width: 280,
        accessor: originalRow =>
          displayDeviceType({
            type: originalRow.deviceModel.type,
            firmwareVersion: originalRow.firmwareVersion,
          }),
      },
      {
        Header: 'DATE IN INVENTORY',
        accessor: originalRow => moment.utc(originalRow.organizationAssignedTimestamp).local().format('lll'),
        width: 300,
      },
      {
        Header: 'DEVICE CONDITION',
        accessor: originalRow => capitalizeWord(originalRow.condition),
        width: 281,
      },
      {
        Header: '',
        accessor: 'id',
        width: 100,
        Cell: ({ cell }): JSX.Element => (
          <span className={styles.action} onClick={() => setSelectedDevice(cell.row.original)}>
            {cell.row.original.condition === deviceStatus.GOOD || cell.row.original.condition === deviceStatus.RECYCLED
              ? 'Assign Device'
              : 'View'}
          </span>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'timestamp', desc: true }] }),
    [],
  );

  const getRowProps = (row, idx) => {
    return {
      key: `${row?.id}`,
      className: styles.tr,
    };
  };

  const getHeaderProps = column => {
    return {
      className: styles.th,
      key: `${column?.id}`,
      style: {
        width: column?.width,
        minWidth: column?.minWidth,
        maxWidth: column?.maxWidth,
      },
    };
  };

  const getCellProps = (cell, idx) => {
    return {
      key: `${cell?.column.id}`,
      className: styles.td,
    };
  };

  const getTableBodyProps = () => {
    return {
      className: styles.tbody,
      style: {
        height: '564px',
      },
    };
  };

  const getTableProps = () => {
    return {
      className: devices.status === 'Done' && !devices.data.values ? styles.noContent : styles.table,
      style: {
        width: 'calc(100% - 36px)',
        marginLeft: '18px',
      },
    };
  };

  return devices.status === 'Done' && !devices.data.values ? (
    <TableEmpty>
      <span className={styles.noDevicesTitle}>No devices in inventory</span>
      <span className={styles.noDevicesSub}>
        Contact support at ViviHealth to ensure inventory sync is established.
      </span>
      <span className={styles.noDevicesBtn}>Conact Support</span>
    </TableEmpty>
  ) : (
    <Table<Device>
      columns={columns}
      data={searchResultDevices ? searchResultDevices.values : devices.status === 'Done' ? devices.data.values : []}
      isLoading={devices.status === 'Loading'}
      error={devices.status === 'Failed'}
      getTableProps={() => getTableProps()}
      getRowProps={(row, idx) => getRowProps(row, idx)}
      getHeaderProps={column => getHeaderProps(column)}
      getCellProps={(cell, idx) => getCellProps(cell, idx)}
      getTableBodyProps={() => getTableBodyProps()}
      defaultColumn={{ width: 180, minWidth: 100 }}
      initialState={defaultSorting}
    />
  );
};

export default UnassignedDevicesTable;
