import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import styles from './client-stats-container.module.scss';
import ClientStatsSection from './components/client-stats-section';
import { useClientStatsReportQuery } from '../../graphql/codegen/hooks';
import { PtsMetric } from '../../graphql/codegen/schemas';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { EPISODE_OF_CARE } from '../../utils/constants';
import { Loading } from '../common/loading';
import TotalClientsChart from './components/total-clients-chart';

type ReportRow = {
  name: string;
  todayCount: number;
  actualWeekCount: number;
  lastWeekCount: number;
  actualMonthCount: number;
  lastMonthCount: number;
};

const ClientStatsContainer = () => {
  const { organization, loading: orgLoading } = useContext(OrganizationContext);
  const [eocCountList, setEocCountList] = useState<ReportRow[]>([]);
  const [clientsIntakeList, setClientsIntakeList] = useState<{ value: number; label: string }[]>([]);
  const queryDates = useMemo(() => {
    return {
      startOfDay: moment().startOf('day').format(),
      endOfDay: moment().endOf('day').format(),
      startLastWeek: moment().subtract(14, 'd').format(),
      endLastWeek: moment().subtract(7, 'd').format(),
      startLastMonth: moment().subtract(1, 'month').format(),
      startOfMonth: moment().startOf('month').format(),
    };
  }, []);
  const org = organization?.data?.org;

  // const getSteps = () => {
  //   query Steps {
  //     user_steps(
  //       id: "${patientId}"
  //       maxPoints: ${maxPoints}
  //       start: "${start}"
  //       end: "${end}"
  //       tz: "${getTz()}"
  //     ) {
  //       id
  //       ts
  //       count
  //     }
  // }
  // };

  const { loading, data } = useClientStatsReportQuery({
    variables: {
      id: org?.id,
      metric: PtsMetric.RelapseRisk,
      orgReportGroups: {
        groups: [
          { name: 'stable', higherBound: 24 },
          { name: 'risk', lowerBound: 25, higherBound: 54 },
          { name: 'critical', lowerBound: 55 },
        ],
      },
      ...queryDates,
    },
    skip: orgLoading || !org?.id,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && data?.organizationReport) {
      const list: ReportRow[] = [];
      const reportResult = data.organizationReport;
      const arrayToSort = [...reportResult.clientsInTakeByMonth?.intakeByMonth!];

      setClientsIntakeList(
        arrayToSort
          ?.sort((a, b) => {
            return a.month.localeCompare(b.month);
          })
          .map(item => ({
            value: item.count!,
            label: moment(item.month).format('MMM'),
          })) ?? [],
      );

      reportResult.today?.episodesOfCare?.forEach(eoc => {
        const reportObj: ReportRow = {
          name: eoc.episodeOfCareType!,
          todayCount: eoc.count!,
          actualMonthCount: 0,
          lastMonthCount: 0,
          actualWeekCount: 0,
          lastWeekCount: 0,
        };

        const countLastWeek = reportResult.lastWeek?.episodesOfCare.find(
          value => value?.episodeOfCareType === reportObj.name,
        );
        const countLastMonth = reportResult.lastMonth?.episodesOfCare.find(
          value => value?.episodeOfCareType === reportObj.name,
        );
        const countActualWeek = reportResult.thisWeek?.episodesOfCare.find(
          value => value?.episodeOfCareType === reportObj.name,
        );
        const countActualMonth = reportResult.thisMonth?.episodesOfCare.find(
          value => value?.episodeOfCareType === reportObj.name,
        );
        list.push({
          name: EPISODE_OF_CARE[reportObj.name],
          todayCount: reportObj.todayCount,
          lastWeekCount: countLastWeek?.count ?? 0,
          lastMonthCount: countLastMonth?.count ?? 0,
          actualWeekCount: countActualWeek?.count ?? 0,
          actualMonthCount: countActualMonth?.count ?? 0,
        });
      });
      setEocCountList(list);
    }
  }, [data, loading]);

  if (loading) {
    return (
      <div className={styles.container}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <span className={styles.title}>CLIENT STATS</span>
      <div className={styles.tableContainer}>
        <span className={styles.verticalLine} />
        <span className={styles.verticalLineRight} />
        <div className={styles.tableHeader}>
          <div />
          <div>Today</div>
          <div>This Week</div>
          <div>Last Week</div>
          <div>This Month</div>
          <div>Last Month</div>
        </div>
        <ClientStatsSection
          title='Category'
          statsList={[
            {
              name: 'Critical Clients',
              todayCount:
                data?.organizationReport?.today?.clientTelemetryCounts?.find(value => value?.name === 'critical')
                  ?.count ?? 0,
              lastMonthCount:
                data?.organizationReport?.lastMonth?.clientTelemetryCounts?.find(value => value?.name === 'critical')
                  ?.count ?? 0,
              actualMonthCount:
                data?.organizationReport?.thisMonth?.clientTelemetryCounts?.find(value => value?.name === 'critical')
                  ?.count ?? 0,
              actualWeekCount:
                data?.organizationReport?.thisWeek?.clientTelemetryCounts?.find(value => value?.name === 'critical')
                  ?.count ?? 0,
              lastWeekCount:
                data?.organizationReport?.lastWeek?.clientTelemetryCounts?.find(value => value?.name === 'critical')
                  ?.count ?? 0,
            },
            {
              name: 'At Risk Clients',
              todayCount:
                data?.organizationReport?.today?.clientTelemetryCounts?.find(value => value?.name === 'risk')?.count ??
                0,
              lastMonthCount:
                data?.organizationReport?.lastMonth?.clientTelemetryCounts?.find(value => value?.name === 'risk')
                  ?.count ?? 0,
              actualMonthCount:
                data?.organizationReport?.thisMonth?.clientTelemetryCounts?.find(value => value?.name === 'risk')
                  ?.count ?? 0,
              actualWeekCount:
                data?.organizationReport?.thisWeek?.clientTelemetryCounts?.find(value => value?.name === 'risk')
                  ?.count ?? 0,
              lastWeekCount:
                data?.organizationReport?.lastWeek?.clientTelemetryCounts?.find(value => value?.name === 'risk')
                  ?.count ?? 0,
            },
            {
              name: 'Stable Clients',
              todayCount:
                data?.organizationReport?.today?.clientTelemetryCounts?.find(value => value.name === 'stable')?.count ??
                0,
              lastMonthCount:
                data?.organizationReport?.lastMonth?.clientTelemetryCounts?.find(value => value.name === 'stable')
                  ?.count ?? 0,
              actualMonthCount:
                data?.organizationReport?.thisMonth?.clientTelemetryCounts?.find(value => value.name === 'stable')
                  ?.count ?? 0,
              actualWeekCount:
                data?.organizationReport?.thisWeek?.clientTelemetryCounts?.find(value => value.name === 'stable')
                  ?.count ?? 0,
              lastWeekCount:
                data?.organizationReport?.lastWeek?.clientTelemetryCounts?.find(value => value.name === 'stable')
                  ?.count ?? 0,
            },
          ]}
        />
        <ClientStatsSection title='Episode of Care' statsList={eocCountList} />
        <ClientStatsSection
          title='Total Clients'
          statsList={[
            {
              name: 'Active Clients',
              todayCount: data?.organizationReport?.today?.activeClients ?? 0,
              lastMonthCount: data?.organizationReport?.lastMonth?.activeClients ?? 0,
              actualMonthCount: data?.organizationReport?.thisMonth?.activeClients ?? 0,
              actualWeekCount: data?.organizationReport?.thisWeek?.activeClients ?? 0,
              lastWeekCount: data?.organizationReport?.lastWeek?.activeClients ?? 0,
            },
            {
              name: 'Pending Clients',
              todayCount: data?.organizationReport?.today?.pendingClients ?? 0,
              lastMonthCount: data?.organizationReport?.lastMonth?.pendingClients ?? 0,
              actualMonthCount: data?.organizationReport?.thisMonth?.pendingClients ?? 0,
              actualWeekCount: data?.organizationReport?.thisWeek?.pendingClients ?? 0,
              lastWeekCount: data?.organizationReport?.lastWeek?.pendingClients ?? 0,
            },
          ]}
        />
      </div>
      <TotalClientsChart title='Total # Clients Intake By Month' legend='# Clients' dataSet={clientsIntakeList} />
    </div>
  );
};

export default ClientStatsContainer;
