import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { TextInput } from '../common/form';
import styles from './verify-phone.module.scss';
import { CODE_VERIFICATION_STATUS } from '../../utils/constants';

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  verifyCode: (code: string) => Promise<void>;
  codeVerificationStatus: string;
  resendCode: () => void;
  enableVerify: boolean;
  enableResend: boolean;
};

const VerifyPhone: React.FC<Props> = ({
  setShow,
  verifyCode,
  codeVerificationStatus,
  resendCode,
  enableVerify,
  enableResend,
}) => {
  const [code, setCode] = useState<string>('');

  const onChangeField = (evt: ChangeEvent<HTMLInputElement>) => {
    setCode(evt.target.value);
  };

  return (
    <div className={styles.verifyModal}>
      <div className={styles.header}>LAST STEP TO ENABLE SMS NOTIFICATIONS</div>
      <div className={styles.form}>
        <TextInput
          name='code'
          label='Enter your verification code from your mobile device:'
          placeholder='Enter 6-digit verification code'
          onChangeCustom={onChangeField}
          showSuccessStyles={codeVerificationStatus === CODE_VERIFICATION_STATUS.SUCCESS}
          showErrorStyles={
            codeVerificationStatus === CODE_VERIFICATION_STATUS.FAILED ||
            (!code && codeVerificationStatus !== CODE_VERIFICATION_STATUS.SUCCESS)
          }
          value={code}
        />
        <p
          className={
            codeVerificationStatus === CODE_VERIFICATION_STATUS.FAILED ||
            codeVerificationStatus !== CODE_VERIFICATION_STATUS.SUCCESS
              ? styles.errorMessage
              : ''
          }
        >
          Note: Your 6-digit code has been sent as an SMS to your mobile device. You may “resend code” up to 10 times.{' '}
        </p>
      </div>
      <div className={styles.buttons}>
        <button className={styles.whiteBtn} type='button' onClick={() => setShow(false)}>
          Cancel
        </button>
        <div>
          <button
            className={enableResend ? styles.whiteBtn : styles.disabledBtn__sideMargin}
            type='button'
            onClick={() => enableResend && resendCode()}
          >
            Resend
          </button>
          <button
            className={code.length === 6 && enableVerify ? styles.blueBtn : styles.disabledBtn}
            type='button'
            onClick={() => code.length === 6 && enableVerify && verifyCode(code)}
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyPhone;
