import React, { FunctionComponent } from 'react';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Patient } from '../../../../interfaces/patient';
import { Practitioner } from '../../../../interfaces/practitioner';
import TableHeader from '../../components/table-header';
import DocInfoHeader from '../../components/doc-info-header';
import moment from 'moment-timezone';
import { BacResult } from '../../../../interfaces/bac-result';
import BacPending from '../../../../assets/icons/bac_pending.png';
import BacMiss from '../../../../assets/icons/bac_missed.png';
import BacPassed from '../../../../assets/icons/bac_passed.png';
import BacFailed from '../../../../assets/icons/bac_failed.png';
import { caseOf } from '../../../../utils/case-of';

const styles = StyleSheet.create({
  page: {
    paddingTop: 100,
    paddingLeft: 208,
    paddingRight: 189,
    paddingBottom: 120,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderBottomColor: '#A3A6B3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingBottom: 18,
  },
  pageNote: {
    position: 'absolute',
    bottom: 90,
    right: 120,
    fontSize: 24,
  },
  content: {
    marginBottom: 45,
    borderBottomColor: '#A3A6B3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    fontSize: 30,
    fontWeight: 'normal',
    color: '#323335',
    borderBottomColor: '#A3A6B3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
  },
  logo: {
    width: 260,
    height: 100,
    objectFit: 'contain',
  },
});

type Props = {
  dateRange: { start: string; end: string; timestamp?: string };
  timezone: string;
  patient?: Patient;
  practitioner?: Practitioner;
  bacList: BacResult[];
  organizationLogo?: string;
};

const BacTablePdf: FunctionComponent<Props> = ({
  patient,
  dateRange,
  practitioner,
  bacList,
  timezone,
  organizationLogo,
}) => {
  const rowWidth = 2900 / 6;

  const getRowStyles = (width: number) => {
    return StyleSheet.create({
      cell: {
        width,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 15,
      },
    });
  };

  const getStatusColor = (status: string) => {
    return caseOf()
      .case('pass', '#6fcf97')
      .case('pending', '#a3a6b3')
      .case('miss', '#efbb55')
      .defaultCase(() => '#ED6F6A')
      .eval(status.toLowerCase());
  };
  const getStatusIcon = (status: string) => {
    return caseOf()
      .case('pass', BacPassed)
      .case('pending', BacPending)
      .case('miss', BacMiss)
      .defaultCase(() => BacFailed)
      .eval(status.toLowerCase());
  };

  return (
    <Document>
      <Page wrap={true} style={styles.page} size={{ width: 3300, height: 2500 }}>
        <Text
          style={styles.pageNote}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
        <View fixed />
        <View style={styles.title} fixed>
          {organizationLogo ? (
            <Image src={organizationLogo} style={styles.logo} />
          ) : (
            <Text>{practitioner?.organization.name} (BAC Report)</Text>
          )}
          <Text>Patient ID: {patient?.emrIdentifier || '--'}</Text>
        </View>
        <DocInfoHeader patient={patient!} practitioner={practitioner!} dateRange={dateRange} />
        <TableHeader
          headers={[
            { title: 'Date & Time', width: rowWidth },
            {
              title: 'Bac Status',
              width: rowWidth,
            },
            { title: 'Scheduled', width: rowWidth - 100 },
            { title: 'Bac Level', width: rowWidth - 100 },
            {
              title: 'Location',
              width: rowWidth + 300,
            },
            { title: 'Face match', width: rowWidth - 100 },
          ]}
        />
        {bacList.map((bac, index) => (
          <View style={styles.tableRow} key={index}>
            <Text style={getRowStyles(rowWidth).cell}>{moment.utc(bac.timestamp).tz(timezone).format('lll')}</Text>
            <View style={{ ...getRowStyles(rowWidth).cell, paddingTop: 0, marginTop: 0 }}>
              <Image style={{ width: 25, height: 25, marginRight: '20px' }} src={getStatusIcon(bac.status)} />
              <Text style={{ color: getStatusColor(bac.status) }}>{bac.status.toLocaleUpperCase()}</Text>
            </View>
            <Text style={getRowStyles(rowWidth - 100).cell}>{bac.scheduled ? 'Yes' : 'No'}</Text>
            <Text style={getRowStyles(rowWidth - 100).cell}>{isNaN(bac.level) ? 'N/A' : bac.level}</Text>
            <Text style={getRowStyles(rowWidth + 300).cell}>{bac.location.address}</Text>
            <Text style={{ ...getRowStyles(rowWidth - 100).cell, textTransform: 'capitalize' }}>
              {bac.identification.faceMatchStatus ? bac.identification.faceMatchStatus.toLocaleLowerCase() : 'N/A'}
            </Text>
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default BacTablePdf;
