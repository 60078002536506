import React, { FunctionComponent } from 'react';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Patient } from '../../../../interfaces/patient';
import { Practitioner } from '../../../../interfaces/practitioner';
import MeetingsTableHeader from '../../components/meetings-table-header';
import DocInfoHeader from '../../components/doc-info-header';
import moment from 'moment-timezone';
import { Meeting } from '../../../../interfaces/meeting';
import { caseOf } from '../../../../utils/case-of';


const styles = StyleSheet.create({
  page: {
    paddingTop: 100,
    paddingLeft: 208,
    paddingRight: 189,
    paddingBottom: 120,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 36,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderBottomColor: '#A3A6B3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    paddingBottom: 18,
  },
  pageNote: {
    position: 'absolute',
    bottom: 90,
    right: 120,
    fontSize: 24,
  },
  content: {
    marginBottom: 45,
    borderBottomColor: '#A3A6B3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    fontSize: 30,
    fontWeight: 'normal',
    color: '#323335',
    borderBottomColor: '#A3A6B3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    marginRight: -10
  },
  logo: {
    width: 260,
    height: 100,
    objectFit: 'contain',
  },
});

type Props = {
  dateRange: { start: string; end: string; timestamp?: string };
  timezone: string;
  patient?: Patient;
  practitioner?: Practitioner;
  meetingList: Meeting[];
  organizationLogo?: string;
};

const MeetingTablePdf: FunctionComponent<Props> = ({
  patient,
  dateRange,
  practitioner,
  meetingList,
  timezone,
  organizationLogo,
}) => {
  const rowWidth = 2900 / 9;

  const getRowStyles = (width: number) => {
    return StyleSheet.create({
      cell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 20,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        paddingBottom: 17,
        paddingTop: 17,
        width: '95%',
        paddingRight: -50,
      },
    });
  };

  const getStatusColor = title => {
    if (title === 'Attneded') {
      return 'red';
    } else {
      return 'blue';
    }
  };

  return (
    <Document>
      <Page wrap={true} style={styles.page} size={{ width: 3300, height: 2500 }}>
        <Text
          style={styles.pageNote}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
        <View fixed />
        <View style={styles.title} fixed>
          {organizationLogo ? (
            <Image src={organizationLogo} style={styles.logo} />
          ) : (
            <Text>{practitioner?.organization.name} (Meeting's Report)</Text>
          )}
          <Text>Patient ID: {patient?.emrIdentifier || '--'}</Text>
        </View>
        <DocInfoHeader patient={patient!} practitioner={practitioner!} dateRange={dateRange} />
        <MeetingsTableHeader
          headers={[
            { title: 'Date & Time'},
            { title: 'Title', },
            { title: 'Type',  },
            { title: 'Time',  },
            { title: 'Repeat', },
            { title: 'Attended', },
            { title: 'Remote', },
            { title: 'Location', },
            { title: 'Schedule By', },
          ]}
        />
        {meetingList.map((meeting, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={getRowStyles(rowWidth).cell}>
              <Text>
                {moment.utc(meeting.timestamp).tz(timezone).format('lll')}
              </Text>
              <Text>{meeting.title}</Text>
              <Text>{meeting.type}</Text>
              <Text>{new Date(meeting.occurrenceStartTimestamp).toDateString()}</Text>
              <Text>{meeting?.repeatOccurrences ? meeting?.repeatOccurrences : '0'}</Text>
              <Text>{meeting.attended}</Text>
              <Text>{meeting.allowRemote ? 'Remote meeting' : 'Attending in person'}</Text>
              {/* <Text>{meeting.location? 'No location its remote'}</Text> */}
              <Text style={{ marginRight: 75}}>Here</Text>
              <Text style={{ textAlign: 'center'}}>{meeting.scheduledBy.type}</Text>
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default MeetingTablePdf;
