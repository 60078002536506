import moment from 'moment-timezone';
import { post, get, destroy } from './base';
import { DateRange } from '../../components/common/date-picker/date-range-picker';
import { Done, Failed, done, failed, RemoteData } from '../../utils/remote-data';
import { CreateReportResponseBody, UtilizationReviewReport } from '../../interfaces/report';
import { UtilizationReview as URTableDTO } from '../../interfaces/utilization-review';
import { arrayBufferToBase64 } from '../../utils/data-parser';

export const createReport = async (
  patientId: string,
  dateRange: DateRange,
  reportType: string,
): Promise<Done<CreateReportResponseBody> | Failed> => {
  try {
    const res = await post('/reports', {
      patientId,
      startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
      endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
      reportType,
    });
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getReviews = async (
  patientId: string,
  reportType: string,
): Promise<RemoteData<{ values: URTableDTO[] }>> => {
  try {
    const res = await get(`/reports?patientId=${patientId}&reportType=${reportType}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getUtilizationReviewData = async (
  reportId: string,
  patientId: string,
): Promise<RemoteData<UtilizationReviewReport>> => {
  try {
    const res = await get(`/reports/${reportId}?patientId=${patientId}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const deleteReport = async (reportId: string, patientId: string): Promise<Done<any> | Failed> => {
  try {
    const res = await destroy(`/reports/${reportId}?patientId=${patientId}`);
    return done(res);
  } catch (err) {
    return failed(err);
  }
};

export const getOrganizationImageAsBase64 = async (url: string) => {
  const image = await fetch(url);
  const blobImage = await image.blob();
  return arrayBufferToBase64(await blobImage.arrayBuffer());
};
