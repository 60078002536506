/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect, FC } from 'react';
import { ScatterChart, XAxis, YAxis, Scatter, CartesianGrid, Tooltip } from 'recharts';
import moment from 'moment-timezone';
import styles from '../chart.module.scss';
import { getTicksH, getTicksDD } from '../utlity';
import { getPatientTimezone, PatientContext } from '../../../../contexts/PatientContext';
import { getBacResultsByPatient } from '../../../../services/api/bac';
import { getInsights } from '../../../../services/api/insight';
import { Insight } from '../../../../interfaces/insight';
import { ReactComponent as InsightDiamond } from '../../../../assets/icons/icn-diamond.svg';
import { ReactComponent as InsightIcon } from '../../../../assets/icons/icn-bkgd-insight-blue.svg';
import InsightHoverBox from '../insight-hover-box';
import { getMeetings } from '../../../../services/api/meeting';
import { getGeofenceLocations } from '../../../../services/api/geofence';
import { GeofencePeriods } from '../../../../interfaces/geofence';
import { Meetings } from '../../../../interfaces/meeting';
import { RemoteData } from '../../../../utils/remote-data';
import { BacResult } from '../../../../interfaces/bac-result';
import { ChartToolTip } from '../chart-tooltip';
import { getSurveys } from '../../../../services/api/survey';
import { Survey, Surveys } from '../../../../interfaces/survey';
import { GRAPH_REVERSE_TOOLTIP_COORDINATE, CHARTS_SIZES, HEX_COLORS } from '../../../../utils/constants';
import { getAllEventsSingleList, getEventIcon } from '../../../../utils/event-helper';
import { Tooltip as InfoTooltip } from '../../tooltip';
import InfoBox from './info-box';
import { ReportInsight, ReportEvents } from '../../../../interfaces/report';
import { getDeviceMinutes } from '../../../../services/api/device';

const getIcon = (urData, isPdf) => props => {
  const { variable, negative, x, y } = props;
  return (
    <g>
      {variable === 'MEETING' &&
        (urData && !isPdf
          ? getEventIcon(variable, 17, 16, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y })
          : urData && isPdf
            ? getEventIcon(variable, 38, 37, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y })
            : getEventIcon(variable, 28, 22, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y }))}
      {['FAIL_BAC', 'BAC'].includes(variable) &&
        (urData && !isPdf
          ? getEventIcon(variable, 17, 19, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y })
          : urData && isPdf
            ? getEventIcon(variable, 39, 44, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y })
            : getEventIcon(variable, 24, 22, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y }))}
      {variable === 'GEOFENCE' &&
        (urData && !isPdf
          ? getEventIcon(variable, 11, 17, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y })
          : urData && isPdf
            ? getEventIcon(variable, 32, 43, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y })
            : getEventIcon(variable, 15, 22, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y }))}
      {variable === 'SPECIAL_GEOFENCE' &&
        (urData && !isPdf
          ? getEventIcon('GEOFENCE', 11, 17, HEX_COLORS.SPECIAL, { x, y })
          : urData && isPdf
            ? getEventIcon('GEOFENCE', 32, 43, HEX_COLORS.SPECIAL, { x, y })
            : getEventIcon('GEOFENCE', 15, 22, HEX_COLORS.SPECIAL, { x, y }))}
      {variable === 'SURVEY' &&
        (urData && !isPdf
          ? getEventIcon(variable, 17, 19, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y })
          : urData && isPdf
            ? getEventIcon(variable, 32, 42, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y })
            : getEventIcon(variable, 16, 22, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y }))}
      {variable === 'REMOTE_MEETING' &&
        (urData && !isPdf
          ? getEventIcon(variable, 17, 14, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y })
          : urData && isPdf
            ? getEventIcon(variable, 40, 32, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y })
            : getEventIcon(variable, 30, 32, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y }))}
      {variable === 'DEVICE_WORN' && getEventIcon(variable, 17, 14, !negative ? HEX_COLORS.STABLE : HEX_COLORS.CRITICAL, { x, y })}
    </g>
  );
};

type EventDto = {
  variable: string;
  title: string;
  timestamp: number;
  lane: number;
  negative?: boolean;
};

interface UrChart extends ShareEventChartProps {
  urData?: ReportEvents;
  isPdf?: boolean;
  latestInsight?: never;
  onInsightClick?: never;
}

interface ClientDetailChart extends ShareEventChartProps {
  urData?: never;
  isPdf?: never;
  latestInsight?: Insight;
  onInsightClick?: (selectedInsight: Insight, insights: Insight[]) => void;
}

interface ShareEventChartProps {
  start: string;
  end: string;
}

export const EventChart: FC<UrChart | ClientDetailChart> = ({
  start,
  end,
  latestInsight,
  onInsightClick,
  urData,
  isPdf,
}): JSX.Element => {
  const [data, setData] = useState<EventDto[]>([]);
  const [bacFails, setBacFails] = useState<EventDto[]>([]);
  const [geoViolations, setGeoViolations] = useState<EventDto[]>([]);
  // const [deviceWornMinutes, setDeviceWornMinutes] = useState<EventDto[]>([]);
  const [insights, setInsights] = useState<{ timestamp: number; lane: number; insight: Insight }[]>([]);
  const [urInsights, setURInsights] = useState<{ timestamp: number; lane: number; insight: ReportInsight }[]>([]);
  const [hoverBoxInsight, setHoverBoxInsight] = useState<{ insight: Insight; top: string; left: string } | undefined>(
    undefined,
  );
  const { patient } = useContext(PatientContext);
  const timezone = getPatientTimezone();
  const GRAPH_WIDTH_PX = urData ? CHARTS_SIZES.UR_WIDTH : CHARTS_SIZES.CDP_WIDTH;
  const GRAPH_HEIGHT_PX = urData ? CHARTS_SIZES.UR_HEIGHT : 200;
  const nowLinePoint = {
    timestamp: moment().unix() * 1000,
    lane: 55,
  };

  const getNowLinePosition = props => {
    return <InsightDiamond width={1} height={1} x={props.x} y={props.y + 1} fill='#6F727B' id='nowLine' />;
  };

  const getDeviceIcon = props => {
    return (
      <svg width="2" height="10" {...props}>
        <rect width="2" height="10" fill={props?.payload?.negative ? 'red' : 'green'} />
      </svg>
    )
  }

  const getLatestInsightIcon = props => {
    return (
      <svg
        width='18'
        height='14'
        viewBox='0 0 18 14'
        x={props.x}
        y={props.y}
        fill='none'
        className='latestInsight'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9 13L1 7L9 1L17 7L9 13Z'
          fill={props.positive ? '#6fcf97' : '#ed6f6a'}
          stroke={props.positive ? '#6fcf97' : '#ed6f6a'}
          strokeWidth='1.3'
        />
      </svg>
    );
  };
  const getInsightIcon = props => (
    <InsightIcon
      x={props.x}
      y={props.y}
      cursor='pointer'
      onMouseEnter={() => handleInsightHover(props.insight)}
      onMouseLeave={() => setHoverBoxInsight(undefined)}
      onClick={() => handleInsightClick(props.insight)}
      id={`insight_${props.insight.id}`}
    />
  );

  const handleInsightClick = (insight: Insight) => {
    // check if there are insight next to selected insight by calculated window time
    const chartDateRangeInDays = moment(end).diff(moment(start), 'days');
    const startDateTime =
      moment
        .utc(insight.endTimestamp)
        .subtract('minute', 7.5 * chartDateRangeInDays)
        .unix() * 1000;
    const endDateTime =
      moment
        .utc(insight.endTimestamp)
        .add('minute', 7.5 * chartDateRangeInDays)
        .unix() * 1000;
    const nearbyInsights: Insight[] = insights.flatMap(value =>
      value.insight.id !== insight.id && value.timestamp >= startDateTime && value.timestamp <= endDateTime
        ? [value.insight]
        : [],
    );
    onInsightClick!(insight, [insight, ...nearbyInsights]);
  };

  const handleInsightHover = (insight: Insight) => {
    const insightChartIcon = document.querySelector(`#insight_${insight.id}`)?.getBoundingClientRect();
    setHoverBoxInsight({
      insight,
      top: `${insightChartIcon!.top + window.pageYOffset}px`,
      left: `${insightChartIcon!.left}px`,
    });
  };
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const isMeetingsDto = (obj: any): obj is PromiseFulfilledResult<RemoteData<Meetings>> => {
    return obj.status === 'fulfilled' && obj.value?.status !== undefined;
  };
  const isGeofencePeriodsDto = (obj: any): obj is PromiseFulfilledResult<RemoteData<GeofencePeriods>> => {
    return obj.status === 'fulfilled' && obj.value?.status !== undefined;
  };
  const isBacDto = (obj: any): obj is PromiseFulfilledResult<RemoteData<{ values: BacResult[] }>> => {
    return obj.status === 'fulfilled' && obj.value?.status !== undefined;
  };
  const isSurveyDto = (obj: any): obj is PromiseFulfilledResult<RemoteData<Survey>> => {
    return obj.status === 'fulfilled' && obj.value?.status !== undefined;
  };
  const isDeviceWornDto = (obj: any): obj is PromiseFulfilledResult<RemoteData<{
    values: Array<{
      timestamp
      : string
      worn: boolean
    }>
  }>> => {
    return obj.status === 'fulfilled' && obj.value?.status !== undefined;
  };
  /* eslint-enable @typescript-eslint/no-explicit-any */
  const customToolTip = ({
    active,
    coordinate,
    payload,
    label,
  }: {
    active: boolean;
    coordinate: { x: number; y: number };
    payload: Array<any>;
    label: number;
  }): JSX.Element | null => {
    if (!active) {
      return null;
    }
    const title = payload[0].payload?.variable;
    if (title === '') return null;
    if (title === 'DEVICE_WORN') return null; // we dont want a tool tip for device worn etc...
    const name = payload ? payload[0]?.payload?.title : 'Unable to retrieve value';
    const date = payload[0].payload?.timestamp;
    const reverse = coordinate.x > GRAPH_REVERSE_TOOLTIP_COORDINATE;
    const paylodData = payload?.length > 1 ? payload[1].payload : {};
    return <ChartToolTip date={date} title={title} name={name} value={name} reverse={reverse} payload={paylodData} />;
  };

  const parseChartData = (): void => {
    setURInsights(
      urData!.insights.map(value => ({
        timestamp:
          moment(value.endTimestamp ?? value.generatedTimestamp)
            .tz(timezone)
            .unix() * 1000,
        lane: 107,
        variable: '',
        insight: value,
      })),
    );

    const events = {
      meetings: urData!.meetings,
      geofencePeriods: urData!.geofencePeriods,
      bac: urData!.bac,
      surveys: urData!.surveys,
    };

    const eventsParsed = getAllEventsSingleList(events, timezone);
    setData(eventsParsed.map(evt => ({ ...evt, lane: 55 })));
  };

  const getInsightsData = async (): Promise<void> => {
    // get insights (diamonds) on the chart
    const res = await getInsights(patient!.id, {
      startDate: start,
      endDate: end,
      timezone,
    });
    if (res.status === 'Done') {
      setInsights(
        res.data.values.filter((value) => !value.title.toLowerCase().includes("missed")).map(value => ({
          timestamp: moment.utc(value.endTimestamp).unix() * 1000,
          lane: 107,
          variable: '',
          insight: value,
        })),
      );
    }
  };

  const getChartData = async (): Promise<void> => {
    if (!patient) {
      return;
    }
    let missedMeetings: EventDto[] = [];
    let geofenceViolations: EventDto[] = [];
    let bacTests: EventDto[] = [];
    let missedSurveys: EventDto[] = [];
    // let deviceWorn: EventDto[] = [];
    const startDate = moment(start).format('YYYY-MM-DD');
    const endDate = moment(end).tz(timezone).format('YYYY-MM-DD');

    const [resMeetings, resGeofence, resBacTest, resSurveys, resDeviceMinutes] = await Promise.allSettled([
      getMeetings(patient.id, { startDate, endDate, timezone }),
      getGeofenceLocations(patient.id, { startDate, endDate, timezone }),
      getBacResultsByPatient(patient.id, { startDate, endDate, timezone }),
      getSurveys(patient.id, { startDate, endDate, timezone }),
      getDeviceMinutes(patient.id, {startDate, endDate, timezone })
    ]);
    if (isDeviceWornDto(resDeviceMinutes) && resDeviceMinutes?.value.status === 'Done') {
      const step = resDeviceMinutes.value.data.values.length / GRAPH_WIDTH_PX
      const trimmedArray = resDeviceMinutes.value.data.values.filter((v, i) => Math.floor(i % step) == 0)
      // deviceWorn = trimmedArray.flatMap(dw => {
      //   return {
      //     variable: 'DEVICE_WORN',
      //     lane: 0,
      //     timestamp: moment.utc(dw.timestamp).tz(timezone).unix() * 1000,
      //     negative: !dw.worn,
      //   } as EventDto
      // });
    }
    if (isMeetingsDto(resMeetings) && resMeetings.value.status === 'Done') {
      missedMeetings = resMeetings.value.data.values.flatMap(meeting =>
        /ATTENDED|MISSED/gim.test(meeting.attended)
          ? [
            {
              variable: meeting.attended.toLowerCase().includes('remote') ? 'REMOTE_MEETING' : 'MEETING',
              lane: 55,
              title: `${/MISSED/gim.test(meeting.attended) ? 'Missed' : 'Attended'} ${meeting.title}`,
              timestamp: moment.utc(meeting.occurrenceStartTimestamp).tz(timezone).unix() * 1000,
              negative: meeting.attended.toLowerCase().includes('missed'),
            } as EventDto,
          ]
          : [],
      );
    }
    if (isGeofencePeriodsDto(resGeofence) && resGeofence.value.status === 'Done') {
      geofenceViolations = resGeofence.value.data.values.flatMap(geofence =>
        (geofence.type === 'NON_COMPLIANT' || geofence.type === 'SPECIAL')
          ? [
            {
              variable: geofence.type === 'SPECIAL' ? 'SPECIAL_GEOFENCE' : 'GEOFENCE',
              lane: 55,
              title: `Inside ${geofence.name}`,
              timestamp: moment.utc(geofence.timestamp).tz(timezone).unix() * 1000,
              negative: true,
              timeSpentMinutes: geofence.timeSpentMinutes
            } as EventDto,
          ]
          : [],
      );
    }
    if (isBacDto(resBacTest) && resBacTest.value.status === 'Done') {
      bacTests = resBacTest.value.data.values.flatMap(bac =>
        ['MISS', 'FAIL', 'PASS'].includes(bac.status)
          ? [
            {
              variable: bac.status === 'FAIL' ? 'FAIL_BAC' : 'BAC',
              lane: 55,
              title: bac.status,
              timestamp: moment.utc(bac.timestamp).tz(timezone).unix() * 1000,
              negative: ['MISS', 'FAIL'].includes(bac.status),
              level: bac.level
            } as EventDto,
          ]
          : [],
      );
    }
    if (isSurveyDto(resSurveys) && resSurveys.value.status === 'Done') {
      missedSurveys = resSurveys.value.data.values.map(survey => ({
        variable: 'SURVEY',
        lane: 55,
        title: `${survey.responseStatus} ${survey.practitionerTitle}`,
        timestamp: moment.utc(survey.ts).tz(timezone).unix() * 1000,
        negative: ['MISSED', 'SKIPPED'].includes(survey.responseStatus),
      }));
    }
    // Place the BAC fail and Geofence violations
    const passedBacs = bacTests.filter((test) => test.title !== "FAIL");
    const failedBacs = bacTests.filter((test) => test.title === "FAIL");
    setGeoViolations(geofenceViolations);
    setBacFails(failedBacs);
    // setDeviceWornMinutes(deviceWorn);
    setData([...missedMeetings, ...passedBacs, ...missedSurveys]);

    getInsightsData();
  };

  useEffect(() => {
    if (patient) {
      if (urData) {
        parseChartData();
      } else {
        getChartData();
      }
    }
  }, [patient, start, end]);

  return (
    <div id={`eventsChart${isPdf ? '__pdf' : ''}`} style={isPdf ? { display: 'none' } : {}}>
      <div className={styles.chartHeader}>
        <span className={styles.chartTitle}>EVENTS</span>
        {!urData && (
          <InfoTooltip baseStyles={`${styles.infoTooltip} ${styles.events}`} type='left' background='#F5F6FA'>
            <InfoBox />
          </InfoTooltip>
        )}
      </div>
      <ScatterChart
        width={isPdf ? CHARTS_SIZES.UR_PDF_WIDTH : GRAPH_WIDTH_PX}
        height={isPdf ? CHARTS_SIZES.UR_PDF_HEIGHT : GRAPH_HEIGHT_PX}
      >
        <CartesianGrid stroke='#A3A6B3' />
        <Scatter name='negative-events' data={data.filter(value => value.negative)} shape={getIcon(!!urData, isPdf)} />
        <Scatter
          name='positive-events'
          data={data.map(value => ({ ...value, lane: 85 })).filter(value => !value.negative)}
          shape={getIcon(!!urData, isPdf)}
        />
        <Scatter
          name='bac-fail-geo-fence'
          data={[...geoViolations, ...bacFails].map(value => ({ ...value, lane: 25 }))}
          shape={getIcon(!!urData, isPdf)}
        />
        <Scatter name='insights' data={urData ? urInsights : insights} shape={getInsightIcon} />
        {moment().isBetween(start, end) && <Scatter name='' data={[nowLinePoint]} shape={getNowLinePosition} />}
        {latestInsight && latestInsight.endTimestamp && moment(latestInsight.endTimestamp).isBetween(start, end) && (
          <Scatter
            name=''
            data={[
              {
                timestamp: moment.utc(latestInsight.endTimestamp).tz(timezone).unix() * 1000,
                lane: 107,
                variable: 'INSIGHT',
                title: latestInsight.title,
                positive: latestInsight.positive,
              },
            ]}
            shape={getLatestInsightIcon}
          />
        )}
        {/* This is for the device worn minutes time */}
        {/* <Scatter
            name='device-worn'
            data={[...deviceWornMinutes]}
            shape={getDeviceIcon}
          /> */}
        <XAxis
          xAxisId={0}
          dy={-10}
          dx={2}
          style={{ fontSize: '9', fontFamily: 'sans-serif', color: '#000000' }}
          tickLine={true}
          tickSize={16}
          tickFormatter={(unixTime): string => moment.utc(unixTime).tz(timezone).format('hA')}
          dataKey='timestamp'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
        />
        <XAxis
          xAxisId={1}
          dy={-18}
          dx={0}
          style={{
            fontSize: '11',
            fontWeight: 'bold',
            fontFamily: 'sans-serif',
            color: '#000000',
          }}
          tickLine={false}
          axisLine={false}
          tickFormatter={(unixTime): string => moment.utc(unixTime).format('ddd')}
          dataKey='timestamp'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
        />
        <XAxis
          xAxisId={2}
          dy={-34}
          dx={0}
          style={{ fontSize: '11', fontFamily: 'sans-serif', color: '#000000' }}
          tickLine={false}
          axisLine={false}
          tickFormatter={(unixTime): string => moment.utc(unixTime).format('M/D')}
          dataKey='timestamp'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
        />
        <YAxis tickFormatter={(): string => ''} interval={0} tickSize={0} ticks={[90, 60, 30, 0]} dataKey='lane' />
        {!urData && <Tooltip content={customToolTip} allowEscapeViewBox={{ x: false, y: true }} />}
      </ScatterChart>
      {hoverBoxInsight && (
        <InsightHoverBox
          style={{ top: hoverBoxInsight.top, left: hoverBoxInsight.left }}
          insight={hoverBoxInsight.insight}
          hideHoverBox={() => setHoverBoxInsight(undefined)}
        />
      )}
    </div>
  );
};
