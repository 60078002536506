import React from 'react';

export const RelapseIcon = props => {
  return (
    <svg style={props.style} xmlns='http://www.w3.org/2000/svg' width='16' height='14' fill='none' viewBox='0 0 18 16'>
      <path
        fill='#323335'
        stroke='#323335'
        strokeWidth='0.15'
        d='M16.086 15.075c.35.002.68-.183.858-.49a.988.988 0 00-.007-.994s0 0 0 0L9.85 1.414a.985.985 0 00-1.702 0L1.128 13.63a.913.913 0 00-.007.919c.163.283.47.454.792.452v.075h0s0 0 0 0h14.173zm0 0s0 0 0 0V15v.075h0zM8.962 5.818a.302.302 0 00-.194.107m.194-.107l.01.075m-.01-.075s0 0 0 0l.01.075m-.01-.075a.3.3 0 01.244.08.308.308 0 01.098.237v4.198h-.075a.238.238 0 01-.066.168.228.228 0 01-.251.05.228.228 0 01-.124-.127l-.02-4.499m0 0a.311.311 0 00-.072.21v4.198l.072-4.408zm.203-.032a.227.227 0 00-.145.08.236.236 0 00-.055.16l.384-.18a.228.228 0 00-.184-.06zm.357-4.17h0l7.087 12.184v.001a.38.38 0 01.007.362l.065.037-.065-.037a.406.406 0 01-.328.188v0h-.007v0H1.913v0h-.007v0a.408.408 0 01-.328-.188l-.065.037.065-.037a.38.38 0 01.007-.362h0L8.672 1.723h0c.06-.102.2-.181.328-.181s.269.079.328.181zM1.063 13.591s0 0 0 0L8.149 1.414 1.063 13.591zM9 11.19c-.545 0-.99.456-.99 1.009 0 .553.445 1.008.99 1.008.545 0 .99-.455.99-1.008 0-.553-.445-1.008-.99-1.008zm0 .617c.212 0 .382.171.382.392 0 .22-.17.392-.382.392a.384.384 0 01-.382-.392c0-.22.17-.392.382-.392z'
      />
    </svg>
  );
};

export default RelapseIcon;
