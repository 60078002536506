import moment from 'moment';
import React from 'react';
import Modal from '../modal/Modal';
import styles from './note.module.scss';

export const NoteModal = ({ onClose, note, patient }) => {
  return (
    <Modal show={true} closeModal={onClose} contentStyle='bigContent__static'>
      <div className={styles.noteContainer}>
        <div className={styles.header}>
          <span className={styles.title}>
            NOTE FOR {patient?.name?.first || ''} {patient?.name?.last || ''} - NOTE GENERATED:{' '}
            {moment(note?.submittedTimestamp || '').format('lll')}
          </span>
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='24' height='24' rx='3' fill='#417EB9' />
            <path
              d='M12.75 4C12.75 3.58579 12.4142 3.25 12 3.25C11.5858 3.25 11.25 3.58579 11.25 4L12.75 4ZM11.4697 15.7303C11.7626 16.0232 12.2374 16.0232 12.5303 15.7303L17.3033 10.9574C17.5962 10.6645 17.5962 10.1896 17.3033 9.8967C17.0104 9.60381 16.5355 9.60381 16.2426 9.8967L12 14.1393L7.75736 9.8967C7.46447 9.60381 6.98959 9.60381 6.6967 9.8967C6.40381 10.1896 6.40381 10.6645 6.6967 10.9574L11.4697 15.7303ZM11.25 4L11.25 15.2L12.75 15.2L12.75 4L11.25 4Z'
              fill='white'
            />
            <line x1='4.75' y1='18.45' x2='19.25' y2='18.45' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
          </svg>
        </div>
        <div className={styles.top}>
          <span>
            Client: {patient?.name?.first || ''} {patient?.name?.last || ''}
          </span>
          <span>Client Id: {patient.emrIdentifier ?? '-'}</span>
          <span>
            Practitioner: {note?.submittedByName?.first || ''} {note?.submittedByName?.last || ''}
          </span>
          <span>
            Period:{' '}
            {moment
              .utc(note?.effectiveStartDateTime || '')
              .local()
              .format('ll')}
            {' - '}
            {moment
              .utc(note?.effectiveEndDateTime || '')
              .local()
              .format('ll')}
          </span>
        </div>
        <div className={styles.middle}>
          <div className={styles.title}>INSIGHTS DURING PERIOD</div>
          <div className={styles.content}>{note?.summary}</div>
        </div>
        <div className={styles.footer}>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </Modal>
  );
};
