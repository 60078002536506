import { Done, done, Failed, failed, RemoteData } from '../../utils/remote-data';
import { destroy, get, post, put } from './base';
import { CreateMeetingRequest, Meeting, Meetings } from '../../interfaces/meeting';
import { castDateTimeFilters, DateTimeFiltersRequest } from '../../utils/requestHelper';

export const getMeetings = async (
  patientId: string,
  dateFilters: DateTimeFiltersRequest,
): Promise<RemoteData<Meetings>> => {
  try {
    const res = await get(`/meetings?patientId=${patientId}`, {
      params: {
        ...castDateTimeFilters(dateFilters),
      },
    });
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getMeetingDetails = async (meetingId: string, patientId: string): Promise<Done<Meeting> | Failed> => {
  try {
    const res = await get(`/meetings/${meetingId}?patientId=${patientId}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const createMeeting = async (meeting: CreateMeetingRequest): Promise<Done<Meeting> | Failed> => {
  try {
    const res = await post('/meetings', meeting);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const editMeeting = async (
  meeting: CreateMeetingRequest,
  meetingId: number,
): Promise<Done<Meeting> | Failed> => {
  try {
    const res = await put(`/meetings/${meetingId}`, meeting);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const deleteMeeting = async (
  meeting: { updateType: string; patientId: string },
  meetingId: string,
): Promise<Done<any> | Failed> => {
  try {
    const res = await destroy(`/meetings/${meetingId}`, {
      data: meeting,
    });
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const patchMeetingAttendedStatus = async (
  meeting: { attended: string; patientId: string },
  meetingId,
): Promise<Done<any> | Failed> => {
  try {
    const res = await put(`/meetings/${meetingId}/attended`, meeting);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const meetingTypes = async (): Promise<Done<any> | Failed> => {
  try {
    const res = await get(`/meetings/types`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const addressSuggestions = async (userInput: string): Promise<Done<any> | Failed> => {
  try {
    const res = await get(`/autocomplete/address?q=${userInput ? userInput : ''}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};
