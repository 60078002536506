import { useMyPractitionerQuery } from 'graphql/codegen/hooks';
import moment from 'moment-timezone';
import firebase from 'firebase';
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Practitioner as PractitionerDto } from '../interfaces/practitioner';
import { timezones } from '../utils/constants';
import { UserContext } from './User';
import { apolloClient } from 'graphql/apollo-client';
import LogRocket from 'logrocket';
import { userInfo } from 'os';
import { Patient } from './PatientContext';

const PractitionerContext = createContext<{
  practitioner: PractitionerDto | undefined;
  reloadPractitioner: () => Promise<any>;
  //getPractitioner?: () => Promise<void>;
  practitionerViewConfig: string;
  setPractitionerViewConfig: Dispatch<SetStateAction<string>>;
  organizationLogoURL: string;
}>({
  practitioner: undefined,
  practitionerViewConfig: '',
  setPractitionerViewConfig: () => {},
  organizationLogoURL: '',
  reloadPractitioner: () => Promise.resolve(),
});
/* eslint-disable */
export const organizationTimezone = (): string => {
  const { practitioner } = useContext(PractitionerContext);
  return practitioner?.organization?.settings?.timezone !== undefined
    ? timezones[practitioner?.organization?.settings?.timezone!]
    : moment.tz.guess();
};

export const organizationTimezoneKey = (): string => {
  const { practitioner } = useContext(PractitionerContext);
  return practitioner?.organization?.settings?.timezone ?? 'CST';
};

const mapOrganizationTheme = () => {};
/* eslint-enable */
const Practitioner = ({ children }) => {
  const { user } = useContext(UserContext);
  const { data, refetch: reloadPractitioner } = useMyPractitionerQuery({ skip: !user });
  const images: { [id: string]: string } = {};
  data?.myPractitioner?.organization?.organizationTheme?.images?.forEach(img => {
    images[img.name!] = img.url!;
  });

  const practitioner = data?.myPractitioner
  ? {
      ...data?.myPractitioner,
      organization: data.myPractitioner.organization
        ? { ...data?.myPractitioner.organization, theme: { images } }
        : null,
    }
  : undefined;
//const [practitioner, setPractitioner] = useState<PractitionerDto | undefined>(undefined);
  const [practitionerViewConfig, setPractitionerViewConfig] = useState<string>('');
  const [organizationLogoURL, setOrganizationLogoUrl] = useState<string>('');

  const setUserForLogRocket = (pract: any) => {
    try {
      LogRocket.identify(pract?.id, {
        name: `${pract?.name?.first} ${pract?.name?.last}`,
        email: pract?.contact?.email,
      });
    } catch (error) {
      
    }
  }

  useEffect(() => {
    if (practitioner) {
      setPractitionerViewConfig(practitioner.viewSettings!.triageView!);
      setOrganizationLogoUrl(practitioner.organization?.theme?.images?.logo || '');
      setUserForLogRocket(practitioner);
    }
  }, [practitioner]);


  useEffect(() => {
    firebase.auth().onAuthStateChanged((u) => {
      if (!u) {
        apolloClient.cache.reset();
      }
    })
  },[]);

  return (
    <PractitionerContext.Provider
      value={{
        practitioner: practitioner as any,
        reloadPractitioner,
        //getPractitioner,
        practitionerViewConfig,
        setPractitionerViewConfig,
        organizationLogoURL,
      }}
    >
      {children}
    </PractitionerContext.Provider>
  );
};

export { Practitioner, PractitionerContext };
