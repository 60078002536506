import React, { CSSProperties, FunctionComponent, useEffect, useRef } from 'react';
import styles from './insightTooltip.module.scss';
import { Insight } from '../../../interfaces/insight';
import { displayDateTimeFormat } from '../../../utils/data-parser';
import { getPatientTimezone } from '../../../contexts/PatientContext';

type Props = {
  insight: Insight;
  hideHoverBox: () => void;
  style: CSSProperties;
};

const InsightHoverBox: FunctionComponent<Props> = ({ insight, style, hideHoverBox }) => {
  const timezone = getPatientTimezone();
  const node = useRef<HTMLDivElement>(null);
  const handleClickOutside = e => {
    if (node.current?.contains(e.target)) {
      return;
    }
    hideHoverBox();
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.hoverTooltip} style={style} ref={node}>
      <div className={styles.hoverTooltipDate}>
        <span>{displayDateTimeFormat(insight.endTimestamp, timezone)}</span>
      </div>
      <div>
        <span className={styles.hoverTooltipTitle}>INSIGHT: {insight.title}</span>
        <span className={styles.hoverTooltipCTA}>Click icon to expand</span>
      </div>
    </div>
  );
};

export default InsightHoverBox;
