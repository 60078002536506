import React from 'react';

export const LocationIcon = props => {
  return (
    <svg width='12' height='17' viewBox='0 0 12 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.75 16.7408C5.64841 16.7408 5.55329 16.69 5.49182 16.603C5.27829 16.2982 0.25 9.10792 0.25 5.953C0.25 2.69091 2.71724 0.0371094 5.75 0.0371094C8.78276 0.0371094 11.25 2.69091 11.25 5.953C11.25 9.10792 6.22171 16.2982 6.00818 16.603C5.94671 16.69 5.85159 16.7408 5.75 16.7408ZM5.75 0.733097C3.07376 0.733097 0.897059 3.0744 0.897059 5.953C0.897059 8.49197 4.71147 14.2791 5.75 15.8075C6.78853 14.2784 10.6029 8.49057 10.6029 5.953C10.6029 3.0744 8.42624 0.733097 5.75 0.733097Z'
        fill='#323335'
      />
      <path
        d='M5.74966 9.0842C4.1443 9.0842 2.83789 7.679 2.83789 5.95226C2.83789 4.22551 4.1443 2.82031 5.74966 2.82031C7.35501 2.82031 8.66142 4.22551 8.66142 5.95226C8.66142 7.679 7.35501 9.0842 5.74966 9.0842ZM5.74966 3.5163C4.50083 3.5163 3.48495 4.609 3.48495 5.95226C3.48495 7.29551 4.50083 8.38821 5.74966 8.38821C6.99848 8.38821 8.01436 7.29551 8.01436 5.95226C8.01436 4.609 6.99848 3.5163 5.74966 3.5163Z'
        fill='#323335'
      />
    </svg>
  );
};
