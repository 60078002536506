import React, { useContext, useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { PractitionerContext } from '../../contexts/Practitioner';
import { RemoteData, loading, notAsked } from '../../utils/remote-data';
import {
  getPatients,
  getPatientDetails,
  getPatientAllies,
  updatePatientEmail,
  dischargePatient,
  removePatient,
  DischargePatientRequest,
} from '../../services/api/patient';
import styles from './admin-clients.module.scss';
import { Patient, Patients } from '../../interfaces/patient';
import UpsertClientContainer from './upsert-client-container';
import { Allies } from '../../interfaces/common';
import { firebaseSendCurrentScreen } from '../../utils/analytics';
import UpdateEmailModal from './update-email-modal';
import ActiveClientsTable from './active-clients-table';
import DischargeClientConfirmation from './discharge-client-confirmation';
import { EPISODE_OF_CARE } from '../../utils/constants';
import SearchInput from '../common/table/search-input';
import FilterCheckbox from '../common/checkbox/filter-checkbox';
import RemoveClientConfirmation from './remove-client-confirmation';

export const AdminClients = (): JSX.Element => {
  const { addToast } = useToasts();
  const { practitioner } = useContext(PractitionerContext);
  const [patients, setPatients] = useState<RemoteData<Patients>>(notAsked());
  const [showUpsertClientModal, setShowUpsertClientModal] = useState<boolean>(false);
  const [selectedPatientId, setSelectedPatientId] = useState<string>('');
  const [selectedPatient, setSelectedPatient] = useState<RemoteData<Patient>>(notAsked());
  const [selectedPatientAllies, setSelectedPatientAllies] = useState<RemoteData<Allies>>(notAsked());
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showDischargeClientModal, setShowDischargeClientModal] = useState<boolean>(false);
  const [filters, setFilters] = useState<{ searchInput: string; eoc: string[] }>({
    searchInput: '',
    eoc: [],
  });
  const [filteredPatients, setFilteredPatients] = useState<Patients | undefined>(undefined);

  useEffect(() => {
    if (practitioner) {
      firebaseSendCurrentScreen('AdminClientsPage', {
        practitionerId: practitioner?.id,
        organizationId: practitioner?.organization.id,
        firebaseUid: practitioner?.firebaseUid,
      });
      getPatientsAction();
    }
  }, [practitioner]);

  useEffect(() => {
    if (selectedPatientId) {
      getPatientDetailsAction(selectedPatientId);
      getPatientAlliesAction(selectedPatientId);
      setShowUpsertClientModal(true);
    } else {
      setShowUpsertClientModal(false);
    }
  }, [selectedPatientId]);

  useEffect(() => {
    if (!showUpsertClientModal) {
      setSelectedPatientId('');
      setSelectedPatient(notAsked());
      setSelectedPatientAllies(notAsked());
    }
  }, [showUpsertClientModal]);

  useEffect(() => {
    if (!!filters.searchInput || filters.eoc.length) {
      if (patients.status === 'Done') {
        let filteredPatients: Patient[];
        const searchValue = filters.searchInput.toLowerCase();
        filteredPatients = patients.data.values.filter(p =>
          [
            p.name.first?.toLowerCase(),
            p.name.last?.toLowerCase(),
            `${p.name.first.toLowerCase()} ${p.name.last.toLowerCase()}`,
          ].find(name => name?.includes(searchValue)),
        );
        if (filters.eoc.length) {
          filteredPatients = filteredPatients.filter(patient => filters.eoc.includes(patient.episodeOfCare));
        }
        setFilteredPatients({ values: filteredPatients, size: filteredPatients.length });
      }
    } else {
      setFilteredPatients(undefined);
    }
  }, [filters, patients]);

  const getPatientsAction = async () => {
    setPatients(loading());
    const res = await getPatients(practitioner?.organization.id);
    setPatients(res);
  };

  const getPatientDetailsAction = async (patientId: string) => {
    setSelectedPatient(loading());
    const res = await getPatientDetails(patientId);
    setSelectedPatient(res);
  };

  const getPatientAlliesAction = async (patientId: string) => {
    setSelectedPatientAllies(loading());
    const res = await getPatientAllies(patientId);
    setSelectedPatientAllies(res);
  };

  const refetchPatientData = () => {
    getPatientDetailsAction(selectedPatientId);
  };

  const updatePatientEmailAction = async (email: string) => {
    if (selectedPatient.status === 'Done') {
      setShowLoading(true);
      const res = await updatePatientEmail(selectedPatient.data.id, email);
      setShowLoading(false);

      if (res.status === 'Done') {
        getPatientsAction();
        getPatientDetailsAction(selectedPatient.data.id);
        setShowUpdateEmailModal(false);
        addToast('Client email updated successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        addToast(`Error: ${res.errorApi.message}`, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  };

  const dischargePatientAction = async (patientId: string, values: DischargePatientRequest) => {
    setShowLoading(true);
    const res = await dischargePatient(patientId, values);
    setShowLoading(false);

    if (res.status === 'Done') {
      getPatientsAction();
      setShowUpsertClientModal(false);
      setShowDischargeClientModal(false);
      addToast('Client discharged successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast(`Error: ${res.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const removePatientAction = async (patientId: string) => {
    setShowLoading(true);
    const res = await removePatient(patientId);
    setShowLoading(false);

    if (res.status === 'Done') {
      getPatientsAction();
      setShowUpsertClientModal(false);
      addToast('Client removed successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast(`Error: ${res.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };
  const openEditEmailModal = () => {
    setShowUpdateEmailModal(true);
  };

  const setSearchInputFilter = (filterValue: string | string[], key: string) => {
    setFilters({ ...filters, [key]: filterValue });
  };

  return (
    <div>
      <div className={styles.tableButtons}>
        <SearchInput
          searchValue={filters.searchInput}
          handleInputChange={ev => setSearchInputFilter(ev.target.value, 'searchInput')}
          handleOnClick={ev => setSearchInputFilter('', 'searchInput')}
          placeholder='Search by name...'
        />
        <div className={styles.filtersRow}>
          {Object.entries(EPISODE_OF_CARE)
            .filter(([key, value]) => EPISODE_OF_CARE.SELF_CARE !== value)
            .map(([key, value]) => (
              <FilterCheckbox
                key={key}
                checked={filters.eoc.includes(key)}
                name='eoc'
                field={value}
                handleOnChange={ev =>
                  setSearchInputFilter(
                    ev.target.checked ? [...filters.eoc, key] : filters.eoc.filter(value => value !== key),
                    'eoc',
                  )
                }
              />
            ))}
        </div>
        <div>
          <span className={`${styles.btn} ${styles.addBtn}`} onClick={() => setShowUpsertClientModal(true)}>
            Add New Client
          </span>
        </div>
      </div>
      <ActiveClientsTable
        patients={patients}
        filterResultPatients={filteredPatients}
        setSelectedPatientId={setSelectedPatientId}
        setShowUpsertClientModal={setShowUpsertClientModal}
      />
      {showUpsertClientModal && !showUpdateEmailModal && !showDischargeClientModal && (
        <UpsertClientContainer
          setShow={setShowUpsertClientModal}
          editMode={!!selectedPatientId}
          organization={practitioner?.organization}
          getPatientsAction={getPatientsAction}
          selectedPatient={selectedPatient}
          selectedPatientAllies={selectedPatientAllies}
          refetchPatient={refetchPatientData}
          openEditEmailModal={openEditEmailModal}
          setShowDischargeClientModal={setShowDischargeClientModal}
        />
      )}
      {selectedPatient.status === 'Done' && showUpdateEmailModal && (
        <UpdateEmailModal
          setShow={setShowUpdateEmailModal}
          patient={selectedPatient.data}
          handleUpdateEmail={updatePatientEmailAction}
          showLoading={showLoading}
        />
      )}
      {selectedPatient.status === 'Done' && showDischargeClientModal && selectedPatient.data.status === 'ACTIVE' && (
        <DischargeClientConfirmation
          setShow={setShowDischargeClientModal}
          client={selectedPatient.data}
          dischargePatientAction={dischargePatientAction}
        />
      )}
      {selectedPatient.status === 'Done' &&
        showDischargeClientModal &&
        selectedPatient.data.status === 'PENDING_INVITE' && (
          <RemoveClientConfirmation
            setShow={setShowDischargeClientModal}
            client={selectedPatient.data}
            removePatientAction={removePatientAction}
          />
        )}
    </div>
  );
};
