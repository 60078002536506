import React, { FunctionComponent, useState } from 'react';
import styles from './recovery-milestone.module.scss';
import MilestonePlan from './milestone-plan';

type SelectedTab = 'Milestones' | 'Victories' | 'VictoryGroupedList';
const RecoveryMilestone: FunctionComponent = () => {
  const [selectedTab, setSelectedTab] = useState<SelectedTab>('Milestones');
  return (
    <>
      <div className={styles.navHeader}>
        <span
          className={`${selectedTab.includes('Milestones') ? styles.active : styles.inactive}`}
          onClick={() => setSelectedTab('Milestones')}
        >
          Track milestones
        </span>
        <span
          className={`${selectedTab.includes('Victories') ? styles.active : styles.inactive}`}
          onClick={() => setSelectedTab('Victories')}
        >
          View All victories
        </span>
        <span
          className={`${selectedTab.includes('VictoryGroupedList') ? styles.active : styles.inactive}`}
          onClick={() => setSelectedTab('VictoryGroupedList')}
        >
          View Victories by Type
        </span>
      </div>
      <MilestonePlan />
    </>
  );
};

export default RecoveryMilestone;
