import React from 'react';

export const CalendarIcon = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none' viewBox='0 0 15 15'>
      <path
        fill='#323335'
        d='M12.813 15H2.187A2.19 2.19 0 010 12.813V3.438A2.19 2.19 0 012.188 1.25h.624a.313.313 0 010 .625h-.624c-.862 0-1.563.7-1.563 1.563v9.375c0 .862.7 1.562 1.563 1.562h10.624c.862 0 1.563-.7 1.563-1.562V3.438c0-.862-.7-1.563-1.563-1.563h-.624a.313.313 0 010-.625h.624A2.19 2.19 0 0115 3.438v9.375A2.19 2.19 0 0112.812 15z'
      />
      <path
        fill='#323335'
        d='M9.688 1.875H5.311a.313.313 0 010-.625h4.375a.313.313 0 010 .625zM14.688 5H.312a.313.313 0 010-.625h14.376a.313.313 0 010 .625zM4.063 3.125a.313.313 0 01-.313-.312v-2.5a.313.313 0 01.625 0v2.5c0 .172-.14.312-.313.312zM10.938 3.125a.313.313 0 01-.313-.312v-2.5a.313.313 0 01.625 0v2.5c0 .172-.14.312-.313.312zM5.313 12.5c-.862 0-1.563-.7-1.563-1.562a.313.313 0 01.625 0 .939.939 0 001.875 0A.939.939 0 005.312 10h-.625a.313.313 0 010-.625h.625a.939.939 0 000-1.875.939.939 0 00-.937.938.313.313 0 01-.625 0 1.564 1.564 0 112.499 1.25 1.561 1.561 0 01-.936 2.812zM9.687 12.5a.313.313 0 01-.312-.312V7.942l-.717.716a.313.313 0 01-.442-.442l1.25-1.25a.312.312 0 01.534.222v5c0 .172-.14.312-.313.312z'
      />
      <path fill='#323335' d='M10.938 12.501h-2.5a.313.313 0 010-.625h2.5a.313.313 0 010 .625z' />
    </svg>
  );
};
