import React, { createContext, useState, useEffect } from 'react';
import { auth } from '../firebase/firebase';
import { useToasts } from 'react-toast-notifications';
import firebase from 'firebase/app';

const UserContext = createContext<any>('');

const User = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>();
  const { addToast } = useToasts();
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(u => {
      if (u) {
        u.getIdTokenResult(false).then((res: firebase.auth.IdTokenResult) => {
          const role: string = res.claims['role'];
          if (!!role && role.includes('CLINICIAN')) {
            setUser(u);
          } else {
            addToast('This account cannot access this system. Please use a valid login', {
              appearance: 'error',
              autoDismiss: true,
            });
          }
          setInitializing(false);
        });
      } else {
        setUser(null);
        setInitializing(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return <UserContext.Provider value={{ user, initializing }}>{children}</UserContext.Provider>;
};

export { User, UserContext };
