import React from 'react';

export const More = props => {
  return (
    <svg
      style={props.style}
      onClick={props.onClick}
      width='18'
      height='4'
      viewBox='0 0 18 4'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.0599 0C17.0983 0 18 0.914286 18 2C18 3.08571 17.1256 4 16.0599 4C15.0215 4 14.1198 3.08571 14.1198 2C14.1471 0.914286 15.0215 0 16.0599 0Z'
        fill='#323335'
      />
      <path
        d='M8.95297 0C9.99135 0 10.8931 0.914286 10.8931 2C10.8931 3.08571 10.0187 4 8.95297 4C7.91458 4 7.01282 3.08571 7.01282 2C7.04014 0.914286 7.91458 0 8.95297 0Z'
        fill='#323335'
      />
      <path
        d='M1.94015 0C2.97854 0 3.88029 0.914286 3.88029 2C3.88029 3.08571 3.00586 4 1.94015 4C0.901758 4 0 3.08571 0 2C0.027326 0.914286 0.901758 0 1.94015 0Z'
        fill='#323335'
      />
    </svg>
  );
};
