import * as Yup from 'yup';
import { EPISODE_OF_CARE, MeetingType } from './constants';

const standardRequiredMessage = 'This field is required.';
const validEmailMessage = 'Please enter a valid email address.';
const validPhoneFormatMessage = 'Invalid phone format.';

export const addPractitionerValidationSchema = Yup.object({
  prefix: Yup.string(),
  firstName: Yup.string().required(standardRequiredMessage),
  lastName: Yup.string().required(standardRequiredMessage),
  email: Yup.string().email(validEmailMessage).required(standardRequiredMessage),
  phone: Yup.string()
    .min(12)
    .max(12)
    .matches(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/, validPhoneFormatMessage)
    .required(standardRequiredMessage),
  admin: Yup.bool(),
  clinician: Yup.bool(),
  aftercare: Yup.bool(),
  adminOrClinician: Yup.bool().when(['admin', 'clinician', 'aftercare'], {
    is: (admin, clinician, aftercare) => !(admin || clinician || aftercare),
    then: Yup.bool().required(),
    otherwise: Yup.bool(),
  }),
});

export const addOrginizationValidationSchema = Yup.object({
  name: Yup.string().required(standardRequiredMessage),
  phone: Yup.string()
    .min(12)
    .max(12)
    .matches(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/, validPhoneFormatMessage)
    .required(standardRequiredMessage),
  timezone: Yup.string().required(standardRequiredMessage),
  line1: Yup.string().required(standardRequiredMessage),
  city: Yup.string().required(standardRequiredMessage),
  state: Yup.string().required(standardRequiredMessage),
  postalCode: Yup.string().required(standardRequiredMessage),
  country: Yup.string().required(standardRequiredMessage),
  first: Yup.string().required(standardRequiredMessage),
  last: Yup.string().required(standardRequiredMessage),
  contactPhone: Yup.string()
    .min(12)
    .max(12)
    .matches(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/, validPhoneFormatMessage)
    .required(standardRequiredMessage),
  contactEmail: Yup.string().email(validEmailMessage).required(standardRequiredMessage),
});

export const addClientValidationSchema = {
  1: Yup.object({
    name: Yup.object({
      first: Yup.string().required(standardRequiredMessage),
      last: Yup.string().required(standardRequiredMessage),
    }),
    dateOfAdmission: Yup.string().required(standardRequiredMessage),
    birthDate: Yup.string().required(standardRequiredMessage),
    gender: Yup.string().required(standardRequiredMessage),
    maritalStatus: Yup.string().optional(),
    height: Yup.string().optional(),
    weight: Yup.number().positive().lessThan(1000).optional(),
  }),
  2: Yup.object({
    emergencyContact: Yup.object({
      name: Yup.string().optional(),
      phone: Yup.string()
        .min(12)
        .max(12)
        .matches(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/, validPhoneFormatMessage)
        .optional(),
    }),
    primaryCareContact: Yup.object({
      name: Yup.string().optional(),
      phone: Yup.string()
        .min(12)
        .max(12)
        .matches(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/, validPhoneFormatMessage)
        .optional(),
    }),
  }),
  3: Yup.object({
    personalContact: Yup.object({
      cellphone: Yup.string()
        .min(12)
        .max(12)
        .matches(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/, validPhoneFormatMessage)
        .required(standardRequiredMessage),
      homePhone: Yup.string().optional(),
      email: Yup.string().email(validEmailMessage).required(standardRequiredMessage),
      home: Yup.object({
        line1: Yup.string().optional(),
        line2: Yup.string().optional(),
        city: Yup.string().optional(),
        state: Yup.string().max(2, 'State length must be 2.').min(2, 'State length must be 2.').optional(),
        postalCode: Yup.string()
          .min(5, 'Postal code length must be 5.')
          .max(5, 'Postal code length must be 5.')
          .optional(),
        country: Yup.string().optional(),
      }),
      work: Yup.object({
        line1: Yup.string().optional(),
        city: Yup.string().optional(),
        state: Yup.string().max(2, 'State length must be 2.').min(2, 'State length must be 2.').optional(),
        postalCode: Yup.string()
          .min(5, 'Postal code length must be 5.')
          .max(5, 'Postal code length must be 5.')
          .optional(),
        country: Yup.string().optional(),
      }),
    }),
  }),
  4: Yup.object({
    episodeOfCare: Yup.string().required(standardRequiredMessage),
    careTeamIds: Yup.array().min(1, 'You need to select at least one practitioner.'),
    emrIdentifier: Yup.string().nullable().optional(),
  }),
};

export const upsertGeofenceValidator = Yup.object({
  type: Yup.string()
    .matches(/NON_COMPLIANT|SPECIAL|COMPLIANT/gi, 'Must be one of these values: Non_Compliant, Special')
    .required('This field is required'),
  name: Yup.string().min(1).max(250).required('This field is required.'),
  geofencePoint: Yup.object({
    address: Yup.string().required('This field is required.'),
  }),
});

export const addMeetingValidator = (edit: boolean) =>
  Yup.object({
    title: Yup.string().min(1).max(250).required(standardRequiredMessage),
    type: Yup.string()
      .matches(
        new RegExp(`${Object.keys(MeetingType).join('|')}`, 'gi'),
        'Must be one of these values: Group Meeting, Clinician Meeting, Private Meeting, Other meeting',
      )
      .required(standardRequiredMessage),
    location: Yup.object({
      address: Yup.string().required(standardRequiredMessage),
    }),
    note: Yup.string().optional(),
    startDate: Yup.string().required(standardRequiredMessage),
    startTime: Yup.string()
      .required(standardRequiredMessage)
      .matches(/\d\d:\d\d/gi, 'Must have 00:00 time format'),
    endTime: Yup.string().required(standardRequiredMessage),
    allowRemote: Yup.boolean().optional(),
    repeat: Yup.boolean().optional(),
    repeatEnding: Yup.boolean().optional(),
    repeatEndingOccurrences: Yup.boolean().optional(),
    repeatIndefinitely: Yup.boolean().optional(),
    repeatEndTimestamp: Yup.string()
      .optional()
      .when(['repeat', 'repeatEnding'], {
        is: (repeat, repeatEnding) => repeat && repeatEnding,
        then: Yup.string().required(standardRequiredMessage),
        otherwise: Yup.string().notRequired(),
      }),
    repeatOccurrences: Yup.number()
      .optional()
      .when(['repeat', 'repeatEndingOccurrences'], {
        is: (repeat, repeatEndingOccurrences) => repeat && repeatEndingOccurrences,
        then: Yup.number()
          .typeError('You must specify a number')
          .positive('Must be greater than zero')
          .required(standardRequiredMessage),
        otherwise: Yup.number().notRequired(),
      }),
    repeatWeekDays: Yup.string().when('repeat', {
      is: true,
      then: Yup.string().required(standardRequiredMessage),
    }),
    endingOption: Yup.bool().when(['repeat', 'repeatEnding', 'repeatEndingOccurrences', 'repeatIndefinitely'], {
      is: (repeat, repeatEnding, repeatEndingOccurrences, repeatIndefinitely) =>
        repeat && !repeatEnding && !repeatEndingOccurrences && !repeatIndefinitely,
      then: Yup.bool().required(),
      otherwise: Yup.bool().optional(),
    }),
    ...(edit ? { updateType: Yup.string().required(standardRequiredMessage) } : {}),
  });

export const deleteMeetingValidator = Yup.object({
  updateType: Yup.string().required(standardRequiredMessage),
});

export const updateAttendedMeetingValidator = Yup.object({
  attended: Yup.string().required(standardRequiredMessage),
});

export const editClientEmailValidationSchema = Yup.object({
  email: Yup.string().email(validEmailMessage).required(standardRequiredMessage),
});

export const editClinicDetailsValidationSchema = Yup.object({
  name: Yup.string().required(standardRequiredMessage),
  phone: Yup.string()
    .min(12)
    .max(12)
    .matches(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/, validPhoneFormatMessage)
    .required(standardRequiredMessage),
  address: Yup.object({
    line1: Yup.string().required(standardRequiredMessage),
    line2: Yup.string(),
    city: Yup.string().required(standardRequiredMessage),
    state: Yup.string()
      .max(2, 'State length must be 2.')
      .min(2, 'State length must be 2.')
      .required(standardRequiredMessage),
    postalCode: Yup.string()
      .min(5, 'Postal code length must be 5.')
      .max(5, 'Postal code length must be 5.')
      .required(standardRequiredMessage),
    country: Yup.string().required(standardRequiredMessage),
  }),
  primaryContact: Yup.object({
    name: Yup.object({
      first: Yup.string().required(standardRequiredMessage),
      last: Yup.string().required(standardRequiredMessage),
    }),
    email: Yup.string().email(validEmailMessage).required(standardRequiredMessage),
    phone: Yup.string()
      .min(12)
      .max(12)
      .matches(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/, validPhoneFormatMessage)
      .required(standardRequiredMessage),
  }),
});

export const SMSNotificationsValidationSchema = Yup.object({
  sms: Yup.string().required(standardRequiredMessage),
  timezone: Yup.string().required(standardRequiredMessage),
  verified: Yup.boolean().optional(),
  weekDays: Yup.string().optional(),
  startWindow: Yup.string().optional(),
  endWindow: Yup.string().optional(),
});

export const readmitClientValidationSchema = Yup.object({
  episodeOfCareType: Yup.string()
    .matches(
      new RegExp(`${Object.keys(EPISODE_OF_CARE).join('|')}`, 'gi'),
      `Must be one of these values: ${Object.values(EPISODE_OF_CARE).join(',')}`,
    )
    .required(standardRequiredMessage),
  practitionerIdList: Yup.array().when(['episodeOfCareType'], {
    is: (episodeOfCareType: string) => episodeOfCareType !== 'AFTER_CARE',
    then: Yup.array()
      .required('You need to select at least one practitioner.')
      .min(1, 'You need to select at least one practitioner.'),
    otherwise: Yup.array().optional(),
  }),
});

export const dischargeValidationScheme = (devices: { healthBand?: string; bac?: boolean }) =>
  Yup.object({
    episodeOfCareType: Yup.string()
      .matches(
        new RegExp(`${Object.keys(EPISODE_OF_CARE).join('|')}`, 'gi'),
        `Must be one of these values: ${Object.values(EPISODE_OF_CARE).join(',')}`,
      )
      .required(standardRequiredMessage),
    reason: Yup.string().required(standardRequiredMessage),
    unassignedDeviceReason: !!devices.healthBand
      ? Yup.string().required(standardRequiredMessage)
      : Yup.string().optional(),
    unnasignBac: !!devices.bac ? Yup.string().required(standardRequiredMessage) : Yup.string().optional(),
  });

export const submitSurveyValidationSchema = Yup.object({
  questionnaireId: Yup.number().required(standardRequiredMessage),
  sendAfter: Yup.number().positive().required(standardRequiredMessage),
});

export const addQuestionnaireScheduleValidator = (edit: boolean) =>
  Yup.object({
    name: Yup.string(),
    timezone: Yup.string().required(standardRequiredMessage),
    // repeatWeekDays: Yup.string().required(standardRequiredMessage),
    questionnaireName: Yup.string().required(standardRequiredMessage),
    frequencyName: Yup.string().required(standardRequiredMessage),
    dayOfMonth: Yup.number().min(1).max(28),
    // time: Yup.string(),
  });
