import React from 'react';
import ExplanationBar from '../explanation-bar';
import ScoreBar from '../score-bar';
import ScoreRange from '../score-range';

const InfoBox = (): JSX.Element => {
  return (
    <div>
      <h3>STRESS</h3>
      <p>
        The Stress chart provides an approximation of the body’s response to potential stressors over time on a scale
        from 0 to 100 (0 is lowest stress level and 100 is highest). To measure stress, the system observes key sensor
        inputs from the health band and uses algorithms to determine the level of stress, reporting out each hour on the
        hour. And, the system can tell the difference between stress and exercise.
      </p>
      <p>
        The Stress levels shown on the chart can help you identify times your client may have a stress burden, and it
        provides opportunities to offer stress management advice and support to make situations a little less
        overwhelming.
      </p>
      <p>
        <strong>Stress ranges are:</strong>
      </p>

      <h5>
        <ScoreBar
          scoreList={[
            { width: '30%', value: 30, color: 'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 100%)' },
            { width: '20%', value: 50, color: 'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)' },
            { width: '25%', value: 75, color: 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)' },
            { width: '25%', value: 100, color: 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)' },
          ]}
        />
      </h5>
      <ExplanationBar
        rangeList={[
          { width: '30%', text: 'Low' },
          { width: '20%', text: 'Fair' },
          { width: '25%', text: 'High' },
          { width: '25%', text: 'Acute' },
        ]}
      />
    </div>
  );
};

export default InfoBox;
