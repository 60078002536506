import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { FormikErrors, FormikTouched, FormikValues, useFormikContext } from 'formik';
import { USER_ROLES } from '../../utils/constants';
import { TextInput, SelectInput, PhoneInput, FileInput, Checkbox } from '../common/form';
import styles from './admin-practitioners.module.scss';
import { Tooltip } from '../common/tooltip';
import rolesHelper from '../../assets/contents/roles-tooltip-text.json';
import { getPatients } from 'services/api/patient';
import { PractitionerContext } from 'contexts/Practitioner';
import SelectArrayInputPatients from 'components/common/form/select-array-input-patients';

type Props = {
  editMode: boolean;
  image: File | string | undefined;
  setImage: Dispatch<SetStateAction<File | string | undefined>>;
  touched: FormikTouched<any>;
  errors: FormikErrors<any>;
  values: FormikValues;
};

const PractitionerFields: React.FC<Props> = ({ editMode, image, setImage, touched, errors, values }) => {

  const { setFieldValue } = useFormikContext();
  const [selection, setSelection] = useState<"ALL" | "ACTIVE" | "PENDING" | null>(null);
  const [patients, setPatients] = useState<any>([]);
  const { practitioner } = useContext(PractitionerContext);

  const getAll = async () => {
    const res = await getPatients(practitioner?.organization.id);
    if (res.status === 'Done') {
      setPatients(res.data.values);
    }
  }

  useEffect(() => {
    if (practitioner) {
      getAll();
    }
  },[practitioner]);

  return (
    <div className={styles.body}>
      <SelectInput label='Prefix:' name='prefix' defaultValue={values.prefix}>
        <option value='Dr.'>Dr.</option>
        <option value='Mr.'>Mr.</option>
        <option value='Ms.'>Ms.</option>
        <option value='Mrs.'>Mrs.</option>
        <option value='Miss.'>Miss.</option>
        <option value='' />
      </SelectInput>
      <TextInput label='First name:' name='firstName' />
      <TextInput label='Last name:' name='lastName' />
      <TextInput label='Email:' name='email' disabled={editMode} />
      <PhoneInput label='Cell phone:' name='phone' />
      <FileInput
        label='Profile Photo:'
        name='image'
        filesAccept='.jpg'
        file={image}
        setFile={setImage}
        fileLimitMB={5}
        fileType='photo'
      />

      <div className={styles.checkboxGroup}>
        <span className={styles.groupName}>
          <span>Roles:</span>
          <Tooltip baseStyles={styles.rolesTooltip} iconHeight='10' iconWidth='10'>
            {rolesHelper.map((role, i) => (
              <div key={i} className={styles.roleDescription}>
                <span className={styles.title}>{role.title}: </span>
                <span>{role.description}</span>
              </div>
            ))}
          </Tooltip>
        </span>

        <Checkbox name='admin' field={USER_ROLES.ADMIN} />
        <Checkbox name='clinician' field={USER_ROLES.CLINICIAN} />
        <Checkbox name='aftercare' field={USER_ROLES.AFTERCARE_MANAGER} />
        <span
          className={`${
            !!(errors as any).adminOrClinician && (touched.admin || touched.clinician)
              ? styles.errorText__checkbox__show
              : styles.errorText__checkbox
          }`}
        >
          Please choose at least one option.
        </span>
      </div>
      {!editMode && (
        <div style={{ maxWidth: 600 }}>
          <SelectArrayInputPatients
            name='patientsToAssign'
            label='Patients to be Assigned:'
            itemsHeader='Patients Assigned:'
            items={patients}
            selectedItems={values?.patientsToAssign || []}
            size='big'
          />
        </div>
      )}


    </div>
  );
};

export default PractitionerFields;
