import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Calendar } from 'react-date-range';
import { useField, useFormikContext, FieldAttributes } from 'formik';
import styles from './form.module.scss';
import datepickerStyles from '../date-picker/datepicker.module.scss';

type Props = {
  id?: string;
  name: string;
  label: string;
  maxdate?: Date;
  selected?: string;
  size?: string;
  position?: string;
} & FieldAttributes<any>;

const CalendarDateInput: React.FC<Props> = props => {
  const node = useRef<HTMLDivElement>(null);
  const { setFieldValue } = useFormikContext();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [field, meta] = useField(props);
  const [selectedDate, setSelectedDate] = useState<string>('');

  const onDateSelection = date => {
    const splittedDate = moment(date).format('L').split('/');
    const formattedDate = `${splittedDate[2]}-${splittedDate[0]}-${splittedDate[1]}`;
    setSelectedDate(formattedDate);
  };

  const handleClickOutside = e => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setShowCalendar(false);
  };

  useEffect(() => {
    if (field.value) {
      onDateSelection(field.value);
    }
  }, []);

  useEffect(() => {
    if (showCalendar) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showCalendar]);

  useEffect(() => {
    if (selectedDate) {
      setFieldValue(props.name, selectedDate);
      setShowCalendar(false);
    }
  }, [selectedDate]);

  return (
    <div className={styles.dateContainer}>
      <div
        className={props.size === 'small' ? styles.smallDateField : styles.field}
        onClick={() => setShowCalendar(true)}
      >
        {!!props.label && <label htmlFor={props.id || props.name}>{props.label}</label>}
        <div className={styles.dateIcon}>
          <input
            className={meta.touched && meta.error ? styles.inputError : props.disabled ? styles.inputDisabled : ''}
            {...field}
            {...props}
            disabled={props.disabled}
            value={selectedDate}
            readOnly
          />
        </div>
        {meta.touched && !!meta.error && <div className={styles.error}>{meta.error}</div>}
      </div>
      {showCalendar && (
        <div
          className={
            props.position === 'top' ? styles.calendarContainer__noTimeCalendarTop : styles.calendarContainer__noTime
          }
          ref={node}
        >
          <Calendar
            rangeColors={['#417EB9']}
            color='#417EB9'
            onChange={date => onDateSelection(date)}
            className={datepickerStyles.parentStyle}
            classNames={{
              nextButton: datepickerStyles.nextButton,
              prevButton: datepickerStyles.prevButton,
              weekDays: datepickerStyles.weekDays,
              monthPicker: datepickerStyles.monthPicker,
              yearPicker: datepickerStyles.yearPicker,
              selected: datepickerStyles.selected,
              dayHovered: datepickerStyles.dayHovered,
            }}
            weekdayDisplayFormat='EEEEE'
            maxDate={props.maxdate}
            placeholder={props.selected ? props.selected : undefined}
          />
        </div>
      )}
    </div>
  );
};

export default CalendarDateInput;
