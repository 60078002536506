import { PhonePermissions } from '../interfaces/patient';

export const ROLE_CODES = {
  ADMIN: 'ADMIN',
  CLINICIAN: 'CLINICIAN',
  AFTERCARE: 'AFTERCARE_MANAGER',
};

export const USER_ROLES = {
  ADMIN: 'Administrator / Management / IT',
  CLINICIAN: 'Physician / Registered Nurse / Counselor / Therapist / Case Manager',
  AFTERCARE_MANAGER: 'Aftercare Manager',
};

export const timezones = {
  CST: 'America/Chicago',
  EST: 'America/New_York',
  PST: 'America/Los_Angeles',
  MST: 'America/Denver',
  HST: 'Pacific/Honolulu',
  PDT: 'America/Los_Angeles',
  PNT: 'America/Phoenix',
  MDT: 'America/Phoenix',
  CDT: 'America/Chicago',
  EDT: 'America/New_York',
};

export const MARITAL_STATUS = {
  SINGLE: 'SINGLE',
  ENGAGED: 'ENGAGED',
  MARRIED: 'MARRIED',
  DIVORCED: 'DIVORCED',
  WIDOWED: 'WIDOWED',
};

export const MeetingType = {
  AA: 'AA',
  GROUP: 'Group',
  CLINICIAN: 'Clinician',
  PRIVATE: 'Private',
  OTHER: 'Other',
  FAMILY_THERAPY: 'Family Therapy',
  INDIVIDUAL_THERAPY: 'Individual Therapy',
  GROUP_THERAPY: 'Group Therapy',
  RECOVERY_YOGA: 'Recovery Yoga',
  SR_FELLOW_PRESENTATION: 'Sr Fellow Presentation',
  DR_APPOINTMENT: 'Dr. Appointment',
  COACH_MEETING: 'Coach',
  ALUMNI_MEETING: 'Alumni',
  PEER_SUPPORT_MEETING: 'Peer Support',
  STEP_MEETING: '12-Step',
  REQUIRED_MEETING: 'Required Meeting',
  DRUG_TEST: "Drug Test"
};

export const MeetingStatus = {
  CLIENT_REMOTE_ATTENDED: 'Attended Remote',
  CLIENT_REMOTE_MISSED: 'Missed',
  CLINICIAN_REMOTE_ATTENDED: 'Attended Remote',
  CLINICIAN_REMOTE_MISSED: 'Missed',
  CLINICIAN_REVISE_ATTENDED: 'Revised Attended',
  CLINICIAN_REVISE_MISSED: 'Revised Missed',
  CLINICIAN_ATTENDED: 'Attended',
  CLINICIAN_MISSED: 'Missed',
  LOCATION_ATTENDED: 'Attended',
  PENDING: 'In Progress',
  MISSED: 'Missed',
  UPCOMING: 'Upcoming',
  UPCOMING_REMOTE: 'Upcoming Remote',
  UNDETERMINED: 'Undetermined',
  WAITING_FOR_DATA: 'Waiting For Data',
};

export const WeekDay = {
  S: 'Su',
  M: 'M',
  T: 'Tu',
  W: 'W',
  R: 'Th',
  F: 'F',
  U: 'Sa',
};

export const deviceStatus = {
  GOOD: 'GOOD',
  BROKEN: 'BROKEN',
  LOST: 'LOST',
  RECYCLED: 'RECYCLED',
};

export const EXTERNAL_LINKS = {
  SUPPORT: 'http://support.viviclinic.com',
  TERMS_AND_CONDITIONS: 'https://www.vivihealth.com/viviclinic-terms-conditions/',
  PRIVACY_POLICY: 'https://www.vivihealth.com/privacy-policy/',
  GOOGLE_MAPS_QUERY: 'https://www.google.com/maps/search/?api=1&query=',
};

export const PATIENT_STATUS = {
  CRITICAL: 'CRITICAL',
  AT_RISK: 'AT RISK',
  STABLE: 'STABLE',
};

export const PATIENT_ACCOUNT_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING_INVITE: 'PENDING_INVITE',
  DISCHARGED: 'DISCHARGED',
};

export const VIVIHEALTH_SCORE_RANGE = {
  CRITICAL: {
    MIN: 1,
    MAX: 29,
  },
  AT_RISK: {
    MIN: 30,
    MAX: 59,
  },
  STABLE: {
    MIN: 60,
    MAX: 100,
  },
};

export const RELAPSE_RISK_SCORE_RANGE = {
  STABLE: {
    MIN: 0,
    MAX: 24,
  },
  AT_RISK: {
    MIN: 25,
    MAX: 54,
  },
  CRITICAL: {
    MIN: 55,
    MAX: 100,
  },
};

export const INSIGHT_STRING_REPLACEMENT = {
  SUBJECT_NAME: 'SUBJECT_NAME',
  TIMESTAMP: 'TIMESTAMP',
  START_TIMESTAMP: 'START_TIMESTAMP',
  DATE: 'DATE',
  START_DATE: 'START_DATE',
  TIME: 'TIME',
  START_TIME: 'START_TIME',
};

export const statusColor: { [id: string]: string } = {
  [PATIENT_STATUS.AT_RISK]: '#EFBB55',
  [PATIENT_STATUS.CRITICAL]: '#ED6F6A',
  [PATIENT_STATUS.STABLE]: '#6FCF97',
};

export const SMS_NOTIFICATION_OPTIONS = {
  INSIGHT_ONLY_ALERT: 'INSIGHT_ONLY_ALERT',
  INSIGHT_ALL_NEGATIVE: 'INSIGHT_ALL_NEGATIVE',
  INSIGHT_ALL_POSITIVE: 'INSIGHT_ALL_POSITIVE',
  INSIGHT_ONLY_BAC: 'INSIGHT_ONLY_BAC',
};

export const CODE_VERIFICATION_STATUS = {
  NOT_SENT: 'NOT_SENT',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const SurveyStatus = {
  MISSED: 'Missed',
  SCHEDULED_COMPLETE: 'Scheduled Complete',
  UNSCHEDULED_COMPLETE: 'Unscheduled Complete',
};

export const GRAPH_REVERSE_TOOLTIP_COORDINATE = 890;
export const SLEEP_CHART_REVERSE_TOOLTIP_COORDINATE = 830;
export const GRAPH_REVERSE_BIG_TOOLTIP_COORDINATE = 815;

export const UTILIZATION_REVIEW_SECTIONS = {
  VH_SCORE: {
    title: 'ViviHealth Score',
  },
  BIOMEDICAL_CONDITION: {
    title: 'BioMedical Condition',
  },
  E_B_C: {
    title: 'Emotional/Behavioral/Cognitive',
  },
  R_P_P: {
    title: 'Readiness to Change/Participation/Planning',
  },
};

export const SCORE_ICON_NAME = {
  HR: 'HEART RATE',
  HRV: 'HEART RATE VARIABILITY',
  RR: 'RESPIRATION',
};

export const EPISODE_OF_CARE = {
  DETOX: 'Detox',
  AMBULATORY_DETOX: 'Ambulatory Detox',
  IN_PATIENT: 'Inpatient',
  INTENSIVE_OUTPATIENT: 'Intensive Outpatient',
  OUT_PATIENT: 'Outpatient',
  VIRTUAL_INTENSIVE_OUTPATIENT: 'Virtual Intensive Outpatient',
  PARTIAL_HOSPITALIZATION: 'Partial Hospitalization',
  DAY_TREATMENT: 'Day Treatment',
  SOBER_LIVING: 'Sober Living',
  AFTER_CARE: 'Aftercare',
  SELF_CARE: 'Post-care',
};

export const SCORE_CARDS_TYPE = {
  SCORE: 'SCORE',
  RATE: 'RATE',
  STRING: 'STRING',
};

export const EBC_CARD_VALUES = {
  DECREASED: 'DECREASED',
  POOR: 'POOR',
  INCREASED: 'INCREASED',
};

export const CHARTS_SIZES = {
  CDP_WIDTH: 1080,
  CDP_HEIGHT: 120,
  UR_WIDTH: 880,
  UR_HEIGHT: 170,
  UR_PDF_WIDTH: 2450,
  UR_PDF_HEIGHT: 250,
};

export const UTILIZATION_REVIEW_CHARTS_IDS = [
  'viviHealthChart__pdf',
  'hrChart__pdf',
  'hrvChart__pdf',
  'respirationChart__pdf',
  'moodChart__pdf',
  'sleepChart__pdf',
  'stressChart__pdf',
  'exerciseChart__pdf',
  'engagementChart__pdf',
  'eventsChart__pdf',
];

export const EVENTS_TYPES = {
  MEETING: 'MEETING',
  REMOTE_MEETING: 'REMOTE_MEETING',
  BAC: 'BAC',
  FAIL_BAC: 'FAIL_BAC',
  GEOFENCE: 'GEOFENCE',
  SURVEY: 'SURVEY',
  MEDICATION_TAKE: 'MEDICATION_TAKE',
};

export const HEX_COLORS = {
  CRITICAL: '#ED6F6A',
  RISK: '#EFBB55',
  STABLE: '#6FCF97',
  DEFAULT: '#323335',
  ACTIVITY: '#417EB9',
  SPECIAL: '#2F80ED',
};

export const VITALS_UNIT = {
  HR: 'HR',
  HRV: 'HRV',
  RR: 'RR',
  Activity: 'Activity',
};

export const REPORT_TYPES = {
  UTILIZATION_REVIEW: 'UTILIZATION_REVIEW',
};

export const SUPPORTER_ACCOUNT_STATE = {
  ACTIVE: 'ACTIVE',
  PENDING_ACCEPTANCE: 'Pending Acceptance',
  PENDING_VERIFICATION: 'Pending Verification',
  PENDING_SUPPORTER: 'Pending Supporter',
};

export const VivihealthScoreComponentWeight: { [component: string]: number } = {
  'engagement': 15,
  'mood': 15,
  'sleep': 20,
  'stress': 15,
  'activity': 20,
  'adherence': 15,
};

export const RelapseRiskScoreComponentWeight: { [component: string]: number } = {
  engagement: 25,
  mood: 15,
  sleep: 20,
  stress: 15,
  adherence: 25,
};

export const EngagementScoreComponentWeight: { [component: string]: number } = {
  'meetingAttendance': 20,
  'communication': 15,
  'testingCompliance': 15,
  'surveyCompliance': 15,
  'selfHelpContent': 10,
  'deviceWorn': 25,
};

export const TREATMENT_COMPLETED_REASON = {
  TREATMENT_DONE: 'No Further Treatment',
  TREATMENT_DONE_ADMIT_LOWER_LEVEL: 'Admitting to lower level of care',
  TREATMENT_DONE_OTHER: 'Other',
};

export const TREATMENT_NOT_COMPLETED_REASON = {
  NOT_COMPLETED_CLIENT: 'Client decision',
  NOT_COMPLETED_FAMILY: 'Family decision',
  NOT_COMPLETED_FINANCIAL: 'Financial',
  NOT_COMPLETED_INSURANCE: 'Insurance',
  NOT_COMPLETED_ADMIT_HIGHER_LEVEL: 'Admitting to higher level of care',
  NOT_COMPLETED_OTHER: 'Other',
};

export const DEVICE_DISCHARGE_REASON = {
  GOOD: {
    HEALTHBAND: 'Client returned healthband - Healthband to be re-used',
    BAC: 'Client returned BAC - BAC to be re-used',
  },
  LOST: {
    HEALTHBAND: 'Client did not return the healthband - Considered lost',
    BAC: 'Client did not return the BAC - Considered lost',
  },
  BOUGHT: {
    HEALTHBAND: 'Client paid for healthband',
    BAC: 'Client paid for BAC',
  },
  RECYCLED: {
    HEALTHBAND: 'Client using the healthband in next episode of care',
    BAC: 'Client using the BAC in next episode of care',
  },
  BROKEN: {
    HEALTHBAND: 'Broken',
    BAC: 'Broken',
  },
};

export const defaultPhonePermissions: PhonePermissions = {
  permissions: {
    isBluetoothApproved: 'unavailable',
    isContactsApproved: 'unavailable',
    isMotionApproved: 'unavailable',
    isPushApproved: 'unavailable',
    isCameraApproved: 'unavailable',
    locationPermission: 'off',
  },
};

export type HomeActions = 'Profile' | 'Insight' | 'Discharged' | 'Notes' | 'Suvey';

export const sleepColors = {
  Rem: '#554E85',
  Deep: '#7669BE',
  Light: '#959BE7',
  Restless: '#BABDED',
  Awake: '#CAC7DF',
};
