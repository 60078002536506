import { AxiosResponse } from 'axios';
import m from 'moment-timezone';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPatientTimezone, PatientContext } from '../../../../contexts/PatientContext';
import { BacResult } from '../../../../interfaces/bac-result';
import { getBacTestImage, updateFaceMatch } from '../../../../services/api/bac';
import { getPatientProfileImage } from '../../../../services/api/patient';
import { Loading } from '../../../common/loading';
import styles from './bac-facial-match.module.scss';

type Props = {
  bacTest: BacResult;
  updateBacTest: (bacTest: BacResult) => void;
  closeModal: () => void;
};

const BacFacialMatch: FunctionComponent<Props> = ({ bacTest, closeModal, updateBacTest }) => {
  const { patient } = useContext(PatientContext);
  const [facialMatchStatus, setFacialMatchStatus] = useState(bacTest.identification.faceMatchStatus === 'TRUE');
  const [isLoading, setIsLoading] = useState(true);
  const [bacImages, setBacImages] = useState({
    profileImage: '',
    bacImage: '',
  });
  const { id } = useParams<{ id: string }>();

  const submitReview = async (): Promise<void> => {
    try {
      const result: AxiosResponse<BacResult> = await updateFaceMatch(
        id!,
        bacTest.id,
        facialMatchStatus ? 'TRUE' : 'FALSE',
      );
      updateBacTest(result.data);
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const [profileImage, bacImage] = await Promise.allSettled([
          getPatientProfileImage(id!, bacTest.identification.profileImageId),
          !!bacTest.identification?.bacImageId
            ? getBacTestImage(id!, bacTest.identification.bacImageId)
            : Promise.resolve({ data: { base64: '' } }),
        ]);
        const patientImage = profileImage.status.includes('fulfilled')
          ? (profileImage as PromiseFulfilledResult<AxiosResponse<{ base64: string }>>).value.data.base64
          : '';
        const bacTestImage = bacImage.status.includes('fulfilled')
          ? (bacImage as PromiseFulfilledResult<AxiosResponse<{ base64: string }>>).value.data.base64
          : '';
        setBacImages({ profileImage: patientImage, bacImage: bacTestImage });
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    })();
  }, [id, bacTest]);

  return (
    <section className={styles.facialMatchModal}>
      <div className={styles.modalHeader}>
        <h4>
          Facial Recognition - {!!bacTest.timestamp && m.utc(bacTest.timestamp).tz(getPatientTimezone()).format('lll')}
        </h4>
      </div>

      <div className={`${styles.facialMatchDetail} clearfix`}>
        <div className={styles.box}>
          <span>Client: </span>
          {patient?.name.first} {patient?.name.last}
        </div>
        <div className={styles.box}>
          <span>Phone Number: </span>
          {patient?.contact.cellphone}
        </div>
        <div className={styles.box}>
          <span>Recognition confidence: </span>
          {(bacTest.identification?.confidence * 100).toFixed(3)}%
        </div>
        <div className={styles.box}>
          <span>BAC level: </span>
          {bacTest.level}
        </div>
      </div>

      <section className={styles.facialMatchContainer}>
        {isLoading ? (
          <Loading style={{ position: 'relative', top: '35%' }} />
        ) : (
          <>
            <p> Does this BAC test photo match the Client's Profile photo?</p>
            <div className={`${styles.images}`}>
              <div className={styles.imageContent}>
                <img
                  alt='profileImage'
                  src={bacImages.profileImage ? `data:image/jpeg;base64,${bacImages.profileImage}` : ''}
                />
                <span className={styles.text}> Client Profile Photo</span>
              </div>
              <div className={styles.imageContent}>
                {bacImages.bacImage ? (
                  <img alt='bacImage' src={`data:image/jpeg;base64,${bacImages.bacImage}`} />
                ) : (
                  <div className={styles.noImage}></div>
                )}
                <span className={styles.text}> {bacImages.bacImage ? 'Client Bac Test Photo' : 'Photo not found'}</span>
              </div>
            </div>

            <div className={styles.options}>
              <button
                className={`${facialMatchStatus ? styles.selected : ''} ${styles.yesButton}`}
                onClick={() => setFacialMatchStatus(true)}
              >
                <i />
                Yes it's a match
              </button>
              <button className={!facialMatchStatus ? styles.selected : ''} onClick={() => setFacialMatchStatus(false)}>
                <i />
                No, it's not a match
              </button>
            </div>

            <div className={`${styles.rowButtons}`}>
              <a className={styles.saveButton} onClick={submitReview}>
                Save
              </a>
              <a className={styles.cancelButton} onClick={closeModal}>
                Cancel
              </a>
            </div>
          </>
        )}
      </section>
    </section>
  );
};

export default BacFacialMatch;
