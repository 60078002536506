import ActiveLink from 'components/common/active-link';
import { EventChart } from 'components/common/chart';
import GadQuestionChart from 'components/common/chart/surveys/gad/gad-question-chart';
import GadTotalChart from 'components/common/chart/surveys/gad/gad-total-chart';
import PhqQuestionChart from 'components/common/chart/surveys/phq/phq-question-chart';
import PhqTotalChart from 'components/common/chart/surveys/phq/phq-total-chart';
import SurveyChart from 'components/common/chart/surveys/survey-chart';
import PdfConfirmationModal from 'components/pdf/pdf-confirmation-modal';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useQuestionnairesQuery } from 'graphql/codegen/hooks';
import { QuestionItem, QuestionnaireInfo, QuestionnaireInstance, QuestionnaireStatus } from 'graphql/codegen/schemas';
import moment from 'moment-timezone';
import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { ROLE_CODES } from 'utils/constants';
import { displayTableDateTimeFormat } from 'utils/data-parser';
import { getPdfFileName } from 'utils/pdf-helper';
import PrivateRoute from 'utils/private-route';
import { ReactComponent as DownloadButton } from '../../assets/icons/icn_download.svg';
import { getPatientTimezone, PatientContext } from '../../contexts/PatientContext';
import { PractitionerContext } from '../../contexts/Practitioner';
import { BacResult } from '../../interfaces/bac-result';
import { getOrganizationImageAsBase64 } from '../../services/api/report';
import { firebaseSendCurrentScreen } from '../../utils/analytics';
import DatePicker, { DateRange } from '../common/date-picker/date-range-picker';
import Table from '../common/table/table';
import { DropDown } from '../dropdown';
import Modal from '../modal/Modal';
import { ignoreQuestionIdx } from './ignore-question-numbers';
import { isSurveyUsable } from './is-survey-useful';
import RequestSurveyModal from './modals/request-survey-modal';
import ViewSurveyModal from './modals/view-survey-modal';
import SchedulesPage from './schedules-page';
import { getStatusColor, getStatusIcon, getStatusText } from './survey-status-styling';
import styles from './survey.module.scss';
import SurveyTablePdf from './tables/survey-table-pdf';
import { Checkbox, TextInput } from 'components/common/form';
import ClinicianCowsSurvey from './modals/clinician-cows-survey';
import ClinicianCiwaSurvey from './modals/clinician-ciwa-survey';
import ClinicianSurveyModal from './modals/clinician-survey-modal';

type SelectedTab = 'results' | 'trend';
export type BacModal = 'Location' | 'FacialMatch' | 'BacTest' | 'None';
type Props = {};

const SurveyIndex: FunctionComponent<Props> = () => {
  const { practitioner } = useContext(PractitionerContext);
  const { patient } = useContext(PatientContext);
  const timezone = getPatientTimezone();
  const patientTimezone = getPatientTimezone();
  const [showPdf, setShowPdf] = useState(false);
  const [selectedTab, setSelectedTab] = useState<SelectedTab>('results');
  const [organizationLogo, setOrganizationLogo] = useState<string>('');
  const { id } = useParams<{ id: string }>();
  const [showCowsSurveys, setShowCowsSurveys] = useState<boolean>(false);
  const [showCiwaSurveys, setShowCiwaSurveys] = useState<boolean>(false);
  const [showClinicianModal, setShowClinicianModal] = useState<boolean>(false);
  const [ciwaChecked, setCiwaChecked] = useState(false);
  const [cowsChecked, setCowsChecked] = useState(false);

  const getSurveyChart = () => {
    if (!!selectedQuestionnaire) {
      switch (selectedQuestionnaire?.name) {
        case 'GAD7':
          return (
            <GadTotalChart
              data={selectedQuestionnaireTrend}
              start={dateRangeFilter.startDate?.toISOString() || ''}
              end={dateRangeFilter.endDate?.toISOString() || ''}
              title={selectedQuestionnaire?.clinicName || ''}
            />
          );
        case 'PHQ_9':
          return (
            <PhqTotalChart
              data={selectedQuestionnaireTrend}
              start={dateRangeFilter.startDate?.toISOString() || ''}
              end={dateRangeFilter.endDate?.toISOString() || ''}
              title={selectedQuestionnaire?.clinicName || ''}
            />
          );

        default:
          return (
            <SurveyChart
              data={selectedQuestionnaireTrend}
              start={dateRangeFilter.startDate?.toISOString() || ''}
              end={dateRangeFilter.endDate?.toISOString() || ''}
              title={selectedQuestionnaire?.clinicName || ''}
            />
          );
      }
    }
  };

  const getSurveyQuestionChart = questionTrend => {
    switch (questionTrend?.survey) {
      case 'GAD7':
        const selectedGadQuestion = questionTrend?.item?.availableAnswers?.find(
          i => i.id === questionTrend?.item?.answer?.id,
        );
        return (
          <GadQuestionChart
            key={questionTrend.item.id}
            title={questionTrend.idx ? `${questionTrend.idx}.${questionTrend.text}` : questionTrend.text}
            start={dateRangeFilter.startDate?.toISOString() || ''}
            end={dateRangeFilter.endDate?.toISOString() || ''}
            data={questionTrend.values}
            selectedQuestion={selectedGadQuestion}
          />
        );

      case 'PHQ_9':
        const selectedPhqQuestion = questionTrend?.item?.availableAnswers?.find(
          i => i.id === questionTrend?.item?.answer?.id,
        );
        return (
          <PhqQuestionChart
            key={questionTrend.item.id}
            title={questionTrend.idx ? `${questionTrend.idx}.${questionTrend.text}` : questionTrend.text}
            start={dateRangeFilter.startDate?.toISOString() || ''}
            end={dateRangeFilter.endDate?.toISOString() || ''}
            data={questionTrend.values}
            selectedQuestion={selectedPhqQuestion}
          />
        );

      default:
        return (
          <SurveyChart
            key={questionTrend.item.id}
            title={questionTrend.idx ? `${questionTrend.idx}.${questionTrend.text}` : questionTrend.text}
            start={dateRangeFilter.startDate?.toISOString() || ''}
            end={dateRangeFilter.endDate?.toISOString() || ''}
            data={questionTrend.values}
          />
        );
    }
  };

  useEffect(() => {
    if (practitioner) {
      firebaseSendCurrentScreen('SurveysPage', {
        practitionerId: practitioner?.id,
        organizationId: practitioner?.organization.id,
        firebaseUid: practitioner?.firebaseUid,
      });
      getBlobFromLogoURL();
    }
  }, [practitioner]);

  const onDateChange = async (item: any): Promise<void> => {
    setDateRangeFilter(item.selection);
  };

  const getBlobFromLogoURL = async () => {
    const base64Image = await getOrganizationImageAsBase64(practitioner!.organization.theme.images.logo);
    setOrganizationLogo(`data:image/png;base64,${base64Image}`);
  };

  const getTableAsPDF = (): JSX.Element => (
    <SurveyTablePdf
      dateRange={{
        start: moment(dateRangeFilter.startDate).format(),
        end: moment(dateRangeFilter.endDate).format(),
        timestamp: moment().format(),
      }}
      timezone={patientTimezone}
      patient={patient}
      practitioner={practitioner}
      data={filteredSurveys.sort((a, b) => moment(b.created).diff(moment(a.created))) as QuestionnaireInstance[]}
      organizationLogo={organizationLogo}
    />
  );

  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    key: 'selection',
    color: '#417EB9',
    showDateDisplay: true,
    autoFocus: true,
  });
  const {
    data: patientSurveys,
    loading: loadingSurveys,
    error,
  } = useQuestionnairesQuery({
    variables: {
      id: patient?.firebaseUid!,
      start: dateRangeFilter.startDate,
      end: dateRangeFilter.endDate,
    },
    skip: !patient?.firebaseUid,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
  const [viewSurvery, setViewSurvey] = useState(null as QuestionnaireInstance | null);
  const [showRequestSurveyModal, setShowRequestSurveyModal] = useState(false);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null as QuestionnaireInfo | null | undefined);
  const selectedQuestionnaireTrend = useMemo(() => {
    const values = (patientSurveys?.questionnairesOfUser || [])
      .filter(q => q.questionnaire.id === selectedQuestionnaire?.id)
      .filter(q => q.status === QuestionnaireStatus.Finished)
      .map(q => ({ ts: moment.utc(q.modified).tz(timezone).valueOf(), value: q.value || 0 }));
    values.sort((a, b) => a.ts - b.ts);
    return values;
  }, [selectedQuestionnaire, patientSurveys, timezone]);
  const questionsTrend = useMemo(
    () =>
      (patientSurveys?.questionnairesOfUser || [])
        .filter(q => q.questionnaire.id === selectedQuestionnaire?.id)
        .filter(q => q.status === QuestionnaireStatus.Finished)
        .reduce((questions, questionnaire) => {
          let lastParentIdx = 1;
          questionnaire.questions.forEach((question, idx) => {
            if (question.designType === 'multiple_answers') {
              question.subQuestions?.forEach((childQuestion, idx2) => {
                if (childQuestion.answer) {
                  let addQuestion = questions.find(q => q.item.id === childQuestion.id);
                  if (!addQuestion) {
                    addQuestion = {
                      item: childQuestion,
                      text: `${question.text}(${childQuestion.text})`,
                      idx: `${idx + 1}.${idx2 + 1}`,
                      values: [],
                    };
                    questions.push(addQuestion);
                  }

                  const selectedAnswer = question?.availableAnswers?.find(i => i.id === question?.answer?.id);
                  addQuestion.values.push({
                    value: childQuestion.answer?.value || 0,
                    ts: moment.utc(childQuestion.answer?.created).tz(timezone).valueOf(),
                    text: selectedAnswer?.text || '',
                  });
                }
              });
            } else if (question.answer) {
              let addQuestion = questions.find(q => q.item.id === question.id);
              if (!addQuestion) {
                addQuestion = {
                  item: question,
                  idx: ignoreQuestionIdx(questionnaire.questionnaire.name, question.id!) ? '' : `${lastParentIdx}`,
                  text:
                    question.designType === 'normal' ? question.subtext || question.text || '' : question.text || '',
                  values: [],
                  survey: selectedQuestionnaire?.name,
                } as any;
                if (!ignoreQuestionIdx(questionnaire.questionnaire.name, question.id!)) {
                  lastParentIdx += 1;
                }
                questions.push(addQuestion!);
              }

              const selectedAnswer = question?.availableAnswers?.find(i => i.id === question?.answer?.id);

              addQuestion!.values.push({
                value: question.answer?.value || 0,
                ts: moment.utc(question.answer?.created).tz(timezone).valueOf(),
                text: selectedAnswer?.text || '',
              });
            }
          });
          questions.forEach(q => q.values.sort((a, b) => a.ts - b.ts));
          return questions;
        }, [] as { item: QuestionItem; text: string; idx: string; values: { ts: number; value: number; text: string }[] }[]),
    [selectedQuestionnaire, patientSurveys, timezone],
  );
  const filteredSurveys = useMemo(
    () =>
      (selectedQuestionnaire
        ? patientSurveys?.questionnairesOfUser?.filter(q => q.questionnaire.id === selectedQuestionnaire.id)
        : patientSurveys?.questionnairesOfUser) || [],
    [selectedQuestionnaire, patientSurveys],
  );
  const hasUsableSurveys = useMemo(
    () => !!patientSurveys?.questionnairesOfUser?.filter(v => !isSurveyUsable(v))?.length,
    [patientSurveys],
  );
  const availableQuestionnaires = useMemo(
    () =>
      (patientSurveys?.questionnairesOfUser || [])
        .map(q => q.questionnaire)
        .reduce(
          (available, q) =>
            available.some(q2 => q2.id === q.id) ? available : (available.push(q as QuestionnaireInfo), available),
          [] as QuestionnaireInfo[],
        ),
    [patientSurveys],
  );
  const bacTableProps: Column<QuestionnaireInstance>[] = useMemo(
    () => [
      {
        accessor: 'created',
        Header: 'DATE',
        width: 200,
        Cell: ({ cell: { value } }: CellProps<BacResult, string>) => displayTableDateTimeFormat(value, timezone),
        sortType: 'basic',
      },
      {
        accessor: v => v.questionnaire.clinicName,
        Header: 'SURVEY NAME',
        width: 143,
        Cell: ({ cell: { value } }) => value,
        sortType: 'basic',
      },
      {
        accessor: v => v.questionnaire.groupName,
        Header: 'SURVEY GROUP',
        width: 143,
        Cell: ({ cell: { value } }) => value,
        sortType: 'basic',
      },
      {
        accessor: v => v,
        Header: 'DID SURVEY',
        width: 143,
        Cell: ({ cell: { value } }) => (
          <div className={styles.status} style={{ color: getStatusColor(value) }}>
            <img src={getStatusIcon(value)} alt={`${getStatusText(value)} icon`} /> {getStatusText(value)}
          </div>
        ),
        sortType: 'basic',
      },
      {
        accessor: v => (isSurveyUsable(v) ? v.value : ''),
        Header: 'SURVEY SCORE',
        width: 143,
        Cell: ({ cell: { value } }) => value,
        sortType: 'basic',
      },
      {
        accessor: v => v,
        id: 'click-action',
        Header: '',
        width: 143,
        Cell: ({ cell: { value } }) => {
          if (isSurveyUsable(value)) {
            return (
              <button type='button' className={styles.viewButton} onClick={() => setViewSurvey(value)}>
                View
              </button>
            );
          }
          return <span className={styles.noData}>No Data</span>;
        },
        sortType: 'basic',
      },
    ],
    [timezone],
  );
  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'created', desc: true }] }),
    [],
  );

  const getTableBodyProps = () => {
    return {
      className: 'styles.tbody',
      style: {
        height: '564px',
      },
    };
  };

  const getTableProps = () => {
    return {
      className: '', // bacTests.status === 'Done' && !bacTests.data.values.length ? styles.noContent : styles.table,
    };
  };

  const closeCowsSurvey = (close: boolean) => {
    setShowCowsSurveys(close);
    setCowsChecked(false);
  };

  const closeCiwaSurvey = (close: boolean) => {
    setShowCiwaSurveys(close);
    setCiwaChecked(false);
  };

  const closeClinicianSurveyModal = (close: boolean) => {
    setShowClinicianModal(!close);
  };

  const chosenSurveys = (cows: boolean, ciwa: boolean) => {
    setShowCowsSurveys(cows);
    setShowCiwaSurveys(ciwa);
  };

  const { url, path } = useRouteMatch();
  const fixedUrl = url.endsWith('/') ? url : `${url}/`;

  return (
    <>
      <header className={styles.surveyHeader}>
        <nav className={styles.surveyNav}>
          <ul className={styles.surveyTabs}>
            <ActiveLink to={`${fixedUrl}`} label='Results' activeOnlyWhenExact={true} />
            {hasUsableSurveys && <ActiveLink to={`${fixedUrl}trend`} label='Trend' activeOnlyWhenExact={true} />}
            <ActiveLink to={`${fixedUrl}schedules`} label='Schedules' activeOnlyWhenExact={true} />
            <li style={{ flex: 1 }} />
            <li className={styles.downloadButton}>
              <button className={styles.clinicianSurvey} onClick={() => setShowClinicianModal(true)}>
                Clinician Take Survey
              </button>
              <DownloadButton onClick={() => setShowPdf(true)} />
            </li>
          </ul>
        </nav>
        <div className={styles.headerOptions}>
          <Switch>
            <Route path={`${url}/schedules`} exact />
            <Route path='/'>
              <ul className={styles.navRight}>
                <li>
                  <select
                    className={styles.dropdownButton}
                    onChange={e => {
                      setSelectedQuestionnaire(
                        availableQuestionnaires.find(q => q.id === parseInt(e.target.value, 10)),
                      );
                    }}
                    value={selectedQuestionnaire?.id}
                  >
                    <option>Select Survey</option>
                    {availableQuestionnaires.map(q => (
                      <option key={q.id} value={q.id}>
                        {q.clinicName}
                      </option>
                    ))}
                  </select>
                </li>
                <li style={{ flex: 1 }} />
                <li className={styles.options}>
                  <DropDown contentStyle='filter'>
                    <span className={styles.dropdownButton}>
                      {moment(dateRangeFilter.startDate).format('MM/DD/YYYY')} -{' '}
                      {moment(dateRangeFilter.endDate).format('MM/DD/YYYY')}
                      <i className={styles.arrow}>
                        <svg width='14' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M13 1L7 7L1 1' stroke='#417EB9' strokeWidth='1.5' strokeLinecap='round' />
                        </svg>
                      </i>
                    </span>
                    <>
                      <DatePicker
                        onDateSelectionChange={onDateChange}
                        dateRange={dateRangeFilter}
                        parentStyle={styles.filterDatepicker}
                      />
                    </>
                  </DropDown>
                </li>
                <li>
                  <a href='#' className='href' onClick={() => setShowRequestSurveyModal(true)}>
                    Request Survey Now
                  </a>
                </li>
              </ul>
            </Route>
          </Switch>
        </div>
      </header>
      <Modal
        contentStyle='bigContent'
        show={showRequestSurveyModal}
        showBtnClose
        closeModal={() => setShowRequestSurveyModal(false)}
      >
        <RequestSurveyModal closeModal={() => setShowRequestSurveyModal(false)} patient={patient!} />
      </Modal>
      <Modal contentStyle='bigContent' show={!!viewSurvery} showBtnClose closeModal={() => setViewSurvey(null)}>
        <ViewSurveyModal
          viewSurvey={viewSurvery}
          patient={patient!}
          closeModal={() => setViewSurvey(null)}
          practitioner={practitioner!}
        />
      </Modal>
      <PrivateRoute path={`${url}/`} exact accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]}>
        <Table<QuestionnaireInstance>
          data={filteredSurveys as any}
          columns={bacTableProps}
          isLoading={loadingSurveys || !patient}
          error={!!error}
          rowStyles={{ background: 'rgba(65, 126, 185, 0.1)' }}
          getRowProps={row => ({
            key: `${row?.id}`,
            style: {
              background: '#FFF', //row?.original?.identification?.identical ? '#FFF' : 'rgba(65, 126, 185, 0.1)',
            },
            className: styles.tr,
          })}
          getHeaderProps={column => ({
            className: styles.th,
            key: `${column?.id}`,
            style: {
              width: column?.width,
              minWidth: column?.minWidth,
              maxWidth: column?.maxWidth,
            },
          })}
          getCellProps={cell => ({
            key: `${cell?.column.id}`,
            style: {
              justifyContent: cell?.column.id === 'photo' ? 'flex-end' : 'flex-start',
              padding: cell?.column.id === 'location' ? '11px 18px' : undefined,
              display: 'flex',
            },
            className: styles.td,
          })}
          defaultColumn={{ width: 180, minWidth: 100 }}
          initialState={defaultSorting}
          getTableBodyProps={() => getTableBodyProps()}
          getTableProps={() => getTableProps()}
          emptyDataMessage='There are no surveys at this time.'
        />
      </PrivateRoute>

      <PrivateRoute path={`${url}/trend`} exact accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]}>
        {getSurveyChart()}
        {questionsTrend.map(questionTrend => getSurveyQuestionChart(questionTrend))}
        <EventChart
          latestInsight={undefined}
          start={dateRangeFilter.startDate?.toISOString() || ''}
          end={dateRangeFilter.endDate?.toISOString() || ''}
          onInsightClick={() => {}}
        />
      </PrivateRoute>
      <PrivateRoute path={`${url}/schedules`} exact accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]}>
        <SchedulesPage />
      </PrivateRoute>
      {showPdf && (
        <PdfConfirmationModal
          title={'Surveys'}
          getPdfComponent={getTableAsPDF}
          filename={getPdfFileName({
            firstName: patient!.name.first,
            lastName: patient!.name.last,
            startDate: dateRangeFilter.startDate!,
            endDate: dateRangeFilter.endDate!,
            pdfTitle: 'Surveys',
          })}
          show={showPdf}
          hide={() => setShowPdf(false)}
        />
      )}
      {showClinicianModal && (
        <ClinicianSurveyModal closeClinicianSurveyModal={closeClinicianSurveyModal} chosenSurveys={chosenSurveys} />
      )}
      {showCowsSurveys && <ClinicianCowsSurvey patient={patient} closeCowsSurvey={closeCowsSurvey} />}
      {showCiwaSurveys && <ClinicianCiwaSurvey patient={patient} closeCiwaSurvey={closeCiwaSurvey} secondSurvey={showCowsSurveys} />}
    </>
  );
};

export default SurveyIndex;
