import * as React from 'react';
import { QuestionnaireInstance } from 'graphql/codegen/schemas';
import styles from './view-penn-craving-modal.module.scss';

interface Props {
    viewSurvey: QuestionnaireInstance;
}

const ViewPhqSurveyModal: React.FC<Props> = props => {
    const totalScore = props?.viewSurvey?.questions.reduce((acc, obj) => {
        return acc + (obj.answer?.value || 0);
    }, 0);

    return (
        <>
            <div className={`${styles.row}`}>
                <span className={`${styles.pentitle}`}>
                    The following questions ask about drinking or drug use, which ever was most strongly experienced.
                </span>
            </div>
            {props?.viewSurvey?.questions.map((question, index) => {
                return (
                    <div className={`${styles.penQuestionContainer}`}>
                        <span className={`${styles.penQuestionText}`}>{index + 1}. {question.text}</span>
                        <div className={`${styles.penQuestionAnswersContainer}`}>
                            {question?.availableAnswers?.map((answer, index) => {
                                return (
                                    <span id={answer.id?.toString()}><span className={question?.answer?.id === answer?.id ? styles.selectedAnswer : ''}>{index}.</span> {answer.text}</span>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
            <div className={styles.totalScoreContainer}>
                <span>Total Score: {totalScore}</span>
            </div>
        </>
    );
};

export default ViewPhqSurveyModal;
