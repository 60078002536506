import React from 'react';

export const CardView = props => {
  return (
    <svg
      style={props.style}
      className={props.className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={props.onClick}
    >
      <rect x='0.75' y='0.75' width='8.65385' height='9.16667' stroke={props.style.color} strokeWidth='1.5' />
      <rect x='0.75' y='14.0835' width='8.65385' height='9.16667' stroke={props.style.color} strokeWidth='1.5' />
      <rect x='14.5962' y='0.75' width='8.65385' height='9.16667' stroke={props.style.color} strokeWidth='1.5' />
      <rect x='14.5961' y='14.0835' width='8.65385' height='9.16667' stroke={props.style.color} strokeWidth='1.5' />
    </svg>
  );
};
