import React from 'react';

export const BacPending = props => {
  return (
    <svg style={props.style} xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' viewBox='0 0 12 12'>
      <circle cx='6' cy='6' r='5.6' stroke='#A3A6B3' strokeWidth='0.8' />
      <path
        fill='#A3A6B3'
        d='M5.8 6h.375v-.442l-.436.072L5.8 6zm0 0l-.061.37.061.01.061-.01L5.8 6zm0 0h-.375v.318l.313.052L5.8 6zm1.8 3v.375h.375V9H7.6zM4 9h-.375v.375H4V9zm1.8-3l.062.37.313-.052V6H5.8zm0 0l.061-.37-.436-.072V6H5.8zm-.061-.37l.122.74-.122-.74zm.436.37h-.75.75zm-.437.37c.54.09.912.503 1.158 1.07.246.564.329 1.2.329 1.56h.75c0-.44-.097-1.184-.391-1.86-.294-.673-.822-1.36-1.722-1.51l-.124.74zM7.6 8.625H5.8v.75h1.8v-.75zm-1.8 0v.75-.75zm-1.8.75h1.8v-.75H4v.75zM5.738 5.63c-.9.15-1.428.837-1.722 1.51A5.002 5.002 0 003.625 9h.75c0-.36.083-.996.329-1.56.246-.567.618-.98 1.158-1.07l-.124-.74zm.437.37h-.75.75zm-.436.37l.122-.74-.122.74z'
      />
      <path
        fill='#A3A6B3'
        d='M5.8 6h.375v.442l-.436-.072L5.8 6zm0 0l-.061-.37.061-.01.061.01L5.8 6zm0 0h-.375v-.318l.313-.052L5.8 6zm1.8-3v-.375h.375V3H7.6zM4 3h-.375v-.375H4V3zm1.8 3l.062-.37.313.052V6H5.8zm0 0l.061.37-.436.072V6H5.8zm-.061.37l.122-.74-.122.74zM6.175 6h-.75.75zm-.437-.37c.54-.09.912-.503 1.158-1.07A4.258 4.258 0 007.225 3h.75c0 .44-.097 1.184-.391 1.86-.294.673-.822 1.36-1.722 1.51l-.124-.74zM7.6 3.375H5.8v-.75h1.8v.75zm-1.8 0v-.75.75zM4 2.625h1.8v.75H4v-.75zM5.738 6.37c-.9-.15-1.428-.837-1.722-1.51A5.002 5.002 0 013.625 3h.75c0 .36.083.996.329 1.56.246.567.618.98 1.158 1.07l-.124.74zM6.175 6h-.75.75zm-.436-.37l.122.74-.122-.74z'
      />
    </svg>
  );
};

export default BacPending;
