import { Field, useField } from 'formik';
import React from 'react';
import styles from './form.module.scss';

type Props = {
  id?: string;
  name: string;
  label: string;
  value?: string;
  size?: string;
  defaultValue?: string;
};

const SelectInput: React.FC<Props> = props => {
  const [field, meta] = useField(props.name);
  return (
    <div
      className={props.size === 'big' ? styles.field__big : props.size === 'small' ? styles.field__small : styles.field}
    >
      <label htmlFor={props.id || props.name}>{props.label}</label>
      <Field
        name={props.name}
        as='select'
        value={props.value}
        defaultValue={props.defaultValue ? props.defaultValue : undefined}
      >
        {props.children}
      </Field>
      {meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
    </div>
  );
};

export default SelectInput;
