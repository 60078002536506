import { post } from './base';
import { Done, done, Failed, failed } from '../../utils/remote-data';

export const sendResetPassword = async (email: string): Promise<Done<null | {}> | Failed> => {
  try {
    const result = await post(`/email`, {
      source: 'VIVI_CLINIC',
      emailType: 'RESET_PASSWORD',
      subject: email,
    });
    return done(result.data);
  } catch (error) {
    return failed(error);
  }
};
