import React from 'react';
import styles from 'components/admin-dashboard/practitioner-device-stats-container.module.scss';
import HealthBand from 'assets/icons/wristband/icn_biostrap.svg';
import BacIcon from 'assets/icons/Illo_BAC.svg';
import PlusIcon from '../../../assets/icons/widget-increase.svg';
import MinusIcon from '../../../assets/icons/widget-decrease.svg';
import { DeviceAsignation } from '../../../graphql/codegen/schemas';

type Props = {
  devicesInventory: DeviceAsignation[];
  updateBACInventory: (increase: boolean) => Promise<void>;
  
  allowDeviceUpdate: boolean;
};

const DeviceStats: React.FC<Props> = ({ devicesInventory, updateBACInventory, allowDeviceUpdate }) => {
  const defaultInventory: DeviceAsignation = { used: 0, allCount: 0, deviceType: '' };
  const wristbandInventory: DeviceAsignation =
    devicesInventory.find(device => device.deviceType === 'WRISTBAND') ?? defaultInventory;
  const bacInventory: DeviceAsignation =
    devicesInventory.find(device => device.deviceType === 'BAC') ?? defaultInventory;

  return (
    <div className={styles.statsCard}>
      <span className={styles.title}>DEVICE STATS</span>
      <div className={styles.devicesTable}>
        <ul>
          <li />
          <li>DEVICE</li>
          <li>INVENTORY</li>
          <li>IN USE</li>
        </ul>
        <ul>
          <li>
            <img className={styles.deviceImage} src={HealthBand} alt='healthband' />
          </li>
          <li>Wristband</li>
          <li className={styles.tableValue}>{wristbandInventory.allCount ?? 0}</li>
          <li className={styles.tableValue}>{wristbandInventory.used ?? 0}</li>
        </ul>
        <ul>
          <li>
            <img className={styles.deviceImage} src={BacIcon} alt='bac-device' />
          </li>
          <li>Breathalyzers</li>
          <li className={styles.tableValue}>
            <img
              className={styles.actionIcon}
              src={MinusIcon}
              onClick={() => allowDeviceUpdate && updateBACInventory(false)}
              alt='minus-sign'
            />
            {bacInventory.allCount ?? 0}
            <img
              className={styles.actionIcon}
              src={PlusIcon}
              onClick={() => allowDeviceUpdate && updateBACInventory(true)}
              alt='plus-sign'
            />
          </li>
          <li className={styles.tableValue}>{bacInventory.used ?? 0}</li>
        </ul>
      </div>
    </div>
  );
};

export default DeviceStats;
