import React from 'react';

export const Heart = props => {
  return (
    <svg style={props.style} width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.18839 0.551758C4.91471 0.551758 5.40162 1.79117 6.02133 2.18955C6.59677 1.79117 7.08369 0.551758 8.85428 0.551758C10.5806 0.551758 11.9528 1.96823 11.9971 3.73882C12.1299 6.30618 7.70339 10.2015 6.02133 11.3081C4.295 10.2015 -0.0872078 6.30618 0.0013218 3.73882C0.0898513 1.96823 1.41779 0.551758 3.18839 0.551758Z'
        fill={props.color || '#ED6F6A'}
      />
    </svg>
  );
};
