import React, { useContext, useState, useEffect } from 'react';
import { startOfMonth, endOfDay, startOfDay } from 'date-fns';
import moment from 'moment-timezone';
import { PractitionerContext } from '../../contexts/Practitioner';
import { createNote } from '../../services/api/note';
import DatePicker, { DateRange } from '../common/date-picker/date-range-picker';
import { DropDown } from '../dropdown/dropdown';
import Modal from '../modal/Modal';
import styles from './new-note.module.scss';
import { loading, notAsked, RemoteData } from '../../utils/remote-data';
import { Insights } from '../../interfaces/insight';
import { getInsights } from '../../services/api/insight';
import { Patient } from '../../interfaces/patient';
import { Loading } from '../common/loading';
import {
  generateVitalsSnapshotText,
  generateScoresSnapshotText,
  insightStringReplacement,
} from '../../utils/data-parser';
import { useToasts } from 'react-toast-notifications';
import { getPatientTimezone } from '../../contexts/PatientContext';

type Props = {
  onClose: any;
  patient: Patient | undefined;
  lastNote: any;
};

export const NewNoteModal: React.FC<Props> = ({ onClose, patient, lastNote }) => {
  const timezone = getPatientTimezone();
  const { addToast } = useToasts();
  const { practitioner } = useContext(PractitionerContext);
  const [insights, setInsights] = useState<RemoteData<Insights>>(notAsked());
  const [note, setNote] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange | undefined>(undefined);

  useEffect(() => {
    if (dateRangeFilter && patient) {
      setInsights(notAsked());
      getInsightsAction(
        patient.id,
        moment(dateRangeFilter.startDate).utc().format('YYYY-MM-DD'),
        moment(dateRangeFilter.endDate).format('YYYY-MM-DD'),
      );
    }
  }, [dateRangeFilter, patient]);

  useEffect(() => {
    if (insights.status === 'Done' && patient) {
      setNote(generateNoteWithInsights(insights.data, patient, timezone));
    }
  }, [insights]);

  useEffect(() => {
    if (lastNote && patient) {
      setDateRangeFilter({
        startDate: lastNote
          ? moment(lastNote.submittedTimestamp + 'Z').toDate()
          : startOfDay(new Date(patient.dateOfAdmission)),
        endDate: endOfDay(new Date()),
        key: 'selection',
        color: '#417EB9',
        showDateDisplay: true,
        autoFocus: true,
      });
    } else {
      setDateRangeFilter({
        startDate: startOfMonth(new Date()),
        endDate: endOfDay(new Date()),
        key: 'selection',
        color: '#417EB9',
        showDateDisplay: true,
        autoFocus: true,
      });
    }
  }, [lastNote]);

  const getInsightsAction = async (patientId: string, startDate: string, endDate: string): Promise<void> => {
    setInsights(loading());
    const res = await getInsights(patientId, {
      startDate,
      endDate,
      timezone,
    });
    setInsights(res);
  };

  const generateNoteWithInsights = (insights: Insights, patient: Patient, timezone: string): string => {
    let insightsNotes = '';
    insights.values.map(ins => {
      insightsNotes += `${insightStringReplacement(ins.title, ins, patient, timezone)} - Insight Recorded: ${moment(
        ins.generatedTimestamp,
      )
        .tz(timezone)
        .format('L')} at ${moment(ins.generatedTimestamp).tz(timezone).format('hh:mm A')}\n`;
      insightsNotes += `${insightStringReplacement(
        ins.description,
        ins,
        patient,
        timezone,
      )}\n${generateVitalsSnapshotText(ins)}\n${generateScoresSnapshotText(ins)}\n\nCOMMENT:\n\n\n`;
    });
    return insightsNotes;
  };

  const title = `NOTE FOR ${patient?.name?.first || ''} ${
    patient?.name?.last || ''
  } - NOTE GENERATED: ${moment().format('lll')}`;

  const submitNote = () => {
    if (patient && dateRangeFilter) {
      createNote(
        patient.id,
        title,
        note,
        moment(dateRangeFilter.startDate).utc().format().replace('Z', ''),
        moment(dateRangeFilter.endDate).utc().format().replace('Z', ''),
      )
        .then(res => onClose(res.data))
        .catch(err =>
          addToast({
            appearance: 'error',
            children: err.toString(),
          }),
        );
    }
  };

  const onDateChange = async (item: any): Promise<void> => {
    setDateRangeFilter(item.selection);
  };

  return (
    <Modal show={true} closeModal={onClose} contentStyle='bigContent__static'>
      <div className={styles.noteContainer}>
        <div className={styles.header}>
          <span className={styles.title}>{title}</span>
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='24' height='24' rx='3' fill='#417EB9' />
            <path
              d='M12.75 4C12.75 3.58579 12.4142 3.25 12 3.25C11.5858 3.25 11.25 3.58579 11.25 4L12.75 4ZM11.4697 15.7303C11.7626 16.0232 12.2374 16.0232 12.5303 15.7303L17.3033 10.9574C17.5962 10.6645 17.5962 10.1896 17.3033 9.8967C17.0104 9.60381 16.5355 9.60381 16.2426 9.8967L12 14.1393L7.75736 9.8967C7.46447 9.60381 6.98959 9.60381 6.6967 9.8967C6.40381 10.1896 6.40381 10.6645 6.6967 10.9574L11.4697 15.7303ZM11.25 4L11.25 15.2L12.75 15.2L12.75 4L11.25 4Z'
              fill='white'
            />
            <line x1='4.75' y1='18.45' x2='19.25' y2='18.45' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
          </svg>
        </div>
        <div className={styles.top}>
          <span>
            Client: {patient?.name?.first || ''} {patient?.name?.last || ''}
          </span>
          <span>Client Id: {patient?.emrIdentifier ?? '-'}</span>
          <span>
            Practitioner: {practitioner?.name?.first || ''} {practitioner?.name?.last || ''}
          </span>
          <span>
            <DropDown contentStyle='filter'>
              <span className={styles.dropdownButton}>
                {dateRangeFilter && moment(dateRangeFilter.startDate).format('MM/DD/YYYY')} -{' '}
                {dateRangeFilter && moment(dateRangeFilter.endDate).format('MM/DD/YYYY')}
                <i className={styles.arrow}>
                  <svg width='14' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M13 1L7 7L1 1' stroke='#417EB9' strokeWidth='1.5' strokeLinecap='round' />
                  </svg>
                </i>
              </span>
              <>
                <DatePicker
                  onDateSelectionChange={onDateChange}
                  dateRange={dateRangeFilter || {}}
                  parentStyle={styles.filterDatepicker}
                />
              </>
            </DropDown>
          </span>
        </div>
        <div className={styles.middle}>
          <div className={styles.title}>INSIGHTS DURING PERIOD</div>
          {insights.status === 'Done' ? (
            <div className={styles.content}>
              <textarea onChange={e => setNote(e.target.value)} value={note} />
            </div>
          ) : (
            <div className={styles.loadingContent}>
              <Loading />
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <span>
            <b>Acknowledgement:</b> By clicking “Save Note” I acknowledge that I have reviewed, understand and agree to
            the contents of this note and I have confirmed any and all data or information as being true and correct.
          </span>
          <button onClick={() => onClose(null)}>Cancel</button>
          <button onClick={() => submitNote()} className={styles.submitBtn}>
            Save Note
          </button>
        </div>
      </div>
    </Modal>
  );
};
