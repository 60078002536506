import React, { FunctionComponent } from 'react';
import styles from './bac-test-request.module.scss';
import { createSingleBacTest } from '../../../../services/api/bac';
import { ReactComponent as BacLogo } from '../../../../assets/icons/Illo_BAC.svg';
import { Patient } from '../../../../interfaces/patient';
import { useToasts } from 'react-toast-notifications';

type Props = {
  closeModal: () => void;
  patient: Patient;
};

const BacTestRequest: FunctionComponent<Props> = ({ closeModal, patient }) => {
  const { addToast } = useToasts();
  const submitReview = async (): Promise<void> => {
    try {
      await createSingleBacTest(patient.id, 5);
      closeModal();
      addToast('Bac test request sent successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <section className={styles.bacTestRequestModal}>
      <div className={styles.modalHeader}>
        <h4>SEND BAC TEST NOW</h4>
      </div>

      <section className={styles.bacTestRequestContainer}>
        <figure className={`${styles.bacImage}`}>
          <BacLogo />
        </figure>

        <div className={styles.mainText}>
          <p>
            Are you sure you want to send a BAC Test request to {patient?.name.first} {patient?.name.last} right now?
          </p>
          <h3>The BAC test will be sent out in 5 minutes.</h3>
        </div>

        <div className={`${styles.rowButtons}`}>
          <a className={styles.saveButton} onClick={submitReview}>
            Save
          </a>
          <a className={styles.cancelButton} onClick={closeModal}>
            Cancel
          </a>
        </div>
      </section>
    </section>
  );
};

export default BacTestRequest;
