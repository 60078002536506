import { gql } from '@apollo/client';

export const CLIENT_REPORT_FIELDS = gql`
  fragment clientStatsReportFields on OrganizationPeriodReport {
    activeClients
    pendingClients
    episodesOfCare {
      episodeOfCareType
      count
    }
    clientTelemetryCounts {
      name
      count
    }
  }
`;

export const PRACTITIONER_CLIENTS_ASSIGNED_FIELDS = gql`
  fragment clientsByPractitionerFields on PractitionerClientAssignment {
    count
    practitioner {
      id
      name {
        first
        last
        prefix
      }
    }
  }
`;
