import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  DraggableSyntheticListeners,
  defaultCoordinates,
  Translate,
  DndContext,
  DragMoveEvent,
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core';
import InsightTooltip from '../chart/insightTooltip';
import { Insight } from '../../../interfaces/insight';
import { Patient } from '../../../interfaces/patient';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';

export type InsightTooltipProps = {
  insight: Insight;
  insightList: Insight[];
  hide: () => void;
  patient: Patient | undefined;
  reverse: boolean;
  listeners?: DraggableSyntheticListeners;
};

const DraggableInsight: FunctionComponent<InsightTooltipProps & { translate?: Translate }> = ({ ...props }) => {
  // DnD hooks
  const [{ translate }, setTranslate] = useState<{
    initialTranslate: Translate;
    translate: Translate;
  }>({ initialTranslate: defaultCoordinates, translate: defaultCoordinates });
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const style: React.CSSProperties | undefined = translate
    ? {
        transform: `translate3d(${translate.x}px, ${translate.y}px, 0)`,
      }
    : undefined;

  useEffect(() => {
    setTranslate({ initialTranslate: defaultCoordinates, translate: defaultCoordinates });
  }, [props.insight]);

  const handleDragMove = (event: DragMoveEvent): void => {
    setTranslate(({ initialTranslate }) => ({
      initialTranslate,
      translate: {
        x: initialTranslate.x + event.delta.x,
        y: initialTranslate.y + event.delta.y - window.pageYOffset,
      },
    }));
  };

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToWindowEdges]}
      onDragMove={handleDragMove}
      onDragStart={() => {}}
      onDragEnd={() => {
        setTranslate(({ translate }) => {
          return {
            translate,
            initialTranslate: translate,
          };
        });
      }}
      onDragCancel={() => {
        setTranslate(({ initialTranslate }) => ({
          translate: initialTranslate,
          initialTranslate,
        }));
      }}
    >
      <InsightTooltip {...props} style={style} />
    </DndContext>
  );
};

export default DraggableInsight;
