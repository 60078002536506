import React, { useEffect, useState, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PractitionerContext } from '../contexts/Practitioner';

const PrivateRoute = props => {
  const { practitioner } = useContext(PractitionerContext);
  const [match, setMatch] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (practitioner) {
      setMatch(roleMatches(practitioner.roles.roles, props.accepted));
    }
  }, [practitioner, props.path]);

  const roleMatches = (roles, accepted): boolean => {
    let match = false;
    roles.map(r => {
      if (!match) {
        match = accepted.includes(r.code);
      }
    });
    return match;
  };

  if (match === undefined) return null;

  if (match) {
    return <Route {...props} />;
  } else {
    return <Redirect to='/' />;
  }
};

export default PrivateRoute;
