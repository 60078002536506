import React from 'react';
import { ReactComponent as BatteryLevel0_1 } from '../assets/icons/battery/icn_battery_0_1.svg';
import { ReactComponent as BatteryLevel2_10 } from '../assets/icons/battery/icn_battery_2_10.svg';
import { ReactComponent as BatteryLevel10_20 } from '../assets/icons/battery/icn_battery_10_20.svg';
import { ReactComponent as BatteryLevel20_40 } from '../assets/icons/battery/icn_battery_20_40.svg';
import { ReactComponent as BatteryLevel40_60 } from '../assets/icons/battery/icn_battery_40_60.svg';
import { ReactComponent as BatteryLevel60_80 } from '../assets/icons/battery/icn_battery_60_80.svg';
import { ReactComponent as BatteryLevel80_100 } from '../assets/icons/battery/icn_battery_80_100.svg';

const getPatientCardBatteryIcon = (batteryLevel: number): JSX.Element => {
  if (batteryLevel >= 80) {
    return <BatteryLevel80_100 />;
  } else if (batteryLevel >= 60) {
    return <BatteryLevel60_80 />;
  } else if (batteryLevel >= 40) {
    return <BatteryLevel40_60 />;
  } else if (batteryLevel >= 20) {
    return <BatteryLevel20_40 />;
  } else if (batteryLevel >= 10) {
    return <BatteryLevel10_20 />;
  } else if (batteryLevel >= 1) {
    return <BatteryLevel2_10 />;
  } else {
    return <BatteryLevel0_1 />;
  }
};

export { getPatientCardBatteryIcon };
