import React from 'react';

export const SpecialGeofence = props => {
  return (
    <svg
      style={props.style}
      xmlns='http://www.w3.org/2000/svg'
      width='196'
      height='13'
      fill='none'
      viewBox='0 0 196 13'
    >
      <path
        fill='#A3A6B3'
        d='M23.326 8.803c0-.375-.133-.664-.398-.867-.262-.204-.737-.409-1.424-.616-.688-.207-1.235-.437-1.64-.691-.778-.488-1.167-1.125-1.167-1.91 0-.688.28-1.254.838-1.7.563-.445 1.291-.667 2.186-.667.593 0 1.123.109 1.588.328.464.218.83.53 1.095.937.266.403.399.85.399 1.342h-1.477c0-.445-.14-.793-.422-1.043-.277-.254-.675-.38-1.195-.38-.484 0-.861.103-1.13.31-.267.207-.4.496-.4.867 0 .312.145.574.434.785.29.207.766.41 1.43.61.664.195 1.197.42 1.6.673.402.25.697.54.884.867.188.325.282.706.282 1.143 0 .711-.274 1.277-.82 1.7-.544.417-1.282.626-2.216.626a4.218 4.218 0 01-1.705-.34c-.515-.23-.918-.546-1.207-.949a2.37 2.37 0 01-.427-1.406h1.482c0 .484.16.86.48 1.125.32.265.78.398 1.377.398.516 0 .903-.103 1.16-.31.262-.211.393-.489.393-.832zm4.266-.973V11h-1.483V2.469h3.264c.953 0 1.709.248 2.268.744.562.496.843 1.152.843 1.969 0 .836-.275 1.486-.826 1.95-.547.466-1.314.698-2.303.698h-1.763zm0-1.19h1.781c.527 0 .93-.122 1.207-.369.277-.25.416-.609.416-1.078 0-.46-.14-.828-.422-1.101-.281-.278-.668-.42-1.16-.428h-1.822v2.977zm11.18.551h-3.504v2.625h4.095V11h-5.578V2.469h5.537v1.195h-4.054V6.02h3.504V7.19zm8.308 1.032c-.086.91-.422 1.62-1.008 2.132-.586.508-1.365.762-2.338.762-.68 0-1.279-.16-1.799-.48-.515-.325-.913-.783-1.195-1.377-.281-.594-.428-1.283-.44-2.069v-.796c0-.805.143-1.514.428-2.127.286-.614.694-1.086 1.225-1.418.535-.332 1.152-.498 1.852-.498.941 0 1.699.255 2.273.767.574.512.908 1.235 1.002 2.168h-1.477c-.07-.613-.25-1.055-.538-1.324-.286-.274-.706-.41-1.26-.41-.645 0-1.14.236-1.489.709-.343.468-.52 1.158-.527 2.068v.756c0 .922.164 1.625.492 2.11.332.484.817.726 1.453.726.582 0 1.02-.131 1.313-.393.293-.261.478-.697.556-1.306h1.477zM49.975 11h-1.477V2.469h1.477V11zm6.703-1.986h-3.305L52.682 11H51.14l3.222-8.531h1.33L58.922 11h-1.547l-.697-1.986zm-2.889-1.196h2.473L55.025 4.28 53.79 7.82zm7.588 1.998h3.873V11h-5.355V2.469h1.482v7.347z'
      />
      <path
        fill='#A3A6B3'
        stroke='#A3A6B3'
        strokeWidth='0.15'
        d='M9.7 11.967h0L6.629 9.865h0a.246.246 0 00-.128-.03.244.244 0 00-.128.03h0L3.3 11.966h0c-.221.152-.474.144-.612-.04-.09-.118-.1-.271-.053-.438 0 0 0 0 0 0l.928-3.28s0 0 0 0c.011-.04.01-.1-.007-.164a.341.341 0 00-.073-.148l-2.39-2.43A.545.545 0 01.93 5.16a.424.424 0 01.02-.207m8.75 7.013L1.396 4.732l-.007-.075s0 0 0 0a.544.544 0 00-.32.127.425.425 0 00-.118.17m8.75 7.013c.102.069.206.108.31.108.12 0 .23-.054.302-.148m-.611.04l-.263-3.76M.95 4.955l.07.026-.07-.026s0 0 0 0zm3.925-.748l1.27-2.93C6.253 1.026 6.429 1 6.5 1c.07 0 .246.027.354.275l1.271 2.931 3.925.75v-.001a.426.426 0 00-.118-.17.547.547 0 00-.32-.129h0L8.45 4.361h0a.327.327 0 01-.148-.064.326.326 0 01-.108-.12h0l-1.27-2.932h0A.54.54 0 006.7.98a.407.407 0 00-.2-.054.408.408 0 00-.2.054.54.54 0 00-.224.267h0l-1.27 2.93.069.03zm0 0l-.07-.03h0l.07.03zm4.563 4.002h0l.927 3.28s0 0 0 0c.048.167.037.32-.053.439m-.874-3.72a.338.338 0 01.006-.163m-.006.164l.006-.164m.868 3.883l-.06-.045.06.045s0 0 0 0zm-.868-3.883a.34.34 0 01.073-.148l-.073.148zm.073-.148l2.39-2.43-2.39 2.43zM6.971 9.362h0a.842.842 0 00-.471-.137.84.84 0 00-.472.137L3.35 11.195l.798-2.821h0a.992.992 0 00-.232-.904l-.054.053.054-.053L1.72 5.237l2.886-.27h0a.978.978 0 00.757-.549h0L6.5 1.798l1.136 2.62.069-.03-.07.03h0c.124.286.447.52.758.549l.007-.075-.007.075 2.887.27L9.083 7.47h0a.989.989 0 00-.23.904l.797 2.821-2.679-1.833z'
      />
    </svg>
  );
};

export default SpecialGeofence;
