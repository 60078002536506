import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Modal from '../../modal/Modal';
import moment from 'moment-timezone';
import { getPatientTimezone } from '../../../contexts/PatientContext';
import { Patient } from '../../../interfaces/patient';
import styles from '../survey.module.scss';
import { submitCowData } from '../../../services/api/survey';
import { auth } from '../../../firebase/firebase';
import { CowsSurvey } from 'interfaces/survey';
import { useToasts } from 'react-toast-notifications';

interface ClinicianCowsSurveyProps {
  patient: Patient | undefined;
  closeCowsSurvey: (close: boolean) => void;
}

const ClinicianCowsSurvey: React.FC<ClinicianCowsSurveyProps> = ({ patient, closeCowsSurvey }) => {
  const timezone = getPatientTimezone();
  const { addToast } = useToasts();

  const getInitials = (fullName: string) => {
    if (!fullName) return '';
    const names = fullName.split(' ');
    const firstInitial = names[0].charAt(0).toUpperCase();
    const lastInitial = names[names.length - 1].charAt(0).toUpperCase();
    return `${firstInitial}${lastInitial}`; // Returns initials like "JD" for "John Doe"
  };

  const initialValues: CowsSurvey = {
    patientName: `${patient?.name.first} ${patient?.name.last}` || '',
    patientId: patient?.id || '',
    date: moment().utc().format('YYYY-MM-DD'),
    time: moment().tz(timezone).format('hh:mm A'),
    reasonForAssessment: '',
    restingPulseRate: 0,
    giUpset: 0,
    sweating: 0,
    tremor: 0,
    restlessness: 0,
    yawning: 0,
    pupilSize: 0,
    anxietyOrIrritability: 0,
    boneOrJointAches: 0,
    goosefleshSkin: 0,
    runnyNoseOrTearing: 0,
    totalScore: 0,
    initialsOfFiller: `${(auth?.currentUser?.displayName || '').split(' ')[0].charAt(0).toUpperCase()}.${(
      auth?.currentUser?.displayName || ''
    )
      .split(' ')[1]
      .charAt(0)
      .toUpperCase()}`,
    clinicianEmail: auth.currentUser?.email || '',
  };

  const handleSubmit = async (values: CowsSurvey) => {
    const scoreFields = [
      'restingPulseRate',
      'giUpset',
      'sweating',
      'tremor',
      'restlessness',
      'yawning',
      'pupilSize',
      'anxietyOrIrritability',
      'boneOrJointAches',
      'goosefleshSkin',
      'runnyNoseOrTearing',
    ];

    // Convert all relevant fields to numbers and calculate total score
    values.totalScore = scoreFields.reduce((total, field) => {
      values[field] = Number(values[field]);
      return total + values[field];
    }, 0);

    try {
      await submitCowData(patient?.firebaseUid || '', values);
      addToast(`You have successfully submited the Cows Survey`, {
        appearance: 'success',
        autoDismiss: true,
      });
      closeCowsSurvey(false);
    } catch (error) {
      addToast(`${error}`, {
        appearance: 'success',
        autoDismiss: true,
      });
    }
  };

  return (
    <Modal show={true} closeModal={() => console.log('close')} showBtnClose={false} contentStyle='bigContent__static'>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} style={{ height: '500px' }}>
        {({ values, setFieldValue }) => {
          useEffect(() => {
            const scoreFields = [
              'restingPulseRate',
              'giUpset',
              'sweating',
              'tremor',
              'restlessness',
              'yawning',
              'pupilSize',
              'anxietyOrIrritability',
              'boneOrJointAches',
              'goosefleshSkin',
              'runnyNoseOrTearing',
            ];

            const totalScore = scoreFields.reduce((total, field) => {
              return total + Number(values[field]);
            }, 0);

            setFieldValue('totalScore', totalScore);
          }, [
            values.restingPulseRate,
            values.giUpset,
            values.sweating,
            values.tremor,
            values.restlessness,
            values.yawning,
            values.pupilSize,
            values.anxietyOrIrritability,
            values.boneOrJointAches,
            values.goosefleshSkin,
            values.runnyNoseOrTearing,
          ]);
          return (
            <Form style={{ padding: 20 }}>
              <h2>Clinical Opiod Withdrawal Scale (COWS)</h2>
              <div className={styles.hr}></div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '65%',
                  alignContent: 'center',
                  marginTop: 30,
                }}
              >
                <div style={{ display: 'flex' }}>
                  <label style={{ marginRight: 10 }}>Patient Name:</label>
                  <div style={{ backgroundColor: 'lightgray', padding: '.5px 5px', fontSize: '12px' }}>
                    {patient?.name.first} {patient?.name.last}
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <label style={{ marginRight: 10 }}>Date:</label>
                  <div style={{ backgroundColor: 'lightgray', padding: '.5px 5px', fontSize: '12px' }}>
                    {moment().utc().format('MM-DD-YYYY')}
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <label style={{ marginRight: 10 }}>Time:</label>
                  <div style={{ backgroundColor: 'lightgray', padding: '.5px 5px', fontSize: '12px' }}>
                    {moment().tz(timezone).format('hh:mm A')}
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 20, display: 'flex' }}>
                <label style={{ marginRight: 10, marginBottom: 5 }}>Reason for Assessment:</label>
                <Field
                  rows={5}
                  style={{
                    backgroundColor: 'lightgray',
                    padding: '.5px 5px',
                    fontSize: '12px',
                    border: 'none',
                    width: '50%',
                    resize: 'none',
                  }}
                  name='reasonForAssessment'
                  placeholder='Enter reason for assessment'
                  required
                />
              </div>
              {/* questions */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  maxHeight: '325px',
                  overflowY: 'auto',
                  padding: '10px',
                  marginTop: 20,
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '250px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>
                      Resting Pulse Rate: (beats/minute) <br />
                      Measured after patient is sitting or lying for one minute
                    </h4>
                    <div style={{ marginTop: 50, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '4'].map((value, index) => (
                        <label key={index} htmlFor={`pulseRate${value}`}>
                          <Field type='radio' id={`pulseRate${value}`} name='restingPulseRate' value={value} required />
                          {value === '0'
                            ? '0 pulse rate 80 or below'
                            : value === '1'
                            ? '1 pulse rate 81 - 100'
                            : value === '2'
                            ? '2 pulse rate 101 - 120'
                            : '4 pulse rate greater than 120'}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '250px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>
                      Sweating: Over past 1/2 hour not accounted for by room temperature or patient activity. <br />
                    </h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '3', '4'].map((value, index) => (
                        <label key={index} htmlFor={`sweating${value}`}>
                          <Field type='radio' id={`sweating${value}`} name='sweating' value={value} required />
                          {value === '0'
                            ? '0 no report of chills or flushing'
                            : value === '1'
                            ? '1 subjective report of chills or flushing'
                            : value === '2'
                            ? '2 flushed or observable moistness on face'
                            : value === '3'
                            ? '3 beads of sweat on brow or face'
                            : '4 sweat streaming off face'}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '250px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>Restlessness: Observation during assessment</h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '3', '5'].map((value, index) => (
                        <label key={index} htmlFor={`restlessness${value}`}>
                          <Field type='radio' id={`restlessness${value}`} name='restlessness' value={value} required />
                          {value === '0'
                            ? '0 able to sit still'
                            : value === '1'
                            ? '1 reports difficulty sitting still, but is able to do so'
                            : value === '3'
                            ? '3 frequent shifting or extraneous movements of legs/arms'
                            : '5 unable to sit still for more than a few seconds'}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '250px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>Pupil Size</h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '5'].map((value, index) => (
                        <label key={index} htmlFor={`pupilSize${value}`}>
                          <Field type='radio' id={`pupilSize${value}`} name='pupilSize' value={value} required />
                          {value === '0'
                            ? '0 pupils pinned or normal size for room light'
                            : value === '1'
                            ? '1 pupils possibly larger than normal for room light'
                            : value === '2'
                            ? '2 pupils moderately dilated'
                            : '5 pupils so dilated that only the rim of the iris is visible'}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '250px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>
                      Bone or Joint Aches (if patient was having pain previously, only the additional component
                      attributed to opiate withdrawal is scored)
                    </h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '4'].map((value, index) => (
                        <label key={index} htmlFor={`boneJointAches${value}`}>
                          <Field type='radio' id={`boneJointAches${value}`} name='boneOrJointAches' value={value} required />
                          {value === '0'
                            ? '0 not present'
                            : value === '1'
                            ? '1 mild diffuse discomfort'
                            : value === '2'
                            ? '2 patient reports severe diffuse aching of joints/muscles'
                            : '4 patient is rubbing joints or muscles and is unable to sit still because of discomfort'}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '250px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>
                      Runny Nose or Tearing (not accounted for by cold symptoms or allergies)
                    </h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '4'].map((value, index) => (
                        <label key={index} htmlFor={`runnyNose${value}`}>
                          <Field type='radio' id={`runnyNose${value}`} name='runnyNoseOrTearing' value={value} required />
                          {value === '0'
                            ? '0 not present'
                            : value === '1'
                            ? '1 nasal stuffiness or unusually moist eyes'
                            : value === '2'
                            ? '2 nose running or tearing'
                            : '4 nose constantly running or tears streaming down cheeks'}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '250px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>GI Upset: (over last 1/2 hour)</h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '3', '5'].map((value, index) => (
                        <label key={index} htmlFor={`giUpset${value}`}>
                          <Field type='radio' id={`giUpset${value}`} name='giUpset' value={value} required />
                          {value === '0'
                            ? '0 no GI symptoms'
                            : value === '1'
                            ? '1 stomach cramps'
                            : value === '2'
                            ? '2 nausea or loose stool'
                            : value === '3'
                            ? '3 vomiting or diarrhea'
                            : '5 multiple episodes of diarrhea or vomiting'}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '250px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>Tremor: (observation of outstretched hands)</h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '4'].map((value, index) => (
                        <label key={index} htmlFor={`tremor${value}`}>
                          <Field type='radio' id={`tremor${value}`} name='tremor' value={value} required />
                          {value === '0'
                            ? '0 no tremor'
                            : value === '1'
                            ? '1 tremor can be felt, but not observed'
                            : value === '2'
                            ? '2 slight tremor observable'
                            : '4 gross tremor or muscle twitching'}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '250px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>Yawning: (observation during assessment)</h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '4'].map((value, index) => (
                        <label key={index} htmlFor={`yawning${value}`}>
                          <Field type='radio' id={`yawning${value}`} name='yawning' value={value} required />
                          {value === '0'
                            ? '0 no yawning'
                            : value === '1'
                            ? '1 yawning once or twice during assessment'
                            : value === '2'
                            ? '2 yawning three or more times during assessment'
                            : '4 yawning several times/minute'}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '250px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>Anxiety or Irritability</h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '4'].map((value, index) => (
                        <label key={index} htmlFor={`anxiety${value}`}>
                          <Field type='radio' id={`anxiety${value}`} name='anxietyOrIrritability' value={value} required />
                          {value === '0'
                            ? '0 none'
                            : value === '1'
                            ? '1 patient reports increasing irritability or anxiousness'
                            : value === '2'
                            ? '2 patient obviously irritable or anxious'
                            : '4 patient so irritable or anxious that participation in the assessment is difficult'}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '250px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>Gooseflesh Skin</h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '3', '5'].map((value, index) => (
                        <label key={index} htmlFor={`gooseflesh${value}`}>
                          <Field type='radio' id={`gooseflesh${value}`} name='goosefleshSkin' value={value} required />
                          {value === '0'
                            ? '0 skin is smooth'
                            : value === '3'
                            ? '3 piloerection of skin can be felt or hairs standing up on arms'
                            : '5 prominent piloerection'}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '250px',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <label style={{ marginRight: 10 }}>Total Score:</label>
                      <div
                        style={{ backgroundColor: 'lightgray', padding: '.5px 5px', fontSize: '12px', width: '100px' }}
                      >
                        {values.totalScore}
                      </div>
                    </div>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      <p>The total score is the sum of all 11 items</p>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <label style={{ marginRight: 10 }}>Initials of person completing assessment:</label>
                      <div
                        style={{ backgroundColor: 'lightgray', padding: '.5px 5px', fontSize: '12px', width: '100px' }}
                      >
                        {(auth?.currentUser?.displayName || '').split(' ')[0].charAt(0).toUpperCase()}.
                        {(auth?.currentUser?.displayName || '').split(' ')[1].charAt(0).toUpperCase()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 100, marginTop: 25 }}>
                Score: <br /> 5 - 12 = mild; 13 - 24 = moderate; <br />
                25-36 = moderately severe; <br />
                more than 36 = severe withdrawal
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 100, marginTop: 25 }}>
                <button className={styles.closeBtn} onClick={() => closeCowsSurvey(false)} type='button'>
                  Cancel
                </button>
                <button className={styles.nextBtn} type='submit'>
                  Save
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ClinicianCowsSurvey;
