import { gql } from '@apollo/client';

export const GET_AVAILABLE_QUESTIONNAIRES = gql`
  query AvailableQuestionnaires {
    questionnaires {
      id
      name
      version
      clinicName
      groupName
      description
    }
  }
`;

export const GET_QUESTIONNAIRE_SCHEDULES_OF_USER = gql`
  query QuestionnaireSchedulesOfUser($fbId: String!) {
    questionnaireSchedulesOfUser(fbUserId: $fbId) {
      scheduleId
      name
      patientFbUserId
      cron
      cronDescription
      repeat {
        at
        repeatOn
        untilWeeks
        until
        frequencyName
        dayOfMonth
        frequencyDisplayName
        frequencyDescription
      }
      questionnaireName
      questionnaire {
        id
        clinicName
      }
      notificationTitle
      notificationMessage
      timezoneOffset
      created
      createdBy
      modified
      modifiedBy
      endDate
    }
  }
`;
