import React, { useContext, Suspense, lazy } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.scss';
import { Admin } from './components/admin';
import { AdminClients } from './components/admin-clients';
import { AdminDashboard } from './components/admin-dashboard';
import { AdminDevices } from './components/admin-devices';
import { AdminPractitioners } from './components/admin-practitioners';
import { Login } from './components/auth/Login';
import ComingSoon from './components/common/coming-soon/coming-soon';
import { Loading } from './components/common/loading';
import { Compliance } from './components/compliance';
import BacTest from './components/compliance/bac/bac-test';
import SurveyAdmin from './components/surveys/survey-admin';
import Geofence from './components/compliance/geofence/geofence';
import Locations from './components/compliance/locations/locations';
import Meetings from './components/compliance/meetings/meetings';
import { Home } from './components/home';
import { Insights } from './components/insights';
import { Notes } from './components/notes/notes';
import { PatientDetail } from './components/patient-detail';
import { PatientHome } from './components/patient-detail/patient-home';
import { TopNavWithProfile } from './components/topnav';
import { Patient } from './contexts/PatientContext';
import { Practitioner, PractitionerContext } from './contexts/Practitioner';
import { PatientsContextProvider } from './contexts/PractitionerPatientsContext';
import { UserContext } from './contexts/User';
import { ROLE_CODES } from './utils/constants';


import CustomRedirect from './utils/custom-redirect';
import PrivateRoute from './utils/private-route';
import AdminAlumni from './components/admin-alumni/admin-alumni';
import VerifyPatientCode from './components/verify-patient-code';
import Aftercare from './components/aftercare/aftercare';
import { AftercarePatientsContextProvider } from './contexts/AftercarePatientsContext';
import RecoveryMilestone from './components/compliance/ricovery-milestone/recovery-milestone';
import { OrganizationContextProvider } from './contexts/OrganizationContext';

const InternalAdmin = lazy(() => import('./components/internal-admin'));
const Policy = lazy(() => import('./components/policy/policy'));
const UtilizationReview = lazy(() => import('./components/utilization-review/utilization-review'));

const App = () => {
  const { user, initializing } = useContext(UserContext);
  if (initializing) {
    return <Loading style={{ position: 'absolute', top: '50%' }} />;
  }
  if (user?.email === 'david.lesnefsky@recoveryally.com') {
    return (
      <BrowserRouter>
        <Suspense fallback={<Loading style={{ position: 'absolute', top: '50%' }} />}>
          <Switch>
            <Route exact path='/internal-admin' component={InternalAdmin} />
            <Redirect to='/internal-admin' from='/' />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
  if (user) {
    return (
      <Practitioner>
        <ParentContainer />
      </Practitioner>
    );
  }
  const path = window.location.pathname;
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/login' component={Login} />
        <Redirect push from='/' to={{ pathname: '/login', state: { loginReferrer: path } }} />
      </Switch>
    </BrowserRouter>
  );
};

const ParentContainer = () => {
  const { practitioner, reloadPractitioner } = useContext(PractitionerContext);
  if (!practitioner) {
    reloadPractitioner();
    return null;
  }
  if (!practitioner.acceptedTermsOfUse) {
    return (
      <BrowserRouter>
        <Suspense fallback={<Loading style={{ position: 'absolute', top: '50%' }} />}>
          <Switch>
            <Route exact path='/clinician-agreement' component={Policy} />
            <Redirect to='/clinician-agreement' />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading style={{ position: 'absolute', top: '50%' }} />}>
        <TopNavWithProfile />
        <PatientsContextProvider>
          <AftercarePatientsContextProvider>
            <Switch>
              <PrivateRoute
                path='/patients/:id'
                render={() => (
                  <Patient>
                    <PatientHome />
                  </Patient>
                )}
                accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]}
              />
              <PrivateRoute path='/admin' component={Admin} accepted={[ROLE_CODES.ADMIN]} />
              <PrivateRoute path='/aftercare' component={Aftercare} accepted={[ROLE_CODES.AFTERCARE]} />
              <PrivateRoute path='/home' component={Home} accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]} />
              <Route path='/u/:code'>
                <VerifyPatientCode />
              </Route>
              <CustomRedirect from='/' />
            </Switch>
          </AftercarePatientsContextProvider>
        </PatientsContextProvider>
      </Suspense>
    </BrowserRouter>
  );
};

export const PatientRoutes = (
  <Switch>
    <Redirect exact from='/patients/:id' to='/patients/:id/details' />
    <PrivateRoute
      path='/patients/:id/details'
      component={PatientDetail}
      accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]}
    />
    <PrivateRoute
      path='/patients/:id/compliance'
      accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]}
      component={Compliance}
    />
    <PrivateRoute path='/patients/:id/notes' component={Notes} accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]} />
    <PrivateRoute
      path='/patients/:id/utilization'
      component={(): JSX.Element => <UtilizationReview />}
      accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]}
    />
    <PrivateRoute
      path='/patients/:id/reports'
      component={(): JSX.Element => <ComingSoon featureName='Reports' />}
      accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]}
    />
    <PrivateRoute
      path='/patients/:id/rx'
      component={(): JSX.Element => <ComingSoon featureName='RX' />}
      accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]}
    />
    <PrivateRoute
      path='/patients/:id/survey'
      component={SurveyAdmin}
      accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]}
    />
    <PrivateRoute
      path='/patients/:id/insights'
      component={Insights}
      accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]}
    />
  </Switch>
);

export const AdminRoutes = (
  <Switch>
    <PrivateRoute path='/admin/clients' accepted={[ROLE_CODES.ADMIN]} component={AdminClients} />
    <PrivateRoute path='/admin/alumni' accepted={[ROLE_CODES.ADMIN]} component={AdminAlumni} />
    <PrivateRoute path='/admin/devices' accepted={[ROLE_CODES.ADMIN]} component={AdminDevices} />
    <PrivateRoute path='/admin/practitioners' accepted={[ROLE_CODES.ADMIN]} component={AdminPractitioners} />
    <OrganizationContextProvider>
      <PrivateRoute path='/admin/dashboard' accepted={[ROLE_CODES.ADMIN]} component={AdminDashboard} />
    </OrganizationContextProvider>
    <Redirect exact from='/admin' to='/admin/clients' />
  </Switch>
);

export const ComplianceRoutes: any = ({ path }) => (
  <Switch>
    <Redirect exact from={`${path}`} to={`${path}/bac`} />
    <PrivateRoute path={`${path}/bac`} component={BacTest} accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]} />
    <PrivateRoute
      path={`${path}/drug`}
      component={(): JSX.Element => <ComingSoon featureName='Drug test' />}
      accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]}
    />
    <PrivateRoute path={`${path}/meetings`} component={Meetings} accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]} />
    <PrivateRoute path={`${path}/geo`} component={Geofence} accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]} />
    <PrivateRoute
      path={`${path}/locations`}
      component={Locations}
      accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]}
    />
    <PrivateRoute
      path={`${path}/recovery`}
      component={RecoveryMilestone}
      accepted={[ROLE_CODES.ADMIN, ROLE_CODES.CLINICIAN]}
    />
  </Switch>
);
export default App;
