import { AxiosResponse } from 'axios';
import { CreatePatientRequest, Patient, Patients } from '../../interfaces/patient';
import { done, Done, failed, Failed, RemoteData } from '../../utils/remote-data';
import { destroy, get, post, put } from './base';
import { Allies } from '../../interfaces/common';
import { UserMobilePermissionsHistory } from '../../interfaces/user-mobile-permissions-history';
import { castDateTimeFilters, DateTimeFiltersRequest } from '../../utils/requestHelper';

export type ReadmitPatientRequest = {
  episodeOfCareType: string;
  practitionerIdList?: number[];
  organizationId: string;
};

export type DischargePatientRequest = {
  episodeOfCareType: string;
  reason: string;
  unassignDevices?: {
    name: string | null;
    condition: string;
  }[];
  unassignedDeviceReason?: string;
  unnasignBac?: string;
};

export const getPatientById = async (patientId: string, detailed?: boolean): Promise<AxiosResponse<Patient>> => {
  return await get(`/patients/${patientId}${detailed ? '?detailed=true' : ''}`);
};

export const getPatientDetails = async (patientId: string): Promise<Done<Patient> | Failed> => {
  try {
    const res = await get(`/patients/${patientId}?detailed=true`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getPatientByShortCode = async (code: string): Promise<RemoteData<Partial<{ patientId: string }>>> => {
  try {
    const res = await get(`/u/${code}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getPatientProfileImage = async (
  patientId: string,
  profileImageId: string,
): Promise<AxiosResponse<{ base64: string }>> => {
  return await get(`/patients/${patientId}/profile-image/${profileImageId}`);
};

export const getPatients = async (
  organizationId: string | undefined,
  active: boolean = true,
): Promise<RemoteData<Patients>> => {
  try {
    const res = await get(`/patients?organizationId=${organizationId}&active=${active}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getPractitionerPatients = async (
  practitionerId: string | undefined,
  view?: string,
  returnAll?: boolean,
): Promise<RemoteData<Patients>> => {
  if (practitionerId) {
    try {
      const res = await get(
        `/patients?practitionerId=${practitionerId}${view ? '&' + view + '=true' : ''}${
          returnAll ? '&returnAll=false' : ''
        }`,
      );
      return done(res.data);
    } catch (err) {
      return failed(err);
    }
  } else {
    return failed({
      status: 0,
      title: 'Invalid Operation',
      message: 'Practitioner Id is required.',
    });
  }
};

export const createPatient = async (patient: CreatePatientRequest): Promise<Done<any> | Failed> => {
  try {
    const res = await post('/patients', patient);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getPatientAllies = async (patientId: string): Promise<RemoteData<Allies>> => {
  if (patientId) {
    try {
      const res = await get(`/patients/${patientId}/circle-interactions`);
      return done(res.data);
    } catch (err) {
      return failed(err);
    }
  } else {
    return failed({
      status: 0,
      title: 'Invalid Operation',
      message: 'Client Id is required.',
    });
  }
};

export const updatePatient = async (patientId: string, patientUpdated: CreatePatientRequest): Promise<any> => {
  if (patientId) {
    try {
      const res = await put(`/patients/${patientId}`, patientUpdated);
      return done(res.data);
    } catch (err) {
      return failed(err);
    }
  } else {
    return failed({
      status: 0,
      title: 'Invalid Operation',
      message: 'Client Id is required.',
    });
  }
};

export const updatePatientEmail = async (patientId: string, email: string): Promise<Done<any> | Failed> => {
  try {
    const res = await put(`/patients/${patientId}/email`, { email });
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const dischargePatient = async (
  patientId: string,
  dischargePayload: DischargePatientRequest,
): Promise<Done<any> | Failed> => {
  try {
    const res = await post(
      `/patients/${patientId}/discharge?episodeOfCareType=${dischargePayload.episodeOfCareType}`,
      dischargePayload,
    );
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const removePatient = async (patientId: string): Promise<Done<any> | Failed> => {
  try {
    const res = await destroy(`/patients/${patientId}`);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const readmitPatient = async (
  patientId: string,
  readmitPatientPayload: ReadmitPatientRequest,
): Promise<Done<any> | Failed> => {
  try {
    const res = await post(`/patients/${patientId}/admit`, readmitPatientPayload);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const getPatientPermissionsHistory = async (
  firebaseUId: string,
  dateFilters: DateTimeFiltersRequest,
): Promise<RemoteData<UserMobilePermissionsHistory[]>> => {
  if (firebaseUId) {
    try {
      const filters = castDateTimeFilters(dateFilters);
      const res = await get(`/user/mobile-permissions/${firebaseUId}`, {
        params: {
          start: filters.startDateTime,
          end: filters.endDateTime,
        },
      });
      return done(res.data);
    } catch (err) {
      return failed(err);
    }
  } else {
    return failed({
      status: 0,
      title: 'Invalid Operation',
      message: 'Client Id is required.',
    });
  }
};
