import React, { Dispatch, SetStateAction } from 'react';
import styles from '../admin-dashboard.module.scss';
import PinSpotIcon from '../../../assets/icons/pin_spot.svg';
import PersonIcon from '../../../assets/icons/person_gray.svg';
import { Organization } from '../../../graphql/codegen/schemas';
import { EXTERNAL_LINKS } from '../../../utils/constants';

type Props = {
  setShowEditModal: Dispatch<SetStateAction<boolean>>;
  organization: Organization;
};

const ClinicDetails: React.FC<Props> = ({ setShowEditModal, organization }) => {
  const openAddressOnGoogleMaps = () => {
    const addressURI = encodeURI(
      `${organization?.contact?.address?.line1}, ${organization?.contact?.address?.city}, ${organization?.contact?.address?.state}`,
    );
    const googleMapsURL = `${EXTERNAL_LINKS.GOOGLE_MAPS_QUERY}${addressURI}`;
    window.open(googleMapsURL);
  };

  return (
    <div className={styles.clinicDetails}>
      <div className={styles.topSection}>
        <span className={styles.header}>
          CLINIC DETAILS:
        <span className={styles.edit} onClick={() => setShowEditModal(true)}>
          Edit
        </span>
        </span>
      </div>
      <div className={styles.body}>
        <div className={styles.location}>
          <img src={PinSpotIcon} />
          <div className={styles.description}>
            <span className={styles.name}>{organization.name}</span>
            <span className={styles.phone}>{organization?.contact?.phone}</span>
            <div className={styles.address} onClick={() => openAddressOnGoogleMaps()}>
              <span>{organization?.contact?.address?.line1},</span>
              <span>{organization?.contact?.address?.line2},</span>
              <span>{`${organization?.contact?.address?.city}, ${organization.contact?.address?.state} ${organization.contact?.address?.postalCode}`}</span>
            </div>
          </div>
        </div>
        <div className={styles.contact}>
          <img src={PersonIcon} />
          <div className={styles.description}>
            <span>{`${organization?.primaryContact?.name?.first} ${organization?.primaryContact?.name?.last}`}</span>
            <span>{organization?.primaryContact?.phone}</span>
            <span>{organization?.primaryContact?.email}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicDetails;
