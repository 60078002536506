import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useToasts } from 'react-toast-notifications';
import { addPractitionerValidationSchema, SMSNotificationsValidationSchema } from '../../utils/form-validations';
import Modal from '../modal/Modal';
import styles from './admin-practitioners.module.scss';
import { CreatePractitionerRequest, Practitioner } from '../../interfaces/practitioner';
import { Done, Failed, RemoteData } from '../../utils/remote-data';
import { Loading } from '../common/loading';
import { Patients, Patient } from '../../interfaces/patient';
import ActiveClientsTable from './active-clients-table';
import RemoveClient from './remove-client';
import {
  removePractitionerCare,
  storeSMSConfiguration,
  sendSMSVerificationCode,
  resendSMSVerificationCode,
  deleteSMSConfiguration,
  editSMSConfiguration,
} from '../../services/api/practitioner';
import PractitionerFields from './practitioner-fields';
import SMSNotifications from './sms-notifications-form';
import { SMSConfig } from '../../interfaces/sms-config';
import VerifyPhone from './verify-phone';
import { SMS_NOTIFICATION_OPTIONS, CODE_VERIFICATION_STATUS, ROLE_CODES } from '../../utils/constants';

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  setShowRemovePractitioner?: Dispatch<SetStateAction<boolean>>;
  createPractitioner: (practitioner: CreatePractitionerRequest) => Promise<Done<Practitioner> | Failed>;
  editPractitioner: (
    practitioner: CreatePractitionerRequest,
    practitionerId: string | undefined,
  ) => Promise<Done<any> | Failed>;
  organizationId: string | undefined;
  getPractitionersAction?: () => Promise<void>;
  getPractitionerPatientsAction: () => Promise<void>;
  editMode: boolean;
  selectedPractitioner?: Practitioner | undefined;
  isAuthenticatedPractitionerProfile: boolean;
  selectedPractitionerPatients: RemoteData<Patients>;
  showRemovePractitionerButton: boolean;
  smsConfig: RemoteData<SMSConfig>;
  getSMSConfigurationAction: () => Promise<void>;
};

const UpsertPractitionerForm: React.FC<Props> = ({
  setShow,
  createPractitioner,
  editPractitioner,
  organizationId,
  getPractitionersAction,
  editMode,
  selectedPractitioner,
  setShowRemovePractitioner,
  selectedPractitionerPatients,
  getPractitionerPatientsAction,
  showRemovePractitionerButton,
  smsConfig,
  getSMSConfigurationAction,
  isAuthenticatedPractitionerProfile,
}) => {
  const VIEWS = {
    DETAILS: 'details',
    ACTIVE_CLIENTS: 'active-clients',
    SMS_NOTIFICATIONS: 'sms-notifications',
  };
  const { addToast } = useToasts();
  const [image, setImage] = useState<File | string | undefined>(undefined);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [selectedView, setSelectedView] = useState<string>(VIEWS.DETAILS);
  const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>(undefined);
  const [showVerifyPhone, setShowVerifyPhone] = useState<boolean>(false);
  const [codeVerificationStatus, setCodeVerificationStatus] = useState<string>(CODE_VERIFICATION_STATUS.NOT_SENT);
  const [enableVerify, setEnableVerify] = useState<boolean>(true);
  const [enableResend, setEnableResend] = useState<boolean>(true);

  useEffect(() => {
    if (editMode && selectedPractitioner) {
      setImage(selectedPractitioner.image?.base64);
    }
  }, [selectedPractitioner, editMode]);

  useEffect(() => {
    if (smsConfig.status === 'Loading') {
      setShowLoading(true);
    } else {
      setShowLoading(false);
    }
  }, [smsConfig]);

  const handleCreatePractitioner = async formData => {
    setShowLoading(true);

    let data = { ...formData, organizationId };

    if (editMode) {
      data = { ...data, imageUpdated: false };
      if (image !== selectedPractitioner?.image?.base64) {
        if (image === undefined) {
          data = { ...data, image: null, imageUpdated: true };
        } else {
          data = { ...data, image, imageUpdated: true };
        }
      }
    } else if (image) {
      data = { ...data, image };
    }

    const result = editMode ? await editPractitioner(data, selectedPractitioner?.id) : await createPractitioner(data);

    setShowLoading(false);

    if (result.status === 'Done') {
      setShow(false);
      getPractitionersAction && getPractitionersAction();
      addToast(`Practitioner ${editMode ? 'updated' : 'added'} successfully`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast(`Error: ${result.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const handleSaveSMSConfiguration = async formData => {
    setShowLoading(true);
    const {
      sms,
      timezone,
      weekDays,
      startWindow,
      endWindow,
      notificationOptions,
      active,
      verified,
      hasPreviousConfig,
    } = formData;

    if (!active) {
      const result = await deleteSMSConfiguration();

      if (result.status === 'Done') {
        addToast('SMS Configuration deleted successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        addToast(`Error: ${result.errorApi.error}. ${result.errorApi.message}`, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } else {
      const smsConfigForm: SMSConfig = {
        sms,
        timezone,
        weekDays,
        startWindow,
        endWindow,
        notificationOptions: Object.keys(notificationOptions).filter(opt => notificationOptions[opt] === true),
        verified: !hasPreviousConfig,
      };

      const result = hasPreviousConfig
        ? await editSMSConfiguration(smsConfigForm)
        : await storeSMSConfiguration(smsConfigForm);

      if (result.status === 'Done') {
        if (!verified) {
          setShowVerifyPhone(true);
        }

        addToast('SMS configuration updated successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        addToast(`Error: ${result.errorApi.error}. ${result.errorApi.message}`, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
    setShowLoading(false);
    getSMSConfigurationAction();
  };

  const handleVerifyCode = async (code: string) => {
    setEnableVerify(false);
    const result = await sendSMSVerificationCode(code);

    if (result.status === 'Done') {
      addToast('Code verified successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      setCodeVerificationStatus(CODE_VERIFICATION_STATUS.SUCCESS);
      setTimeout(() => {
        setShowVerifyPhone(false);
        setCodeVerificationStatus(CODE_VERIFICATION_STATUS.NOT_SENT);
        setEnableVerify(true);
      }, 3000);
    } else {
      addToast(`Error: ${result.errorApi.error}. ${result.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
      setCodeVerificationStatus(CODE_VERIFICATION_STATUS.FAILED);
      setEnableVerify(true);
    }
  };

  const handleResendCode = () => {
    setEnableResend(false);
    resendSMSVerificationCode();
    setTimeout(() => {
      setEnableResend(true);
    }, 2000);
  };

  const addPractitionerInitialValues = {
    prefix: 'Dr.',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    admin: false,
    clinician: false,
    aftercare: false,
  };

  const editPractitionerInitialValues = {
    prefix: selectedPractitioner?.name.prefix ?? '',
    firstName: selectedPractitioner?.name.first,
    lastName: selectedPractitioner?.name.last,
    email: selectedPractitioner?.contact.email,
    phone: selectedPractitioner?.contact.cellphone,
    admin: !!selectedPractitioner?.roles.roles.find(r => r.code === ROLE_CODES.ADMIN),
    clinician: !!selectedPractitioner?.roles.roles.find(r => r.code === ROLE_CODES.CLINICIAN),
    aftercare: !!selectedPractitioner?.roles.roles.find(r => r.code === ROLE_CODES.AFTERCARE),
  };

  const editSMSNotificationsInitialValues =
    smsConfig.status === 'Done'
      ? {
          sms: smsConfig.data.sms ?? selectedPractitioner?.contact.cellphone,
          timezone: smsConfig.data.timezone ?? selectedPractitioner?.organization.settings?.timezone ?? 'CST',
          verified: smsConfig.data.verified ?? false,
          notificationOptions: smsConfig.data.notificationOptions
            ? {
                INSIGHT_ONLY_ALERT: !!smsConfig.data.notificationOptions.find(
                  el => el === SMS_NOTIFICATION_OPTIONS.INSIGHT_ONLY_ALERT,
                ),
                INSIGHT_ALL_NEGATIVE: !!smsConfig.data.notificationOptions.find(
                  el => el === SMS_NOTIFICATION_OPTIONS.INSIGHT_ALL_NEGATIVE,
                ),
                INSIGHT_ALL_POSITIVE: !!smsConfig.data.notificationOptions.find(
                  el => el === SMS_NOTIFICATION_OPTIONS.INSIGHT_ALL_POSITIVE,
                ),
                INSIGHT_ONLY_BAC: !!smsConfig.data.notificationOptions.find(
                  el => el === SMS_NOTIFICATION_OPTIONS.INSIGHT_ONLY_BAC,
                ),
              }
            : {
                INSIGHT_ONLY_ALERT: true,
                INSIGHT_ALL_NEGATIVE: false,
                INSIGHT_ALL_POSITIVE: false,
                INSIGHT_ONLY_BAC: false,
              },
          weekDays: smsConfig.data.weekDays ?? 'MTWRF',
          startWindow: smsConfig.data.startWindow ?? '08:00',
          endWindow: smsConfig.data.endWindow ?? '18:00',
          active: smsConfig.data.active ?? false,
          hasPreviousConfig: !!smsConfig.data,
        }
      : {
          timezone: 'CST',
          weekDays: 'MTWRF',
          startWindow: '08:00',
          endWindow: '18:00',
          active: false,
          hasPreviousConfig: false,
        };

  const getCurrentFormValue = () => {
    if (selectedView === VIEWS.SMS_NOTIFICATIONS) return editSMSNotificationsInitialValues;
    return editMode ? editPractitionerInitialValues : addPractitionerInitialValues;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={getCurrentFormValue()}
      validationSchema={
        selectedView === VIEWS.SMS_NOTIFICATIONS ? SMSNotificationsValidationSchema : addPractitionerValidationSchema
      }
      onSubmit={(values, { setSubmitting }) => {
        selectedView === VIEWS.SMS_NOTIFICATIONS
          ? handleSaveSMSConfiguration(values)
          : handleCreatePractitioner(values);
        setSubmitting(false);
      }}
    >
      {({ errors, touched, values }) => (
        <Modal show={true} closeModal={() => setShow(false)} showBtnClose={false} contentStyle='bigContent__static'>
          {showLoading && (
            <div className={styles.loadingModal}>
              <Loading />
            </div>
          )}
          {!showLoading && showVerifyPhone && (
            <VerifyPhone
              setShow={setShowVerifyPhone}
              verifyCode={handleVerifyCode}
              codeVerificationStatus={codeVerificationStatus}
              resendCode={handleResendCode}
              enableVerify={enableVerify}
              enableResend={enableResend}
            />
          )}
          {!showLoading && !showVerifyPhone && (
            <Form className={selectedView === VIEWS.SMS_NOTIFICATIONS ? styles.form__large : styles.form}>
              <div className={editMode ? styles.header__edit : styles.header}>
                {editMode ? 'VIEW PRACTITIONER' : 'ADD PRACTITIONER'}
                {editMode && (
                  <div className={styles.viewSelection}>
                    <span
                      className={selectedView === VIEWS.DETAILS ? styles.active : styles.inactive}
                      role='menuitem'
                      tabIndex={0}
                      onClick={() => setSelectedView(VIEWS.DETAILS)}
                      onKeyPress={event => event.key === 'Enter' && setSelectedView(VIEWS.DETAILS)}
                    >
                      Details
                    </span>
                    <span
                      className={selectedView === VIEWS.ACTIVE_CLIENTS ? styles.active : styles.inactive}
                      role='menuitem'
                      tabIndex={0}
                      onClick={() => setSelectedView(VIEWS.ACTIVE_CLIENTS)}
                      onKeyPress={event => event.key === 'Enter' && setSelectedView(VIEWS.ACTIVE_CLIENTS)}
                    >
                      Active Clients
                    </span>
                    <span
                      className={selectedView === VIEWS.SMS_NOTIFICATIONS ? styles.active : styles.inactive}
                      role='menuitem'
                      tabIndex={0}
                      onClick={() => setSelectedView(VIEWS.SMS_NOTIFICATIONS)}
                      onKeyPress={event => event.key === 'Enter' && setSelectedView(VIEWS.SMS_NOTIFICATIONS)}
                    >
                      SMS Notifications
                    </span>
                  </div>
                )}
              </div>
              {editMode && !selectedPatient && selectedView === VIEWS.ACTIVE_CLIENTS && (
                <ActiveClientsTable
                  patients={selectedPractitionerPatients}
                  setShow={setShow}
                  setPatient={setSelectedPatient}
                />
              )}
              {editMode && selectedPatient && (
                <RemoveClient
                  selectedPatient={selectedPatient}
                  practitioner={selectedPractitioner}
                  removePatient={removePractitionerCare}
                  getPractitionersAction={getPractitionersAction}
                  getPractitionerPatientsAction={getPractitionerPatientsAction}
                  closeModal={() => setSelectedPatient(undefined)}
                />
              )}
              {!selectedPatient && selectedView === VIEWS.DETAILS && (
                <PractitionerFields
                  editMode={editMode}
                  image={image}
                  setImage={setImage}
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              )}
              {selectedView === VIEWS.SMS_NOTIFICATIONS && (
                <SMSNotifications
                  formValues={values}
                  formTouched={touched}
                  formErrors={errors}
                  config={smsConfig}
                  getSMSConfigurationAction={getSMSConfigurationAction}
                  isAuthenticatedPractitionerProfile={isAuthenticatedPractitionerProfile!}
                />
              )}
              {selectedView !== VIEWS.ACTIVE_CLIENTS && !showVerifyPhone && (
                <div className={styles.bottomButtons}>
                  <button
                    type='button'
                    className={`${
                      editMode && showRemovePractitionerButton && selectedView !== VIEWS.SMS_NOTIFICATIONS
                        ? styles.deleteBtn
                        : styles.deleteBtn__hide
                    }`}
                    onClick={() => editMode && setShowRemovePractitioner && setShowRemovePractitioner(true)}
                  >
                    Remove Practitioner
                  </button>
                  <div className={styles.rightButtons}>
                    <button type='button' className={styles.closeBtn} onClick={() => setShow(false)}>
                      Close
                    </button>
                    <button
                      className={styles.submitBtn}
                      type='submit'
                      disabled={selectedView === VIEWS.SMS_NOTIFICATIONS && !isAuthenticatedPractitionerProfile}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Modal>
      )}
    </Formik>
  );
};

export default UpsertPractitionerForm;
