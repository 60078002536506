import React from 'react';
import { Form, Formik } from 'formik';
import Modal from '../../modal/Modal';
import styles from '../admin-dashboard.module.scss';
import { TextInput } from '../../common/form';
import { UpdateOrganizationRequestBody } from '../../../interfaces/organization';
import { Organization } from '../../../graphql/codegen/schemas';
import { editClinicDetailsValidationSchema } from '../../../utils/form-validations';
import PhoneInput from '../../common/form/phone-input';
import moment from 'moment-timezone';

type Props = {
  organization: Organization;
  show: boolean;
  hide: () => void;
  updateOrganization: (organizationId: string, formResult: Organization) => Promise<void>;
};

const EditClinicDetails: React.FC<Props> = ({ organization, show, hide, updateOrganization }) => {
  const editClinicInitialValues = {
    name: organization.name,
    phone: organization?.contact?.phone,
    timezone: moment.tz.guess(),
    address: {
      line1: organization?.contact?.address?.line1,
      line2: organization?.contact?.address?.line2,
      city: organization?.contact?.address?.city,
      state: organization?.contact?.address?.state,
      postalCode: organization?.contact?.address?.postalCode,
      country: organization?.contact?.address?.country,
    },
    primaryContact: {
      name: {
        first: organization?.primaryContact?.name?.first,
        last: organization?.primaryContact?.name?.last,
      },
      email: organization?.primaryContact?.email,
      phone: organization?.primaryContact?.phone,
    },
  };

  return (
    <Formik
      initialValues={editClinicInitialValues}
      validationSchema={editClinicDetailsValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        updateOrganization(organization.id!, values);
        hide();
      }}
    >
      {({ resetForm }) => (
        <Modal show={show} closeModal={() => hide} showBtnClose={false} contentStyle='bigContent__static'>
          <Form className={styles.form}>
            <div className={styles.editClinicInfoModal}>
              <div className={styles.header}>EDIT CLINIC DETAILS</div>
              <div className={styles.body}>
                <TextInput label='Clinic name:' name='name' />
                <PhoneInput label='Clinic phone number:' name='phone' />
                <TextInput label='Clinic address line 1:' name='address.line1' />
                <TextInput label='Clinic address line 2:' name='address.line2' />
                <TextInput label='City:' name='address.city' />
                <TextInput label='State:' name='address.state' size='small' />
                <TextInput label='Zip:' name='address.postalCode' size='small' />
                <TextInput label='Primary contact first name:' name='primaryContact.name.first' />
                <TextInput label='Primary contact last name:' name='primaryContact.name.last' />
                <PhoneInput label='Primary contact phone number:' name='primaryContact.phone' />
                <TextInput label='Primary contact email:' name='primaryContact.email' />
              </div>
              <div className={styles.footer}>
                <button
                  className={styles.cancelBtn}
                  type='button'
                  onClick={() => {
                    resetForm();
                    hide();
                  }}
                >
                  Cancel
                </button>
                <button className={styles.saveBtn} type='submit'>
                  Save
                </button>
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default EditClinicDetails;
