import React, { useState } from 'react';
import Modal from '../modal/Modal';
import styles from './admin-devices.module.scss';
import { deviceStatus } from '../../utils/constants';
import { Patient } from '../../interfaces/patient';
import { Loading } from '../common/loading';

type Props = {
  show: boolean;
  hide: () => void;
  mode: string;
  handleUnassign: (uassignReason: string) => void;
  handleReassign: () => void;
  previousAssignee?: string;
  nextAssignee?: Patient;
  loading: boolean;
};

const AssignConfirmationModal: React.FC<Props> = ({
  show,
  hide,
  mode,
  handleUnassign,
  handleReassign,
  previousAssignee,
  nextAssignee,
  loading,
}) => {
  const [unassignmentReason, setUnassignmentReason] = useState<string>('');
  const [showSelectOptions, setShowSelectOptions] = useState<boolean>(false);

  const handleAddOption = (option: string) => {
    setShowSelectOptions(false);
    setUnassignmentReason(option);
  };

  return loading ? (
    <Modal show={true} closeModal={() => hide} showBtnClose={false} contentStyle='bigContent__static'>
      <div className={styles.assignDeviceModal__loading}>
        <Loading />
      </div>
    </Modal>
  ) : (
    <Modal show={show} closeModal={() => hide()} showBtnClose={false} contentStyle='bigContent__static'>
      <div className={`${styles.assignDeviceModal} ${styles.spacedModal}`}>
        <div className={styles.header}>UNASSIGN DEVICE</div>
        {mode === 'unassign' && (
          <div className={styles.assignBody}>
            <span className={styles.textStrong}>Are you sure you would like to unassign this device?</span>
            <span className={styles.textSecondary}>Note: this cannot be undone.</span>
            <span className={styles.textStrong}>Please indicate reason for unassignment:</span>
            <div className={styles.customSelect} onClick={() => setShowSelectOptions(!showSelectOptions)}>
              {unassignmentReason === deviceStatus.BROKEN
                ? 'Device broken'
                : unassignmentReason === deviceStatus.LOST
                ? 'Device lost'
                : unassignmentReason === deviceStatus.RECYCLED
                ? 'Device recycled'
                : 'Choose an option'}
            </div>
            {showSelectOptions && (
              <div className={styles.customSelectOptions}>
                <div className={styles.customOption} onClick={() => handleAddOption(deviceStatus.LOST)}>
                  Device lost
                </div>
                <div className={styles.customOption} onClick={() => handleAddOption(deviceStatus.BROKEN)}>
                  Device broken
                </div>
                <div className={styles.customOption} onClick={() => handleAddOption(deviceStatus.RECYCLED)}>
                  Device recycled
                </div>
              </div>
            )}
          </div>
        )}
        {mode === 'reassign' && (
          <div className={styles.assignBody}>
            <span className={styles.textStrong}>Are you sure you would like to reassign this device?</span>
            <span className={styles.textSecondary}>
              Note: this cannot be undone. This device will no longer be assigned to {previousAssignee} and will be
              assigned to {`${nextAssignee?.name.first} ${nextAssignee?.name.last}`} instead.
            </span>
          </div>
        )}
        <div className={styles.buttons}>
          <button className={styles.closeBtn} type='button' onClick={() => hide()}>
            Cancel
          </button>
          <button
            className={styles.submitBtn}
            type='button'
            onClick={mode === 'reassign' ? () => handleReassign() : () => handleUnassign(unassignmentReason)}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AssignConfirmationModal;
