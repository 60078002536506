import moment from 'moment-timezone';
import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { Column } from 'react-table';
import FemaleAvatarIcon from '../../assets/icons/icn-inverted-female.svg';
import MaleAvatarIcon from '../../assets/icons/icn-inverted-male.svg';
import { Patient, Patients } from '../../interfaces/patient';
import { EPISODE_OF_CARE } from '../../utils/constants';
import { capitalizeWord, displayFullName, displayTableDateTimeFormat } from '../../utils/data-parser';
import { RemoteData } from '../../utils/remote-data';
import Table from '../common/table/table';
import TableEmpty from '../common/table/table-empty';
import styles from './admin-clients.module.scss';
import { Tooltip } from '../common/tooltip';
import { render } from '@testing-library/react';
import { transform } from 'typescript';

type Props = {
  patients: RemoteData<Patients>;
  filterResultPatients: Patients | undefined;
  setSelectedPatientId: Dispatch<SetStateAction<string>>;
  setShowUpsertClientModal: Dispatch<SetStateAction<boolean>>;
};

const ActiveClientsTable: FC<Props> = ({
  patients,
  setSelectedPatientId,
  setShowUpsertClientModal,
  filterResultPatients,
}) => {
  const columns: Column<Patient>[] = useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: (originalRow): object => {
          return {
            nameDisplay: displayFullName(originalRow.name),
            profilePicture: originalRow.image ? originalRow.image.base64 : null,
          };
        },
        width: 200,
        Cell: ({ cell: { value, row } }) => (
          <div className={styles.nameContainer}>
            {value.profilePicture ? (
              <img
                className={styles.profilePicture}
                src={`data:image/jpeg;base64,${value.profilePicture}`}
                alt='avatar'
              />
            ) : (
              <img
                className={styles.picturePlaceholder}
                src={row.original.gender === 'FEMALE' ? FemaleAvatarIcon : MaleAvatarIcon}
                alt='avatar'
              />
            )}
            <span className={styles.overflowWrapped}>{value.nameDisplay}</span>
          </div>
        ),
      },
      {
        Header: 'PRACTITIONERS',
        width: 270,
        accessor: originalRow => {
          return originalRow?.careTeam?.careTeamMembers?.map((tm, idx) => displayFullName(tm.name)).join(', ') || '';
        },
        Cell: ({ cell: { value } }) => <div className={styles.dataFullDisplay}>{value}</div>,
      },
      {
        Header: 'ADMISSION DATE',
        width: 200,
        accessor: 'dateOfAdmission',
        Cell: ({ cell: { value } }) => displayTableDateTimeFormat(value, moment.tz.guess()),
      },
      {
        Header: 'PHONE NUMBER',
        accessor: originalRow => originalRow.contact.cellphone,
        width: 180,
      },
      {
        Header: 'EMAIL ADDRESS',
        accessor: originalRow => originalRow.contact.email,
        width: 200,
      },
      {
        Header:
          <>
            <span className={styles.status}>STATUS</span>
            <div  style={{position: 'absolute', marginLeft: '55px', marginTop: '-13px'}} >
              <Tooltip baseStyles={styles.infoTooltip} type='left'>
                <div className={styles.toolTipText} >
                  <h3 className={styles.infoClientStatus}>Client Status:</h3>
                  <h3 className={styles.statusHeader}>Active:</h3>
                  <p className={styles.statusDescription}>
                    - The client has been entered into <span className={styles.viviUnderline}>ViviClinic</span> and the
                    client has downloaded <span className={styles.viviUnderline}>ViviCompanion</span> and completed
                    the <span className={styles.viviSignup}>first time sign up</span> process.
                  </p>
                  <h3 className={styles.statusHeader}>Pending Invite:</h3>
                  <p className={styles.statusDescription}>
                    - The client has been entered into <span className={styles.viviUnderline}>ViviClinic</span> and the
                    client has not downloaded <span className={styles.viviUnderline}>ViviCompanion</span> and
                    completed the <span className={styles.viviSignup}>first time sign up</span> process.
                  </p>
                  <h3 className={styles.statusHeader}>Pending Accept to Join:</h3>
                  <p className={styles.statusDescription}>
                    - The client has been using <span className={styles.viviUnderline}>ViviCompanion</span> (they
                    downloaded the <span className={styles.viviUnderline}>ViviCompanion</span> app and completed
                    the <span className={styles.viviSignup}>first time sign up</span> process). They are considered to
                    be in consumer mode (or post care).
                    <br></br>
                    - Then the client is added to a clinic.
                    <br></br>
                    -The client will then receive a pop-up messasge in
                    <span className={styles.viviUnderline}> ViviCompanion</span> to join your clinic. The client must
                    Accept. After they accept, then the status will
                    change to Active.
                  </p>
                </div>
              </Tooltip>
            </div>
          </>,
        accessor: 'status',
        // originalRow =>
        // `${
        //   originalRow.status
        //     ? originalRow.status
        //         .split('_')
        //         .map(w => capitalizeWord(w))
        //         .join(' ')
        //     : ''
        // }${originalRow.userConfiguration?.appVersion ? ` - v${originalRow.userConfiguration?.appVersion}` : ''}`,
        width: 120,
      },
      {
        Header: 'EPISODE OF CARE',
        width: 150,
        accessor: 'episodeOfCare',
        Cell: ({ cell: { value } }) => (value ? EPISODE_OF_CARE[value] : ''),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ cell }) => (
          <span
            className={`${styles.action} ${styles.floatRight}`}
            onClick={() => setSelectedPatientId(cell.row.original.id)}
          >
            View
          </span>
        ),
        disableSortBy: true,
        width: 70,
        minWidth: 10,
      },
    ],
    [],
  );

  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'timestamp', desc: true }] }),
    [],
  );

  const getHeaderProps = column => {
    return {
      className: styles.th,
      key: `${column?.id}`,
      style: {
        width: column?.width,
        minWidth: column?.minWidth,
        maxWidth: column?.maxWidth,
      },
    };
  };

  const getTableProps = () => {
    return {
      className: patients.status === 'Done' && !patients.data.size ? styles.noContent : styles.table,
      style: {
        width: 'calc(100% - 36px)',
        marginLeft: '18px',
      },
    };
  };

  const getTableBodyProps = () => {
    return {
      className: styles.tbody,
      style: {
        height: '564px',
      },
    };
  };

  const getRowProps = row => {
    return {
      key: `${row?.id}`,
      className: styles.adminClientsTr,
    };
  };

  const getCellProps = cell => {
    return {
      key: `${cell?.column.id}`,
      className: `${styles.td} ${styles.tdInline}`,
    };
  };

  return patients.status === 'Done' && !patients.data.size ? (
    <TableEmpty>
      <span className={styles.noClientsTitle}>You have no clients</span>
      <span className={styles.noClientsSub}>Get started by adding your first client.</span>
      <span
        className={styles.noClientsAddBtn}
        onClick={() => setShowUpsertClientModal(true)}
        onKeyPress={event => event.key === 'Enter' && setShowUpsertClientModal(true)}
        tabIndex={0}
        role='button'
      >
        Add New Client
      </span>
    </TableEmpty>
  ) : (
    <Table<Patient>
      columns={columns}
      data={filterResultPatients ? filterResultPatients.values : patients.status === 'Done' ? patients.data.values : []}
      isLoading={patients.status === 'Loading'}
      error={patients.status === 'Failed'}
      getTableProps={() => getTableProps()}
      getRowProps={row => getRowProps(row)}
      getHeaderProps={column => getHeaderProps(column)}
      getCellProps={cell => getCellProps(cell)}
      getTableBodyProps={() => getTableBodyProps()}
      defaultColumn={{ width: 180, minWidth: 100 }}
      initialState={defaultSorting}
    />
  );
};

export default ActiveClientsTable;
