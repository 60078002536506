import React, { FunctionComponent } from 'react';
import styles from './explanation-bar.module.scss';

type Props = {
  rangeList: { width: string; text: string }[];
  smallStyle?: boolean;
};
const ExplanationBar: FunctionComponent<Props> = ({ rangeList, smallStyle = false }) => {
  return (
    <div className={styles.progressContainerScoreLabel}>
      {rangeList.map((range, index) => (
        <span
          key={index}
          className={` ${styles.scoreLabelContainer} ${smallStyle ? styles.small : ''}`}
          style={{ width: range.width }}
        >
          {range.text}
        </span>
      ))}
    </div>
  );
};

export default ExplanationBar;
