import React, { FunctionComponent, useMemo } from 'react';
import { Meeting } from '../../../../interfaces/meeting';
import styles from './meeting-location.module.scss';
import m from 'moment-timezone';
import { MeetingType } from '../../../../utils/constants';
import Map from '../../../common/map/map';
import { Marker } from '@react-google-maps/api';
import getMeetingStatus from '../../../../utils/meeting-helper';
import { getPatientTimezone } from '../../../../contexts/PatientContext';

type Props = {
  meeting: Meeting;
};

const MeetingLocation: FunctionComponent<Props> = ({ meeting }) => {
  const timezone = getPatientTimezone();
  const marker = useMemo(
    () => ({
      lat: meeting.location.latitude,
      lng: meeting.location.longitude,
    }),
    [],
  );

  const mapOptions: google.maps.MapOptions = useMemo(
    () => ({
      center: { lat: marker.lat, lng: marker.lng },
      clickableIcons: false,
      zoom: 17,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
      ],
    }),
    [],
  );

  return (
    <section className={styles.locationModal}>
      <div className={styles.modalHeader}>
        <h4>
          {meeting.title} -{' '}
          {m(meeting.occurrenceStartTimestamp).isValid() &&
            m.utc(meeting.occurrenceStartTimestamp).tz(timezone).format('MMM DD')}{' '}
          {m.utc(meeting.occurrenceStartTimestamp).tz(timezone).format('hh:mm a')}
          {' - '}
          {m.utc(meeting.occurrenceEndTimestamp).tz(timezone).format('hh:mm a')}
        </h4>
      </div>
      <div className={styles.meetingDetail}>
        <div className={styles.box}>
          <span>Address: </span>
          {meeting.location?.address}
        </div>
        <div className={styles.box}>
          <span>Event Type: </span>
          {MeetingType[meeting.type]} event
        </div>
        <div className={styles.box}>
          <span>Scheduled by: </span>
          {meeting.scheduledBy.type}
        </div>
        <div className={styles.box}>
          {m.utc(meeting.occurrenceStartTimestamp).tz(timezone).isSameOrAfter(m.utc().tz(timezone)) ? (
            <span className={styles.upcomingStatus}>
              UPCOMING {meeting.attended.toLowerCase().includes('remote') && <>REMOTE</>}
            </span>
          ) : (
            getMeetingStatus(meeting.attended, meeting)
          )}
        </div>
      </div>
      <div className={styles.mapContainer}>
        <Map
          mapOptions={mapOptions}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          markers={[marker]}
          fitBounds={false}
        >
          <Marker key={`${marker.lng} ${marker.lat}`} position={marker} cursor='pointer'></Marker>
        </Map>
      </div>
    </section>
  );
};

export default MeetingLocation;
