import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Modal from '../../modal/Modal';
import moment from 'moment-timezone';
import { getPatientTimezone } from '../../../contexts/PatientContext';
import { Patient } from '../../../interfaces/patient';
import styles from '../survey.module.scss';
import { submitCiwaData } from '../../../services/api/survey';
import { auth } from '../../../firebase/firebase';
import { useToasts } from 'react-toast-notifications';
import { CiwaSurvey } from 'interfaces/survey';

interface ClinicianCiwaSurveyProps {
  patient: Patient | undefined;
  closeCiwaSurvey: (close: boolean) => void;
  secondSurvey: boolean;
}

const ClinicianCiwaSurvey: React.FC<ClinicianCiwaSurveyProps> = ({ patient, closeCiwaSurvey, secondSurvey }) => {
  const timezone = getPatientTimezone();
  const [totalScore, setTotalScore] = useState<number>(0);
  const { addToast } = useToasts();

  const initialValues: CiwaSurvey = {
    // patientId: patient?.id || '',
    date: moment().utc().format('YYYY-MM-DD'),
    time: moment().tz(timezone).format('HH:mm:ss'),
    initialsOfFiller: `${(auth?.currentUser?.displayName || '').split(' ')[0].charAt(0).toUpperCase()}.${(
      auth?.currentUser?.displayName || ''
    )
      .split(' ')[1]
      .charAt(0)
      .toUpperCase()}`,
    pulse: 0,
    respRate: 0,
    o2: 0,
    bloodPressure: "",
    nauseaVomiting: 0,
    tremors: 0,
    anxiety: 0,
    agitation: 0,
    paroxysmalSweats: 0,
    orientationAndCloudingOfSensorium: 0,
    tactileDisturbances: 0,
    visualDisturbances: 0,
    auditoryDisturbances: 0,
    headache: 0,
    totalCiwaScore: 0,
  };

  const handleSubmit = async (values: CiwaSurvey) => {

    const convertedValues: CiwaSurvey = {
      ...values,
      pulse: Number(values.pulse),
      respRate: Number(values.respRate),
      o2: Number(values.o2),
      nauseaVomiting: Number(values.nauseaVomiting),
      tremors: Number(values.tremors),
      anxiety: Number(values.anxiety),
      agitation: Number(values.agitation),
      paroxysmalSweats: Number(values.paroxysmalSweats),
      orientationAndCloudingOfSensorium: Number(values.orientationAndCloudingOfSensorium),
      tactileDisturbances: Number(values.tactileDisturbances),
      visualDisturbances: Number(values.visualDisturbances),
      auditoryDisturbances: Number(values.auditoryDisturbances),
      headache: Number(values.headache),
      totalCiwaScore: Number(values.totalCiwaScore),
    };

    try {
      const apiCall = await submitCiwaData(patient?.firebaseUid || '', convertedValues);
      addToast(`You have successfully submited the Ciwa Survey`, {
        appearance: 'success',
        autoDismiss: true,
      });
      closeCiwaSurvey(false);
    } catch (error) {
      addToast(`${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
      console.error('Error submitting data:', error);
    }
  };

  return (
    <Modal show={true} closeModal={() => console.log('close')} showBtnClose={false} contentStyle='bigContent__static'>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} style={{ height: '500px' }}>
        {({ values, setFieldValue }) => {
          useEffect(() => {
            const totalScore = [
              'pulse',
              'respRate',
              'o2',
              'bloodPressure',
              'nauseaVomiting',
              'tremors',
              'anxiety',
              'agitation',
              'paroxysmalSweats',
              'orientationAndCloudingOfSensorium',
              'tactileDisturbances',
              'visualDisturbances',
              'auditoryDisturbances',
              'headache',
              'totalCiwaScore',
            ].reduce((total, field) => {
              return Number(total) + Number(values[field]);
            }, 0);
            setTotalScore(totalScore);
          }, [values]);
          return (
            <Form style={{ padding: 20 }}>
              <h2>Clinical Institute Withdrawal Assessment (CIWA)</h2>
              <div className={styles.hr}></div>
              {/* patient details */}
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 25 }}>
                {/* First Row */}
                <div style={{ display: 'column', alignContent: 'space-between' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <label style={{ marginRight: 10 }}>Date:</label>
                    <div style={{ backgroundColor: 'lightgray', padding: '.5px 5px', fontSize: '12px', width: 150 }}>
                      {moment().utc().format('MM-DD-YYYY')}
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 7.5, borderRadius: 8 }}>
                    <label style={{ marginRight: 10 }}>Time:</label>
                    <div style={{ backgroundColor: 'lightgray', padding: '.5px 5px', fontSize: '12px', width: 150 }}>
                      {moment().tz(timezone).format('hh:mm A')}
                    </div>
                  </div>
                </div>
                {/* Second Row */}
                <div style={{ display: 'column', alignContent: 'space-between' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <label style={{ marginRight: 10 }}>Pulse:</label>
                    <Field
                      type='number'
                      name='pulse'
                      required
                      min={1}
                      style={{
                        backgroundColor: 'lightgray',
                        padding: '.5px 5px',
                        fontSize: '12px',
                        border: 'none',
                        width: 150,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 7.5, borderRadius: 8 }}>
                    <label style={{ marginRight: 10 }}>Resp Rate:</label>
                    <Field
                      type='number'
                      name='respRate'
                      required
                      min={1}
                      style={{
                        backgroundColor: 'lightgray',
                        padding: '.5px 5px',
                        fontSize: '12px',
                        border: 'none',
                        width: 150,
                      }}
                    />
                  </div>
                </div>
                {/* Third Row */}
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <label style={{ marginRight: 10 }}>O2:</label>
                    <Field
                      type='number'
                      as={'input'}
                      name='o2'
                      required
                      min={1}
                      style={{
                        backgroundColor: 'lightgray',
                        padding: '.5px 5px',
                        fontSize: '12px',
                        border: 'none',
                        width: 150,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 7.5, borderRadius: 8 }}>
                    <label style={{ marginRight: 10 }}>Blood Pressure:</label>
                    <Field
                      type='input'
                      name='bloodPressure'
                      required
                      style={{
                        backgroundColor: 'lightgray',
                        padding: '.5px 5px',
                        fontSize: '12px',
                        border: 'none',
                        width: 150,
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* question section */}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  maxHeight: '300px',
                  overflowY: 'auto',
                  marginTop: 20,
                }}
              >
                {/* right column */}
                <div>
                  {/* Nausea/Vomiting */}
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '350px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>Nausea/Vomiting - Rate on scale 0 - 7</h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '3', '4', '5', '6', '7'].map((value, index) => (
                        <label key={index} htmlFor={`nauseaVomiting${value}`}>
                          <Field type='radio' id={`nauseaVomiting${value}`} name='nauseaVomiting' value={value} required />
                          {value === '0'
                            ? '0 - None'
                            : value === '1'
                            ? '1 - Mild nausea with no vomiting'
                            : value === '4'
                            ? '4 - Intermittent nausea'
                            : value === '7'
                            ? '7 - Constant nausea and frequent dry heaves and vomiting'
                            : value}
                        </label>
                      ))}
                    </div>
                  </div>
                  {/* Anxiety */}
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '350px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>Anxiety - Rate on scale 0 - 7</h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '3', '4', '5', '6', '7'].map((value, index) => (
                        <label key={index} htmlFor={`anxiety${value}`}>
                          <Field type='radio' id={`anxiety${value}`} name='anxiety' value={value} required />
                          {value === '0'
                            ? '0 - No anxiety, patient at ease'
                            : value === '1'
                            ? '1 - Mildly anxious'
                            : value === '4'
                            ? '4 - Moderately anxious or guarded, so anxiety is inferred'
                            : value === '7'
                            ? '7 - Equivalent to acute panic states seen in severe delirium or acute schizophrenic reactions'
                            : value}
                        </label>
                      ))}
                    </div>
                  </div>
                  {/* Paroxysmal Sweats */}
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '350px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>Paroxysmal Sweats - Rate on scale 0 - 7</h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '3', '4', '5', '6', '7'].map((value, index) => (
                        <label key={index} htmlFor={`paroxysmalSweats${value}`}>
                          <Field type='radio' id={`paroxysmalSweats${value}`} name='paroxysmalSweats' value={value} required />
                          {value === '0'
                            ? '0 - No sweats'
                            : value === '1'
                            ? '1 - Barely perceptible sweating, palms moist'
                            : value === '4'
                            ? '4 - Beads of sweat obvious on forehead'
                            : value === '7'
                            ? '7 - Drenching sweats'
                            : value}
                        </label>
                      ))}
                    </div>
                  </div>
                  {/* Tactile disturbances  */}
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '350px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>
                      Tactile disturbances - Ask, "Have you experienced any itching, pins & needles sensation, burning
                      or numbness, or a feeling of bugs crawling on or under your skin?" none
                    </h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '3', '4', '5', '6', '7'].map((value, index) => (
                        <label key={index} htmlFor={`tactileDisturbances${value}`}>
                          <Field
                            type='radio'
                            id={`tactileDisturbances${value}`}
                            name='tactileDisturbances'
                            value={value}
                            required
                          />
                          {value === '0'
                            ? '0 - None'
                            : value === '1'
                            ? '1 - Very mild itching, pins & needles, burning, or numbness'
                            : value === '2'
                            ? '2 - Mild itching, pins & needles, burning, or numbness'
                            : value === '3'
                            ? '3 - Moderate itching, pins & needles, burning, or numbness'
                            : value === '4'
                            ? '4 - Moderate hallucinations'
                            : value === '5'
                            ? '5 - Severe hallucinations'
                            : value === '6'
                            ? '6 - Extremely severe hallucinations'
                            : value === '7'
                            ? '7 - Continuos hallucinations'
                            : value}
                        </label>
                      ))}
                    </div>
                  </div>
                  {/* Visual disturbances  */}
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '350px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>
                      Visual disturbances - Ask, "Does the light appear to be too bright? Is its color different than
                      normal? Does it hurt your eyes? Are you seeing anything that disturbs you or that you know isnt
                      there?"
                    </h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '3', '4', '5', '6', '7'].map((value, index) => (
                        <label key={index} htmlFor={`visualDisturbances${value}`}>
                          <Field
                            type='radio'
                            id={`visualDisturbances${value}`}
                            name='visualDisturbances'
                            value={value}
                            required
                          />
                          {value === '0'
                            ? '0 - Not present'
                            : value === '1'
                            ? '1 - Very mild sensitivity'
                            : value === '2'
                            ? '2 - Mild sensitivity'
                            : value === '3'
                            ? '3 - Moderate sensitivity'
                            : value === '4'
                            ? '4 - Moderate hallucinations'
                            : value === '5'
                            ? '5 - Severe hallucinations'
                            : value === '6'
                            ? '6 - Extremely severe hallucinations'
                            : value === '7'
                            ? '7 - Continous hallucinations'
                            : value}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
                {/* right column */}
                <div>
                  {/* Tremors */}
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '350px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>Tremors - Rate on scale 0 - 7</h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '3', '4', '5', '6', '7'].map((value, index) => (
                        <label key={index} htmlFor={`tremors${value}`}>
                          <Field type='radio' id={`tremors${value}`} name='tremors' value={value} required />
                          {value === '0'
                            ? '0 - No tremor'
                            : value === '1'
                            ? '1 - Not visible, but can be felt fingertip to fingertip'
                            : value === '4'
                            ? '4 - Moderate, with patient’s arms extended'
                            : value === '7'
                            ? '7 - Severe, even with arms not extended'
                            : value}
                        </label>
                      ))}
                    </div>
                  </div>
                  {/* Agitation */}
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '350px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>Agitation - Rate on scale 0 - 7</h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '3', '4', '5', '6', '7'].map((value, index) => (
                        <label key={index} htmlFor={`agitation${value}`}>
                          <Field type='radio' id={`agitation${value}`} name='agitation' value={value} required />
                          {value === '0'
                            ? '0 - Normal activity'
                            : value === '1'
                            ? '1 - Somewhat normal activity'
                            : value === '4'
                            ? '4 - Moderately fidgety and restless'
                            : value === '7'
                            ? '7 - Paces back and forth, or constantly trashes about'
                            : value}
                        </label>
                      ))}
                    </div>
                  </div>
                  {/* Orientation and cloduing of sensorium */}
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '350px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>
                      Orientation and Clouding of Sensorium - Ask "What day is this? Where are you? Who am I?" Rate
                      scare 0 - 4
                    </h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '3', '4'].map((value, index) => (
                        <label key={index} htmlFor={`orientationAndCloudingOfSensorium${value}`}>
                          <Field
                            type='radio'
                            id={`orientationAndCloudingOfSensorium${value}`}
                            name='orientationAndCloudingOfSensorium'
                            value={value}
                            required
                          />
                          {value === '0'
                            ? '0 - Oriented'
                            : value === '1'
                            ? '1 - Cannot do serial additions or is uncertain about date'
                            : value === '2'
                            ? '2 - Disoriented to date by no more than 2 calendar days'
                            : value === '3'
                            ? '3 - Disoriented to date by more than 2 calendar days'
                            : value === '4'
                            ? '4 - Disoriented to place and / or person'
                            : value}
                        </label>
                      ))}
                    </div>
                  </div>
                  {/* Auditory Disturbance */}
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '350px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>
                      Auditory Disturbance - Ask "Are you more aware of sounds around you? Are they harsh? Do they
                      starle you? Do you hear anything that disturbs you or that you know isnt there?"
                    </h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '3', '4', '5', '6', '7'].map((value, index) => (
                        <label key={index} htmlFor={`auditoryDisturbances${value}`}>
                          <Field
                            type='radio'
                            id={`auditoryDisturbances${value}`}
                            name='auditoryDisturbances'
                            value={value}
                            required
                          />
                          {value === '0'
                            ? '0 - Not present'
                            : value === '1'
                            ? '1 - Very mild harshness or ability to startle'
                            : value === '2'
                            ? '2 - Mild harshness or ability'
                            : value === '3'
                            ? '3 - Moderate harshness or ability to startle'
                            : value === '4'
                            ? '4 - Moderate hallucinations'
                            : value === '5'
                            ? '5 - Severe hallucinations'
                            : value === '6'
                            ? '6 - Extremely severe hallucinations'
                            : value === '7'
                            ? '7 - Continous hallucinations'
                            : value}
                        </label>
                      ))}
                    </div>
                  </div>
                  {/* Headache */}
                  <div
                    style={{
                      marginTop: 20,
                      padding: 20,
                      border: '1px solid black',
                      width: '500px',
                      minHeight: '350px',
                    }}
                  >
                    <h4 style={{ fontWeight: 'bolder' }}>
                      Headache - Ask "Does your head feel different than usual? Does it feel like there is a band around
                      your head?" Do not rate dizziness or lightheadness
                    </h4>
                    <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                      {['0', '1', '2', '3', '4', '5', '6', '7'].map((value, index) => (
                        <label key={index} htmlFor={`headache${value}`}>
                          <Field type='radio' id={`headache${value}`} name='headache' value={value} required />
                          {value === '0'
                            ? '0 - Not present'
                            : value === '1'
                            ? '1 - Very mild'
                            : value === '2'
                            ? '2 - Mild'
                            : value === '3'
                            ? '3 - Moderate'
                            : value === '4'
                            ? '4 - Moderately severe'
                            : value === '5'
                            ? '5 - Severe'
                            : value === '6'
                            ? '6 - Very severe'
                            : value === '7'
                            ? '7 - Extremely severe'
                            : value}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* Total Score Display */}
              <div
                style={{
                  marginTop: 20,
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '75%',
                  textAlign: 'center',
                  margin: '0px 150px',
                }}
              >
                <div>
                  <h4 style={{ marginRight: 10 }}>
                    Total Ciwa - Ar Score: <u>{totalScore}</u>
                    <br />
                    <span>(maximum possible score = 67)</span>
                  </h4>
                  <span>
                    Raters Initials: <u>{values.initialsOfFiller}</u>
                  </span>
                </div>
                <div>
                  <h3>
                    Patient's scoring less than 10 <br />
                    do not usually need additional <br />
                    meidcation for Withdrawal
                  </h3>
                </div>
              </div>
              <div>
                <p style={{ fontSize: '14px' }}>
                  <span style={{fontSize: '14px',fontWeight: 'bolder'}}>Procedure:</span><br />
                  1. Assess and rate each of the 10 criteria of the CIWA scale. Each criterion is rated on a scale from
                  0 to 7, except for “Orientation and clouding of sensorium” which is rated on scale 0 to 4. Add up the
                  scores for all ten criteria. This is the total CIWA-Ar score for the patient at that time.
                  Prophylactic medication should be started for any patient with a total CIWA-Ar score of 8 or greater
                  (ie. start on withdrawal medication). If started on scheduled medication, additional PRN medication
                  should be given for a total CIWA-Ar score of 15 or greater. <br />
                  2. Document vitals and CIWA-Ar assessment on the Withdrawal Assessment Sheet. Document administration
                  of PRN medications on the assessment sheet as well. <br />
                  3. The CIWA-Ar scale is the most sensitive tool for assessment of the patient experiencing alcohol
                  withdrawal. Nursing assessment is vitally important. Early intervention for CIWA-Ar score of 8 or
                  greater provides the best means to prevent the progression of withdrawal.
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 100, marginTop: 25 }}>
                <button className={styles.closeBtn} onClick={() => closeCiwaSurvey(false)} type='button'>
                  Cancel
                </button>
                <button className={styles.nextBtn} type='submit'>
                  {secondSurvey ? 'Next' : 'Save'}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ClinicianCiwaSurvey;
