import React from 'react';
import { Insight } from '../../interfaces/insight';
import { Practitioner } from '../../interfaces/practitioner';
import { RemoteData } from '../../utils/remote-data';
import { Loading } from '../common/loading';
import Modal from '../modal/Modal';
import styles from './insights.module.scss';
import { Patient } from '../../interfaces/patient';
import moment from 'moment-timezone';
import WarningMark from '../../assets/icons/warning-mark-red.svg';
import CheckGreen from '../../assets/icons/icn_check_green.svg';
import {
  generateVitalsSnapshotText,
  generateScoresSnapshotText,
  insightStringReplacement,
} from '../../utils/data-parser';

type Props = {
  insight: Insight | undefined;
  hide: () => void;
  show: boolean;
  practitioner: RemoteData<Practitioner>;
  patient: Patient | undefined;
  getPatientStatusElement: (score: number) => JSX.Element;
  clearAlertAction: (patientId: string, insightId: number) => Promise<void>;
};

const InsightDetailsModal: React.FC<Props> = ({
  insight,
  hide,
  show,
  practitioner,
  patient,
  getPatientStatusElement,
  clearAlertAction,
}) => {
  const timezone = patient?.timezone ?? moment.tz.guess();
  if (!show) return null;
  if (practitioner.status === 'Loading' || !patient) {
    return (
      <Modal show={show} closeModal={() => hide()} showBtnClose={false} contentStyle='bigContent__static'>
        <div className={styles.loadingModal}>
          <Loading />
        </div>
      </Modal>
    );
  }
  return (
    <Modal show={show} closeModal={() => hide()} showBtnClose={false} contentStyle='bigContent__static'>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <span>INSIGHT</span>
        </div>
        <div className={styles.modalBody}>
          <ul>
            {insight?.hasAlert &&
              (insight?.clearedAt ? (
                <li className={styles.statusStable}>
                  <img className={styles.greenCheck} src={CheckGreen} />
                  <strong>Action Cleared by:</strong>{' '}
                  {practitioner.status === 'Done' && `${practitioner.data.name.first} ${practitioner.data.name.last}`}
                </li>
              ) : (
                <li className={styles.statusCritical}>
                  <img src={WarningMark} />
                  <strong>Action Required:</strong> Clear Alert
                </li>
              ))}
            <li>
              <strong>Client:</strong> {`${patient.name.first} ${patient.name.last}`}
            </li>
            <li>
              <strong>Timestamp:</strong> {moment.utc(insight?.endTimestamp).tz(timezone).format('lll z')}
            </li>
            <li>
              <strong>Client Status:</strong> {insight?.relapseRisk && getPatientStatusElement(insight.relapseRisk)}
            </li>
          </ul>

          <div className={styles.insightContent}>
            <div className={styles.snapshotTitle}>
              <h1>{insight && insightStringReplacement(insight?.title, insight, patient, timezone)}</h1>
              <span>{insight && insightStringReplacement(insight?.description, insight, patient, timezone)}</span>
            </div>

            <div className={styles.snapshot}>
              <h3>Vitals Snapshot</h3>
              <span>{insight && generateVitalsSnapshotText(insight)}</span>
            </div>

            <div className={styles.snapshot}>
              <h3>Scores Snapshot</h3>
              <span>{insight && generateScoresSnapshotText(insight)}</span>
            </div>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <button className={styles.closeBtn} onClick={() => hide()}>
            Close
          </button>
          {insight?.hasAlert && !insight.clearedAt && (
            <button className={styles.clearBtn} onClick={() => clearAlertAction(patient.id, insight.id)}>
              Clear Alert
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default InsightDetailsModal;
