import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Column, useSortBy } from 'react-table';
import { AxiosResponse } from 'axios';
import Table from '../common/table/table';
import { DropDown } from '../dropdown';
import styles from './notes.module.scss';
import DatePicker, { DateRange } from '../common/date-picker/date-range-picker';
import { PractitionerContext } from '../../contexts/Practitioner';
import { PatientContext } from '../../contexts/PatientContext';
import { getNotes } from '../../services/api/note';
import { NoteModal } from '../note-modal/note-modal';
import { NewNoteModal } from '../new-note-modal/new-note-modal';
import { firebaseSendCurrentScreen } from '../../utils/analytics';

export const Notes = () => {
  const { practitioner } = useContext(PractitionerContext);
  const [notes, setNotes] = useState<any>([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [addNote, setAddNote] = useState(false);
  const { patient } = useContext(PatientContext);
  const timezone = moment.tz.guess();
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    key: 'selection',
    color: '#417EB9',
    showDateDisplay: true,
    autoFocus: true,
  });

  useEffect(() => {
    if (practitioner) {
      firebaseSendCurrentScreen('NotesPage', {
        practitionerId: practitioner?.id,
        organizationId: practitioner?.organization.id,
        firebaseUid: practitioner?.firebaseUid,
      });
    }
  }, [practitioner]);

  useEffect(() => {
    if (!patient) {
      return;
    }
    getNotes(patient.id, moment(dateRangeFilter.startDate).format(), moment(dateRangeFilter.endDate).format()).then(
      ({ data }: AxiosResponse<any>) => {
        if (data?.size > 0) {
          setNotes(data.values);
        } else {
          setNotes([]);
        }
      },
    );
  }, [patient, dateRangeFilter.startDate, dateRangeFilter.endDate]);

  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: 'DATE',
        accessor: originalRow => moment.utc(originalRow.submittedTimestamp).tz(timezone).format('lll'),
        width: 150,
      },
      {
        Header: 'DATE RANGE',
        accessor: originalRow =>
          `${moment.utc(originalRow.effectiveStartDateTime).tz(timezone).format('ll')} - ${moment
            .utc(originalRow.effectiveEndDateTime)
            .tz(timezone)
            .format('ll')}`,
        width: 250,
      },
      {
        Header: 'PRACTITIONER',
        accessor: originalRow => {
          return `${originalRow.submittedByName?.first} ${originalRow.submittedByName?.last}`;
        },
        width: 270,
      },
      {
        Header: '',
        accessor: 'id',
        width: 100,
        Cell: ({ cell }) => (
          <span className={styles.action} onClick={() => setSelectedNote(cell.row.original)}>
            Details
          </span>
        ),
        disableSortBy: true,
      },
    ],
    [],
  );

  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'timestamp', desc: true }] }),
    [],
  );

  const getRowProps = (row, idx) => {
    return {
      key: `${row?.id}`,
      className: styles.tr,
    };
  };

  const getHeaderProps = column => {
    return {
      className: styles.th,
      key: `${column?.id}`,
      style: {
        width: column?.width,
        minWidth: column?.minWidth,
        maxWidth: column?.maxWidth,
      },
    };
  };

  const getCellProps = (cell, idx) => {
    return {
      key: `${cell?.column.id}`,
      className: styles.td,
    };
  };

  const getTableBodyProps = () => {
    return {
      className: styles.tbody,
      style: {
        height: '564px',
      },
    };
  };

  const getTableProps = () => {
    return {
      className: notes.length === 0 ? styles.noContent : styles.table,
      style: {
        width: 'calc(100% - 18px)',
        marginLeft: '18px',
      },
    };
  };

  const onDateChange = async (item: any): Promise<void> => {
    setDateRangeFilter(item.selection);
  };

  return (
    <div>
      <div className={styles.top}>
        <DropDown contentStyle='filter'>
          <span className={styles.dropdownButton}>
            {moment(dateRangeFilter.startDate).format('MM/DD/YYYY')} -{' '}
            {moment(dateRangeFilter.endDate).format('MM/DD/YYYY')}
            <i className={styles.arrow}>
              <svg width='14' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M13 1L7 7L1 1' stroke='#417EB9' strokeWidth='1.5' strokeLinecap='round' />
              </svg>
            </i>
          </span>
          <>
            <DatePicker
              onDateSelectionChange={onDateChange}
              dateRange={dateRangeFilter}
              parentStyle={styles.filterDatepicker}
            />
          </>
        </DropDown>
        <button className={styles.generate} onClick={() => setAddNote(true)}>
          Generate Note
        </button>
      </div>
      <Table<any>
        columns={columns}
        data={notes}
        isLoading={false}
        error={false}
        getTableProps={() => getTableProps()}
        getRowProps={(row, idx) => getRowProps(row, idx)}
        getHeaderProps={column => getHeaderProps(column)}
        getCellProps={(cell, idx) => getCellProps(cell, idx)}
        getTableBodyProps={() => getTableBodyProps()}
        defaultColumn={{ width: 180, minWidth: 100 }}
        initialState={defaultSorting}
      />
      {selectedNote !== null && (
        <NoteModal note={selectedNote} onClose={() => setSelectedNote(null)} patient={patient} />
      )}
      {addNote && (
        <NewNoteModal
          onClose={res => {
            if (res) {
              setNotes([...notes, res]);
            }
            setAddNote(false);
          }}
          patient={patient}
          lastNote={notes.length > 0 ? notes[0] : null}
        />
      )}
    </div>
  );
};
