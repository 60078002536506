import React, { useEffect, useState, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { RemoteData, loading, notAsked } from '../../utils/remote-data';
import {
  getPractitioners,
  createPractitioner,
  editPractitioner,
  updatePractitionerStatus,
  getSMSConfiguration,
} from '../../services/api/practitioner';
import { getPractitionerPatients } from '../../services/api/patient';
import { PractitionerContext } from '../../contexts/Practitioner';
import { Practitioners, Practitioner } from '../../interfaces/practitioner';
import styles from './admin-practitioners.module.scss';
import UpsertPractitionerForm from './upsert-practitioner-form';
import RemovePractitioner from './remove-practitioner';
import { Patients, Patient } from '../../interfaces/patient';
import AdminPractitionersTable from './admin-practitioners-table';
import { PATIENT_ACCOUNT_STATUS } from '../../utils/constants';
import { firebaseSendCurrentScreen } from '../../utils/analytics';
import { SMSConfig as SMSConfigDTO } from '../../interfaces/sms-config';

export const AdminPractitioners = (): JSX.Element => {
  const { addToast } = useToasts();
  const { practitioner } = useContext(PractitionerContext);
  const [practitioners, setPractitioners] = useState<RemoteData<Practitioners>>(loading());
  const [selectedPractitioner, setSelectedPractitioner] = useState<Practitioner | undefined>(undefined);
  const [practitionerPatients, setPractitionerPatients] = useState<RemoteData<Patients>>(notAsked());
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showRemovePractitioner, setShowRemovePractitioner] = useState<boolean>(false);
  const [modalLoadingState, setModalLoadingState] = useState<boolean>(false);
  const [SMSConfig, setSMSConfig] = useState<RemoteData<SMSConfigDTO>>(notAsked());

  useEffect(() => {
    if (practitioner) {
      firebaseSendCurrentScreen('AdminPractitionersPage', {
        practitionerId: practitioner?.id,
        organizationId: practitioner?.organization.id,
        firebaseUid: practitioner?.firebaseUid,
      });
    }
    getPractitionersAction();
  }, [practitioner]);

  useEffect(() => {
    if (selectedPractitioner) {
      setShowAddModal(true);
      getPractitionerPatientsAction();
    } else {
      setShowAddModal(false);
    }
  }, [selectedPractitioner]);

  useEffect(() => {
    if (!showAddModal) {
      setSelectedPractitioner(undefined);
      setSMSConfig(notAsked());
    }
  }, [showAddModal]);

  const getPractitionersAction = async (): Promise<void> => {
    if (practitioner) {
      setPractitioners(loading());
      const res = await getPractitioners(practitioner.organization.id);
      setPractitioners(res);
    }
  };

  const getPractitionerPatientsAction = async (): Promise<void> => {
    if (selectedPractitioner) {
      setPractitionerPatients(loading());
      const res = await getPractitionerPatients(selectedPractitioner.id);
      setPractitionerPatients(res);
    }
  };

  const deactivatePractitionerAction = async (): Promise<void> => {
    if (practitioner && selectedPractitioner) {
      setModalLoadingState(true);
      if (practitionerPatients.status !== 'Done') await getPractitionerPatientsAction();

      if (practitionerPatients.status === 'Failed') {
        addToast(`Error: ${practitionerPatients.errorApi.message}`, {
          appearance: 'error',
          autoDismiss: true,
        });
        setModalLoadingState(false);
        setShowRemovePractitioner(false);
        setShowAddModal(false);
        setSelectedPractitioner(undefined);
      } else if (practitionerPatients.status === 'Done') {
        const activePatientsWithOnePractitioner: Patient[] = practitionerPatients.data.values.filter(
          p =>
            (p.status === PATIENT_ACCOUNT_STATUS.ACTIVE || p.status === PATIENT_ACCOUNT_STATUS.PENDING_INVITE) &&
            p.careTeam.careTeamMembers.length === 1,
        );

        if (activePatientsWithOnePractitioner.length === 0) {
          const res = await updatePractitionerStatus(selectedPractitioner?.id, false);
          if (res.status === 'Done') {
            addToast('Practitioner deactivated successfully', {
              appearance: 'success',
              autoDismiss: true,
            });
            getPractitionersAction();
            setShowRemovePractitioner(false);
            setShowAddModal(false);
            setSelectedPractitioner(undefined);
          } else {
            addToast(`Error: ${res.errorApi.message}`, {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        } else {
          activePatientsWithOnePractitioner.map(p => {
            addToast(
              `${selectedPractitioner.name.first} ${selectedPractitioner.name.last} is the only practitioner on the care team of ${p.name.first} ${p.name.last}.
            Please add another practitioner to the care team of ${p.name.first} ${p.name.last} before removing ${selectedPractitioner.name.first} ${selectedPractitioner.name.last}`,
              {
                appearance: 'error',
                autoDismiss: false,
              },
            );
          });
        }
        setModalLoadingState(false);
      }
    }
  };

  const getSMSConfigurationAction = async () => {
    if (selectedPractitioner) {
      setSMSConfig(loading());
      const res = await getSMSConfiguration(selectedPractitioner.id);
      setSMSConfig(res);
    }
  };

  return (
    <div>
      <a className={styles.addBtn} onClick={() => setShowAddModal(true)}>
        Add New Practitioner
      </a>
      <AdminPractitionersTable practitioners={practitioners} setSelectedPractitioner={setSelectedPractitioner} />
      {showAddModal && !showRemovePractitioner && (
        <UpsertPractitionerForm
          setShow={setShowAddModal}
          setShowRemovePractitioner={setShowRemovePractitioner}
          createPractitioner={createPractitioner}
          editPractitioner={editPractitioner}
          organizationId={practitioner?.organization.id}
          getPractitionersAction={getPractitionersAction}
          getPractitionerPatientsAction={getPractitionerPatientsAction}
          editMode={!!selectedPractitioner}
          selectedPractitioner={selectedPractitioner}
          isAuthenticatedPractitionerProfile={selectedPractitioner?.id === practitioner?.id}
          selectedPractitionerPatients={practitionerPatients}
          showRemovePractitionerButton={true}
          smsConfig={SMSConfig}
          getSMSConfigurationAction={getSMSConfigurationAction}
        />
      )}
      {showRemovePractitioner && (
        <RemovePractitioner
          setShow={setShowRemovePractitioner}
          selectedPractitioner={selectedPractitioner}
          removePractitioner={deactivatePractitionerAction}
          showLoading={modalLoadingState}
        />
      )}
    </div>
  );
};
