import React from 'react';
import { getPatientTimezone } from 'contexts/PatientContext';
import { curveLinear } from 'd3-shape';
import { QuestionnaireInfo } from 'graphql/codegen/schemas';
import moment from 'moment-timezone';
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis, ReferenceArea } from 'recharts';
import { CHARTS_SIZES, GRAPH_REVERSE_TOOLTIP_COORDINATE } from '../../../../../utils/constants';
import { Tooltip as InfoTooltip } from '../../../tooltip';
import styles from '../../chart.module.scss';
import { getTicksDD, getTicksH } from '../../utlity';
import { GadTotalToolTip } from './gad-total-tooltip';

type Props = {
  title: string;
  start: string;
  end: string;
  data: { ts: number; value: number }[];
  survey?: QuestionnaireInfo;
  showXAxis?: boolean;
};
const GadTotalChart = ({ title, start, end, data, survey, showXAxis }: Props) => {
  const GRAPH_WIDTH_PX = CHARTS_SIZES.CDP_WIDTH;
  const GRAPH_HEIGHT_PX = showXAxis ? CHARTS_SIZES.UR_HEIGHT : CHARTS_SIZES.CDP_HEIGHT;
  const patientTimezone = getPatientTimezone();

  const customToolTip = ({
    active,
    coordinate,
    payload,
    label,
  }: {
    active: boolean;
    coordinate: { x: number; y: number };
    label: number;
    payload: Array<any>;
  }): JSX.Element | null => {
    if (!active) {
      return null;
    }
    const reverse = coordinate.x > GRAPH_REVERSE_TOOLTIP_COORDINATE;
    let yValue = payload ? payload[0]?.payload?.value : 'Unable to retrieve value';
    const title = yValue < 5 ? 'Minimal Anxiety' : yValue < 10 ? 'Mild Anxiety' : yValue < 15 ? 'Moderate Anxiety' : 'Severe Anxiety'


    return <GadTotalToolTip date={label} title={title} value={yValue} reverse={reverse} />;
  };

  return (
    <div className={styles.chartContainer}>
      <div className={styles.chartHeader}>
        <span className={styles.chartTitle}>Generalized Anxiety Disorder (GAD-7) - Total Score</span>
        <InfoTooltip portal baseStyles={`${styles.infoTooltip} ${styles.events}`} type='left' background='#F5F6FA'>
          <div
            dangerouslySetInnerHTML={{
              __html:
                'The Generalized Anxiety Disorder Assessment (GAD-7) is a seven-item instrument that is used to measure or assess the severity of generalized anxiety disorder (GAD). Each item asks the individual to rate the severity of his or her symptoms over the past two weeks. Response options include &quot;not at all&quot;, &quot;several days&quot;, &quot;more than half the days&quot; and &quot;nearly every day&quot;.<br />\nAlthough designed as a screening tool for generalized anxiety, the GAD-7 is also performs reasonably well as a screening tool for three other common anxiety disorders—Panic Disorder, Social Anxiety Disorder, and Posttraumatic Stress Disorder.<br />\nWhen screening for anxiety disorders, a score of 8 or greater represents a reasonable cut-point for identifying probable cases of generalized anxiety disorder; further diagnostic assessment is warranted to determine the presence and type of anxiety disorder. <br />\nThe following cut-offs correlate with level of anxiety severity:\n<ul>\n<li style="background-color: rgba(209, 66, 86, 0.5); padding: 5px;width: 225px;">Score greater than 15: Severe Anxiety</li>\n<li style="background-color: rgba(209, 66, 86, 0.3); padding: 5px;width: 225px;">Score 10-14: Moderate Anxiety</li>\n<li style="background-color: rgba(253, 243, 184, 0.4); padding: 5px;width: 225px;">Score 5-9: Mild Anxiety</li>\n<li style="background-color: rgba(65, 126, 185, 0.4); padding: 5px;width: 225px;">Score 0-4: Minimal Anxiety</li>\n</ul>\nWhen used as a screening tool, further evaluation is recommended when the score is 10 or greater.',
            }}
          />
        </InfoTooltip>
      </div>
      <AreaChart width={GRAPH_WIDTH_PX} height={GRAPH_HEIGHT_PX} data={data}>
        <defs>
          <linearGradient id='d1' x1={0} y1={0} x2={0} y2={1}>
            <stop offset='3%' stopColor='#417EB9' stopOpacity={0.8} />
            <stop offset='97%' stopColor='rgba(65, 126, 185, 0.2)' stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis
          xAxisId={0}
          dy={-10}
          dx={2}
          tickLine={true}
          tickSize={16}
          tickFormatter={(unixTime): string => moment.utc(unixTime).tz(patientTimezone).format('hA')}
          dataKey='ts'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksH(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
          hide={!showXAxis}
        />
        <XAxis
          xAxisId={2}
          dy={-18}
          dx={0}
          style={{
            fontSize: '11',
            fontWeight: 'bold',
            fontFamily: 'sans-serif',
            color: '#000000',
          }}
          tickLine={false}
          axisLine={false}
          tickFormatter={(unixTime): string => moment.utc(unixTime).format('ddd')}
          dataKey='ts'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
          hide={!showXAxis}
        />

        <XAxis
          xAxisId={3}
          dy={-34}
          dx={0}
          tickLine={false}
          axisLine={false}
          tickFormatter={(unixTime): string => moment.utc(unixTime).format('M/D')}
          dataKey='ts'
          domain={[moment(start).unix() * 1000, moment(end).unix() * 1000]}
          allowDataOverflow={true}
          type='number'
          scale='time'
          textAnchor='start'
          ticks={getTicksDD(start, end, GRAPH_WIDTH_PX)}
          mintickgap={0}
          interval={0}
          hide={!showXAxis}
        />
        <YAxis dataKey='value' interval={0} tickSize={0} domain={[0, 21]} ticks={[0, 5, 10, 15, 21]} tickMargin={10} />
        <CartesianGrid stroke='#A3A6B3' />
        <Tooltip content={customToolTip} allowEscapeViewBox={{ x: false, y: true }} />
        <Area type={curveLinear} dataKey='value' stroke='#417EB9' fill='url(#d1)' strokeWidth='2' />
        <ReferenceArea
            x1={moment(start).unix() * 1000}
            x2={moment(end).unix() * 1000}
            y1={0}
            y2={5}
            fill='rgba(65, 126, 185, 0.2)'
          />,
          <ReferenceArea
            x1={moment(start).unix() * 1000}
            x2={moment(end).unix() * 1000}
            y1={5}
            y2={10}
            fill='rgba(253, 243, 184, 0.4)'
          />,
          <ReferenceArea
            x1={moment(start).unix() * 1000}
            x2={moment(end).unix() * 1000}
            y1={10}
            y2={15}
            fill='rgba(255, 122, 115, 0.3)'
          />,
          <ReferenceArea
            x1={moment(start).unix() * 1000}
            x2={moment(end).unix() * 1000}
            y1={15}
            y2={21}
            fill='rgba(209, 66, 86, 0.5)'
          />,
      </AreaChart>
    </div>
  );
};

export default GadTotalChart;
