import React from 'react';
import ExplanationBar from '../explanation-bar';
import ScoreBar from '../score-bar';
import styles from '../chart.module.scss';

const InfoBox = (): JSX.Element => {
  return (
    <div>
      <h3>POSITIVE/NEGATIVE AFFECT & MOOD</h3>
      <p>
        <strong>Positive Affect (PosA) and Negative Affect (NegA)</strong> assessment can provide valuable information
        about a client’s internal emotional and feeling state and how such state may be changing over time in response
        to their recovery and therapy. PosA is the extent to which a person feels pleasantly alert. NegA is a dimension
        of subjective distress, which encompasses states such as anger, guilt, and fear.
      </p>
      <p>
        The Positive and Negative Affect Schedule (PANAS) is a widely used measure of PosA and NegA and comprises 20
        word-response questions. ViviCompanion delivers the PANAS to the client daily as a quick morning and evening
        survey with 2 questions each, available from 6am–noon and from 6pm–midnight, respectively.
      </p>
      <p>
        The quick survey responses are scored for PosA and NegA and scaled from 0 to 100. The timeline chart shows PosA
        and NegA in a unique manner. PosA is plotted on the upper portion of the graph (Y-axis range of 0 to 100). NegA
        is plotted negatively on the lower portion of the graph (Y-axis range of 0 to -100).
      </p>
      <p>
        High levels of PosA (50 to 100) indicate a state of positive emotional engagement, while low levels of PosA (0
        to 50) indicate a state of sadness and lethargy. <br />
        High levels of NegA (-50 to -100) indicate a state of significant distress, while low levels of NegA (0 to -50)
        indicate a state of calmness.
      </p>
      <p>
        A <strong>Mood Score</strong> is calculated as the mid-point between PosA and -NegA and is plotted in middle of
        the graph (dotted line with Y-axis range of -50 to 50). This allows easy visualization of the parallel or
        independent movements of PosA and NegA along with the Mood Score as a single summary measure of their median.
        The Mood Score (only) is seen by the client in ViviCompanion, but rescaled from 0 to 100. It covers a wide range
        of emotional/feeling states.
      </p>
      <p>
        <strong>Mood Ranges</strong>
      </p>
      <h5>
        <div className={styles.parentWrapper}>
          <div className={styles.scoreRow}>
            <div className={styles.score}>
              <span className={styles.scoreValue}> -50</span>
              <span className={styles.scoreValue} style={{ width: '29%' }}>
                -20
              </span>
              <span className={styles.scoreValue} style={{ width: '20%' }}>
                0
              </span>
              <span className={styles.scoreValue} style={{ width: '20%' }}>
                20
              </span>
              <span className={styles.scoreValue} style={{ width: '15%' }}>
                35
              </span>
              <span className={styles.scoreValue} style={{ width: '11%' }}>
                50
              </span>
            </div>
            <div>{'< ViviClinic Range'}</div>
          </div>
          <hr />
          <div className={styles.scoreRow}>
            <div className={styles.score}>
              <span className={styles.scoreValue}> 0</span>
              <span className={styles.scoreValue} style={{ width: '32%' }}>
                30
              </span>
              <span className={styles.scoreValue} style={{ width: '20%' }}>
                70
              </span>
              <span className={styles.scoreValue} style={{ width: '20%' }}>
                70
              </span>
              <span className={styles.scoreValue} style={{ width: '15%' }}>
                85
              </span>
              <span className={styles.scoreValue} style={{ width: '11%' }}>
                100
              </span>
            </div>
            <div>{'< ViviCompanion Range'}</div>
          </div>
        </div>
        <ScoreBar
          scoreList={[
            { width: '30%', value: 30, color: 'linear-gradient(90deg, #D18195 0%, #EA6969 100%)' },
            { width: '40%', value: 70, color: 'linear-gradient(90deg, #E39253 0%, #F0BF55 100%)' },
            { width: '16%', value: 85, color: 'linear-gradient(270deg, #6BE3BA 0%, #5CC5E2 70%)' },
            { width: '16%', value: 100, color: 'linear-gradient(90deg, #6BE3B9 100%, #5CC5E2 100%)' },
          ]}
          showScore={false}
        />
      </h5>
      <ExplanationBar
        rangeList={[
          { width: '29.5%', text: 'Burdened' },
          { width: '39.5%', text: 'Reflective' },
          { width: '16%', text: 'Assured' },
          { width: '16%', text: 'Positive' },
        ]}
      />
    </div>
  );
};

export default InfoBox;
