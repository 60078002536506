import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

type Props = {
  label: string;
  to: string;
  activeOnlyWhenExact: boolean;
};

const ActiveLink: React.FunctionComponent<Props> = ({ label, to, activeOnlyWhenExact }) => {
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });
  return (
    <li className={match ? 'active' : ''}>
      <Link to={to}>{label}</Link>
    </li>
  );
};

export default ActiveLink;
