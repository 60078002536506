import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useToasts } from 'react-toast-notifications';
import { addClientValidationSchema } from '../../utils/form-validations';
import Modal from '../modal/Modal';
import PrimaryInfo from './upsert-sections/primary-info';
import EmergencyContacts from './upsert-sections/emergency-contacts';
import ContactInfo from './upsert-sections/contact-info';
import ClinicInfo from './upsert-sections/clinic-info';
import { getPractitioners } from '../../services/api/practitioner';
import { loading, notAsked, RemoteData } from '../../utils/remote-data';
import { Practitioners } from '../../interfaces/practitioner';
import { Allies, Organization } from '../../interfaces/common';
import AssignedDevices from './upsert-sections/assigned-devices';
import { parseTotalInches, parseTotalInchesToOriginalInput } from '../../utils/data-parser';
import { createPatient, getPatientAllies, updatePatient } from '../../services/api/patient';
import { Loading } from '../common/loading';
import styles from './upsert-client.module.scss';
import WizardStepIndicator from '../common/wizard-step-indicator';
import { Patient, ViviPatient } from '../../interfaces/patient';
import ClientProfileNav from './client-profile-nav';
import AlliesTable from './upsert-sections/allies-table';
import { Devices, IndistinguishableDevices } from '../../interfaces/device';
import { getDevices, getIndistinguishableDevices } from '../../services/api/device';
import { PATIENT_ACCOUNT_STATUS } from '../../utils/constants';
import PermissionSettings from './upsert-sections/permission-settings/permission-settings';
import PermissionsHistoryContainer from './upsert-sections/permissions-history/permissions-history-container';

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  editMode: boolean;
  organization: Organization | undefined;
  getPatientsAction?: () => void;
  selectedPatient: RemoteData<Patient>;
  selectedPatientAllies?: RemoteData<Allies>;
  refetchPatient: () => void;
  openEditEmailModal?: () => void;
  firebasePatient?: ViviPatient;
  setShowDischargeClientModal: Dispatch<SetStateAction<boolean>>;
  initialTab?: number;
};

const UpsertClientContainer: React.FC<Props> = ({
  setShow,
  editMode,
  organization,
  getPatientsAction,
  selectedPatient,
  selectedPatientAllies,
  refetchPatient,
  openEditEmailModal,
  firebasePatient,
  setShowDischargeClientModal,
  initialTab = 1,
}) => {
  const { addToast } = useToasts();

  const [step, setStep] = useState<number>(initialTab);
  const [stepHeaderText, setStepHeaderText] = useState<string>('PRIMARY INFO');
  const [practitioners, setPractitioners] = useState<RemoteData<Practitioners>>(notAsked());
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [devices, setDevices] = useState<RemoteData<Devices>>(notAsked());
  const [indDevices, setIndDevices] = useState<RemoteData<IndistinguishableDevices>>(notAsked());
  const [formValues, setFormValues] = useState<any | undefined>(undefined);
  const [viviPatientAllies, setViviPatientAllies] = useState<RemoteData<Allies>>(notAsked());
  const formSteps = 5;

  const getPractitionersAction = async () => {
    setPractitioners(loading());
    const res = await getPractitioners(organization?.id);
    setPractitioners(res);
  };

  const getDevicesAction = async organizationId => {
    setDevices(loading());
    const res = await getDevices(organizationId, false);
    setDevices(res);
  };

  const getIndistinguishableDevicesAction = async organizationId => {
    setIndDevices(loading());
    const res = await getIndistinguishableDevices(organizationId);
    setIndDevices(res);
  };

  const getPatientAlliesAction = async (patientId: string) => {
    setViviPatientAllies(loading());
    const res = await getPatientAllies(patientId);
    setViviPatientAllies(res);
  };

  useEffect(() => {
    if (organization?.id) {
      if (practitioners.status !== 'Done') getPractitionersAction();
      if (devices.status !== 'Done') getDevicesAction(organization.id);
      if (indDevices.status !== 'Done') getIndistinguishableDevicesAction(organization.id);
    }
  }, []);

  useEffect(() => {
    if (firebasePatient) {
      if (!selectedPatientAllies) {
        getPatientAlliesAction(firebasePatient.id);
      }
    }
  }, [firebasePatient]);

  useEffect(() => {
    if (
      selectedPatient.status === 'Loading' ||
      (selectedPatientAllies && selectedPatientAllies.status === 'Loading') ||
      devices.status === 'Loading' ||
      indDevices.status === 'Loading' ||
      practitioners.status === 'Loading'
    ) {
      setShowLoading(true);
    } else {
      setShowLoading(false);
    }
  }, [selectedPatient, selectedPatientAllies, devices, indDevices, practitioners]);

  useEffect(() => {
    if (step === 1) setStepHeaderText('PRIMARY INFO');
    if (step === 2) setStepHeaderText('EMERGENCY CONTACTS');
    if (step === 3) setStepHeaderText('CONTACT INFO');
    if (step === 4) setStepHeaderText('CLINIC INFO');
    if (step === 5) setStepHeaderText('ASSIGNED DEVICES');
  }, [step]);

  const addClientInitialValues = {
    name: formValues?.name || {
      first: '',
      last: '',
    },
    dateOfAdmission: formValues?.dateOfAdmission ?? '',
    birthDate: formValues?.birthDate ?? '',
    gender: formValues?.gender ?? '',
    maritalStatus: formValues?.maritalStatus ?? undefined,
    height: formValues?.height ?? undefined,
    weight: formValues?.weight ?? undefined,
    personalContact: formValues?.personalContact || {
      cellphone: '',
      homePhone: '',
      email: '',
      home: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'US',
      },
      work: {
        line1: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'US',
      },
    },
    emergencyContact: formValues?.emergencyContact || {
      name: '',
      phone: '',
    },
    primaryCareContact: formValues?.primaryCareContact || {
      name: '',
      phone: '',
    },
    episodeOfCare: formValues?.episodeOfCare || 'OUT_PATIENT',
    careTeamIds: formValues?.careTeamIds || [],
    devicesIssued: formValues?.devicesIssued || {
      bacDeviceProvided: false,
    },
    emrIdentifier: '',
    organizationId: organization?.id,
  };

  const editClientInitialValues = data => {
    return {
      name: {
        first: data.name.first,
        last: data.name.last,
      },
      dateOfAdmission: data.dateOfAdmission,
      birthDate: data.birthday,
      gender: data.gender,
      maritalStatus: data.maritalStatus ?? undefined,
      height: parseTotalInchesToOriginalInput(data.height),
      weight: data.weight,
      personalContact: {
        cellphone: data.contact.cellphone,
        homePhone: data.contact.homePhone,
        email: data.contact.email,
        home: {
          line1: data.contact.homeAddress?.line1,
          line2: data.contact.homeAddress?.line2,
          city: data.contact.homeAddress?.city,
          state: data.contact.homeAddress?.state,
          postalCode: data.contact.homeAddress?.postalCode,
          country: data.contact.homeAddress?.country,
        },
        work: {
          line1: data.contact.workAddress?.line1,
          city: data.contact.workAddress?.city,
          state: data.contact.workAddress?.state,
          postalCode: data.contact.workAddress?.postalCode,
          country: 'US',
        },
      },
      emergencyContact: {
        name: data.emergencyContact?.name,
        phone: data.emergencyContact?.phone,
      },
      primaryCareContact: {
        name: data.primaryCareContact?.name,
        phone: data.primaryCareContact?.phone,
      },
      episodeOfCare: data.episodeOfCare,
      careTeamIds: data.careTeam.careTeamMembers.map(pract => pract.id),
      devicesIssued: data.devicesIssued,
      emrIdentifier: data.emrIdentifier,
      organizationId: organization?.id,
    };
  };

  const handleUpsertClient = async (values, operationType) => {
    setShowLoading(true);
    setFormValues(values);

    let formResult = {
      ...values,
      PersonalContact: {
        ...values.personalContact,
        email: values.personalContact.email.toLowerCase(),
      },
      devicesIssued: {
        deviceName1: values.devicesIssued.deviceName1 || undefined,
        deviceName2: values.devicesIssued.deviceName2 || undefined,
        bacDeviceProvided: values.devicesIssued.bacDeviceProvided,
      },
      maritalStatus: values.maritalStatus || undefined,
      height: values.height ? parseTotalInches(values.height) : null,
    };

    if (['YES', 'NO'].includes(formResult.devicesIssued.bacDeviceProvided)) {
      formResult = {
        ...formResult,
        devicesIssued: {
          ...formResult.devicesIssued,
          bacDeviceProvided: formResult.devicesIssued.bacDeviceProvided === 'YES',
        },
      };
    }

    const result =
      operationType === 'create'
        ? await createPatient(formResult)
        : await updatePatient(selectedPatient.status === 'Done' ? selectedPatient.data.id : '', formResult);

    setShowLoading(false);

    if (result.status === 'Done') {
      getPatientsAction && getPatientsAction();
      addToast(`Client ${operationType === 'create' ? 'added' : 'updated'} successfully`, {
        appearance: 'success',
        autoDismiss: true,
      });

      if (operationType === 'create') {
        setFormValues(undefined);
        setShow(false);
      } else {
        refetchPatient();
        await getDevicesAction(organization?.id);
      }
    } else {
      addToast(`Error: ${result.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return showLoading ? (
    <Modal show={true} closeModal={() => setShow(false)} showBtnClose={false} contentStyle='bigContent__static'>
      <div className={styles.loadingState}>
        <Loading />
      </div>
    </Modal>
  ) : (
    <Formik
      initialValues={
        editMode && selectedPatient.status === 'Done'
          ? editClientInitialValues(selectedPatient.data)
          : addClientInitialValues
      }
      validationSchema={addClientValidationSchema[step]}
      onSubmit={(values, { setSubmitting }) => {
        if (editMode) {
          handleUpsertClient(values, 'update');
        } else if (step === formSteps) {
          handleUpsertClient(values, 'create');
        } else {
          setStep(step + 1);
          setSubmitting(false);
        }
      }}
    >
      {({ touched, errors, values }) => (
        <Modal show={true} closeModal={() => setShow(false)} showBtnClose={false} contentStyle='bigContent__static'>
          <Form className={styles.form}>
            {editMode ? (
              <div className={styles.header__edit}>
                <span className={styles.header_title}>CLIENT PROFILE</span>
                <ClientProfileNav step={step} setStep={setStep} />
              </div>
            ) : (
              <div className={styles.header}>
                <span>ADD CLIENT - {stepHeaderText}</span>
                <WizardStepIndicator totalPages={formSteps} page={step} />
              </div>
            )}
            {step === 1 && <PrimaryInfo errors={errors} touched={touched} values={values} />}
            {step === 2 && <EmergencyContacts />}
            {step === 3 && (
              <ContactInfo
                editMode={editMode}
                patientStatus={selectedPatient.status === 'Done' ? selectedPatient.data.status : ''}
                openEditEmailModal={openEditEmailModal}
              />
            )}
            {step === 4 && (
              <ClinicInfo
                formValues={values}
                practitioners={practitioners}
                organization={organization}
                editMode={editMode}
              />
            )}
            {step === 5 && (
              <AssignedDevices
                formValues={values}
                devices={devices}
                indistinguishableDevices={indDevices}
                editMode={editMode}
              />
            )}
            {editMode && step === 6 && <AlliesTable allies={selectedPatientAllies || viviPatientAllies} />}
            {step === 7 && (
              <PermissionSettings
                patientId={selectedPatient.status === 'Done' ? selectedPatient.data.firebaseUid! : ''}
              />
            )}
            {step === 8 && (
              <PermissionsHistoryContainer
                patientFirebaseUid={selectedPatient.status === 'Done' ? selectedPatient.data.firebaseUid! : ''}
              />
            )}
            {editMode ? (
              <div className={styles.bottomButtons}>
                <button className={styles.closeBtn} type='button' onClick={() => setShowDischargeClientModal(true)}>
                  {selectedPatient.status === 'Done' &&
                  selectedPatient.data.status === PATIENT_ACCOUNT_STATUS.PENDING_INVITE
                    ? 'Remove'
                    : 'Discharge'}
                </button>
                <div className={styles.rightButtons}>
                  <button className={styles.closeBtn} type='button' onClick={() => setShow(false)}>
                    Close
                  </button>
                  <button className={styles.submitBtn} type='submit'>
                    Save Changes
                  </button>
                </div>
              </div>
            ) : (
              <div className={styles.bottomButtons}>
                {step === 1 ? (
                  <button className={styles.closeBtn} type='button' onClick={() => setShow(false)}>
                    Cancel
                  </button>
                ) : (
                  <button className={styles.closeBtn} type='button' onClick={() => setStep(step - 1)}>
                    Back
                  </button>
                )}
                {step === 5 && (
                  <button className={styles.cancelBtnRight} type='submit' onClick={() => setShow(false)}>
                    Cancel
                  </button>
                )}
                <button className={styles.submitBtn} type='submit'>
                  {step === formSteps ? 'Finish' : 'Next'}
                </button>
              </div>
            )}
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default UpsertClientContainer;
