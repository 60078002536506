import React from 'react';
import styles from './filter-checkbox.module.scss';

type Props = {
  name: string;
  checked: boolean;
  field: string;
  handleOnChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
};

const FilterCheckbox: React.FC<Props> = ({ name, checked, field, handleOnChange }): JSX.Element => {
  return (
    <label className={styles.labelCheck}>
      <input type='checkbox' name={name} checked={checked} onChange={handleOnChange} />
      <span />
      {field}
    </label>
  );
};

export default FilterCheckbox;
