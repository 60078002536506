import React, { FunctionComponent, useEffect, useState, useContext } from 'react';
import { loading, notAsked, RemoteData } from '../../../utils/remote-data';
import DatePicker, { DateRange } from '../../common/date-picker/date-range-picker';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
// these need to be results for Meeting
import { BacResult } from '../../../interfaces/bac-result';
import styles from './meetings.module.scss';
import { DropDown } from '../../dropdown';
import TableEmpty from '../../common/table/table-empty';
import MeetingsTable from './meetings-table';
import { getMeetings, getMeetingDetails } from '../../../services/api/meeting';
import { Meeting, Meetings as MeetingsDto } from '../../../interfaces/meeting';
import UpsertMeetingForm from './modals/upsert-meeting-form';
import DeleteMeetingForm from './modals/delete-meeting-form';
import Modal from '../../modal/Modal';
import MeetingLocation from './modals/meeting-location';
import MeetingDialog from './modals/meeting-dialog';
import { ReactComponent as DownloadButton } from '../../../assets/icons/icn_download.svg'
import PdfConfirmationModal from '../../pdf/pdf-confirmation-modal';
import { getPdfFileName } from '../../../utils/pdf-helper';
import { getPatientTimezone, PatientContext } from '../../../contexts/PatientContext';
// need this to be for meetings
import MeetingTablePdf from '../../pdf/tables/meetings/meetings-table-pdf';
import { PractitionerContext } from '../../../contexts/Practitioner';
import { getOrganizationImageAsBase64 } from '../../../services/api/report';






const Meetings: FunctionComponent = () => {
  const [meetings, setMeetings] = useState<RemoteData<MeetingsDto>>(loading());
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    key: 'selection',
    color: '#417EB9',
    showDateDisplay: true,
    autoFocus: true,
  });
  const [showUpsertMeetingModal, setShowUpsertMeetingModal] = useState<boolean>(false);
  const [showRemoveMeetingModal, setShowRemoveMeetingModal] = useState<boolean>(false);
  const [showPastMeetingDialog, setShowPastMeetingDialog] = useState<boolean>(false);
  const [selectedMeeting, setSelectedMeeting] = useState<RemoteData<Meeting>>(notAsked());
  const [selectedMeetingId, setSelectedMeetingId] = useState<string>('');
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const patientTimezone = getPatientTimezone();
  const { patient } = useContext(PatientContext);
  const { id }: { id: string } = useParams();
  const { practitioner } = useContext(PractitionerContext);
  const [organizationLogo, setOrganizationLogo] = useState<string>('');
  const [results, setResults] = useState<RemoteData<{ values: BacResult[] }>>(loading());


  const requestMeetings = async () => {
    setMeetings(loading());
    const res = await getMeetings(id!, {
      startDate: moment(dateRangeFilter.startDate).utc().format('YYYY-MM-DD'),
      endDate: moment(dateRangeFilter.endDate).format('YYYY-MM-DD'),
      timezone: moment.tz.guess(),
    });
    setMeetings(res);
  };


  const getMeetingDetailAction = async (meetingId: string) => {
    setSelectedMeeting(loading());
    const res = await getMeetingDetails(meetingId, id);
    setSelectedMeeting(res);
  };

  const onDateChange = async (item: any): Promise<void> => {
    setDateRangeFilter(item.selection);
  };

  const getBlobFromLogoURL = async () => {
    const base64Image = await getOrganizationImageAsBase64(practitioner!.organization.theme.images.logo);
    setOrganizationLogo(`data:image/png;base64,${base64Image}`);
  };

  const showMeetingDetails = async (selectedMeetingId: string, futureMeeting: boolean): Promise<void> => {
    await getMeetingDetailAction(selectedMeetingId);
    if (futureMeeting) {
      setShowUpsertMeetingModal(true);
    } else {
      setShowPastMeetingDialog(true);
    }
  };

  useEffect(() => {
    getBlobFromLogoURL()
  }, [])

  const getTableAsPDF = () => {
    const meetingResults: Meeting[] = meetings.status === 'Done' ? meetings.data.values : [];

    meetingResults.sort((bacA, bacB) => {
      return moment(bacB.timestamp).unix() - moment(bacA.timestamp).unix();
    });
    
    return (
      <MeetingTablePdf
        dateRange={{
          start: moment(dateRangeFilter.startDate).format(),
          end: moment(dateRangeFilter.endDate).format(),
          timestamp: moment().format(),
        }}
        timezone={patientTimezone}
        patient={patient}
        practitioner={practitioner}
        meetingList={meetingResults}
        organizationLogo={organizationLogo}
      />
    );
  };

  useEffect(() => {
    (async (): Promise<void> => {
      await requestMeetings();
    })();
  }, [dateRangeFilter]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (selectedMeetingId) {
        await getMeetingDetailAction(selectedMeetingId);
      }
    })();
  }, [selectedMeetingId]);

  useEffect(() => {
    if (!showUpsertMeetingModal && !showRemoveMeetingModal && !showPastMeetingDialog) {
      setSelectedMeeting(notAsked());
      setSelectedMeetingId('');
    }
  }, [showUpsertMeetingModal, showRemoveMeetingModal, showPastMeetingDialog]);
  return (
    <div>
      <header className={styles.header} style={{borderRadius: 20}}>
        <nav>
          <ul className={styles.navRight}>
            <li className={styles.options}>
              <DownloadButton onClick={() => {
                setShowPdf(true)
                }} />
            </li>
            <li className={styles.options}>
              <button className={styles.button} onClick={() => setShowUpsertMeetingModal(true)}>
                Add Event
              </button>
            </li>
            <li className={styles.options}>
              <DropDown contentStyle='filter'>
                <span className={styles.dropdownButton}>
                  {moment(dateRangeFilter.startDate).format('MM/DD/YYYY')} -{' '}
                  {moment(dateRangeFilter.endDate).format('MM/DD/YYYY')}
                  <i className={styles.arrow}>
                    <svg width='14' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M13 1L7 7L1 1' stroke='#417EB9' strokeWidth='1.5' strokeLinecap='round' />
                    </svg>
                  </i>
                </span>
                <>
                  <DatePicker
                    onDateSelectionChange={onDateChange}
                    dateRange={dateRangeFilter}
                    parentStyle={styles.filterDatepicker}
                  />
                </>
              </DropDown>
            </li>
          </ul>
        </nav>
      </header>
      {meetings.status === 'Done' && !meetings.data.size ? (
        <TableEmpty>
          <p>No events scheduled for this client yet</p>
          <p className={styles.noClientsSub}>
            <button className={styles.button} onClick={() => setShowUpsertMeetingModal(true)}>
              Add Meeting
            </button>
          </p>
        </TableEmpty>
      ) : (
        <MeetingsTable
          meetingsList={meetings}
          setSelectedMeeting={setSelectedMeetingId}
          setShowDeleteModal={setShowRemoveMeetingModal}
          setShowMeetingModal={showMeetingDetails}
        />
      )}
      {showUpsertMeetingModal && (
        <UpsertMeetingForm
          setShow={setShowUpsertMeetingModal}
          editMode={selectedMeeting.status === 'Done'}
          getMeetingAction={requestMeetings}
          selectedMeeting={selectedMeeting}
        />
      )}
      {showRemoveMeetingModal && (
        <DeleteMeetingForm
          setShow={setShowRemoveMeetingModal}
          selectedMeeting={selectedMeeting}
          getMeetingAction={requestMeetings}
        />
      )}
      {showPastMeetingDialog && (
        <MeetingDialog
          setShow={setShowPastMeetingDialog}
          selectedMeeting={selectedMeeting}
          patientId={id}
          getMeetingAction={requestMeetings}
        />
      )}
      {selectedMeeting.status === 'Done' &&
        !(showUpsertMeetingModal || showRemoveMeetingModal || showPastMeetingDialog) && (
          <Modal
            contentStyle='bigContent__static'
            show={
              selectedMeeting.status === 'Done' &&
              !!selectedMeetingId &&
              !(showUpsertMeetingModal || showRemoveMeetingModal || showPastMeetingDialog)
            }
            showBtnClose
            closeModal={() => {
              setSelectedMeetingId('');
              setSelectedMeeting(notAsked());
            }}
          >
            {selectedMeeting.status === 'Done' && <MeetingLocation meeting={selectedMeeting.data} />}
          </Modal>
        )}
        {showPdf && (
        <PdfConfirmationModal
          title={'EVENT'}
          getPdfComponent={getTableAsPDF}
          filename={getPdfFileName({
            firstName: patient!.name.first,
            lastName: patient!.name.last,
            startDate: dateRangeFilter.startDate!,
            endDate: dateRangeFilter.endDate!,
            pdfTitle: 'EVENT',
          })}
          show={showPdf}
          hide={() => setShowPdf(false)}
        />
      )}
    </div>
  );
};

export default Meetings;
