import React from 'react';

export const InsightGood = props => {
  return (
    <svg
      style={props.style}
      width={props.width}
      height={props.height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='10' cy='10' r='9.25' fill='white' stroke='#323335' strokeWidth='1.5' />
      <path
        d='M8.99283 14.0271L8.99247 14.0268L5.96763 10.9155L5.96758 10.9154C5.74564 10.687 5.74379 10.3183 5.96417 10.0879M8.99283 14.0271L9.41378 13.9996C9.51738 13.9957 9.61455 13.9476 9.68261 13.8662L9.83577 13.9948L9.83586 13.9947C9.83591 13.9947 9.83596 13.9946 9.83602 13.9945L15.0608 7.77313C15.0608 7.77311 15.0608 7.77309 15.0608 7.77307C15.2656 7.52937 15.2421 7.16125 15.0055 6.94731L15.0053 6.94714C14.7642 6.72964 14.3969 6.75681 14.1892 7.00505C14.1891 7.00514 14.189 7.00523 14.1889 7.00532L9.36927 12.7448L6.78264 10.0843L6.78232 10.084C6.55625 9.85254 6.18837 9.85308 5.96417 10.0879M8.99283 14.0271C9.09969 14.1365 9.24572 14.2 9.39979 14.2C9.40263 14.2 9.4129 14.2001 9.42504 14.1993L8.99283 14.0271ZM5.96417 10.0879C5.96414 10.0879 5.9641 10.088 5.96406 10.088L6.10876 10.2261L5.96424 10.0878C5.96422 10.0879 5.9642 10.0879 5.96417 10.0879Z'
        fill='#323335'
        stroke='#323335'
        strokeWidth='0.4'
      />
    </svg>
  );
};
