import React, { FunctionComponent, useMemo } from 'react';
import styles from './geofence-location.module.scss';
import m from 'moment-timezone';
import Map from '../../../common/map/map';
import { Marker } from '@react-google-maps/api';
import { GeofenceType } from '../../../../interfaces/enums';
import SpecialGeofence from '../../../../assets/icons/special-geofence';
import NonCompliantGeofence from '../../../../assets/icons/non-compliant-geofence';
import { GeofenceLocation as GeofenceLocationDto } from '../../../../interfaces/geofence';
import specialIcon from '../../../../assets/icons/icn-special.svg';
import nonCompliantIcon from '../../../../assets/icons/icn_map_pin_non_compliant.svg';
import { getPatientTimezone } from '../../../../contexts/PatientContext';

type Props = {
  geofenceLocation: GeofenceLocationDto;
};

const GeoLocation: FunctionComponent<Props> = ({ geofenceLocation }) => {
  const marker = useMemo(
    () => ({
      lat: geofenceLocation.geofencePoint.latitude,
      lng: geofenceLocation.geofencePoint.longitude,
    }),
    [],
  );
  const mapOptions: google.maps.MapOptions = useMemo(
    () => ({
      center: { lat: marker.lat, lng: marker.lng },
      clickableIcons: false,
      zoom: 17,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
      ],
    }),
    [],
  );
  const tz = getPatientTimezone();
  const parseTimeSpent = (timeSpent: number) => {
    const hours = Math.floor(timeSpent / 60);
    const minutes = timeSpent % 60;
    if (hours === 0) {
      return `${timeSpent % 60} minutes`;
    } else {
      return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${minutes > 0 ? `${minutes} min` : ''}`;
    }
  };

  return (
    <section className={styles.locationModal}>
      <div className={`${styles.modalHeader}`}>
        <h4>
          {geofenceLocation.name} -{' '}
          {m(geofenceLocation.timestamp).isValid() && m.utc(geofenceLocation.timestamp).tz(tz).format('lll')}
        </h4>
      </div>
      <div className={styles.locationDetail}>
        <div className={styles.box}>
          <span>Address: </span>
          {geofenceLocation.geofencePoint?.address}
        </div>
        <div className={styles.box}>
          {geofenceLocation.type === GeofenceType.Special ? <SpecialGeofence /> : <NonCompliantGeofence />}
        </div>
        <div className={styles.box}>
          <span>Time spent: </span>
          {parseTimeSpent(geofenceLocation.timeSpentMinutes)}
        </div>
        <div className={styles.box}>
          <span>Visited: </span>
          {geofenceLocation.monthVisitCount} {geofenceLocation.monthVisitCount > 1 ? 'times' : 'time'}
        </div>
      </div>
      <div className={styles.mapContainer}>
        <Map
          mapOptions={mapOptions}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          markers={[marker]}
          fitBounds={false}
        >
          <Marker
            key={`${marker.lng} ${marker.lat}`}
            position={marker}
            cursor='pointer'
            icon={{
              url: geofenceLocation.type === 'SPECIAL' ? specialIcon : nonCompliantIcon,
            }}
          />
        </Map>
      </div>
    </section>
  );
};

export default GeoLocation;
