import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment-timezone';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styles from './insights.module.scss';
import { DropDown } from '../dropdown';
import DatePicker, { DateRange } from '../common/date-picker/date-range-picker';
import InsightsTable from './insights-table';
import { Insights as InsightsRequest, Insight } from '../../interfaces/insight';
import { loading, notAsked, RemoteData } from '../../utils/remote-data';
import { getInsights, clearAlert, clearAllAlerts } from '../../services/api/insight';
import InsightDetailsModal from './insight-details-modal';
import { Practitioner } from '../../interfaces/practitioner';
import { getPractitioner } from '../../services/api/practitioner';
import { PatientContext } from '../../contexts/PatientContext';
import { PATIENT_STATUS } from '../../utils/constants';
import { getPatientStatus } from '../../utils/data-parser';
import { PractitionerContext } from '../../contexts/Practitioner';
import { firebaseSendCurrentScreen } from '../../utils/analytics';
import ClearAlertsModal from './clear-alerts-modal';
import SearchInput from '../common/table/search-input';
import { ReactComponent as DownloadButton } from '../../assets/icons/icn_download.svg';
import PdfConfirmationModal from '../pdf/pdf-confirmation-modal';
import { getPdfFileName } from '../../utils/pdf-helper';
import InsightTablePdf from '../pdf/tables/insight/insight-table-pdf';
import { getOrganizationImageAsBase64 } from '../../services/api/report';

export const Insights = () => {
  const { practitioner } = useContext(PractitionerContext);
  const { patient } = useContext(PatientContext);
  const { id } = useParams<{ id: string }>();
  const timezone = moment.tz.guess();
  const { addToast } = useToasts();
  const [insights, setInsights] = useState<RemoteData<InsightsRequest>>(notAsked());
  const [selectedInsight, setSelectedInsight] = useState<Insight | undefined>(undefined);
  const [selectedInsightPractitioner, setSelectedInsightPractitioner] = useState<RemoteData<Practitioner>>(notAsked());
  const [showClearAlertsModal, setShowClearAlertsModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredInsights, setFilteredInsights] = useState<InsightsRequest | undefined>(undefined);
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [organizationLogo, setOrganizationLogo] = useState<string>('');

  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    key: 'selection',
    color: '#417EB9',
    showDateDisplay: true,
    autoFocus: true,
  });

  useEffect(() => {
    if (practitioner) {
      firebaseSendCurrentScreen('InsightsPage', {
        practitionerId: practitioner?.id,
        organizationId: practitioner?.organization.id,
        firebaseUid: practitioner?.firebaseUid,
      });
      getBlobFromLogoURL();
    }
  }, [practitioner]);

  useEffect(() => {
    if (id && dateRangeFilter) {
      getInsightsAction(
        id!,
        moment(dateRangeFilter.startDate).utc().format('YYYY-MM-DD'),
        moment(dateRangeFilter.endDate).format('YYYY-MM-DD'),
      );
    }
  }, [id, dateRangeFilter]);

  useEffect(() => {
    if (selectedInsight && selectedInsight.clearedBy) {
      getPractitionerAction(selectedInsight.clearedBy);
    } else if (!selectedInsight) {
      setSelectedInsightPractitioner(notAsked());
    }
  }, [selectedInsight]);

  useEffect(() => {
    if (!searchValue) {
      setFilteredInsights(undefined);
    } else if (insights.status === 'Done') {
      const filterResult = insights.data.values.filter(insight =>
        [insight.title.toLowerCase(), getPatientStatus(insight.relapseRisk!).toLowerCase()].find(text =>
          text.includes(searchValue.toLowerCase()),
        ),
      );
      setFilteredInsights({ values: filterResult, size: filterResult.length });
    }
  }, [searchValue, insights]);

  const getBlobFromLogoURL = async () => {
    const base64Image = await getOrganizationImageAsBase64(practitioner!.organization.theme.images.logo);
    setOrganizationLogo(`data:image/png;base64,${base64Image}`);
  };

  const getInsightsAction = async (patientId: string, startDate: string, endDate: string): Promise<void> => {
    setInsights(loading());
    const res = await getInsights(patientId, {
      startDate,
      endDate,
      timezone,
    });
    setInsights(res);
  };

  const getPractitionerAction = async (practitionerId: string): Promise<void> => {
    setSelectedInsightPractitioner(loading());
    const res = await getPractitioner(practitionerId);
    setSelectedInsightPractitioner(res);
  };

  const clearAlertAction = async (patientId: string, insightId: number) => {
    setSelectedInsight(undefined);
    const res = await clearAlert(patientId, insightId);

    if (res.status === 'Done') {
      getInsightsAction(
        id!,
        moment(dateRangeFilter.startDate).utc().format('YYYY-MM-DD'),
        moment(dateRangeFilter.endDate).format('YYYY-MM-DD'),
      );
      addToast('Alert cleared', {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast(`Unable to clear Alert - Error: ${res.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const clearAllAlertsAction = async () => {
    const res = await clearAllAlerts(id!);
    if (res.status === 'Done') {
      getInsightsAction(
        id!,
        moment(dateRangeFilter.startDate).utc().format('YYYY-MM-DD'),
        moment(dateRangeFilter.endDate).format('YYYY-MM-DD'),
      );
      addToast('All alerts cleared', {
        appearance: 'success',
        autoDismiss: true,
      });
      setShowClearAlertsModal(false);
    } else {
      addToast(`Unable to clear alerts - Error: ${res.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const onDateChange = async (item: any): Promise<void> => {
    setDateRangeFilter(item.selection);
  };

  const getPatientStatusElement = (score: number): JSX.Element => {
    const status = getPatientStatus(score);
    if (status === PATIENT_STATUS.AT_RISK) {
      return <span className={styles.statusAtRisk}>AT RISK</span>;
    }
    if (status === PATIENT_STATUS.CRITICAL) {
      return <span className={styles.statusCritical}>CRITICAL</span>;
    }
    return <span className={styles.statusStable}>STABLE</span>;
  };

  const getTableAsPDF = (): JSX.Element => {
    const insightList: Insight[] = !!filteredInsights
      ? filteredInsights.values
      : insights.status === 'Done'
      ? insights.data.values
      : [];
    insightList.sort((insightA, insightB) => {
      return moment(insightB.endTimestamp).unix() - moment(insightA.endTimestamp).unix();
    });
    return (
      <InsightTablePdf
        dateRange={{
          start: moment(dateRangeFilter.startDate).format(),
          end: moment(dateRangeFilter.endDate).format(),
          timestamp: moment().format(),
        }}
        timezone={timezone}
        patient={patient}
        practitioner={practitioner}
        insights={insightList}
        organizationLogo={organizationLogo}
      />
    );
  };

  return (
    <section className={styles.contents}>
      <div className={styles.header}>
        <SearchInput
          searchValue={searchValue}
          handleInputChange={ev => setSearchValue(ev.target.value)}
          handleOnClick={() => setSearchValue('')}
          placeholder='Search Insight and Status...'
        />

        <div className={styles.rightItems}>
          <DropDown contentStyle='filter'>
            <span className={styles.dropdownButton}>
              {moment(dateRangeFilter.startDate).format('MM/DD/YYYY')} -{' '}
              {moment(dateRangeFilter.endDate).format('MM/DD/YYYY')}
              <i className={styles.arrow}>
                <svg width='14' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M13 1L7 7L1 1' stroke='#417EB9' strokeWidth='1.5' strokeLinecap='round' />
                </svg>
              </i>
            </span>
            <>
              <DatePicker
                onDateSelectionChange={onDateChange}
                dateRange={dateRangeFilter}
                parentStyle={styles.filterDatepicker}
              />
            </>
          </DropDown>
          <button
            type='button'
            className={`${styles.btn} ${styles.primary} ${styles.clearInsightsBtn}`}
            onClick={() => setShowClearAlertsModal(true)}
            onKeyPress={event => event.key === 'Enter' && setShowClearAlertsModal(true)}
          >
            Clear All Alerts
          </button>
          <DownloadButton onClick={() => setShowPdf(true)} />
        </div>
      </div>
      <InsightsTable
        insights={insights}
        filterResultInsights={filteredInsights}
        setSelectedInsight={setSelectedInsight}
        getPatientStatusElement={getPatientStatusElement}
      />
      <InsightDetailsModal
        insight={selectedInsight}
        hide={() => setSelectedInsight(undefined)}
        show={!!selectedInsight}
        practitioner={selectedInsightPractitioner}
        patient={patient}
        getPatientStatusElement={getPatientStatusElement}
        clearAlertAction={clearAlertAction}
      />
      {showClearAlertsModal && (
        <ClearAlertsModal
          show={showClearAlertsModal}
          hide={() => setShowClearAlertsModal(false)}
          clearAlertsAction={clearAllAlertsAction}
        />
      )}
      {showPdf && (
        <PdfConfirmationModal
          title={'Insights'}
          getPdfComponent={getTableAsPDF}
          filename={getPdfFileName({
            firstName: patient!.name.first,
            lastName: patient!.name.last,
            startDate: dateRangeFilter.startDate!,
            endDate: dateRangeFilter.endDate!,
            pdfTitle: 'Insight',
          })}
          show={showPdf}
          hide={() => setShowPdf(false)}
        />
      )}
    </section>
  );
};
