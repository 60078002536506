import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import styles from './admin-dashboard.module.scss';
import { PractitionerContext } from '../../contexts/Practitioner';
import ClinicDetails from './components/clinic-details';
import EditClinicDetails from './components/edit-clinic-details';
import { updateOrganization } from '../../services/api/organization';
import { Loading } from '../common/loading';
import { firebaseSendCurrentScreen } from '../../utils/analytics';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import PractitionerDeviceStatsContainer from './practitioner-device-stats-container';
import ClientStatsContainer from './client-stats-container';
import { Organization } from '../../graphql/codegen/schemas';
import AdminPreferences from './admin-preferences';

type SelectedTab = 'Client Stats' | 'Practitioner/Devices Stats' | 'Client Demographics' | 'Practitioner Usage' | 'Preferences';

export const AdminDashboard = (): JSX.Element => {
  const { addToast } = useToasts();
  const { practitioner } = useContext(PractitionerContext);
  const { organization, getOrganizationAction, loading } = useContext(OrganizationContext);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<SelectedTab>('Client Stats');

  const org = organization?.data?.org;

  const tabs = useMemo(
    () => [
      {
        label: 'Client Stats',
        onClickEvent: () => setSelectedTab('Client Stats'),
      },
      {
        label: 'Practitioner/Devices Stats',
        onClickEvent: () => setSelectedTab('Practitioner/Devices Stats'),
      },
      // {
      //   label: 'Preferences',
      //   onClickEvent: () => setSelectedTab('Preferences'),
      // },
      // removed per VHOP-1865
      // {
      //   label: 'Client Demographics',
      //   onClickEvent: () => setSelectedTab('Client Demographics'),
      // },
      // {
      //   label: 'Practitioner Usage',
      //   onClickEvent: () => setSelectedTab('Practitioner Usage'),
      // },
    ],
    [],
  );

  useEffect(() => {
    if (practitioner) {
      firebaseSendCurrentScreen('AdminDashboardPage', {
        practitionerId: practitioner?.id,
        organizationId: practitioner?.organization.id,
        firebaseUid: practitioner?.firebaseUid,
      });
    }
  }, [organization]);

  const updateOrganizationAction = async (organizationId: string, formResult: Organization): Promise<void> => {
    setShowLoading(true);
    const res = await updateOrganization(organizationId, formResult);
    setShowLoading(false);

    if (res.status === 'Done') {
      getOrganizationAction(organizationId);
      addToast('Organization updated successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      addToast(`Error: ${res.errorApi.message}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  // eslint-disable-next-line no-nested-ternary
  return org && !loading ? (
    <div className={styles.dashboardContainer}>
      <ClinicDetails setShowEditModal={setShowEditModal} organization={org} />

      <div className={styles.topRow}>
        {tabs.map(tab => (
          <button
            type='button'
            key={tab.label}
            className={selectedTab === tab.label ? styles.active : styles.inactive}
            onClick={tab.onClickEvent}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className={styles.clinicStats}>
        {selectedTab === 'Practitioner/Devices Stats' && <PractitionerDeviceStatsContainer />}
        {selectedTab === 'Client Stats' && <ClientStatsContainer />}
        {selectedTab === 'Preferences' && <AdminPreferences />}
      </div>
      <EditClinicDetails
        show={showEditModal}
        hide={() => setShowEditModal(false)}
        organization={org}
        updateOrganization={updateOrganizationAction}
      />
    </div>
  ) : (
    <div className={styles.loadingState}>
      {' '}
      <Loading />{' '}
    </div>
  );
};
