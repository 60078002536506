import React, { Dispatch, SetStateAction } from 'react';
import Modal from '../modal/Modal';
import { Practitioner } from '../../interfaces/practitioner';
import styles from './remove-practitioner.module.scss';
import { Loading } from '../common/loading';
import { displayFullName } from '../../utils/data-parser';

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  selectedPractitioner: Practitioner | undefined;
  removePractitioner: () => Promise<void>;
  showLoading: boolean;
};

const RemovePractitioner: React.FC<Props> = ({ setShow, selectedPractitioner, removePractitioner, showLoading }) => {
  return (
    <Modal show={true} closeModal={() => setShow(false)} showBtnClose={false} contentStyle='bigContent__static'>
      {showLoading ? (
        <div className={styles.loadingState}>
          <Loading />
        </div>
      ) : (
        <div className={styles.removeModal}>
          <div className={styles.header}>
            <span>VIEW PRACTITIONER</span>
          </div>
          <div className={styles.body}>
            <div className={styles.userInfo}>
              {selectedPractitioner?.image?.base64 ? (
                <img
                  className={styles.picturePlaceholder__loaded}
                  src={`data:image/jpeg;base64,${selectedPractitioner?.image?.base64}`}
                />
              ) : (
                <div className={styles.picturePlaceholder} />
              )}
              <span>{displayFullName(selectedPractitioner?.name!)}</span>
            </div>
            <div className={styles.role}>
              {selectedPractitioner?.roles.roles.map((r, i) => (
                <span key={i}>{r.display}</span>
              ))}
            </div>
            <span className={styles.confirmationText}>Are you sure you would like to remove this practitioner?</span>
            <span className={styles.footNote}>Note: this cannot be undone.</span>
          </div>
          <div className={styles.buttons}>
            <button className={styles.closeBtn} onClick={() => setShow(false)}>
              Cancel
            </button>
            <button className={styles.submitBtn} onClick={() => removePractitioner()}>
              Remove Practitioner
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default RemovePractitioner;
