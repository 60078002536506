import React, { Fragment, useEffect, useState } from 'react';
import styles from '../practitioner-device-stats-container.module.scss';
import { Maybe, PractitionerClientAssignment } from '../../../graphql/codegen/schemas';

type ReportRow = {
  name: string;
  id: string;
  countToday: number;
  countLastWeek: number;
  countLastMonth: number;
};

type Props = {
  reportResult: {
    today: Array<Maybe<PractitionerClientAssignment>>;
    lastWeek: Array<Maybe<PractitionerClientAssignment>>;
    lastMonth: Array<Maybe<PractitionerClientAssignment>>;
  };
};
const ClientAssignedStats = ({ reportResult }: Props) => {
  const [practitionerClientsList, setPractitionerClientsList] = useState<ReportRow[]>([]);

  useEffect(() => {
    if (reportResult) {
      const list: ReportRow[] = [];
      for (const item of reportResult.today) {
        const resObj: ReportRow = {
          id: item?.practitioner.id!,
          name: item?.practitioner.name?.first ?? '',
          countToday: item?.count!,
          countLastWeek: 0,
          countLastMonth: 0,
        };
        const practitionerLastWeek = reportResult.lastWeek.find(value => value?.practitioner.id === resObj.id);
        const practitionerLastMonth = reportResult.lastMonth.find(value => value?.practitioner.id === resObj.id);
        list.push({
          ...resObj,
          countLastWeek: practitionerLastWeek?.count!,
          countLastMonth: practitionerLastMonth?.count!,
        });
      }
      setPractitionerClientsList(list);
    }
  }, [reportResult]);

  return (
    <div className={styles.statsCard}>
      <span className={styles.title}>Total # Clients Assigned</span>
      <div className={`${styles.dataRow}  ${styles.clientAssignedTable}`}>
        <strong>Practitioner</strong>
        <strong>Total Today</strong>
        <strong>Last Week</strong>
        <strong>Last Month</strong>
      </div>
      <div className={styles.tableBody}>
        {practitionerClientsList.map(value => (
          <div className={`${styles.dataRow}  ${styles.clientAssignedTable}`}>
            <Fragment key={value.id}>
              <span>{value.name}</span>
              <span>{value.countToday}</span>
              <span>{value.countLastWeek}</span>
              <span>{value.countLastMonth}</span>
            </Fragment>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientAssignedStats;
