import React from 'react';

export const Insight = props => {
  return (
    <svg
      style={props.style}
      width={props.width}
      height={props.height}
      viewBox='0 0 23 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5365 1.13514L22.6884 16.5946C23.5759 18.1081 22.4666 20 20.6362 20H2.33231C0.557393 20 -0.607399 18.1081 0.335527 16.5946L9.48746 1.13514C10.3749 -0.378378 12.649 -0.378378 13.5365 1.13514ZM12.3162 1.83784C11.9834 1.18919 11.0405 1.18919 10.7077 1.83784L1.55579 17.2973C1.16752 17.8919 1.61125 18.6486 2.33231 18.6486H20.6362C21.4127 18.6486 21.8564 17.8919 21.4682 17.2973L12.3162 1.83784Z'
        fill={props.color || '#ED6F6A'}
      >
        {props.alert && (
          <animate attributeName='fill' values={`${props.flashColor};#323335`} dur='1s' repeatCount='indefinite' />
        )}
      </path>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4834 5.45959C11.9272 5.45959 12.3154 5.78392 12.3154 6.21635V12.4326C12.3154 12.865 11.9272 13.1893 11.4834 13.1893C11.0952 13.1893 10.7069 12.865 10.7069 12.4326V6.21635C10.7069 5.78392 11.0952 5.45959 11.4834 5.45959Z'
        fill={props.color || '#ED6F6A'}
      >
        {props.alert && (
          <animate attributeName='fill' values={`${props.flashColor};#323335`} dur='1s' repeatCount='indefinite' />
        )}
      </path>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4818 14.1074C12.0365 14.1074 12.4802 14.5399 12.4802 15.0804C12.4802 15.621 12.0365 16.0534 11.4818 16.0534C10.9271 16.0534 10.4834 15.621 10.4834 15.0804C10.4834 14.5399 10.9271 14.1074 11.4818 14.1074Z'
        fill={props.color || '#ED6F6A'}
      >
        {props.alert && (
          <animate attributeName='fill' values={`${props.flashColor};#323335`} dur='1s' repeatCount='indefinite' />
        )}
      </path>
    </svg>
  );
};
