import { castDateTimeFilters, DateTimeFiltersRequest } from '../../utils/requestHelper';
import { done, failed, RemoteData } from '../../utils/remote-data';
import { get, post } from './base';
import { CowsSurvey, Surveys, CiwaSurvey } from '../../interfaces/survey';

export const getSurveys = async (
  patientId: string,
  dateFilters: DateTimeFiltersRequest,
): Promise<RemoteData<Surveys>> => {
  try {
    const res = await get(`/survey-responses?patientId=${patientId}`, {
      params: {
        ...castDateTimeFilters(dateFilters),
      },
    });
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const submitCowData = async (patientFbUserId: string, cowData: CowsSurvey): Promise<RemoteData<any>> => {
  // Adjust the return type as necessary
  try {
    const res = await post(`/clinician/patient/${patientFbUserId}/cow?patientFbUserId=${patientFbUserId}`, cowData);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};

export const submitCiwaData = async (patientFbUserId: string, ciwaData: CiwaSurvey): Promise<RemoteData<any>> => {
  try {
    const res = await post(`/clinician/patient/${patientFbUserId}/ciwa`, ciwaData);
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};
