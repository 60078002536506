import React, { useState, useRef, useEffect } from 'react';
import styles from './dropdown.module.scss';

export const DropDown = props => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const wrapperRef = useRef<any>(null);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleChildComponentClick = props.hideDropdownOnClick ? () => setIsVisible(!isVisible) : () => {};

  return (
    <div style={props.style} ref={wrapperRef} className={styles.outerContainer}>
      <div onClick={() => setIsVisible(!isVisible)} className='triggerContainer'>
        {props.children[0]}
      </div>
      {isVisible && (
        <div className={`${styles.dropdownContent} ${styles[props.contentStyle]}`} onClick={handleChildComponentClick}>
          {props.children[1]}
        </div>
      )}
    </div>
  );
};
