import moment from 'moment-timezone';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { PatientContext } from '../../../contexts/PatientContext';
import { BacSchedule } from '../../../interfaces/bac-schedule';
import { ScheduleType } from '../../../interfaces/enums';
import { deletePatientBacSchedule, getPatientBacSchedule } from '../../../services/api/bac';
import styles from './bac-schedule.module.scss';
import ScheduleForm from './schedule-form';

type Props = {};

const Bac_Schedule: FunctionComponent<Props> = props => {
  const { addToast } = useToasts();
  const { id } = useParams<{ id: string }>();
  const { patient } = useContext(PatientContext);
  const timezone = moment.tz.guess();
  const [schedule, setSchedule] = useState<BacSchedule>();
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleType | undefined>(undefined);
  const [expanded, setExpanded] = React.useState<{
    random: boolean;
    schedule: boolean;
  }>({
    random: false,
    schedule: false,
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await getPatientBacSchedule(id!);
        setSchedule({
          ...response.data,
          startWindow: parseTime(response.data.startWindow),
          endWindow: parseTime(response.data.endWindow),
        });
        setSelectedSchedule(response.data.type);
        const isSchedule = response.data.type === ScheduleType.Interval;
        setExpanded({ schedule: isSchedule, random: !isSchedule });
      } catch (err) {
        setSelectedSchedule(ScheduleType.None);
        console.error(err);
      }
    })();
  }, [id]);

  const parseTime = (time: string): string => moment(time, ['HH.mm']).format('HH:mm');

  const handleChange = (random: boolean, scheduleBac: boolean): void => {
    setExpanded({ random, schedule: scheduleBac });
    setSelectedSchedule(random ? ScheduleType.Random : scheduleBac ? ScheduleType.Interval : ScheduleType.None);
  };

  const deleteBacSchedule = async (event): Promise<void> => {
    try {
      event.preventDefault();
      await deletePatientBacSchedule(id!);
      addToast('Schedule Deleted', {
        appearance: 'success',
        autoDismiss: true,
      });
      setSchedule(undefined);
    } catch (e) {
      console.error(e);
    }
  };

  const updateBacSchedule = (bacSchedule: BacSchedule): void => {
    setSchedule({
      ...bacSchedule,
      startWindow: parseTime(bacSchedule.startWindow),
      endWindow: parseTime(bacSchedule.endWindow),
    });
  };

  return (
    <>
      <ScheduleForm
        scheduleType={ScheduleType.Random}
        selectedType={selectedSchedule}
        schedule={schedule}
        expanded={expanded.random}
        title='Random BAC Test'
        handleChange={ev => handleChange(ev.currentTarget.checked, false)}
        onCloseArrow={() => handleChange(!expanded.random, false)}
        onCloseButton={() => handleChange(false, expanded.schedule)}
        updateSchedule={updateBacSchedule}
        timezone={timezone}
      />

      <ScheduleForm
        scheduleType={ScheduleType.Interval}
        selectedType={selectedSchedule}
        schedule={schedule}
        expanded={expanded.schedule}
        title='Scheduled BAC Test'
        handleChange={ev => handleChange(false, ev.currentTarget.checked)}
        onCloseArrow={() => handleChange(false, !expanded.schedule)}
        onCloseButton={() => handleChange(expanded.random, false)}
        updateSchedule={updateBacSchedule}
        timezone={timezone}
      />

      <div className={styles.collapseContainer}>
        <div className={`${styles.collapseHeader} clearfix`}>
          <ul className='header-content'>
            <li>
              <label className={styles.container}>
                No BAC Test
                <input
                  type='radio'
                  checked={selectedSchedule === ScheduleType.None}
                  name='schedule'
                  onChange={e => handleChange(false, false)}
                />
                <span className={styles.checkmark}></span>
              </label>
            </li>
          </ul>
        </div>
        {schedule?.id && (
          <section
            className={`${styles.collapseInnerContent} ${styles.deleteBacContent} ${
              selectedSchedule !== ScheduleType.None ? styles.collapsed : ''
            }`}
          >
            <div
              className={`${styles.formContent} ${
                selectedSchedule !== ScheduleType.None ? styles.collapsed : ''
              } clearfix`}
            >
              <h1 className='title'>
                Are you sure you want to delete {patient?.name.first} {patient?.name.last} BAC schedule ?{' '}
              </h1>
              <div className={`${styles.rowButtons}`}>
                <a className={styles.saveButton} onClick={event => deleteBacSchedule(event)}>
                  Delete Schedule
                </a>
                <a className={styles.cancelButton} onClick={() => handleChange(false, false)}>
                  Cancel
                </a>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default Bac_Schedule;
