import React from 'react';
import { TextInput, PhoneInput } from '../../common/form';
import styles from '../upsert-client.module.scss';

const EmergencyContacts = () => {
  return (
    <div className={styles.body}>
      <div className={styles.emergencySection}>
        <TextInput name='emergencyContact.name' label='Emergency contact:' />
        <PhoneInput name='emergencyContact.phone' label='Emergency contact phone:' />
      </div>
      <div className={styles.emergencySection}>
        <TextInput name='primaryCareContact.name' label='Primary care physician:' />
        <PhoneInput name='primaryCareContact.phone' label='Primary care physician phone:' />
      </div>
    </div>
  );
};

export default EmergencyContacts;
