import React from 'react';
import styles from './form.module.scss';
import { Field, ErrorMessage } from 'formik';

type Props = {
  name: string;
  field: string;
  value: string;
  displayError: boolean;
};

const RadioButton: React.FC<Props> = ({ name, field, value, displayError }) => {
  return (
    <label className={styles.labelRadio}>
      <Field type='radio' name={name} value={value} />
      <span></span>
      {field}
      {displayError && <ErrorMessage name={name} />}
    </label>
  );
};

export default RadioButton;
