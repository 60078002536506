import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import styles from './form.module.scss';

type Props = {
  label: string;
  name: string;
  filesAccept: string;
  file: File | string | undefined;
  setFile: Dispatch<SetStateAction<File | string | undefined>>;
  fileLimitMB: number;
  fileType: string;
};

export const FileInput: React.FC<Props> = ({ label, name, filesAccept, file, setFile, fileLimitMB, fileType }) => {
  const [fileError, setFileError] = useState<string>('');

  useEffect(() => {
    if (file) {
      if (file instanceof File) {
        const reader = new FileReader();
        reader.onload = e => {
          document.querySelector('#userPhoto')?.setAttribute('src', e.target?.result as string);
        };

        reader.readAsDataURL(file);
      }
    }
  }, [file]);

  const addFile = file => {
    setFile(undefined);
    setFileError('');

    const mbLimit = fileLimitMB;
    const byteLimit = mbLimit * 1024 * 1024;

    if (file.size > byteLimit) {
      setFileError('File size limit is 5 MB.');
    } else {
      setFile(file);
    }
  };

  const handleClickOnFileUpload = event => {
    event.preventDefault();
    document.getElementById('uploadFileAnchor')?.click();
  };

  return (
    <div className={styles.field}>
      <label htmlFor={name}>{label}</label>
      <input
        id='uploadFileAnchor'
        className={styles.uploadFileAnchor}
        type='file'
        accept={filesAccept}
        onChange={e => e.currentTarget.files && addFile(e.currentTarget.files[0])}
      />
      {!file ? (
        <div className={styles.uploadFilePlaceholder}>
          {fileType === 'photo' && <div className={styles.picturePlaceholder__big} />}
          <span className={styles.uploadFileLink} onClick={e => handleClickOnFileUpload(e)}>
            Upload {fileType}
          </span>
        </div>
      ) : (
        <div className={styles.uploadFilePlaceholder}>
          {fileType === 'photo' && (
            <img
              id='userPhoto'
              className={styles.picturePlaceholder__loaded}
              src={file ? `data:image/jpeg;base64,${file}` : '#'}
            />
          )}
          <span className={styles.uploadFileLink} onClick={() => setFile(undefined)}>
            Delete {fileType}
          </span>
        </div>
      )}
      {fileError && <div className={styles.error}>{fileError}</div>}
    </div>
  );
};

export default FileInput;
