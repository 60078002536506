import { analytics } from '../firebase/firebase';

type FirebaseScreenLogEvent = {
  practitionerId: string;
  organizationId: string;
  firebaseUid: string;
};

export const firebaseSendCurrentScreen = (screenName: string, eventData: FirebaseScreenLogEvent): void => {
  analytics.logEvent('page_view', {
    page_title: screenName,
    ...eventData,
  });
};
